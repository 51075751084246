import { withRouter } from 'react-router-dom'
import { StyleSheet, View, ScrollView } from 'react-native'
import React from 'react'

const VideoPlayerForm = props => {
  const videoId = props.videoId
  if (props.fit) {
    return (
      <View style={{ alignItems: 'center', margin: 20 }}>
        <iframe
          title={videoId}
          src={`https://player.vimeo.com/video/${videoId}?title=0&portrait=0&byline=0`}
          width="640"
          height="360"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        ></iframe>
      </View>
    )
  }
  if (props.width && props.height) {
    return (
      <View style={{ alignItems: 'center', margin: 20 }}>
        <iframe
          title={videoId}
          src={`https://player.vimeo.com/video/${videoId}?title=0&portrait=0&byline=0`}
          width={props.width}
          height={props.height}
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        ></iframe>
      </View>
    )
  }
  return (
    <ScrollView contentContainerStyle={styles.videoContainer}>
      <View style={{ margin: 'auto', alignItems: 'center' }}>
        <iframe
          title={videoId}
          src={`https://player.vimeo.com/video/${videoId}?title=0&portrait=0&byline=0`}
          width="640"
          height="360"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        ></iframe>
      </View>
    </ScrollView>
  )
}
const styles = StyleSheet.create({
  videoContainer: {
    width: '100%',
    height: '100%',
  },
})
export default withRouter(VideoPlayerForm)
