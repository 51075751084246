import React, { Component } from 'react'
import { client } from './services/ardent-api/client'
import { ApolloProvider } from 'react-apollo'
import { BrowserRouter } from 'react-router-dom'
import { Main } from './web/components/'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { muiTheme } from './config'
import { CookiesProvider } from 'react-cookie'

class App extends Component {
  render() {
    return (
      <ApolloProvider client={client}>
        <MuiThemeProvider theme={muiTheme}>
          <BrowserRouter forceRefresh={true}>
            <CookiesProvider>
              <Main />
            </CookiesProvider>
          </BrowserRouter>
        </MuiThemeProvider>
      </ApolloProvider>
    )
  }
}

export default App
