import gql from 'graphql-tag'

export const CREATE_FAMILY_ACCOUNT = gql`
  mutation CreateFamilyAccount($input: CreateFamilyAccountInput!) {
    createFamilyAccount(input: $input) {
      id
    }
  }
`

export const CREATE_FAMILY_MEMBER = gql`
  mutation CreateFamilyMember($input: CreateFamilyMemberInput!) {
    createFamilyMember(input: $input) {
      id
    }
  }
`
export const CREATE_STUDENT_ACCOUNT = gql`
  mutation CreateStudentAccount($input: CreateStudentInput!) {
    createStudent(input: $input) {
      id
    }
  }
`

export const UPDATE_FAMILY_ACCOUNT = gql`
  mutation UpdateFamilyAccount($id: ID!, $input: UpdateFamilyAccountInput!) {
    updateFamilyAccount(id: $id, input: $input) {
      id
    }
  }
`

export const COUNT_FAMILY_MEMBERS = gql`
  query countFamilyMembers($filter: Filters) {
    countFamilyMembers(filter: $filter)
  }
`
