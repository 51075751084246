import React, { Component } from 'react'
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native'
import Modal from 'modal-react-native-web'
import solveColors from '../../components/colors'
import CloseIcon from '@material-ui/icons/Close'
// import StudentForm from './StudentForm'
import ParentForm from './ParentForm'
import { withRouter } from 'react-router-dom'

class RegisterPage extends Component {
  onSuccessHandler = () => {
    this.props.history.push(
      this.props.redirectTo ? this.props.redirectTo : '/posts'
    )
  }

  render() {
    return (
      <Modal
        visible={this.props.visible}
        transparent={true}
        animationType="fade"
        appElement={
          document.getElementById('app') || document.getElementById('root')
        }
        // onShow={() => this.textInput.current.focus()}
      >
        <View style={styles.backdrop}></View>
        <View style={styles.results}>
          <View style={styles.resultTopBar}>
            <Text style={{ color: 'white' }}>Join</Text>
            <TouchableOpacity
              onPress={this.props.close}
              style={styles.exitButton}
            >
              <CloseIcon htmlColor="white" />
            </TouchableOpacity>
          </View>
          <View style={styles.registerContainer}>
            <ParentForm onSuccess={this.onSuccessHandler} />
          </View>
        </View>
      </Modal>
    )
  }
}
export default withRouter(RegisterPage)
const styles = StyleSheet.create({
  results: {
    borderRadius: '10px',
    margin: 'auto',
    backgroundColor: 'white',
    flexDirection: 'column',
    maxWidth: '360px',
    width: '100%',
  },
  resultTopBar: {
    height: '32px',
    backgroundColor: solveColors.blue1,
    borderTopRightRadius: '10px',
    borderTopLeftRadius: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px',
  },
  backdrop: {
    position: 'absolute',
    backgroundColor: 'rgba(52, 52, 52, 0.2)',
    height: '100%',
    width: '100%',
  },
  registerBtn: {
    width: 100,
    height: 40,
    color: 'white',
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: solveColors.blue5,
  },

  registerContainer: {
    margin: '16px',
  },

  exitButton: {
    padding: '5px',
  },
})
