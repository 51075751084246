import { Button } from '@material-ui/core'
import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { View, Text, ScrollView } from 'react-native-web'
import { GET_ENROLLMENT_DETAILS } from '../../../services/queries/reports_querie'
import { getGradeColor } from '../../../utils/grades'
import GradebookForm from '../../components/GradebookForm'
import LoadingPage from '../Loading/LoadingPage'

export default class EnrollmentDetails extends Component {
  render() {
    if (!this.props.enrollmentId) {
      return null
    }

    return (
      <ScrollView
        style={{
          width: '100%',
          height: '100%',
          zIndex: 999,
          padding: '16px',
        }}
      >
        <Query
          query={GET_ENROLLMENT_DETAILS}
          variables={{
            id: this.props.enrollmentId,
            filter: { studentId: this.props.studentId },
          }}
        >
          {({ data, loading, error, refetch }) => {
            if (loading) return <LoadingPage />
            if (error) return `${error}`
            const lessons = data.enrollment.classroom.lessons
            lessons.sort((a, b) => a.lessonPlan.order - b.lessonPlan.order)

            // const lessonGrades = data.enrollment.attendees.map(
            //   a => ({
            //     lessonId: a.lesson.id,
            //     overallGrade: a.overallGrade,
            //   })
            // )
            let lessonGrades = []

            for (let lesson of lessons) {
              if (lesson.attendees.length === 0) {
                lessonGrades.push({ lessonId: lesson.id, overallGrade: 0 })
              } else {
                lessonGrades.push({
                  overallGrade: lesson.attendees[0].overallGrade,
                  lessonId: lesson.id,
                  percentCompleted: lesson.attendees[0].percentCompleted,
                })
              }
            }

            return (
              <View>
                <Button
                  onClick={this.props.close}
                  color="primary"
                  variant="contained"
                  style={{ position: 'absolute', alignSelf: 'flex-start' }}
                >
                  {'< Back'}
                </Button>
                <View
                  style={{
                    alignSelf: 'center',
                    alignItems: 'center',
                    padding: '16px',
                    fontSize: 'medium',
                  }}
                >
                  <Text style={{ fontSize: 'inherit' }}>
                    {data.enrollment.classroom.code}
                  </Text>
                  <Text
                    style={{
                      color: getGradeColor(data.enrollment.overallGrade),
                      fontSize: 32,
                    }}
                  >
                    {data.enrollment.overallGrade + '%'}
                  </Text>
                  <Text style={{ textAlign: 'center', fontSize: 'inherit' }}>
                    {data.enrollment.classroom.title}
                  </Text>
                </View>
                <GradebookForm
                  lessons={lessons}
                  lessonGrades={lessonGrades}
                  studentId={this.props.studentId}
                />
              </View>
            )
          }}
        </Query>
      </ScrollView>
    )
  }
}
