import gql from 'graphql-tag'

export const GET_CLASSROOM_INFO = gql`
  query GetClassroomInfo($id: ID!) {
    classroom(id: $id) {
      course {
        title
        description
        longDescription
        endGrade
        startGrade
        imageUrl
      }
      title
      startDate
      endDate
      startTime
      endTime
      quarter
      teachingStyle
      meets
      leadInstructor
      instructors {
        displayName
      }
      lessons(
        filter: { lessonPlan: { lessonPlanType: "INSTRUCTION" } }
        sort: { lessonPlan: { order: 1 } }
      ) {
        id
        lessonPlan {
          title
          order
        }
        startOn
      }
    }
  }
`
