import React from 'react'
import {
  View,
  TouchableOpacity,
  Text,
  StyleSheet,
  TextInput,
} from 'react-native-web'
import { debounce } from 'lodash'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'

import { DialogModal } from './../../components'
import solveColors from './../../components/colors'

import eraserImg from './../../images/eraser4.png'
import checkmark from './../../images/check3.png'
import undo from './../../images/undo4.png'
import trashcan from '../../images/clearAll.png'

import canvasGrid from './../../images/canvasGrid.png'
import { Rnd } from 'react-rnd'
import TabUnselectedIcon from '@material-ui/icons/TabUnselected'
import { withCookies } from 'react-cookie'

const MAX_UNDO = 10

class Canvas extends React.Component {
  constructor(props) {
    super(props)
    this.textInput = React.createRef()
    this.state = {
      flag: false,
      prevX: 0,
      currX: 0,
      prevY: 0,
      currY: 0,
      dot_flag: false,
      color: 'black',
      lineWidth: 2,
      undoStack: [],
      showGrid: false,
      girdSpace: 50,
      justCleared: true,
      openModal: false,
      tool: null,
      textX: null,
      textY: null,
      currentText: '',
      fontSize: 24,
      selectionX1: null,
      selectionY1: null,
      selectionX2: null,
      selectionY2: null,
      tempX: null,
      tempY: null,
      imageData: null,
    }
  }

  componentDidMount() {
    const canvas = this.refs.canvas
    const ctx = canvas.getContext('2d')
    canvas.addEventListener(
      'mousemove',
      e => {
        this.findxy('move', e)
      },
      false
    )
    canvas.addEventListener(
      'mousedown',
      e => {
        this.findxy('down', e)
      },
      false
    )
    canvas.addEventListener(
      'mouseup',
      e => {
        this.findxy('up', e)
      },
      false
    )
    canvas.addEventListener(
      'mouseout',
      e => {
        this.findxy('out', e)
      },
      false
    )
    canvas.addEventListener(
      'touchstart',
      e => {
        e.preventDefault()
        if (this.state.tool === 'TEXT') {
          if (this.state.textX === null) {
            this.setState({ textX: e.x - 105, textY: e.y - 140 })
          } else if (this.state.currentText !== '') {
            this.printText()
          }
        }
        this.findxy('touchDown', e)
      },
      false
    )

    canvas.addEventListener(
      'touchmove',
      e => {
        e.preventDefault()
        if (this.state.tool !== 'TEXT') this.findxy('touchMove', e)
      },
      false
    )

    canvas.addEventListener('touchend', e => {
      e.preventDefault()
      this.findxy('up', e)
    })

    this.fitToContainer(this.refs.canvas)

    this.setState({ canvas, ctx })
    this.drawWithUrl(this.props.currentProbUrl)
    window.addEventListener('resize', debounce(this.handleResize, 500))
  }

  handleResize = () => {
    const canvas = this.refs.canvas
    if (!canvas) return
    const ctx = canvas.getContext('2d')
    let imgData = ctx.getImageData(0, 0, canvas.width, canvas.height)

    this.fitToContainer(canvas)

    ctx.putImageData(imgData, 0, 0)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.currentProbNum !== prevProps.currentProbNum) {
      this.drawWithUrl(this.props.currentProbUrl)
    }
    if (this.state.textX !== null) {
      //this.textInput.current.focus()
    }
  }

  drawWithUrl = url => {
    //redraw the canvas with the image in url
    const canvas = this.refs.canvas
    const ctx = canvas.getContext('2d')
    if (!url) {
      this.setState({ justCleared: true })
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      return
    }
    const img = new Image()
    img.onload = () => {
      this.setState({ justCleared: false })
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.drawImage(img, 0, 0)
    }
    img.crossOrigin = 'Anonymous'
    img.src = url
  }

  fitToContainer = canvas => {
    // Make it visually fill the positioned parent
    canvas.style.width = '100%'
    canvas.style.height = '100%'
    // ...then set the internal size to match
    canvas.width = canvas.offsetWidth
    canvas.height = canvas.offsetHeight
  }

  draw = () => {
    this.setState({ justCleared: false })
    const canvas = this.refs.canvas
    const ctx = canvas.getContext('2d')

    ctx.strokeStyle = this.state.color
    ctx.lineWidth = this.state.lineWidth

    ctx.beginPath()
    if (this.state.tool !== 'ERASER') {
      ctx.globalCompositeOperation = 'source-over'
      ctx.moveTo(this.state.prevX, this.state.prevY)
      ctx.lineTo(this.state.currX, this.state.currY)
      ctx.stroke()
    } else {
      ctx.globalCompositeOperation = 'destination-out'
      ctx.arc(this.state.prevX, this.state.prevY, 8, 0, Math.PI * 2, false)
      ctx.fill()
    }
    ctx.closePath()
  }
  grabImage = () => {
    const canvas = this.refs.canvas
    const ctx = canvas.getContext('2d')

    const imageData = ctx.getImageData(
      this.state.selectionX1,
      this.state.selectionY1 - 50,
      Math.abs(this.state.selectionX2 - this.state.selectionX1),
      Math.abs(this.state.selectionY2 - this.state.selectionY1)
    )
    ctx.clearRect(
      this.state.selectionX1,
      this.state.selectionY1 - 50,
      Math.abs(this.state.selectionX2 - this.state.selectionX1),
      Math.abs(this.state.selectionY2 - this.state.selectionY1)
    )
    this.setState({ imageData })
  }
  movePixels = (deltaX, deltaY) => {
    const canvas = this.refs.canvas
    const ctx = canvas.getContext('2d')
    const { imageData } = this.state
    ctx.putImageData(
      imageData,
      this.state.selectionX1 + deltaX,
      this.state.selectionY1 - 50 + deltaY
    )
    this.setState({ imageData: null })
  }
  getXY = (res, e) => {
    const canvas = this.refs.canvas
    const rect = canvas.getBoundingClientRect()
    let newX, newY
    if (res === 'down' || res === 'move') {
      newX = e.clientX - rect.left
      newY = e.clientY - rect.top
    } else {
      //get new pos differently if touch
      newX = e.touches[0].clientX - rect.left
      newY = e.touches[0].clientY - rect.top
    }

    return { x: newX, y: newY }
  }
  findxy = (res, e) => {
    const canvas = this.refs.canvas
    const ctx = canvas.getContext('2d')
    const selectionOffset = 38
    const textOffset = 55
    if (res === 'down' || res === 'touchDown') {
      const { x: newX, y: newY } = this.getXY(res, e)

      if (this.state.tool === 'SELECTION') {
        if (this.state.tempX === null) {
          this.setState({
            tempX: newX,
            tempY: newY,
          })
        } else {
          this.setState({
            selectionX1: null,
            selectionY1: null,
            selectionX2: null,
            selectionY2: null,
            tempX: null,
            tempY: null,
          })
        }
      }
      if (this.state.tool === 'TEXT') {
        if (this.state.textX === null || this.state.currentText === '') {
          this.setState({ textX: newX, textY: newY + textOffset })
        } else if (this.state.currentText !== '') {
          this.printText()
        }
      }

      this.setState({
        prevX: this.state.currX,
        prevY: this.state.currY,
        currX: newX,
        currY: newY,
      })

      this.storeSnapshot()

      this.setState({ flag: true })
    }
    if (res === 'up' || res === 'out') {
      this.setState({ flag: false })
      ctx.globalCompositeOperation = 'source-over'
    }
    if (res === 'move' || res === 'touchMove') {
      if (this.state.flag) {
        const { x: newX, y: newY } = this.getXY(res, e)
        if (this.state.tool === 'SELECTION' && this.state.tempX !== null) {
          const { tempX, tempY } = this.state
          this.setState({
            selectionX1: Math.min(tempX, newX),
            selectionX2: Math.max(tempX, newX),
            selectionY1: Math.min(tempY, newY) + selectionOffset,
            selectionY2: Math.max(tempY, newY) + selectionOffset,
          })
        }
        this.setState({
          prevX: this.state.currX,
          prevY: this.state.currY,
          currX: newX,
          currY: newY,
        })
        if (this.state.tool === null || this.state.tool === 'ERASER')
          this.draw()
      }
    }
  }

  toggleEraser = () => {
    this.setState({ tool: this.state.tool === 'ERASER' ? null : 'ERASER' })
  }
  toggleSelection = () => {
    this.setState({
      tool: this.state.tool === 'SELECTION' ? null : 'SELECTION',
    })
  }

  clear = () => {
    this.setState({ justCleared: true })
    const canvas = this.refs.canvas
    const ctx = canvas.getContext('2d')
    this.storeSnapshot()

    ctx.clearRect(0, 0, canvas.width, canvas.height)
  }

  storeSnapshot = () => {
    const canvas = this.refs.canvas
    const ctx = canvas.getContext('2d')
    let stack = this.state.undoStack
    while (stack.length >= MAX_UNDO) {
      stack.shift()
    }
    stack.push(ctx.getImageData(0, 0, canvas.width, canvas.height))
    this.setState({ undoStack: stack })
  }

  undo = () => {
    this.setState({ justCleared: false })
    if (this.state.undoStack.length !== 0) {
      const canvas = this.refs.canvas
      const ctx = canvas.getContext('2d')
      let stack = this.state.undoStack
      let img = stack.pop()

      ctx.clearRect(0, 0, canvas.width, canvas.height)

      ctx.putImageData(img, 0, 0)

      this.setState({ undoStack: stack })
    }
  }

  colorPicker = color => {
    switch (color) {
      case 'black':
        this.setState({ color: 'black' })
        break
      case 'red':
        this.setState({ color: 'red' })
        break
      case 'green':
        this.setState({ color: 'green' })
        break
      case 'blue':
        this.setState({ color: 'blue' })
        break
      default:
        this.setState({ color: 'black' })
        break
    }
    this.setState({ eraser: false })
  }

  toggleText = () => {
    this.setState({ tool: this.state.tool === 'TEXT' ? null : 'TEXT' })
  }

  toggleGrid = () => {
    if (this.state.showGrid) {
      this.setState({ showGrid: false })
    } else {
      this.setState({ showGrid: true })
    }
  }

  onChangeValue = value => {
    this.setState({ gridSpace: value })
  }

  onSave = async () => {
    if (this.state.justCleared && this.props.currentProbUrl) {
      this.setState({ openModal: true })
      return
    }
    this.forceSave()
  }

  forceSave = async () => {
    //save without checking for clear screen
    const canvas = this.refs.canvas

    this.props.onSaveScratchpad(canvas.toDataURL())
  }
  printText = () => {
    const canvas = this.refs.canvas
    const ctx = canvas.getContext('2d')
    ctx.font = `${this.state.fontSize}px arial`
    const lines = this.state.currentText.split('\n')
    const yOffset = (this.state.fontSize - 53) * 0.93
    for (let i = 0; i < lines.length; i++) {
      ctx.fillText(
        lines[i],
        this.state.textX + 1,
        this.state.textY + this.state.fontSize * i * 1.15 + yOffset
      )
    }

    this.setState({ currentText: '', textX: null, textY: null })
  }

  convertToImage = () => {
    const { imageData } = this.state
    var c = document.createElement('canvas')
    c.width = imageData.width
    c.height = imageData.height
    c.getContext('2d').putImageData(imageData, 0, 0)
    return c.toDataURL()
  }

  render() {
    return (
      <View
        style={[
          styles.container,
          { visibility: this.props.visible ? 'visible' : 'hidden' },
        ]}
      >
        <DialogModal
          visible={this.state.openModal}
          message={'your saved image will be replaced, continue?'}
          onYes={() => {
            this.setState({ openModal: false, justCleared: false })
            this.forceSave()
          }}
          onNo={() => {
            this.setState({ openModal: false })
          }}
        />

        <View style={styles.headerContainer}>
          <View style={styles.eraseAllContainer}>
            <TouchableOpacity style={styles.eraseAll} onPress={this.clear}>
              <img
                src={trashcan}
                alt={'clear all'}
                style={{ width: '3vh', height: '3.5vh' }}
              />
            </TouchableOpacity>
          </View>

          <View style={styles.rightContainer}>
            {this.props.cookies.get('instructor') === '1' && (
              <TouchableOpacity
                style={[styles.button, { backgroundColor: solveColors.orange }]}
                onPress={this.props.toggleFullScreen}
              >
                <Text>{`Full Screen: ${
                  this.props.fullScreen ? 'O' : 'X'
                }`}</Text>
              </TouchableOpacity>
            )}

            <TouchableOpacity onPress={this.toggleGrid} style={styles.button}>
              <Text stlye={{ fontSize: 12 }}>
                Grid: {this.state.showGrid ? 'O' : 'X'}
              </Text>
            </TouchableOpacity>
            {this.state.showGrid && (
              <View style={{ width: 100, marginRight: '3vh' }}>
                <Text style={{ fontSize: 10 }}>Grid Size:</Text>
                <Slider
                  min={1}
                  max={100}
                  defaultValue={50}
                  style={{ width: 100 }}
                  onChange={this.onChangeValue}
                />
              </View>
            )}
            <TouchableOpacity style={styles.save} onPress={() => this.onSave()}>
              <Text style={{ fontSize: 12 }}>SAVE</Text>
            </TouchableOpacity>

            <TouchableOpacity onPress={this.undo} style={styles.undoBtn}>
              <img src={undo} alt={'undo'} />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                this.colorPicker('black')
              }}
              style={[
                styles.colorPalette,
                {
                  backgroundColor: 'black',
                },
              ]}
            >
              <img
                src={checkmark}
                alt={'X'}
                style={{
                  width: '1.5vh',
                  height: '2.5vh',
                  display:
                    this.state.color === 'black' ? 'inline-block' : 'none',
                }}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                this.colorPicker('red')
              }}
              style={[
                styles.colorPalette,
                {
                  backgroundColor: 'red',
                },
              ]}
            >
              <img
                src={checkmark}
                alt={'X'}
                style={{
                  width: '1.5vh',
                  height: '2.5vh',
                  display: this.state.color === 'red' ? 'inline-block' : 'none',
                }}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                this.colorPicker('green')
              }}
              style={[
                styles.colorPalette,
                {
                  backgroundColor: 'rgb(0,200,40)',
                },
              ]}
            >
              <img
                src={checkmark}
                alt={'X'}
                style={{
                  width: '1.5vh',
                  height: '2.5vh',
                  display:
                    this.state.color === 'green' ? 'inline-block' : 'none',
                }}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                this.colorPicker('blue')
              }}
              style={[
                styles.colorPalette,
                {
                  backgroundColor: 'rgb(0,68,255)',
                },
              ]}
            >
              <img
                src={checkmark}
                alt={'X'}
                style={{
                  width: '1.5vh',
                  height: '2.5vh',
                  display:
                    this.state.color === 'blue' ? 'inline-block' : 'none',
                }}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={this.toggleEraser}
              style={[
                styles.erase,
                {
                  borderBottom:
                    this.state.tool === 'ERASER' ? '5px solid black' : 'none',
                },
              ]}
            >
              <img
                src={eraserImg}
                alt={'erase'}
                style={{
                  width: '4vh',
                  height: '4vh',
                }}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={this.toggleSelection}
              style={[
                {
                  borderBottom:
                    this.state.tool === 'SELECTION'
                      ? '5px solid black'
                      : 'none',
                  paddingVertical: 3,
                  marginRight: '2vh',
                },
              ]}
            >
              <TabUnselectedIcon style={{ height: 30, width: 30 }} />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                this.toggleText()
              }}
              style={[
                {
                  marginRight: '3vh',
                  paddingHorizontal: 5,
                  alignItems: 'center',
                  borderBottom:
                    this.state.tool === 'TEXT' ? '5px solid black' : 'none',
                },
              ]}
            >
              <Text style={{ fontWeight: 'bold', fontSize: 27 }}>T</Text>
            </TouchableOpacity>
            {this.state.tool === 'TEXT' && (
              <View style={{ width: 100, marginRight: '3vh' }}>
                <Text
                  style={{ fontSize: 10 }}
                >{`Font Size: ${this.state.fontSize}`}</Text>
                <Slider
                  min={1}
                  max={100}
                  defaultValue={24}
                  onChange={size => this.setState({ fontSize: size })}
                />
              </View>
            )}
            <TouchableOpacity
              style={styles.x}
              onPress={this.props.toggleScratchpad}
            >
              <Text style={{ color: 'white', fontSize: 12 }}>CLOSE</Text>
            </TouchableOpacity>
          </View>
        </View>
        {this.state.tool === 'SELECTION' && this.state.selectionX1 !== null && (
          <Rnd
            size={{
              width: Math.abs(this.state.selectionX2 - this.state.selectionX1),
              height: Math.abs(this.state.selectionY2 - this.state.selectionY1),
            }}
            position={{
              x: this.state.selectionX1,
              y: this.state.selectionY1,
            }}
            style={{
              display: 'flex',
              justifyContent: 'center',
              borderWidth: 1,
              borderStyle: 'solid',
              position: 'absolute',
              zIndex: 10,
              borderColor: 'black',
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
              if (
                direction === 'left' ||
                direction === 'topLeft' ||
                direction === 'bottomLeft'
              ) {
                this.setState({
                  selectionX1: this.state.selectionX1 - delta.width,
                })
              }
              if (
                direction === 'right' ||
                direction === 'topRight' ||
                direction === 'bottomRight'
              ) {
                this.setState({
                  selectionX2: this.state.selectionX2 + delta.width,
                })
              }
              if (
                direction === 'top' ||
                direction === 'topLeft' ||
                direction === 'topRight'
              ) {
                this.setState({
                  selectionY1: this.state.selectionY1 - delta.height,
                })
              }
              if (
                direction === 'bottom' ||
                direction === 'bottomRight' ||
                direction === 'bottomLeft'
              ) {
                this.setState({
                  selectionY2: this.state.selectionY2 + delta.height,
                })
              }
            }}
            onDragStart={() => {
              if (this.state.imageData === null) {
                this.grabImage()
              }
            }}
            onDragStop={(e, data) => {
              const { x, y } = data
              const deltaX = x - this.state.selectionX1
              const deltaY = y - this.state.selectionY1
              this.movePixels(deltaX, deltaY)
              this.setState({
                selectionX1: this.state.selectionX1 + deltaX,
                selectionX2: this.state.selectionX2 + deltaX,
                selectionY1: this.state.selectionY1 + deltaY,
                selectionY2: this.state.selectionY2 + deltaY,
              })
            }}
          >
            {Array.from({ length: 9 }, (_, index) => index + 1).map(i => {
              const row = Math.floor((i - 1) / 3)
              const col = (i - 1) % 3

              if (i === 5) {
                return <View />
              }
              return (
                <View
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    height: 10,
                    width: 10,
                    position: 'absolute',
                    top: `calc(${row * 50}% - 5px)`,
                    left: `calc(${col * 50}% - 5px)`,
                  }}
                />
              )
            })}
            {this.state.imageData !== null && (
              <img
                src={this.convertToImage()}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  pointerEvents: 'none',
                }}
                alt={'Conversion'}
              />
            )}
          </Rnd>
        )}
        {this.state.tool === 'TEXT' && this.state.textX !== null && (
          <Rnd
            default={{
              width: 50 + this.state.fontSize * 5,
              height: 10 + this.state.fontSize,
            }}
            position={{
              x: this.state.textX,
              y: this.state.textY,
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
              if (direction === 'left' || direction === 'topLeft') {
                this.setState({ textX: this.state.textX - delta.width })
              }
              if (direction === 'top' || direction === 'topLeft') {
                this.setState({ textY: this.state.textY - delta.height })
              }
            }}
            style={{
              display: 'flex',
              justifyContent: 'center',
              borderWidth: 1,
              borderStyle: 'solid',
              position: 'absolute',
              zIndex: 10,
              borderColor: 'black',
            }}
          >
            {Array.from({ length: 9 }, (_, index) => index + 1).map(i => {
              const row = Math.floor((i - 1) / 3)
              const col = (i - 1) % 3

              if (i === 5) {
                return <View />
              }
              return (
                <View
                  style={{
                    backgroundColor: 'white',
                    borderWidth: 1,
                    height: 10,
                    width: 10,
                    position: 'absolute',
                    top: `calc(${row * 50}% - 5px)`,
                    left: `calc(${col * 50}% - 5px)`,
                  }}
                />
              )
            })}

            <TextInput
              ref={this.textInput}
              style={{
                width: '100%',
                height: '100%',
                fontFamily: 'Arial',
                fontSize: this.state.fontSize,
              }}
              multiline={true}
              value={this.state.currentText}
              onChangeText={text => {
                this.setState({
                  currentText: text,
                })
              }}
            />
          </Rnd>
        )}
        <div>
          <canvas
            ref="canvas"
            style={{
              backgroundImage: this.state.showGrid
                ? `url(${canvasGrid})`
                : 'none',
              backgroundSize: `${this.state.gridSpace}%, auto`,
              backgroundColor: 'rgba(0,0,0,0.05)',
              zIndex: 2,
              position: 'absolute',
              cursor: `url(../../images/eraser2.png), auto`,
            }}
          />
        </div>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    zIndex: 100,
  },
  headerContainer: {
    width: '100%',
    height: '50px',
    // backgroundColor: 'rgb(224,224,224)',
    display: 'flex',
    flexDirection: 'row',
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    backgroundColor: solveColors.grey2,
  },
  eraseAllContainer: {
    flex: 1,

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  eraseAll: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '5vh',
  },

  rightContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  erase: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: '2vh',
  },
  x: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '2vh',
    paddingRight: '2vh',
    paddingTop: '0.7vh',
    paddingBottom: '0.7vh',
    marginRight: '2vh',
    backgroundColor: 'rgb(15,98,188)',
    borderRadius: '3px',
  },
  colorPalette: {
    width: '3vh',
    height: '3vh',
    borderRadius: '50%',
    marginRight: '3vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  undoBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '3vh',
  },
  save: {
    marginRight: '3vh',
    backgroundColor: 'rgb(0,200,40)',
    color: 'white',
    borderRadius: '3px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '2vh',
    paddingRight: '2vh',
    paddingTop: '0.7vh',
    paddingBottom: '0.7vh',
  },
  button: {
    marginRight: '3vh',
    backgroundColor: 'rgb(15,98,188)',
    color: 'white',
    paddingLeft: '2vh',
    paddingRight: '2vh',
    paddingTop: '0.7vh',
    paddingBottom: '0.7vh',
  },
})
export default withCookies(Canvas)
