import { StyleSheet, View, Text, TouchableOpacity } from 'react-native'
import React, { Component } from 'react'
import Modal from 'modal-react-native-web'
import solveColors from './colors'

class DialogModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.visible !== props.visible) {
      return { visible: props.visible }
    }
    return null
  }

  render() {
    return (
      <Modal
        visible={this.state.visible}
        transparent={true}
        appElement={
          document.getElementById('app') || document.getElementById('root')
        }
      >
        <View style={styles.backdrop}>
          <View style={styles.results}>
            <Text style={{ textAlign: 'center', marginBottom: 20 }}>
              {this.props.message}
            </Text>
            <View style={{ flexDirection: 'row' }}>
              <TouchableOpacity
                style={[
                  styles.button,
                  {
                    backgroundColor: solveColors.blue5,
                    bottom: 0,
                    marginRight: 20,
                  },
                ]}
                onPress={() => {
                  this.props.onYes()
                }}
              >
                <Text style={{ color: 'white' }}>
                  {this.props.yesText || 'Yes'}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  styles.button,
                  { backgroundColor: solveColors.blue5, bottom: 0 },
                ]}
                onPress={() => {
                  this.props.onNo()
                }}
              >
                <Text style={{ color: 'white' }}>
                  {this.props.noText || 'No'}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    )
  }
}

const styles = StyleSheet.create({
  results: {
    maxWidth: '400px',
    borderRadius: '10px',
    margin: 'auto',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: 25,
  },
  backdrop: {
    backgroundColor: 'rgba(52, 52, 52, 0.2)',
    height: '100%',
    width: '100%',
  },
  button: {
    width: 100,
    height: 40,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
})
export default DialogModal
