import { StyleSheet, Text, TouchableOpacity } from 'react-native'
import React, { Component } from 'react'
import { solveColors } from '../../../components/colors'

class AssignmentSubmitButton extends Component {
  render() {
    return (
      <TouchableOpacity
        style={[
          styles.submit_button,
          this.props.locked ? styles.locked : styles.active,
        ]}
        onPress={this.props.handleAssignmentSubmit}
        disabled={this.props.locked}
      >
        <Text style={styles.buttonText}>SUBMIT PROBLEM SET</Text>
      </TouchableOpacity>
    )
  }
}

const styles = StyleSheet.create({
  submit_button: {
    alignItems: 'center',
    paddingVertical: 10,
    borderRadius: 10,
    margin: 10,
    width: 160,
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
  },
  locked: {
    backgroundColor: solveColors.grey2,
  },
  active: {
    backgroundColor: solveColors.blue5,
  },
})

export default AssignmentSubmitButton
