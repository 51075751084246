import React, { useState } from 'react'
import {
  View,
  Text,
  Image,
  ImageBackground,
  TextInput,
  TouchableOpacity,
} from 'react-native-web'
import { RESET_PASSWORD_UN } from '../../../services/queries'
import login_background from './../../images/login_background.svg'
import logo from '../../images/solve-logo.png'
import { access } from '../../components/styles'
import solveColors from '../../components/colors'
import { ApolloConsumer } from 'react-apollo'
import BannerMessage from '../../components/BannerMessage'
import { withRouter } from 'react-router-dom'
import { useCookies } from 'react-cookie'

const RecoveryPage = props => {
  const [cookies] = useCookies([])
  const [input, setInput] = useState('')
  const [invalid, setInvalid] = useState(false)
  const [success, setSuccess] = useState(false)
  const forgottenItem = props.location.search.substr(1)
  const resetPassword = async client => {
    setInvalid(false)
    setSuccess(false)

    const data = (
      await client.mutate({
        mutation: RESET_PASSWORD_UN,
        variables: {
          username: input,
        },
      })
    ).data
    if (data.resetPassword.success) {
      setSuccess(true)
      return
    }
    setInvalid(true)
  }
  return (
    <ApolloConsumer>
      {client => (
        <ImageBackground source={login_background} style={access.background}>
          {invalid && <BannerMessage msg="Username was not found." />}

          <View style={access.header}>
            {success && (
              <BannerMessage
                success
                msg="An email has been sent to your email to reset your password."
              />
            )}

            <Image
              style={access.logo}
              source={props.image ? cookies['imageUrl'] : logo}
            />
            <View>
              <TextInput
                secureTextEntry={false}
                style={[access.inputField]}
                placeholder={
                  forgottenItem === 'password'
                    ? 'Enter Username'
                    : 'Enter Email'
                }
                onChangeText={text => {
                  setInput(text)
                }}
                onSubmitEditing={() => {
                  forgottenItem === 'password'
                    ? resetPassword(client)
                    : console.log('Send email to recover username')
                }}
                placeholderTextColor={solveColors.offWhite}
              />
              <TouchableOpacity
                style={access.button}
                disabled={false}
                onPress={() => {
                  forgottenItem === 'password'
                    ? resetPassword(client)
                    : console.log('Send email to recover username')
                }}
              >
                <Text style={{ color: 'white' }}>SUBMIT</Text>
              </TouchableOpacity>
            </View>
          </View>
        </ImageBackground>
      )}
    </ApolloConsumer>
  )
}

export default withRouter(RecoveryPage)
