import React, { useState } from 'react'
import { Query } from 'react-apollo'
import { View, Text, FlatList, TouchableOpacity, Image } from 'react-native-web'
import LoadingPage from '../Loading/LoadingPage'
import { GET_LEADERBOARD } from '../../../services/queries'
import solveColors from './../../components/colors'
import defaultProfile from './../../images/dummy_profile.png'
import gets3Img from './../../../utils/s3config'
import { useCookies } from 'react-cookie'

const LeaderboardForm = props => {
  const [lessonId, setLessonId] = useState(null)
  const { classroomId, lesson } = props

  const studentId = sessionStorage.getItem('studentId')
  return (
    <Query
      query={GET_LEADERBOARD}
      variables={{
        classroomId: classroomId,
        lessonId: lessonId,
        studentId: studentId,
      }}
    >
      {({ data, loading, error }) => {
        if (loading) {
          return <LoadingPage />
        }
        if (error) {
          return `${error}`
        }
        if (!data) return 'No messages available'

        setLessonId(lesson ? lesson.id : null)
        let onLeaderboard, leaderboard
        if (data.leaderboard) {
          onLeaderboard = data.leaderboard.onLeaderboard
          leaderboard = data.leaderboard.students
        }
        return (
          <View style={{ width: 'calc(100% - 350px)', margin: 10 }}>
            {!onLeaderboard && leaderboard && leaderboard.length > 0 && (
              <View
                style={{
                  flexDirection: 'row',
                  marginHorizontal: 10,
                  padding: 10,
                  marginBottom: 2,
                  backgroundColor: solveColors.orange,
                  borderRadius: 5,
                }}
              >
                <Text style={{ color: 'white' }}>
                  To be placed on the Leaderboard, you need to complete the
                  homework and quiz for each lesson. Each question is worth
                  points on the first submission and 9 points on the second
                  submission.
                </Text>
              </View>
            )}
            <HeaderRow rank={'Rank'} student={'Student'} points={'Points'} />
            <FlatList
              data={leaderboard}
              renderItem={({ item }) => {
                return (
                  <LeaderboardRow
                    rank={item.ranking}
                    student={item.studentName}
                    points={item.totalPoints}
                    last={item.ranking === leaderboard.length}
                    profileImg={item.profilePicture}
                  />
                )
              }}
            />
          </View>
        )
      }}
    </Query>
  )
}

const LeaderboardRow = props => {
  const [cookies] = useCookies([])
  const { rank, student, points, last, profileImg } = props
  const [hover, setHover] = useState(null)
  const firstName = student.split(' ')[0]
  const lastInitial = student.split(' ')[student.split(' ').length - 1][0]
  const currFirstName = cookies['firstName']
  const currLastInitial = cookies['lastName'] ? cookies['lastName'][0] : ''

  return (
    <TouchableOpacity
      disabled={true}
      style={{
        flexDirection: 'row',
        marginHorizontal: 10,
        padding: 10,
        marginBottom: 2,
        backgroundColor:
          hover === rank ||
          (firstName === currFirstName && lastInitial === currLastInitial)
            ? '#e6fdff'
            : 'white',
        justifyContent: 'space-between',
        borderBottomRightRadius: last ? 5 : 0,
        borderBottomLeftRadius: last ? 5 : 0,
      }}
      onMouseEnter={() => {
        setHover(rank)
      }}
      onMouseLeave={() => {
        setHover(null)
      }}
    >
      <Text style={{ width: 50 }}>{rank}</Text>
      <View style={{ width: 200, flexDirection: 'row', alignItems: 'center' }}>
        <Image
          source={
            !profileImg
              ? defaultProfile
              : profileImg.includes('amazonaws')
              ? profileImg
              : gets3Img(profileImg)
          }
          style={{
            resizeMode: 'cover',
            height: 25,
            width: 25,
            marginRight: 10,
          }}
        />
        <Text>{student}</Text>
      </View>
      <Text style={{ width: 100 }}>{points}</Text>
    </TouchableOpacity>
  )
}
const HeaderRow = props => {
  const { rank, student, points } = props
  return (
    <View
      style={{
        flexDirection: 'row',
        marginHorizontal: 10,
        padding: 10,
        marginTop: 10,
        marginBottom: 2,
        backgroundColor: solveColors.blue1,
        justifyContent: 'space-between',
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
      }}
    >
      <Text style={{ color: 'white', width: 50 }}>{rank}</Text>
      <Text style={{ color: 'white', width: 200 }}>{student}</Text>
      <Text style={{ color: 'white', width: 100 }}>{points}</Text>
    </View>
  )
}

export default LeaderboardForm
