import { StyleSheet } from 'react-native'
import solveColors from '../../components/colors'

const styles = StyleSheet.create({
  code: {
    fontFamily:
      'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace',
  },
  link: {
    color: '#61dafb',
    textDecorationLine: 'underline',
  },
  textinput: {
    padding: 10,
    color: 'white',
    borderBottomWidth: 1,
    borderBottomColor: 'white',
    marginBottom: 20,
    marginTop: 20,
    textAlign: 'center',
  },
  button: {
    alignItems: 'center',
    backgroundColor: '#237FBB',
    padding: 10,
    borderRadius: 10,
    marginBottom: 10,
    width: 175,
  },
  basicText: {
    color: solveColors.offWhite,
    textAlign: 'center',
  },
})
export default styles
