import React, { useMemo } from 'react'
import { TouchableOpacity, Text } from 'react-native-web'
import { useTable } from 'react-table'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import solveColors from '../../components/colors'

const CartCreditsTable = props => {
  const { credits, loading, removeSelectedCredit } = props

  const columns = useMemo(
    () => [
      {
        id: 'x',
        Header: 'X',
        Cell: row => (
          <TouchableOpacity
            disabled={loading}
            onPress={() => {
              if (credits != null) {
                removeSelectedCredit(row.row.original.id)
              }
            }}
          >
            <Text>X</Text>
          </TouchableOpacity>
        ),
      },
      {
        Header: '#',
        Cell: props => props.row.index + 1,
        width: 30,
      },
      {
        id: 'creditType',
        Header: 'Credit Type',
        accessor: row => row.creditType,
      },
      {
        id: 'balance',
        Header: 'Balance',
        accessor: 'balance',
        Cell: props => <span>{`${props.value.toFixed(2)}`}</span>,
        width: 100,
        style: {
          textAlign: 'right',
        },
      },
      {
        id: 'notes',
        Header: 'Notes',
        accessor: row => row.notes,
      },
    ],
    [credits, loading, removeSelectedCredit]
  )

  const hideColumns = []

  if (props.isPaid) {
    hideColumns.push('x')
  }

  const { getTableProps, headerGroups, prepareRow, rows } = useTable({
    columns,
    data: credits || [],
    initialState: { hiddenColumns: hideColumns },
  })

  return (
    <Table {...getTableProps()}>
      <TableHead>
        {headerGroups.map(headerGroup => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <TableCell
                {...column.getHeaderProps()}
                style={{
                  borderBottom: `solid 3px ${solveColors.blue1}`,
                  color: solveColors.blue1,
                  fontWeight: 'bold',
                }}
              >
                {column.render('Header')}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <TableCell {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

export default CartCreditsTable
