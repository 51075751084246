import { Dimensions } from 'react-native-web'
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import AdminForm from './AdminForm'

export const DashboardForm = props => {
  const { filter, classrooms } = props
  const [windowWidth, setWindowWidth] = useState(Dimensions.get('window').width)
  useEffect(() => {
    const getWidth = () => setWindowWidth(Dimensions.get('window').width)
    window.addEventListener('resize', getWidth)
    return () => {
      window.removeEventListener('resize', getWidth)
    }
  })
  return (
    <AdminForm
      filter={filter}
      windowWidth={windowWidth}
      classrooms={classrooms}
    />
  )
}

export default withRouter(DashboardForm)
