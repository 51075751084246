import React from 'react'
import { Text, TouchableOpacity } from 'react-native-web'
import { withRouter } from 'react-router-dom'
import solveColors from '../../components/colors'

const ForgotCredentialsButton = props => {
  const { history } = props
  return (
    <TouchableOpacity
      style={{ paddingVertical: '3px' }}
      onPress={() => {
        history.push({
          pathname: '/recovery',
          search: `${props.forgottenItem}`,
        })
      }}
    >
      <Text
        style={{ color: solveColors.offWhite, textDecorationLine: 'underline' }}
      >
        {props.forgottenItem === 'password'
          ? `Forgot Password?`
          : `Forgot Username?`}
      </Text>
    </TouchableOpacity>
  )
}

export default withRouter(ForgotCredentialsButton)
