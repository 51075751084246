import gql from 'graphql-tag'

export const GET_LESSONS = gql`
  query GetLessons(
    $id: ID!
    $lessonFilter: Filters
    $enrollmentsFilter: Filters
  ) {
    classroom(id: $id) {
      title
      onSolve
      startDate
      headline(filter: { headline: true }) {
        id
        likes
        announceDateTime
        subject
        message
      }
      enrollments(filter: $enrollmentsFilter) {
        id
        unreadMessages
      }
      lessons(filter: $lessonFilter) {
        id
        startOn
        endOn
        previewOn
        lessonPlan {
          title
          lessonPlanType
          order
          description
        }
      }
    }
  }
`

export const GET_ATTENDEES = gql`
  query GetAttendees($filter: Filters) {
    attendees(filter: $filter) {
      status
    }
  }
`

export const GET_LESSON_ASSIGNMENTS = gql`
  query GetLessonAssignments($id: ID!) {
    lesson(id: $id) {
      previewOn
      startOn
      endOn
      dueOn
      webinarId
      webinarUrl
      webinarReplay
      officeHourDuration
      primaryOfficeHourOn
      makeupOfficeHourOn
      officeHourMeetingId
      assignments {
        status
        id
        startOn
        graded
        dueOn
        problemSet {
          id
          order
          learningObjective
          title
          instructions
          id
          allowedAttempts
          autoGrading
          kind
          videoId
          purpose
        }
      }
    }
  }
`

export const CREATE_SUBMISSION = gql`
  mutation CreateSubmission($assignmentId: String!, $studentId: String!) {
    createSubmission(
      input: { assignmentId: $assignmentId, studentId: $studentId }
    ) {
      id
    }
  }
`

export const GET_SUBMISSION = gql`
  query GetSubmission($filter: Filters, $sort: Sorts, $page: Pagination) {
    submissions(sort: $sort, filter: $filter, page: $page) {
      id
      graded
      reviewed
      createdOn
      submittedAt
      excused
      count
      sum
      overallGrade
      attemptNumber
      assignment {
        id
      }
    }
  }
`
export const UPDATE_SUBMISSION_REVIEWED = gql`
  mutation UpdateSubmissionReviewed($submissionId: ID!) {
    updateSubmission(id: $submissionId, input: { reviewed: true }) {
      id
    }
  }
`

export const GET_GRADEBOOK_ASSIGNMENTS = gql`
  query GetGradebookAssignments($id: ID!, $filter: Filters, $sort: Sorts) {
    lesson(id: $id) {
      id
      assignments {
        id
        dueOn
        problemSet {
          id
          order
          title
          kind
        }
        submissions(sort: $sort, filter: $filter) {
          id
          graded
          excused
          submittedAt
          overallGrade
          totalTimeSpent
        }
      }
    }
  }
`
export const GET_GRADEBOOK_SUBMISSION = gql`
  query GetGradebookSubmission($filter: Filters, $sort: Sorts) {
    submissions(sort: $sort, filter: $filter) {
      id
      graded
      submittedAt
      overallGrade
      totalTimeSpent
      assignment {
        id
      }
    }
  }
`

export const GET_CLASSROOM_INSTRUCTORS = gql`
  query GetClassroomInstructors($classroomId: ID!) {
    classroom(id: $classroomId) {
      id
      instructors {
        id
        employee {
          id
          firstName
          lastName
          imageUrl
        }
      }
    }
  }
`

export const GET_CLASSROOM_GRADES = gql`
  query GetClassroomGrades(
    $classroomId: ID!
    $enrollmentFilter: Filters
    $attendeeFilter: Filters
  ) {
    classroom(id: $classroomId) {
      id
      enrollments(filter: $enrollmentFilter) {
        overallGrade
        student {
          attendees(filter: $attendeeFilter) {
            lesson {
              id
            }
            overallGrade
            percentCompleted
          }
        }
      }
    }
  }
`
export const COUNT_MESSAGES = gql`
  query countMessages($filter: Filters!) {
    countIssues(filter: $filter)
  }
`
export const GET_MAKEUPS = gql`
  query getMakeups($filter: Filters!) {
    makeups(filter: $filter) {
      id
      fromLesson {
        id
        classroom {
          id
        }
      }
      toLesson {
        id
        startOn
        previewOn
        classroom {
          id
          title
          centerLocation {
            id
            name
          }
        }
      }
    }
  }
`

export const GET_MAKEUP_ASSIGNMENTS = gql`
  query getMakeupAssignments($id: ID!) {
    lesson(id: $id) {
      id
      assignments {
        problemSet {
          id
        }
        startOn
        status
      }
    }
  }
`
export const GET_NEWS = gql`
  query GetNews($filter: Filters!) {
    news(filter: $filter, sort: { announceDateTime: -1 }) {
      id
      announcer {
        firstName
        lastName
      }
      headline
      subject
      message
      announceDateTime
      likes
    }
  }
`
export const LIKE_NEWS = gql`
  mutation LikeNews($newsId: ID!, $likes: Int!) {
    updateNews(id: $newsId, input: { likes: $likes }) {
      id
      likes
    }
  }
`
export const GET_NEWS_LIKES = gql`
  query GetNewsLikes($filter: Filters!) {
    news(filter: $filter) {
      id
      likes
    }
  }
`
