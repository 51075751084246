import React, { useState, useEffect } from 'react'
import {
  ScrollView,
  View,
  Text,
  FlatList,
  TouchableOpacity,
  Dimensions,
  StyleSheet,
} from 'react-native-web'
import solveColors from '../../components/colors'
import LeaderboardForm from './LeaderboardForm'

const LeaderboardPage = props => {
  const { lessons } = props
  const classroomId = sessionStorage.getItem('classroomId')
  const initLesson = lessons.find(
    l =>
      l.lessonPlan.order ===
      parseInt(sessionStorage.getItem(`lessonNum:${classroomId}`))
  )
  const [currLesson, setLesson] = useState(initLesson)
  // eslint-disable-next-line no-unused-vars
  const [windowWidth, setWindowWidth] = useState(Dimensions.get('window').width)
  useEffect(() => {
    const getWidth = () => setWindowWidth(Dimensions.get('window').width)
    window.addEventListener('resize', getWidth)
    return () => {
      window.removeEventListener('resize', getWidth)
    }
  })
  return (
    <ScrollView
      contentContainerStyle={{ flexDirection: 'row', display: 'flex' }}
    >
      <View>
        <Text
          style={{
            marginLeft: 10,
            marginTop: 10,
            fontWeight: 'bold',
            fontSize: 16,
          }}
        >
          Classroom:
        </Text>
        <TouchableOpacity
          style={[styles.button, !currLesson ? styles.selected : {}]}
          onPress={() => {
            setLesson(null)
          }}
        >
          <Text
            style={
              !currLesson ? { color: 'white' } : { color: solveColors.blue1 }
            }
          >
            Classroom Leaderboard
          </Text>
        </TouchableOpacity>
        <Text style={{ marginLeft: 10, fontWeight: 'bold', fontSize: 16 }}>
          Lessons:
        </Text>
        <FlatList
          data={lessons}
          key={currLesson ? currLesson.id : ''}
          keyExtractor={item => item.id}
          renderItem={({ item }) => {
            return (
              <TouchableOpacity
                style={[
                  styles.button,
                  currLesson && currLesson.id === item.id
                    ? styles.selected
                    : {},
                ]}
                onPress={() => {
                  setLesson(item)
                }}
              >
                <Text
                  style={
                    currLesson && currLesson.id === item.id
                      ? { color: 'white' }
                      : { color: solveColors.blue1 }
                  }
                >
                  {item.lessonPlan.order}. {item.lessonPlan.title}
                </Text>
              </TouchableOpacity>
            )
          }}
        />
      </View>
      <LeaderboardForm
        classroomId={sessionStorage.getItem('classroomId')}
        lesson={currLesson}
      />
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: 'white',
    margin: 10,
    padding: 10,
    display: 'flex',
    width: 300,
    alignItems: 'flex-start',
    justifyContent: 'center',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: solveColors.blue1,
  },
  selected: {
    backgroundColor: solveColors.blue1,
    borderWidth: 0,
  },
})

export default LeaderboardPage
