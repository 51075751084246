import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native-web'
import { withRouter } from 'react-router-dom'
import { InfoRow } from './'
import solveColors from './colors'
import { formatTime, formatDate } from './../../utils/dateTime'
import { useCookies } from 'react-cookie'
import { gradeLevel } from './../../utils/gradeLevel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const PromoCard = React.memo(props => {
  const {
    onEnroll,
    isStudent,
    isFamily,
    students,
    imageUrl,
    title,
    classroomId,
    startGrade,
    endGrade,
    startDate,
    startTime,
    endTime,
    endDate,
    classroomDays,
    tuitionCents,
    hasPreview,
    videoId,
    history,
    openLoginPopup,
    openVideo,
  } = props
  const [student, setStudent] = useState(
    students && students.length ? students[0] : {}
  )
  const [cookies] = useCookies([])
  const classroomStartDate = formatDate(startDate, false)
  const classroomEndDate = formatDate(endDate, true)
  const classroomStartTime = formatTime(startTime, true)
  const classroomEndTime = formatTime(endTime, false)
  const grades = gradeLevel(startGrade, endGrade)
  const isInstructor = !isStudent && !isFamily
  const schedule = tuitionCents
    ? `${classroomDays}: ${classroomStartDate} - ${classroomEndDate}`
    : `${classroomDays}: ${classroomEndDate}`
  const amountLabel = tuitionCents > 30000 ? 'Tuition' : 'Price'
  const handlePressEnroll = () => {
    if (tuitionCents === 0) {
      const enrollmentInput = {
        studentId: student.id,
        classroomId: classroomId,
        status: 'PAID',
      }
      const success = onEnroll(enrollmentInput)
      student.enrolled = success
      setStudent({ ...student })
    } else {
      history.push(`/checkout?classroomId=${classroomId}`)
    }
  }

  return (
    <Card
      title="COURSE CARD"
      style={{
        backgroundColor: 'white',
        margin: 10,
        width: 300,
        boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.25)',
      }}
    >
      <Card.Img style={{ width: 300 }} src={imageUrl} />
      <Card.Body
        style={{
          padding: 10,
          paddingBottom: 60,
          minHeight: 175,
        }}
      >
        <Card.Title style={{ fontWeight: 'bold', fontSize: '16px' }}>
          {title}
        </Card.Title>

        <View style={{ marginTop: 10 }}>
          <InfoRow text1={'Grade: '} text2={`${grades}`} />
          <InfoRow
            text1={amountLabel}
            text2={
              tuitionCents !== 0
                ? `$${(tuitionCents / 100).toFixed(2)}`
                : 'FREE'
            }
          />
          <InfoRow text1={'Schedule:'} text2={schedule} />
          <InfoRow
            text1={'When:'}
            text2={`${classroomStartTime} - ${classroomEndTime}`}
          />
          <InfoRow text1={'Where:'} text2={'ONLINE'} />
          {/* <InfoRow text1={'Instructor:'} text2={instructorName} /> */}
        </View>

        <View
          style={{
            flexDirection: 'row',
            position: 'absolute',
            bottom: 25,
            width: 280,
            justifyContent: 'space-evenly',
          }}
        >
          {isInstructor && (
            <TouchableOpacity
              style={[
                styles.button,
                {
                  background: student.enrolled
                    ? solveColors.grey2
                    : `linear-gradient(to right,  ${solveColors.green2} 50%, ${solveColors.green} 90%)`,
                },
                videoId ? { width: '30%' } : { width: '40%' },
              ]}
              onPress={handlePressEnroll}
              disabled={student.enrolled}
            >
              <Text style={styles.buttonText}>
                {student.enrolled ? 'Enrolled' : 'Enroll'}
              </Text>
            </TouchableOpacity>
          )}
          {isStudent && (
            <TouchableOpacity
              style={[
                styles.button,
                {
                  background: student.enrolled
                    ? solveColors.grey2
                    : `linear-gradient(to right,  ${solveColors.green2} 50%, ${solveColors.green} 90%)`,
                },
                videoId ? { width: '30%' } : { width: '40%' },
              ]}
              onPress={handlePressEnroll}
              disabled={student.enrolled}
            >
              <Text style={styles.buttonText}>
                {student.enrolled ? 'Enrolled' : 'Enroll'}
              </Text>
            </TouchableOpacity>
          )}
          {isFamily && (
            <View
              style={{
                width: '100%',
                justifyContent: 'space-around',
                flexDirection: 'row',
              }}
            >
              <FormControl>
                <Select
                  native
                  onChange={e => {
                    setStudent(students[e.target.value])
                  }}
                >
                  {students.map((s, index) => (
                    <option
                      key={index}
                      value={index}
                    >{`${s.firstName}`}</option>
                  ))}
                </Select>
              </FormControl>
              <TouchableOpacity
                style={[
                  styles.button,
                  {
                    background: student.enrolled
                      ? solveColors.grey2
                      : `linear-gradient(to right,  ${solveColors.green2} 50%, ${solveColors.green} 90%)`,
                  },
                  videoId ? { width: '30%' } : { width: '40%' },
                ]}
                onPress={handlePressEnroll}
                disabled={student.enrolled}
              >
                <Text style={styles.buttonText}>
                  {student.enrolled ? 'Enrolled' : 'Enroll'}
                </Text>
              </TouchableOpacity>
            </View>
          )}
          {videoId !== null && (
            <TouchableOpacity
              style={[
                styles.button,
                {
                  background:
                    'linear-gradient(to right,  #f5a700 50%, #ff6a00 90%)',
                },
                { width: '30%' },
              ]}
              onPress={() => {
                openVideo(videoId)
              }}
            >
              <Text style={styles.buttonText}>Demo</Text>
            </TouchableOpacity>
          )}
          {hasPreview && (
            <TouchableOpacity
              style={[
                styles.button,
                {
                  background:
                    'linear-gradient(to right,  #f5a700 50%, #ff6a00 90%)',
                  width: '30%',
                },
              ]}
              onPress={() => {
                if (!cookies['token']) {
                  openLoginPopup()
                } else {
                  sessionStorage.setItem('classroomTitle', title)
                  sessionStorage.setItem('classroomId', classroomId)
                  sessionStorage.setItem('preview', 1)
                  history.push({
                    pathname: '/lessons',
                    search: `classroomId=${classroomId}`,
                  })
                }
              }}
            >
              <Text style={styles.buttonText}>Preview</Text>
            </TouchableOpacity>
          )}
        </View>
      </Card.Body>
    </Card>
  )
})

export default withRouter(PromoCard)

const styles = StyleSheet.create({
  buttonText: {
    color: 'white',
    fontSize: 'vmin',
    fontWeight: 'bold',
    textAlign: 'center',
    textAlignVertical: 'center',
  },
  button: {
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: 50,
    height: 30,
    alignSelf: 'center',
    alignItems: 'center',
    boxShadow: '5px 5px 3px rgba(0, 0, 0, 0.25)',
  },
})
