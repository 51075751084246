import React from 'react'
import {
  Text,
  View,
  TouchableOpacity,
  StyleSheet,
  FlatList,
} from 'react-native'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'

const RadioButtons = props => (
  <View style={{ marginLeft: 10 }}>
    <Text style={styles.fieldDescriptor}>{props.name}</Text>
    <FlatList
      data={props.options}
      horizontal
      keyExtractor={item => item.value}
      renderItem={({ item }) => {
        const selected = props.field === item.value
        return (
          <TouchableOpacity
            style={styles.radioButton}
            onPress={() => {
              props.setField(item.value)
            }}
          >
            {selected && (
              <RadioButtonCheckedIcon style={{ width: 20, marginRight: 5 }} />
            )}
            {!selected && (
              <RadioButtonUncheckedIcon style={{ width: 20, marginRight: 5 }} />
            )}
            <Text style={{ fontSize: 16 }}>{item.name}</Text>
          </TouchableOpacity>
        )
      }}
    />
  </View>
)

const styles = StyleSheet.create({
  fieldDescriptor: {
    fontSize: 12,
    marginBottom: '3px',
  },
  radioButton: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 15,
  },
})

export default RadioButtons
