import {
  Text,
  View,
  FlatList,
  TouchableOpacity,
  Dimensions,
  Image,
} from 'react-native'
import React, { Component } from 'react'
import styles from './styles'
import { withRouter } from 'react-router-dom'
import { Query } from 'react-apollo'
import {
  CREATE_SUBMISSION,
  GET_LESSON_ASSIGNMENTS,
  GET_SUBMISSION,
  UPDATE_SUBMISSION_REVIEWED,
  GET_ATTENDEES,
  GET_MAKEUP_ASSIGNMENTS,
} from './../../../services/queries'
import jwt from 'jsonwebtoken'
import LoadingPage from './../Loading/LoadingPage'
import { DialogModal, InfoRow } from './../../components/'
import { Redirect } from 'react-router-dom'
import lockIcon from './../../images/lock_icon.svg'
import homeworkIcon from './../../images/homework.svg'
import contestIcon from './../../images/contest.svg'
import instructionIcon from './../../images/instruction.svg'
import practiceIcon from './../../images/practice.svg'
import quizIcon from './../../images/quiz.svg'
import reviewIcon from './../../images/review.svg'
import _ from 'lodash'
import WebinarCard from './WebinarCard'
import PrintModal from './../ProblemSet/PrintModal'
import PrintIcon from '@material-ui/icons/Print'
import solveColors from '../../components/colors'
import { withCookies } from 'react-cookie'

class LessonForm extends Component {
  constructor(props) {
    super(props)
    Dimensions.addEventListener('change', this.updateWidth)
    this.state = {
      windowWidth: Dimensions.get('window').width,
      submissionId: null,
      assignmentId: null,
      allowedAttempts: null,
      bestSubmissionId: null,
      reviewDlgModal: false,
      employee:
        this.props.cookies.get('instructor') === '1' ||
        this.props.cookies.get('admin') === '1',
      printDialogVisible: false,
      problemSetId: null,
    }
  }
  componentDidMount = () => {
    sessionStorage.removeItem('currentProbNum')
    sessionStorage.removeItem('submissionId')
    sessionStorage.removeItem('review')
    sessionStorage.removeItem('corrections')
    sessionStorage.removeItem('attemptNumber')
    sessionStorage.removeItem('size')
  }
  openReviewDlgModal = () => {
    this.setState({ reviewDlgModal: true })
  }
  closeReviewDlgModal = () => {
    this.setState({ reviewDlgModal: false })
  }
  openPrintDialog = problemSetId => {
    this.setState({ printDialogVisible: true, problemSetId })
  }
  closePrintDialog = () => {
    this.setState({ printDialogVisible: false })
  }
  updateWidth = () => {
    this.setState({ windowWidth: Dimensions.get('window').width })
  }

  createSubmission = _.throttle(async assignmentId => {
    if (this.state.employee) {
      this.props.history.push({
        pathname: '/problemSets',
        search: `assignmentId=${assignmentId}`,
      })
      return
    }
    const { data } = await this.props.client.mutate({
      mutation: CREATE_SUBMISSION,
      context: {
        headers: { 'X-Ardent-Session-Id': sessionStorage.getItem('sessionId') },
      },
      variables: {
        assignmentId: assignmentId,
        studentId: jwt.decode(this.props.cookies.get('token')).id,
      },
    })
    const { id: submissionId } = data.createSubmission
    this.setState({
      submissionId: submissionId,
      assignmentId: assignmentId,
    })
    this.props.setSubmissionLoading(true)
  }, 5000)
  updateSubmissionReviewed = async submissionId => {
    await this.props.client.mutate({
      mutation: UPDATE_SUBMISSION_REVIEWED,
      context: {
        headers: { 'X-Ardent-Session-Id': sessionStorage.getItem('sessionId') },
      },
      variables: { submissionId },
    })
  }
  renderProblemType(kind, purpose) {
    if (kind !== 'INSTRUCTION') return <Text>{kind}</Text>
    return (
      <Text>
        {purpose === 'MENTAL'
          ? 'Mental Math'
          : purpose === 'SKILL'
          ? 'Skill Builder'
          : purpose === 'PROBLEM_SOLVING'
          ? 'Problem Solving'
          : 'Instruction'}
      </Text>
    )
  }

  render() {
    const { history, lessonPlanType, lessonId, openModal, makeup } = this.props
    const preview = lessonPlanType === 'INTRODUCTION'
    const {
      submissionId,
      assignmentId,
      employee,
      windowWidth,
      bestSubmissionId,
      reviewDlgModal,
      printDialogVisible,
    } = this.state
    if (submissionId) {
      sessionStorage.setItem('submissionId', submissionId)
      return (
        <Redirect
          to={{
            pathname: '/problemSets',
            search: `assignmentId=${assignmentId}`,
          }}
        />
      )
    }

    const studentId = jwt.decode(this.props.cookies.get('token')).id
    return (
      <Query
        query={GET_MAKEUP_ASSIGNMENTS}
        variables={{
          id: makeup ? makeup.toLesson.id : null,
        }}
        skip={!makeup}
      >
        {({ data: data0, loading: loading0, error: error0 }) => (
          <Query
            query={GET_LESSON_ASSIGNMENTS}
            variables={{
              id: lessonId,
            }}
          >
            {({ data: data1, loading: loading1, error: error1 }) => (
              <Query
                query={GET_ATTENDEES}
                variables={{
                  filter: {
                    lessonId: lessonId,
                    studentId: jwt.decode(this.props.cookies.get('token')).id,
                  },
                }}
                skip={true}
              >
                {({ data: data2, loading: loading2, error: error2 }) => (
                  <Query
                    query={GET_SUBMISSION}
                    context={{ headers: { 'cache-control': 'no-cache' } }}
                    variables={{
                      filter: {
                        lessonId: lessonId,
                        studentId: studentId,
                      },
                    }}
                  >
                    {({ data: data3, loading: loading3, error: error3 }) => {
                      if (error0) return `${error0}`
                      if (error1) return `${error1}`
                      if (error2) return `${error2}`
                      if (error3) return `${error3}`
                      if (loading0 || loading1 || loading2 || loading3) {
                        return (
                          <View style={{ height: '60%' }}>
                            <LoadingPage />
                          </View>
                        )
                      }
                      let {
                        assignments,
                        dueOn,
                        webinarId,
                        webinarUrl,
                        webinarReplay,
                        primaryOfficeHourOn,
                        makeupOfficeHourOn,
                        officeHourMeetingId,
                        officeHourDuration,
                        startOn,
                        endOn,
                      } = data1.lesson
                      const webinar = webinarId
                        ? {
                            webinarReplay,
                            webinarUrl,
                            webinarId,
                            primaryOfficeHourOn,
                            makeupOfficeHourOn,
                            officeHourMeetingId,
                            officeHourDuration,
                            lessonStartTime: startOn,
                            lessonEndTime: endOn,
                            studentId,
                            lessonId,
                          }
                        : null
                      //const { attendees } = data2
                      assignments.sort(
                        (a, b) => a.problemSet.order - b.problemSet.order
                      )
                      if (webinarId) {
                        assignments = [webinar, ...assignments]
                      }
                      if (assignments.length === 0) {
                        return 'This lessons is under construction :('
                      }
                      const teachingStyle = sessionStorage.getItem(
                        'teachingStyle'
                      )
                      return (
                        <View>
                          <View>
                            <PrintModal
                              visible={printDialogVisible}
                              close={this.closePrintDialog}
                              problemSetId={this.state.problemSetId}
                            />
                            <DialogModal
                              visible={reviewDlgModal}
                              transparent={true}
                              onYes={async () => {
                                sessionStorage.setItem('review', 1)
                                await this.updateSubmissionReviewed(
                                  bestSubmissionId
                                )
                                this.setState({
                                  submissionId: bestSubmissionId,
                                })
                              }}
                              onNo={async () => {
                                this.closeReviewDlgModal()
                              }}
                              message={
                                'By reviewing this assignment you will no longer be able to make any further submissions. Would you like to continue?'
                              }
                            />

                            <FlatList
                              numColumns={
                                Math.floor((windowWidth - 300) / 300) >= 1
                                  ? Math.floor((windowWidth - 300) / 300)
                                  : 1
                              }
                              key={Math.floor((windowWidth - 300) / 300)}
                              keyExtractor={item => item.id}
                              horizontal={false}
                              data={assignments}
                              renderItem={({ item }) => {
                                if (item.webinarId) {
                                  return <WebinarCard {...item} />
                                }
                                const { id: assignmentId } = item
                                const {
                                  title,
                                  order,
                                  allowedAttempts,
                                  kind,
                                  videoId,
                                  purpose,
                                  learningObjective,
                                  autoGrading,
                                  id: problemSetId,
                                } = item.problemSet
                                const unlimitedAttempts =
                                  teachingStyle === 'SELF_PACED'
                                const makeupAssignment = makeup
                                  ? data0.lesson.assignments.find(
                                      a => a.problemSet.id === problemSetId
                                    )
                                  : null
                                const status = makeupAssignment
                                  ? makeupAssignment.status
                                  : item.status
                                const startOn = makeupAssignment
                                  ? makeupAssignment.startOn
                                  : item.startOn

                                /*
                          kind === 'QUIZ'
                            ? Date.now() < prevMonday
                            : attendees[0] === 'UNKNOWN' ||
                              (startOn && Date.now() < Date.parse(startOn))
                        */
                                function typeIcon(kind) {
                                  switch (kind) {
                                    case 'PREVIEW':
                                      return homeworkIcon
                                    case 'PRACTICE':
                                      return practiceIcon
                                    case 'HOMEWORK':
                                      return homeworkIcon
                                    case 'INSTRUCTION':
                                      return instructionIcon
                                    case 'EXERCISE':
                                      return instructionIcon
                                    case 'QUIZ':
                                      return quizIcon
                                    case 'REVIEW':
                                      return reviewIcon
                                    case 'CONTEST':
                                      return contestIcon
                                    default:
                                      return practiceIcon
                                  }
                                }
                                const submissions = data3.submissions.filter(
                                  sub => sub.assignment.id === assignmentId
                                )
                                const completedSubmissions = submissions
                                  .filter(submission => submission.submittedAt)
                                  .sort((s1, s2) => s2.sum - s1.sum)
                                  .sort(
                                    (s1, s2) =>
                                      s2.attemptNumber - s1.attemptNumber
                                  )
                                const gradedSubmissions = completedSubmissions
                                  .filter(submission => submission.graded)
                                  .sort((s1, s2) => s2.sum - s1.sum)
                                  .sort(
                                    (s1, s2) =>
                                      s2.attemptNumber - s1.attemptNumber
                                  )
                                const InProgressSubmissions = submissions.filter(
                                  submission => !submission.submittedAt
                                )
                                if (completedSubmissions.length > 1) {
                                  console.error(
                                    `This assignment(${title}) has multiple completed submissions`
                                  )
                                } else if (submissions.length > 2) {
                                  console.error(
                                    `This assignment(${title}) has multiple unfinished submissions`
                                  )
                                }
                                const attemptNumber =
                                  completedSubmissions.length > 0
                                    ? completedSubmissions[0].attemptNumber
                                    : 0
                                const excused =
                                  completedSubmissions.length > 0
                                    ? completedSubmissions[0].excused
                                    : false
                                /*
                            const earlyStartExceptions = ['M201', 'M202']
                            const dateCondition =
                              earlyStartExceptions.find(d =>
                                sessionStorage
                                  .getItem('classroomTitle')
                                  .includes(d)
                              ) !== undefined
                                ? Date.now() <
                                  Date.parse(startOn) - 24 * 60 * 60 * 1000
                                : Date.now() < Date.parse(startOn)
                            */
                                const startDate = new Date(Date.parse(startOn))
                                const dateCondition = Date.now() < startDate
                                const locked =
                                  !employee &&
                                  (status === 'NOT_ASSIGNED' ||
                                    (status !== 'ASSIGNED' &&
                                      !preview &&
                                      !employee &&
                                      teachingStyle !== 'SELF_PACED' &&
                                      teachingStyle !== 'MENTORED' &&
                                      startOn &&
                                      dateCondition))
                                const reviewed =
                                  completedSubmissions.length > 0
                                    ? completedSubmissions[0].reviewed
                                    : false
                                const correctionsAllowed =
                                  (kind === 'HOMEWORK' || kind === 'QUIZ') &&
                                  gradedSubmissions.length > 0 &&
                                  autoGrading
                                const correctionLock =
                                  correctionsAllowed &&
                                  gradedSubmissions[0].overallGrade >= 100
                                const reviewUnlockDate = new Date(
                                  Date.parse(dueOn)
                                )
                                const bestScore = excused
                                  ? 'N/A'
                                  : gradedSubmissions.length > 0
                                  ? gradedSubmissions[0].overallGrade
                                  : 0
                                return (
                                  <View style={styles.container}>
                                    {locked && (
                                      <View style={styles.lock_overlay} />
                                    )}
                                    {locked && (
                                      <Image
                                        source={lockIcon}
                                        style={styles.lock_icon}
                                      />
                                    )}
                                    {kind === 'INSTRUCTION' && (
                                      <TouchableOpacity
                                        style={{
                                          position: 'absolute',
                                          left: 15,
                                          top: 15,
                                        }}
                                        onPress={() => {
                                          this.openPrintDialog(problemSetId)
                                        }}
                                      >
                                        <PrintIcon
                                          style={{ color: solveColors.grey4 }}
                                        />
                                      </TouchableOpacity>
                                    )}
                                    <Text>{status}</Text>
                                    <Image
                                      source={typeIcon(kind)}
                                      style={styles.type_icon}
                                    />
                                    {this.renderProblemType(kind, purpose)}

                                    <React.Fragment>
                                      <Text
                                        style={[
                                          styles.problem_title,
                                          styles.text,
                                        ]}
                                      >
                                        {`${order}. ${title}`}
                                      </Text>

                                      {kind !== 'REVIEW' && (
                                        <React.Fragment>
                                          <Text style={styles.text}>
                                            {learningObjective}
                                          </Text>
                                          <InfoRow
                                            style={styles.info_row}
                                            text1={'Attempts:'}
                                            text2={
                                              excused
                                                ? 'Excused'
                                                : unlimitedAttempts
                                                ? `${attemptNumber}`
                                                : `${attemptNumber}/${allowedAttempts}`
                                            }
                                          />
                                          <InfoRow
                                            style={styles.info_row}
                                            text1={'Grade:'}
                                            text2={
                                              excused
                                                ? 'N/A'
                                                : gradedSubmissions.length > 0
                                                ? `${bestScore}%`
                                                : completedSubmissions.length >
                                                  0
                                                ? 'To be Graded'
                                                : submissions.length > 0
                                                ? 'In Progress'
                                                : 'N/A'
                                            }
                                          />
                                        </React.Fragment>
                                      )}
                                    </React.Fragment>

                                    <View
                                      style={{
                                        marginTop: 15,
                                        flex: 1,
                                        flexDirection: 'row',
                                        alignItems: 'flex-end',
                                      }}
                                    >
                                      {teachingStyle !== 'CLASSROOM' &&
                                        kind !== 'REVIEW' && (
                                          <TouchableOpacity
                                            disabled={locked || correctionLock}
                                            onPress={async () => {
                                              if (reviewed) {
                                                openModal(
                                                  'You cannot make another submission because you have already reviewed this assignment.'
                                                )
                                                return
                                              }

                                              if (
                                                unlimitedAttempts ||
                                                attemptNumber < allowedAttempts
                                              ) {
                                                sessionStorage.setItem(
                                                  'size',
                                                  (kind === 'INSTRUCTION' ||
                                                    kind === 'EXERCISE' ||
                                                    kind === 'HOMEWORK' ||
                                                    kind === 'QUIZ') &&
                                                    this.state.employee
                                                    ? 'large'
                                                    : 'small'
                                                )
                                                if (
                                                  teachingStyle ===
                                                    'SELF_PACED' &&
                                                  videoId
                                                ) {
                                                  sessionStorage.setItem(
                                                    'videoId',
                                                    videoId
                                                  )
                                                }
                                                if (correctionsAllowed) {
                                                  this.setState({
                                                    submissionId:
                                                      gradedSubmissions[0].id,
                                                    assignmentId,
                                                  })

                                                  sessionStorage.setItem(
                                                    'corrections',
                                                    1
                                                  )
                                                  sessionStorage.setItem(
                                                    'attemptNumber',
                                                    attemptNumber + 1
                                                  )
                                                } else if (
                                                  completedSubmissions.length >
                                                  0
                                                ) {
                                                  this.setState({
                                                    submissionId:
                                                      completedSubmissions[0]
                                                        .id,
                                                    assignmentId,
                                                  })
                                                } else if (
                                                  InProgressSubmissions.length >
                                                  0
                                                ) {
                                                  this.setState({
                                                    submissionId:
                                                      InProgressSubmissions[0]
                                                        .id,
                                                    assignmentId,
                                                  })
                                                } else {
                                                  await this.createSubmission(
                                                    assignmentId
                                                  )
                                                }
                                                sessionStorage.setItem(
                                                  'attemptNumber',
                                                  attemptNumber + 1
                                                )
                                              } else {
                                                openModal(
                                                  'You do not have any attempts left :('
                                                )
                                              }
                                            }}
                                          >
                                            <Text
                                              style={[
                                                styles.start_button,
                                                locked || correctionLock
                                                  ? styles.locked_button
                                                  : null,
                                              ]}
                                            >
                                              {correctionsAllowed
                                                ? 'Revise'
                                                : 'Start'}
                                            </Text>
                                          </TouchableOpacity>
                                        )}

                                      {videoId !== null &&
                                        videoId !== undefined &&
                                        videoId !== '' &&
                                        (kind !== 'QUIZ' ||
                                          gradedSubmissions.length > 0 ||
                                          employee) && (
                                          <TouchableOpacity
                                            disabled={locked}
                                            onPress={() => {
                                              history.push({
                                                pathname: '/video',
                                                search: `assignmentId=${assignmentId}`,
                                              })
                                            }}
                                          >
                                            <Text
                                              style={[
                                                styles.learn_button,
                                                locked
                                                  ? styles.locked_button
                                                  : null,
                                              ]}
                                            >
                                              Video
                                            </Text>
                                          </TouchableOpacity>
                                        )}
                                      {!preview && kind !== 'REVIEW' && (
                                        <TouchableOpacity
                                          disabled={locked}
                                          onPress={async () => {
                                            if (employee) {
                                              sessionStorage.setItem(
                                                'review',
                                                1
                                              )
                                              this.props.history.push({
                                                pathname: '/problemSets',
                                                search: `assignmentId=${assignmentId}`,
                                              })
                                              return
                                            }
                                            if (
                                              bestScore < 80 &&
                                              new Date() < reviewUnlockDate
                                            ) {
                                              openModal(
                                                `You need to score 80% or higher to unlock review, otherwise reviews will unlock on ${reviewUnlockDate.toDateString()}`
                                              )
                                              return
                                            }
                                            if (attemptNumber > 0) {
                                              if (reviewed) {
                                                sessionStorage.setItem(
                                                  'review',
                                                  1
                                                )
                                                this.setState({
                                                  submissionId:
                                                    completedSubmissions[0].id,
                                                  assignmentId: assignmentId,
                                                })
                                              } else {
                                                this.setState({
                                                  bestSubmissionId:
                                                    completedSubmissions[0].id,
                                                  assignmentId: assignmentId,
                                                })
                                                this.openReviewDlgModal()
                                              }
                                            } else {
                                              openModal(
                                                'You have not done this assignment yet!'
                                              )
                                            }
                                          }}
                                        >
                                          <Text
                                            style={[
                                              styles.review_button,
                                              locked
                                                ? styles.locked_button
                                                : null,
                                            ]}
                                          >
                                            {employee ? 'Solutions' : 'Review'}
                                          </Text>
                                        </TouchableOpacity>
                                      )}
                                    </View>
                                  </View>
                                )
                              }}
                            />
                          </View>
                        </View>
                      )
                    }}
                  </Query>
                )}
              </Query>
            )}
          </Query>
        )}
      </Query>
    )
  }
}

export default withRouter(withCookies(LessonForm))
