import { withRouter } from 'react-router-dom'
import { solveColors } from '../../components/colors'
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native'
import React from 'react'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { VideoPlayerForm } from '../../components/'

/*
Video Privacy Settings for vimeo:
1. Who can watch? -> Hide this video from vimeo.com
2. Where can this be embedded? -> Specific domains
  Domains:
    1.ardentacademy.com
    2.ardentlabs.io
    3.localhost
*/
const VideoForm = props => {
  const gotoLessons = () => {
    const classroomId = sessionStorage.getItem('classroomId')
    props.history.push({
      pathname: '/lessons',
      search: `classroomId=${classroomId}`,
    })
  }
  const videoId = props.videoId
  return (
    <View style={styles.container}>
      <View style={styles.bar}>
        <TouchableOpacity
          style={styles.back_button}
          onPress={() => {
            gotoLessons()
          }}
        >
          <ArrowBackIosIcon />
          <Text>Lessons</Text>
        </TouchableOpacity>
        <Text style={{ margin: 'auto' }}>{props.title}</Text>
      </View>
      <VideoPlayerForm videoId={videoId} />
    </View>
  )
}
const styles = StyleSheet.create({
  videoContainer: {
    width: '100%',
    height: '100%',
  },
  back_button: {
    position: 'absolute',
    left: '2%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  bar: {
    flexDirection: 'row',
    backgroundColor: solveColors.grey2,
    borderTopLeftRadius: 10,
    paddingVertical: 10,
    alignItems: 'center',
  },
  container: {
    backgroundColor: 'white',
    height: '100%',
    marginLeft: 5,
    marginTop: 5,
    borderTopLeftRadius: 10,
    flex: 1,
  },
})
export default withRouter(VideoForm)
