import { StyleSheet } from 'react-native'
import solveColors from '../../components/colors'
export const styles = StyleSheet.create({
  lesson: {
    backgroundColor: solveColors.grey1,
  },
  list: {
    paddingBottom: 20,
    width: '100%',
    alignItems: 'center',
    height: '100%',
    flexDirection: 'column',
    display: 'flex',
    backgroundColor: solveColors.grey1,
  },
  lesson_title: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: solveColors.grey4,
    borderRadius: 100,
    paddingHorizontal: 15,
    paddingVertical: 7,
    marginVertical: 10,
  },
  container: {
    backgroundColor: 'white',
    paddingVertical: '2vh',
    borderRadius: 5,
    borderColor: 'white',
    alignItems: 'center',
    width: 275,
    paddingHorizontal: 10,
    shadowColor: '#000',
    shadowOffset: { width: 5, height: 5 },
    shadowOpacity: 0.25,
    shadowRadius: 2,
    marginHorizontal: 10,
    marginTop: 20,
    marginBottom: 70,
  },
  start_button: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#FFFFFF',
    backgroundColor: solveColors.blue1,
    borderRadius: 10,
    paddingHorizontal: 10,
    paddingVertical: 7,
    marginRight: '15px',
  },
  learn_button: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#FFFFFF',
    backgroundColor: solveColors.green,
    borderRadius: 10,
    paddingHorizontal: 10,
    paddingVertical: 7,
    marginRight: '15px',
  },
  review_button: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#FFFFFF',
    backgroundColor: solveColors.red1,
    borderRadius: 10,
    paddingHorizontal: 10,
    paddingVertical: 7,
  },
  locked_button: {
    backgroundColor: solveColors.grey3,
  },
  problem_title: {
    fontSize: 14,
    fontWeight: 'bold',
    color: 'black',
  },
  text: {
    textAlign: 'center',
    flexWrap: 'wrap',
    flex: 1,
  },
  class_grade: {
    marginLeft: '1%',
    fontSize: 16,
    fontWeight: 'bold',
  },
  lock_overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(200,200,200,0.4)',
  },
  lock_icon: {
    width: 15,
    height: 20,
    position: 'absolute',
    right: 15,
    top: 15,
  },
  type_icon: {
    width: 50,
    height: 50,
    marginBottom: 15,
  },
  info_row: {
    paddingTop: 10,
    marginBottom: 10,
    width: 250,
  },
  lesson_back: {
    flexDirection: 'row',
    paddingVertical: 10,
    paddingHorizontal: 20,
    marginVertical: 10,
    backgroundColor: 'white',
    width: 125,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    shadowColor: '#000',
    shadowOffset: { width: 5, height: 5 },
    shadowOpacity: 0.25,
    shadowRadius: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  message_button: {
    backgroundColor: 'white',
    padding: 10,
    borderRadius: 10,
    marginVertical: 20,
    marginHorizontal: 10,
  },
  announcement_title: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',
  },
})

export default styles
