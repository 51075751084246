import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { GET_CLASSROOMS } from '../../../services/queries/class_overview_queries'
import LoadingPage from '../Loading/LoadingPage'
import CourselistForm from './CourselistForm'
import { ScrollView, Text, View } from 'react-native-web'
import solveColors from '../../components/colors'
import { APCOURSE, RESEARCHCOURSE, YEAR, QUARTER } from '../../../config'

class CourselistPage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <Query
        query={GET_CLASSROOMS}
        variables={{
          filter: {
            $or: [{ status: 'OPENED' }, { status: 'PUBLISHED' }],
            year: YEAR,
            quarter: QUARTER,
            onCatalog: true,
          },
        }}
      >
        {({ data, loading, error }) => {
          if (loading) return <LoadingPage />
          if (error) console.log(error)
          const classrooms = data.classrooms
          //1. Math Olympiad, 2. Accelerated Math, 3. English, 4. Science, 5. Biology, 6. Chemistry, 7. Physics, 8. Programming, 9. AP Classes, 10. Research
          let classList = {
            'Math Olympiad': [],
            'Accelerated Math': [],
            English: [],
            Science: [],
            Biology: [],
            Chemistry: [],
            Physics: [],
            Programming: [],
            AP: [],
            Research: [],
          }
          for (let classroom of classrooms) {
            // classList[classroom.course.subject.name] =
            //   classList[classroom.course.subject.name] || []

            // classList[classroom.course.subject.name].push(classroom)
            switch (classroom.course.subject.name) {
              case 'Olympiad':
                classList['Math Olympiad'].push(classroom)
                break
              case 'Accelerated':
                classList['Accelerated Math'].push(classroom)
                break
              case 'Accelerated English':
                classList['English'].push(classroom)
                break
              case 'Speech and Debate':
                classList['Speech and Debate'].push(classroom)
                break
              case 'Science and Engineering':
                classList['Science'].push(classroom)
                break
              case 'Honors Biology':
                classList['Biology'].push(classroom)
                break
              case 'Honor':
                classList['Chemistry'].push(classroom)
                break
              case 'Physics Olympiad':
                classList['Physics'].push(classroom)
                break
              case 'Python':
                classList['Programming'].push(classroom)
                break
              default:
                break
              // classList['AP'].push(classroom)
            }
            if (APCOURSE.includes(classroom.course.code)) {
              classList['AP'].push(classroom)
            }
            if (RESEARCHCOURSE.includes(classroom.course.code)) {
              classList['Research'].push(classroom)
            }
          }

          for (let key of Object.keys(classList)) {
            classList[key].sort((a, b) => {
              return a.code.localeCompare(b.code)
            })
          }

          return (
            <ScrollView
              style={{ backgroundColor: 'white' }}
              showsVerticalScrollIndicator={false}
            >
              {Object.keys(classList).map(
                subj =>
                  classList[subj].length !== 0 && (
                    <View>
                      <Text style={{ fontSize: 30, color: solveColors.blue1 }}>
                        {subj}
                      </Text>
                      <CourselistForm classes={classList[subj]} />
                    </View>
                  )
              )}
            </ScrollView>
          )
        }}
      </Query>
    )
  }
}

export default CourselistPage
