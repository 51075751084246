import React, { Component } from 'react'
import { View, Image, ImageBackground, Text } from 'react-native'
import BannerMessage from '../../components/BannerMessage'
import ResponseSubscriptionView from '../../components/ResponseSubscriptionView'
import LoginInput from './LoginInput'
import { Redirect, withRouter } from 'react-router-dom'
import logo from './../../images/solve-logo.png'
import login_background from './../../images/login_background.svg'
import { access } from '../../components/styles'
import { ApolloConsumer } from 'react-apollo'
import ForgotCredentialsButton from './ForgotCredentialsButton'
import gets3Img from '../../../utils/s3config'
import { version } from './../../../../package.json'
import {
  USERNAME_CHECK,
  STUDENT_LOGIN,
  START_SESSION,
} from '../../../services/queries'
import _ from 'lodash'
import { TouchableOpacity } from 'react-native-web'
import styles from './styles'
import jwt from 'jsonwebtoken'
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core'
import FamilyLoginPage from '../FamilyLogin/FamilyLoginPage'
import { isMobile } from './../../../config'
import { withCookies } from 'react-cookie'

class LoginPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      validUN: false,
      redirect: false,
      userData: null,
      badUsername: false,
      badPassword: false,
      gotoCatalog: false,
      loading: false,
      loginMode: 'student',
    }
    this.handleUNChange = this.handleUNChange.bind(this)
    this.handlePWChange = this.handlePWChange.bind(this)
    //this.props.hideBars()
  }

  componentDidMount() {
    // this.props.hideBars()
  }

  handleUNChange(username) {
    this.setState({ username: username })
  }

  handlePWChange(password) {
    this.setState({ password: password })
  }
  throttledUsernameCheck = _.throttle(async client => {
    const { data } = await client.query({
      query: USERNAME_CHECK,
      variables: { id: this.state.username },
    })
    if (data.requestStudentByUsername.exists)
      this.setState({
        validUN: true,
        userData: data,
        badUSername: false,
      })
    else this.setState({ badUsername: true })
  }, 3000)

  throttledStudentLogin = _.throttle(async client => {
    sessionStorage.clear()
    Object.keys(this.props.cookies.getAll()).forEach(k =>
      this.props.cookies.remove(k)
    )
    const { data } = await client.mutate({
      mutation: STUDENT_LOGIN,
      variables: {
        id: this.state.username,
        pw: this.state.password,
        filters: {
          stage: 'TRIAL',
          status: 'OPEN',
        },
      },
    })
    if (data && data.studentLogin.success) {
      this.props.cookies.set('token', data.studentLogin.token)
      const { data: data2 } = await client.mutate({
        mutation: START_SESSION,
        variables: {
          studentId: jwt.decode(data.studentLogin.token).id,
        },
      })
      sessionStorage.setItem('sessionId', data2.startStudentSession)
      const trials = []
      data.studentLogin.student.opportunities.forEach(opportunity => {
        opportunity.trials.forEach(trial => {
          trials.push({
            toLesson: trial.toLesson.id,
            classroomId: trial.toLesson.classroom.id,
          })
        })
      })
      sessionStorage.setItem('trials', JSON.stringify(trials))
      this.state.redirect = true
      let today = new Date()
      let bday = new Date(data.studentLogin.student.birthdate)
      if (
        today.getMonth() === bday.getMonth() &&
        today.getDate() === bday.getDate()
      ) {
        sessionStorage.setItem('birthdate', true)
      }
      this.handlePWChange('')
      this.setState({ badPassword: false })
    } else {
      this.setState({ badPassword: true })
    }
  }, 3000)

  render() {
    if (this.state.loginMode === 'parent') {
      return <FamilyLoginPage handleBack={this.props.handleBack} />
    }

    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: '/dashboard',
          }}
        />
      )
    }

    if (this.state.gotoCatalog) {
      this.props.history.push({ pathname: '/catalog' })
    }

    if (this.state.validUN && this.state.userData) {
      const {
        firstName,
        lastName,
        image,
      } = this.state.userData.requestStudentByUsername
      sessionStorage.clear()
      Object.keys(this.props.cookies.getAll()).forEach(k =>
        this.props.cookies.remove(k)
      )
      this.props.cookies.set('firstName', firstName)
      this.props.cookies.set('lastName', lastName)
      this.props.cookies.set('imageUrl', image ? gets3Img(image) : null)
    }

    return (
      <ApolloConsumer>
        {client => (
          <ImageBackground source={login_background} style={access.background}>
            {!this.state.validUN && this.state.badUsername && (
              <BannerMessage msg="Whoops, thats not a valid username" />
            )}
            {this.state.badPassword && (
              <BannerMessage msg="Whoops, thats not a valid password" />
            )}

            <View style={access.header}>
              {!isMobile && <ResponseSubscriptionView />}
              <Image
                style={access.logo}
                source={
                  this.state.validUN ? this.props.cookies.get('imageUrl') : logo
                }
              />

              <FormControl color="primary" variant="contained">
                <RadioGroup
                  row
                  aria-label="login"
                  name="login"
                  value={this.state.loginMode}
                  onChange={e => this.setState({ loginMode: e.target.value })}
                >
                  <FormControlLabel
                    value="parent"
                    control={<Radio />}
                    label="Parent"
                    style={{ color: 'white' }}
                  />
                  <FormControlLabel
                    value="student"
                    control={<Radio />}
                    label="Student"
                    style={{ color: 'white' }}
                  />
                </RadioGroup>
              </FormControl>

              <LoginInput
                value={
                  this.state.validUN ? this.state.password : this.state.username
                }
                onChangeText={
                  this.state.validUN ? this.handlePWChange : this.handleUNChange
                }
                disabled={
                  this.state.validUN
                    ? this.password === ''
                    : this.state.username === ''
                }
                secure={this.state.validUN ? true : false}
                placeholder={this.state.validUN ? 'Password' : 'Username'}
                buttonText={this.state.validUN ? 'Login' : 'Next'}
                onSubmit={async () => {
                  if (!this.state.validUN) {
                    await this.throttledUsernameCheck(client)
                  } else {
                    this.setState({ loading: true })

                    await this.throttledStudentLogin(client)
                    this.setState({ loading: false })
                  }
                }}
              />
              <TouchableOpacity
                style={styles.button}
                onPress={() => {
                  if (this.state.validUN) {
                    this.setState({ validUN: false })
                  } else {
                    this.props.handleBack
                      ? this.props.handleBack()
                      : this.props.history.push('/')
                  }
                }}
              >
                <Text style={{ color: 'white' }}>Back</Text>
              </TouchableOpacity>
              <ForgotCredentialsButton forgottenItem={'username'} />
              <ForgotCredentialsButton forgottenItem={'password'} />
              <Text style={styles.basicText}>Version {version}</Text>
              <Text style={styles.basicText}>
                {'\n'}This site is for enrolled students of Ardent Academy.
                {'\n'}To enroll, call (949) 861-2211.
              </Text>
            </View>
          </ImageBackground>
        )}
      </ApolloConsumer>
    )
  }
}

export default withRouter(withCookies(LoginPage))
