import React, { useState } from 'react'
import { Text, Image, View } from 'react-native'
import { withRouter } from 'react-router-dom'
import Button from './Button'
import styles from './styles'
import moment from 'moment'
import { solveColors } from '../../components/colors'
import { ApolloConsumer } from 'react-apollo'
import TextField from '../../components/TextField'
import { DropDownMenu, CheckBoxMenu } from '../../components/'
import { UPDATE_INSTRUCTOR_INFO } from './../../../services/queries'

const EditInstructorProfileForm = props => {
  const {
    firstName,
    lastName,
    gender,
    instructorId,
    imageUrl,
    company,
    birthdate,
    workEmail,
    personalEmail,
    mobileNumber,
  } = props
  const companyOptions = [
    'ArdentAcademy',
    'ArdentLabs',
    'STEAMForAll',
    'Independent',
  ]

  const defaultFirstName = firstName
  const defaultLastName = lastName
  const defaultMale = gender === 'MALE' ? true : false
  const defaultFemale = gender === 'FEMALE' ? true : false
  const defaultCompany = company
  const defaultWorkEmail = workEmail
  const defaultPersonalEmail = personalEmail
  const defaultMobileNumber = mobileNumber
  const defaultBirthdate = new Date(birthdate)
  const defaultMonth = defaultBirthdate.getMonth() + 1
  const defaultDay = defaultBirthdate.getDate()
  const defaultYear = defaultBirthdate.getFullYear()

  const [firstNameField, setFNField] = useState(firstName)
  const [lastNameField, setLNField] = useState(lastName)
  const [maleField, setMaleField] = useState(gender === 'MALE' ? true : false)
  const [femaleField, setFemaleField] = useState(
    gender === 'FEMALE' ? true : false
  )
  const [companyField, setCompanyField] = useState(company)
  const [workEmailField, setWorkEmailField] = useState(workEmail)
  const [personalEmailField, setPersonalEmailField] = useState(personalEmail)
  const [mobileNumberField, setMobileNumberField] = useState(mobileNumber)
  const [monthField, setMonth] = useState(defaultMonth)
  const [dayField, setDay] = useState(defaultDay)
  const [yearField, setYear] = useState(defaultYear)
  const [focus, setFocus] = useState(null)

  const handleSetGender = () => {
    setFocus(null)
    if (maleField) {
      setMaleField(false)
      setFemaleField(true)
    } else {
      setMaleField(true)
      setFemaleField(false)
    }
  }

  const handleCancel = () => {
    props.setEditable(false)
  }
  const handleReset = () => {
    setFNField(defaultFirstName)
    setLNField(defaultLastName)
    setMaleField(defaultMale)
    setFemaleField(defaultFemale)
    setCompanyField(defaultCompany)
    setWorkEmailField(defaultWorkEmail)
    setPersonalEmailField(defaultPersonalEmail)
    setMobileNumberField(defaultMobileNumber)
    setMonth(defaultMonth)
    setDay(defaultDay)
    setYear(defaultYear)
    setFocus(null)
  }

  const handleSave = async client => {
    if (process.env.NODE_ENV !== 'production') {
      await client.mutate({
        mutation: UPDATE_INSTRUCTOR_INFO,
        variables: {
          id: instructorId,
          input: {
            firstName: firstNameField,
            lastName: lastNameField,
            gender: maleField ? 'MALE' : 'FEMALE',
            company: companyField,
            workEmail: workEmailField,
            personalEmail: personalEmailField,
            mobileNumber: mobileNumberField,
            birthdate: new Date(
              yearField,
              monthField - 1,
              dayField
            ).toISOString(),
          },
        },
      })
    }
    props.refetch()
    props.setEditable(false)
  }

  return (
    <ApolloConsumer>
      {client => (
        <View style={[styles.view, { paddingVertical: '8vh' }]}>
          <View style={styles.editProfileView}>
            <Image style={styles.icon} source={imageUrl} />
            <View style={styles.header}>
              <Text style={styles.title}>Edit Profile</Text>
              <Text style={styles.subtitle}>
                Modify your personal information here
              </Text>
            </View>
            <View style={styles.inputs}>
              <View style={styles.fieldRow}>
                <TextField
                  name={'First Name'}
                  field={firstNameField}
                  onChange={setFNField}
                  focus={focus}
                  setFocus={setFocus}
                />
                <TextField
                  name={'Last Name'}
                  field={lastNameField}
                  onChange={setLNField}
                  focus={focus}
                  setFocus={setFocus}
                />
              </View>
              <Text style={[styles.fieldText, { marginHorizontal: '20px' }]}>
                Gender
              </Text>
              <View
                style={[
                  styles.fieldRow,
                  { alignSelf: 'flex-start', marginHorizontal: '20px' },
                ]}
              >
                <CheckBoxMenu
                  name={'Male'}
                  field={maleField}
                  onChange={handleSetGender}
                />
                <CheckBoxMenu
                  name={'Female'}
                  field={femaleField}
                  onChange={handleSetGender}
                />
              </View>
              <View style={styles.SelectableMenu}>
                <DropDownMenu
                  name={'Day'}
                  width={{ width: 150 }}
                  options={Array.from(
                    {
                      length: moment(
                        yearField + '-' + monthField,
                        'YYYY-MM'
                      ).daysInMonth(),
                    },
                    (x, i) => i + 1
                  )}
                  field={dayField}
                  onChange={setDay}
                  setFocus={setFocus}
                />
                <DropDownMenu
                  name={'Month'}
                  width={{ width: 280 }}
                  options={moment.months()}
                  field={moment()
                    .month(Number(monthField - 1))
                    .format('MMMM')}
                  onChange={val => {
                    setMonth(
                      moment()
                        .month(val)
                        .format('MM')
                    )
                  }}
                  setFocus={setFocus}
                />
                <DropDownMenu
                  name={'Year'}
                  width={{ width: 150 }}
                  options={Array.from(
                    { length: 60 },
                    (x, i) => i + new Date().getFullYear() - 60
                  )}
                  field={yearField}
                  onChange={setYear}
                  setFocus={setFocus}
                />
              </View>
              <View style={styles.fieldRow}>
                <TextField
                  name={'Personal Email'}
                  field={personalEmailField}
                  onChange={setPersonalEmailField}
                  focus={focus}
                  setFocus={setFocus}
                />
                <TextField
                  name={'Mobile Number'}
                  keyboardType={'numeric'}
                  field={mobileNumberField}
                  onChange={setMobileNumberField}
                  focus={focus}
                  setFocus={setFocus}
                />
              </View>
              <View style={styles.fieldRow}>
                <TextField
                  name={'Work Email'}
                  field={workEmailField}
                  onChange={setWorkEmailField}
                  focus={focus}
                  setFocus={setFocus}
                />
                <DropDownMenu
                  name={'Company'}
                  width={{ width: 300 }}
                  field={companyField}
                  options={companyOptions}
                  onChange={setCompanyField}
                  setFocus={setFocus}
                />
              </View>
              <View style={styles.editButtons}>
                <Button
                  buttonStyling={[
                    styles.defaultButton,
                    { backgroundColor: solveColors.red1 },
                  ]}
                  text="CANCEL"
                  onPress={handleCancel}
                />
                <Button
                  buttonStyling={[
                    styles.defaultButton,
                    styles.resetButton,
                    { backgroundColor: solveColors.orange },
                  ]}
                  text="RESET"
                  onPress={handleReset}
                />
                <Button
                  buttonStyling={[
                    styles.defaultButton,
                    styles.green,
                    styles.saveButton,
                  ]}
                  text="SAVE"
                  onPress={() => {
                    handleSave(client)
                  }}
                />
              </View>
              <Text style={{ fontSize: 10, color: 'dimgrey', margin: 20 }}>
                To change your password, please refer to the change password
                button which can be found on the profile page
              </Text>
            </View>
          </View>
        </View>
      )}
    </ApolloConsumer>
  )
}

export default withRouter(EditInstructorProfileForm)
