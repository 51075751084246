import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { Dimensions, ScrollView, View } from 'react-native-web'
import { withRouter } from 'react-router-dom'
import { GET_FAMILY_STUDENTS } from '../../../services/queries'
import LoadingPage from '../Loading/LoadingPage'
import RecommendationForm from './RecommendationForm'
import StudentInfoTab from '../../components/StudentInfoTab'
import AddStudentPopup from '../../components/AddStudentPopup'
import { isMobile } from '../../../config'
import { VideoModal } from '../../components'
import BadgeBanner from '../../components/BadgeBanner'
import { withCookies } from 'react-cookie'
import ReferralPage from '../FamilyDashboard/ReferralPage'
import NewFamilyPage from '../FamilyDashboard/NewFamilyPage'

const CURRENT_Q = 'Spring'
const NEXT_Q = 'Summer'
const REFERRAL_ENABLED = true

class RecommendationPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedStudent: null,
      windowWidth: Dimensions.get('window').width,
      scrollOffset: 0,
      displayStudents: true,
      showAddStudent: false,
      videoVisible: false,
      currVideoId: null,
    }
    this.scrollRef = React.createRef()
  }

  onScrollHandler = event => {
    const currentOffset = event.nativeEvent.contentOffset.y
    const dif = currentOffset - (this.state.scrollOffset || 0)
    if (Math.abs(dif) < 100) {
      return
    } else if (dif < 0) {
      this.setState({ displayStudents: true, scrollOffset: currentOffset })
    } else {
      this.setState({ displayStudents: false, scrollOffset: currentOffset })
    }

    this.offset = currentOffset
  }

  render() {
    const {
      selectedStudent,
      displayStudents,
      showAddStudent,
      videoVisible,
      currVideoId,
    } = this.state

    return (
      <Query
        query={GET_FAMILY_STUDENTS}
        variables={{ id: this.props.cookies.get('familyAccountId') }}
        fetchPolicy={'network-only'}
      >
        {({ data, loading, error, refetch }) => {
          if (loading) return <LoadingPage />
          if (error) return `${error}`

          const activeStudentsFirst = data.familyAccount.students
          const familyStatus = data.familyAccount.status
          const familyMember = data.familyAccount.primaryFamilyMember

          activeStudentsFirst.sort((a, b) => {
            if (a.enrollmentStatus === b.enrollmentStatus) {
              return a.id.localeCompare(b.id)
            }
            if (a.enrollmentStatus === 'ACTIVE') {
              return -1
            }
            return 1
          })

          return (
            <View style={{ flex: 1 }}>
              {familyStatus !== 'REFERRED' || activeStudentsFirst.length ? (
                <React.Fragment>
                  <AddStudentPopup
                    onSuccess={() => {
                      this.setState({ showAddStudent: false })
                      refetch()
                    }}
                    visible={showAddStudent}
                    onClose={() => this.setState({ showAddStudent: false })}
                  />

                  <StudentInfoTab
                    students={activeStudentsFirst}
                    selected={
                      selectedStudent ||
                      (activeStudentsFirst[0]
                        ? activeStudentsFirst[0].id
                        : null)
                    }
                    setStudent={id => {
                      this.scrollRef.current.scrollTo(0)
                      this.setState({ selectedStudent: id })
                    }}
                    shrunk={isMobile || !displayStudents} //always display small icon in mobile
                    openAddStudent={() =>
                      this.setState({ showAddStudent: true })
                    }
                  />
                  <BadgeBanner
                    selectedStudent={
                      selectedStudent ||
                      (activeStudentsFirst[0]
                        ? activeStudentsFirst[0].id
                        : null)
                    }
                  />

                  <VideoModal
                    visible={videoVisible}
                    onClose={() => {
                      this.setState({ videoVisible: false })
                    }}
                    videoId={currVideoId}
                  />
                </React.Fragment>
              ) : (
                <ScrollView
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    padding: '20px',
                  }}
                >
                  <NewFamilyPage familyMember={familyMember} />
                </ScrollView>
              )}

              {activeStudentsFirst.length > 0 && (
                <ScrollView
                  style={{ padding: '20px' }}
                  onScroll={e => this.onScrollHandler(e)}
                  scrollEventThrottle={16}
                  alwaysBounceVertical={false}
                  ref={this.scrollRef}
                >
                  {REFERRAL_ENABLED && <ReferralPage />}
                  <div>
                    <RecommendationForm
                      studentId={selectedStudent || activeStudentsFirst[0].id}
                      windowWidth={this.state.windowWidth}
                      currentQuarter={CURRENT_Q}
                      nextQuarter={NEXT_Q}
                      openVid={v =>
                        this.setState({ currVideoId: v, videoVisible: true })
                      }
                    />
                  </div>
                </ScrollView>
              )}
            </View>
          )
        }}
      </Query>
    )
  }
}

export default withRouter(withCookies(RecommendationPage))
