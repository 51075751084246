const bucket = 'solve-app-dev'
const region = 'us-west-1'

const s3Config = {
  bucket,
  region,
  url: `https://${bucket}.s3-${region}.amazonaws.com`,
}

const gets3Img = url => {
  return s3Config.url + '/' + url
}

export default gets3Img
