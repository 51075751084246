import React from 'react'
import { Text, View, FlatList, ScrollView, Image } from 'react-native'
import { withRouter } from 'react-router-dom'
import { formatDate, formatTime } from './../../../utils/dateTime'
import { StyleSheet, TouchableOpacity } from 'react-native-web'
import { solveColors } from '../../components/colors'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { gradeLevel, instructorNames } from './../../../utils/gradeLevel'

const SyllabusForm = props => {
  const { title, classroom, history } = props
  const {
    startDate,
    endDate,
    startTime,
    endTime,
    meets,
    course,
    lessons,
    instructors,
    quarter,
  } = classroom
  const {
    description,
    longDescription,
    endGrade,
    startGrade,
    imageUrl,
  } = course

  const classroomDays = meets
  const classroomStartDate = formatDate(startDate)
  const classroomEndDate = formatDate(endDate)
  const classroomStartTime = formatTime(startTime, true)
  const classroomEndTime = formatTime(endTime, false)
  const grades = gradeLevel(startGrade, endGrade)
  const instructorText = instructorNames(instructors)
  const tutored = quarter === 'Tutoring'
  return (
    <View style={styles.page}>
      <View style={styles.bar}>
        <TouchableOpacity
          style={styles.backButton}
          onPress={() => {
            history.push({
              pathname: '/dashboard',
            })
          }}
        >
          <ArrowBackIosIcon />
          <Text style={styles.backText}>Recommendations</Text>
        </TouchableOpacity>
      </View>
      <ScrollView style={styles.container}>
        <View style={styles.header}>
          <Image style={styles.headerImage} source={imageUrl} />
          <View style={styles.headerTextBox}>
            <Text style={styles.headerText}>Syllabus</Text>
            <Text style={styles.headerText}>{title}</Text>
            <Text style={styles.headerSubText}>{description}</Text>
            <Text style={styles.headerSubText}>{longDescription}</Text>
            <Text style={styles.headerSubText}>{`For Grades ${grades}`}</Text>
          </View>
        </View>
        <View style={styles.body}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ fontWeight: 'bold' }}>Duration: </Text>
            <Text>{`${classroomStartDate} - ${classroomEndDate}`}</Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ fontWeight: 'bold' }}>Times: </Text>
            <Text>{`${classroomDays}: ${classroomStartTime} - ${classroomEndTime}`}</Text>
          </View>
          {!tutored && (
            <View style={{ flexDirection: 'row' }}>
              <Text style={{ fontWeight: 'bold' }}>Instructors: </Text>
              <Text>{instructorText}</Text>
            </View>
          )}
        </View>
        <View style={styles.body}>
          <Text style={styles.lessonPlanHeader}>Lessons</Text>
          <FlatList
            data={lessons}
            keyExtractor={item => item.id}
            renderItem={({ item }) => {
              const lessonStartDate = formatDate(item.startOn)
              const { title, order } = item.lessonPlan
              return (
                <TouchableOpacity style={styles.lessonRow}>
                  <Text style={styles.lessonTitle}>{`${order}. ${title}`}</Text>
                  <div style={{ display: 'flex' }}>
                    <View style={styles.lessonDate}>
                      <Text>Date: </Text>
                      <Text>{lessonStartDate}</Text>
                    </View>
                    <View style={styles.lessonArrow}>
                      <KeyboardArrowDownIcon />
                    </View>
                  </div>
                </TouchableOpacity>
              )
            }}
          />
        </View>
      </ScrollView>
    </View>
  )
}

export const styles = StyleSheet.create({
  page: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  bar: {
    backgroundColor: 'white',
    width: '100%',
  },
  backButton: {
    flexDirection: 'row',
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 10,
    width: 180,
  },
  backText: {
    fontSize: '15',
  },
  container: {
    borderRadius: 10,
    backgroundColor: 'white',
    paddingRight: 40,
    padding: 25,
    marginVertical: 30,
  },
  header: {
    paddingBottom: 20,
    borderBottomWidth: 1,
    borderColor: solveColors.grey1,
    marginBottom: 10,
    minHeight: 170,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
  },

  headerText: {
    fontSize: '1.5rem',
    color: solveColors.blue1,
    flexWrap: 'wrap',
  },
  headerTextBox: {
    display: 'flex',
    maxWidth: '100%',
    flexShrink: 1,
  },
  headerImage: {
    width: 180,
    height: 135,
    resizeMode: 'contain',
  },
  headerSubText: {
    fontSize: 18,
    padding: 5,
    flexWrap: 'wrap',
  },
  body: {
    width: '100%',
    paddingBottom: 20,
  },
  lessonPlanHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    paddingBottom: 10,
  },
  lessonRow: {
    flexDirection: 'row',
    padding: 10,
    borderBottomWidth: 1,
    borderColor: solveColors.grey1,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  lessonTitle: {
    marginLeft: '3%',
    fontWeight: 'bold',
  },
  lessonDate: {
    fontWeight: 'bold',
    flexDirection: 'row',
  },
})

export default withRouter(SyllabusForm)
