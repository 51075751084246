import {
  FlatList,
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
} from 'react-native'
import React, { Component } from 'react'
import ProblemSubmitButton from './ProblemSubmitButton'
import AssignmentSubmitButton from './AssignmentSubmitButton'
import SkipButton from './SkipButton'
import MatchChoice from './MatchChoice'
import { DialogModal, Latex } from '../../../components/'
import ScratchpadButton from './ScratchpadButton'
import SolutionButton from './SolutionButton'
import LineTo from 'react-lineto'
import _ from 'lodash'
const decodeMatches = (value, length) => {
  const matches = {}
  if (value.length % 2 === 1) return matches
  for (let i = 0; i < value.length / 2; i++) {
    const choice1 = parseInt(value.substring(i * 2, i * 2 + 1))
    const choice2 = parseInt(value.substring(i * 2 + 1, i * 2 + 2))
    if (choice1 > length || choice2 > length) return {}
    matches[choice1] = choice2
  }
  return matches
}

const encodeMatches = matches => {
  let answer = ''
  for (let key in matches) {
    answer += `${key}${matches[key]}`
  }
  return answer
}
const initProblem = (choices1, choices2) => {
  const selected1 = new Array(choices1.length)
  const selected2 = new Array(choices2.length)
  for (let i = 0; i < choices1.length; i++) {
    selected1[i] = { selected: false, order: choices1[i].order }
    selected2[i] = { selected: false, order: choices2[i].order }
  }

  return { selected1, selected2 }
}

class MatchProblemForm extends Component {
  constructor(props) {
    super(props)
    const { choices1, choices2 } = props.problem
    const { selected1, selected2 } = initProblem(choices1, choices2)
    this.state = {
      numChoices: 0,
      dlgModalOpen: false,
      selected1,
      selected2,
      choices1,
      choices2,
      partial: true,
      matches: decodeMatches(
        props.currentAnswer,
        props.problem.choices1.length
      ),
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateMatchLines)
  }

  componentDidUpdate(prevProps) {
    if (this.props.problem.order !== prevProps.problem.order) {
      const { choices1, choices2 } = this.props.problem
      const { selected1, selected2 } = initProblem(choices1, choices2)
      this.setState({
        selected1,
        selected2,
        choices1,
        choices2,
      })
    }
    if (
      !_.isEqual(
        this.state.matches,
        decodeMatches(
          this.props.currentAnswer,
          this.props.problem.choices1.length
        )
      )
    ) {
      this.setState({
        matches: decodeMatches(
          this.props.currentAnswer,
          this.props.problem.choices1.length
        ),
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateMatchLines)
  }

  updateMatchLines = _.throttle(() => {
    this.forceUpdate()
  }, 100)

  handleProblemSubmit = async (fromSkip = false) => {
    const {
      problem,
      onProblemSubmit,
      review,
      lastProb,
      gotoLessons,
    } = this.props
    let corrAnswer = ''
    for (let i = 0; i < problem.choices.length; i++) {
      corrAnswer += `${i}${i}`
    }
    const studentAnswer = encodeMatches(this.state.matches)
    if (review && !lastProb) {
      onProblemSubmit(studentAnswer, corrAnswer)
      return
    }
    if (review && lastProb) {
      gotoLessons()
      return
    }
    onProblemSubmit(studentAnswer, corrAnswer, fromSkip)
  }

  handleAssignmentSubmit = () => {
    const { answered } = this.props
    if (answered) {
      this.setState({ partial: false })
    }
    this.openDialogModal()
  }

  openDialogModal = () => {
    this.setState({ dlgModalOpen: true })
  }

  closeDialogModal = () => {
    this.setState({ dlgModalOpen: false })
  }
  setSelected1 = index => {
    if (
      Object.entries(this.state.matches).find(
        m => parseInt(m[0]) === this.state.selected1[index].order
      )
    ) {
      let tempM = this.state.matches
      delete tempM[this.state.selected1[index].order]
      this.props.updateProblemAnswer(encodeMatches(tempM))
      this.setState({ matches: tempM })
    } else if (this.state.selected2.map(s => s.selected).includes(true)) {
      let tempM = this.state.matches
      tempM[this.state.selected1[index].order] = this.state.selected2.find(
        s => s.selected
      ).order
      this.props.updateProblemAnswer(encodeMatches(tempM))
      let temp = this.state.selected1
      for (let i = 0; i < temp.length; i++) {
        temp[i].selected = false
      }
      let temp2 = this.state.selected2
      for (let i = 0; i < temp.length; i++) {
        temp2[i].selected = false
      }
      this.setState({ selected: temp, selected2: temp2, matches: tempM })
    } else {
      let temp = this.state.selected1
      temp[index].selected = !temp[index].selected
      for (let i = 0; i < temp.length; i++) {
        if (i !== index) temp[i].selected = false
      }
      this.setState({ selected1: temp })
    }
  }
  setSelected2 = index => {
    if (
      Object.entries(this.state.matches).find(
        m => parseInt(m[1]) === this.state.selected2[index].order
      )
    ) {
      const match = Object.entries(this.state.matches).find(
        m => parseInt(m[1]) === this.state.selected2[index].order
      )
      let tempM = this.state.matches
      delete tempM[match[0]]
      this.setState({ matches: tempM })
      this.props.updateProblemAnswer(encodeMatches(tempM))
    } else if (this.state.selected1.map(s => s.selected).includes(true)) {
      let tempM = this.state.matches
      tempM[
        this.state.selected1.find(s => s.selected).order
      ] = this.state.selected2[index].order
      this.props.updateProblemAnswer(encodeMatches(tempM))
      let temp = this.state.selected1
      for (let i = 0; i < temp.length; i++) {
        temp[i].selected = false
      }
      let temp2 = this.state.selected2
      for (let i = 0; i < temp.length; i++) {
        temp2[i].selected = false
      }
      this.setState({ selected: temp, selected2: temp2, matches: tempM })
    } else {
      let temp = this.state.selected2
      temp[index].selected = !temp[index].selected
      for (let i = 0; i < temp.length; i++) {
        if (i !== index) temp[i].selected = false
      }
      this.setState({ selected2: temp })
    }
  }
  render() {
    const {
      problem,
      probState,
      complete,
      allowSkip,
      incrementProblem,
      feedbackType,
      review,
      lastProb,
      onAssignmentSubmit,
      scratchpadUrl,
      currentProbNum,
      problemStates,
      instructor,
      showSolution,
      toggleShowSolution,
      isLoading,
    } = this.props
    const { choices1, choices2, selected1, selected2 } = this.state
    const { questionText } = problem

    const typesetMath = true
    return (
      <View style={styles.problem}>
        <View style={styles.question}>
          <View style={styles.choices}>
            <View style={styles.questionText}>
              {typesetMath && <Latex latex={questionText} />}
              {!typesetMath && <Text>{questionText}</Text>}
            </View>
            <View style={{ flexDirection: 'row' }}>
              <View style={{ margin: 20 }}>
                <FlatList
                  data={choices1}
                  extraData={this.state}
                  key={this.state.selected1}
                  keyExtractor={item => item.text}
                  renderItem={({ item, index }) => (
                    <View style={{ flexDirection: 'row' }}>
                      <MatchChoice
                        feedbackType={feedbackType}
                        typesetMath={typesetMath}
                        selected={selected1[index].selected}
                        setSelected={() => this.setSelected1(index)}
                        index={index}
                        text={item.text}
                        probState={probState}
                      />
                      <div className={`1${index}`} />
                    </View>
                  )}
                />
              </View>
              <View style={{ margin: 20 }}>
                <FlatList
                  data={choices2}
                  extraData={this.state}
                  key={this.state.selected2}
                  keyExtractor={item => item.text}
                  renderItem={({ item, index }) => (
                    <View style={{ flexDirection: 'row' }}>
                      <div className={`2${index}`} />
                      <MatchChoice
                        feedbackType={feedbackType}
                        typesetMath={typesetMath}
                        selected={selected2[index].selected}
                        setSelected={() => this.setSelected2(index)}
                        index={index}
                        text={item.text}
                        probState={probState}
                      />
                    </View>
                  )}
                />
              </View>
              {Object.entries(this.state.matches).map(match => {
                return (
                  <LineTo
                    borderColor={'black'}
                    borderWidth={5}
                    from={`1${this.state.selected1.findIndex(
                      s => s.order === parseInt(match[0])
                    )}`}
                    to={`2${this.state.selected2.findIndex(
                      s => s.order === match[1]
                    )}`}
                  />
                )
              })}
            </View>
          </View>
          <DialogModal
            visible={this.state.dlgModalOpen}
            transparent={true}
            onYes={async () => {
              if (this.state.studentAnswer) await this.handleProblemSubmit()
              onAssignmentSubmit()
              this.closeDialogModal()
            }}
            onNo={() => {
              this.closeDialogModal()
            }}
            message={
              this.state.partial
                ? `You have answered ${
                    problemStates.filter(p => p !== 0).length
                  }/${
                    problemStates.length
                  } problems. Are you sure you want to submit the assignment for grading?`
                : 'Once submitted, you cannot change your answers. Are you sure you want to submit?'
            }
          />
        </View>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          {scratchpadUrl && (
            <TouchableOpacity
              style={{ marginRight: '0.5vh' }}
              onPress={() => this.props.deleteScratchpadImg(currentProbNum)}
            >
              <Text>{'X'}</Text>
            </TouchableOpacity>
          )}
          <Text>{scratchpadUrl}</Text>
        </View>
        <View style={styles.buttonBar}>
          <ScratchpadButton toggleScratchpad={this.props.toggleScratchpad} />
          <ProblemSubmitButton
            isLoading={isLoading}
            probState={probState}
            feedbackType={feedbackType}
            complete={complete}
            handleProblemSubmit={this.handleProblemSubmit}
            review={review}
            lastProb={lastProb}
          />
          {allowSkip && !instructor && !review && (
            <SkipButton
              probState={probState}
              allowSkip={allowSkip}
              lastProb={lastProb}
              handleProblemSubmit={this.handleProblemSubmit}
              incrementProblem={incrementProblem}
            />
          )}
          {!review && !instructor && (
            <AssignmentSubmitButton
              handleAssignmentSubmit={this.handleAssignmentSubmit}
              locked={problemStates.filter(s => s !== 0).length === 0}
            />
          )}
          {!review && instructor && (
            <SolutionButton
              showSolution={showSolution}
              toggleShowSolution={toggleShowSolution}
            />
          )}
        </View>

        {(review || showSolution) && (
          <View style={styles.answerText}>
            <Text>Correct Answer:</Text>
            {this.props.problem.choices.map(c => {
              const a = c.text.split(';')[0]
              const b = c.text.split(';')[1]
              return <Text>{`${a} = ${b}`}</Text>
            })}
            <Text></Text>
          </View>
        )}
      </View>
    )
  }
}

const styles = StyleSheet.create({
  answerText: {
    alignItems: 'center',
  },
  row: {
    flexDirection: 'row',
    padding: 10,
    alignSelf: 'center',
  },
  question: {
    alignItems: 'center',
    userSelect: 'none',
    maxWidth: '40vw',
  },
  textinput: {
    borderColor: '#EDEDED',
    borderWidth: 1,
    borderRadius: 10,
    padding: 10,
  },
  buttonBar: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  problem: {
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '60vw',
  },
  choices: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  questionText: {
    fontSize:
      sessionStorage.getItem('size') === 'large'
        ? 24
        : sessionStorage.getItem('size') === 'med'
        ? 20
        : 14,
  },
})

export default MatchProblemForm
