import {
  Text,
  View,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
} from 'react-native'
import React from 'react'
import { withRouter } from 'react-router-dom'
import GradebookForm from './../../components/GradebookForm'
import { numToLetterGrade } from './../../../utils/grades'
import LoadingPage from './../Loading/LoadingPage'
import jwt from 'jsonwebtoken'
import { Query } from 'react-apollo'
import { GET_CLASSROOM_DETAILS } from './../../../services/queries'
import { formatDate } from './../../../utils/dateTime'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { useCookies } from 'react-cookie'

const TranscriptPage = props => {
  const [cookies] = useCookies([])
  const classroomId = props.location.search.split('=')[1]
  return (
    <Query
      context={{ headers: { 'cache-control': 'no-cache' } }}
      variables={{
        id: classroomId,
        studentFilter: {
          studentId: jwt.decode(cookies['token']).id,
        },
      }}
      query={GET_CLASSROOM_DETAILS}
    >
      {({ data, loading, error }) => {
        if (error) return `${error}`
        if (loading) return <LoadingPage />

        const { startDate, endDate, quarter, year, title } = data.classroom
        const classroomTitle = `Transcript: ${title.split(':')[1].trim()}`
        props.changeTitles(classroomTitle)
        const { lessons } = data.classroom
        const lessonGrades = data.classroom.lessons.map(l => ({
          lessonId: l.attendees[0].lesson.id,
          overallGrade: l.attendees[0].overallGrade,
        }))
        const { overallGrade: classroomGrade } = data.classroom.enrollments[0]
        const classroomStartDate = formatDate(startDate)
        const classroomEndDate = formatDate(endDate)
        return (
          <React.Fragment>
            <View style={styles.bar}>
              <TouchableOpacity
                style={styles.backButton}
                onPress={() => {
                  props.history.push({
                    pathname: '/dashboard',
                    search: `defaultTab=1`,
                  })
                }}
              >
                <ArrowBackIosIcon />
                <Text style={styles.backText}>Dashboard</Text>
              </TouchableOpacity>
            </View>
            <ScrollView style={{ margin: 20, paddingRight: 30 }}>
              <View
                style={{
                  backgroundColor: 'white',
                  padding: 10,
                  marginBottom: 10,
                  borderRadius: 10,
                  width: 450,
                }}
              >
                <Text style={styles.class_grade}>
                  {`Completed: ${quarter} ${year} (${classroomStartDate} - ${classroomEndDate})`}
                </Text>
                <Text style={styles.class_grade}>
                  {classroomGrade === null || classroomGrade === undefined
                    ? 'The grades for this class are not available on SOLVE'
                    : `Final Grade: ${classroomGrade}% (${numToLetterGrade(
                        classroomGrade
                      )})`}
                </Text>
              </View>
              <GradebookForm
                lessons={lessons}
                classroomId={classroomId}
                lessonGrades={lessonGrades}
              />
            </ScrollView>
          </React.Fragment>
        )
      }}
    </Query>
  )
}
const styles = StyleSheet.create({
  class_grade: {
    marginLeft: '1%',
    fontSize: 16,
    fontWeight: 'bold',
  },
  bar: {
    backgroundColor: 'white',
    width: '100%',
  },
  backButton: {
    flexDirection: 'row',
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 10,
    width: 100,
  },
})
export default withRouter(TranscriptPage)
