import React from 'react'
import Card from 'react-bootstrap/Card'
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native-web'
import { withRouter } from 'react-router-dom'
import { InfoRow } from './'
import solveColors from './colors'
import { formatTime, formatDate } from './../../utils/dateTime'
import { useCookies } from 'react-cookie'
import { gradeLevel } from './../../utils/gradeLevel'

const ClassroomCard = props => {
  const {
    onEnroll,
    imageUrl,
    title,
    classroomId,
    startGrade,
    endGrade,
    tuitionCents,
    enrollmentCount,
    enrollmentMax,
    startTime,
    endTime,
    startDate,
    endDate,
    instructorName,
    locationName,
    classroomDays,
    meetingsPerWeek,
    teachingStyle,
    hasPreview,
    videoId,
    history,
    openLoginPopup,
    openSyllabus,
    openVideo,
  } = props
  const [cookies] = useCookies([])

  const classroomStartDate = formatDate(startDate, false)
  const classroomEndDate = formatDate(endDate, true)
  const classroomStartTime = formatTime(startTime, true)
  const classroomEndTime = formatTime(endTime, false)
  const grades = gradeLevel(startGrade, endGrade)

  const classTitlePosition = title.indexOf('-')
  const classTitle =
    classTitlePosition > 0
      ? title.substring(classTitlePosition + 1).trim()
      : title
  const centerLocation = locationName ? locationName : 'ONLINE'
  const tutored = teachingStyle === 'TUTORED'
  const mentored = teachingStyle === 'MENTORED'
  const instructorLabel =
    teachingStyle === 'COMPETITION' ? 'Contest Manager:' : 'Instructor:'
  const self_paced = teachingStyle === 'SELF_PACED'
  const enrollName = enrollmentMax <= enrollmentCount ? 'FULL' : 'Enroll'
  const meetings = meetingsPerWeek === 1 ? 'Once per Week' : 'Twice per Week'
  const enrollDisabled = enrollmentMax <= enrollmentCount ? true : false
  const enrollColor1 =
    enrollmentMax <= enrollmentCount ? solveColors.red1 : solveColors.green
  const enrollColor2 =
    enrollmentMax <= enrollmentCount ? solveColors.red2 : solveColors.green2
  let weeklyMeetings
  if (tutored) {
    weeklyMeetings =
      meetingsPerWeek === 1 ? '4 classes monthly' : '8 classes monthly'
  }
  if (self_paced) {
    weeklyMeetings = 'Learn at Your Own Pace'
  }
  const newLocation =
    centerLocation === 'Northwood' ? '820 Roosevelt, Irvine' : centerLocation
  return (
    <Card
      title="COURSE CARD"
      style={{
        backgroundColor: 'white',
        margin: 10,
        width: 300,
        boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.25)',
      }}
    >
      <Card.Img style={{ width: 300 }} src={imageUrl} />
      <Card.Body
        style={{
          padding: 10,
          paddingBottom: 60,
          minHeight: 215,
        }}
      >
        <Card.Title style={{ fontWeight: 'bold', fontSize: '16px' }}>
          {classTitle}
        </Card.Title>

        <View style={{ marginTop: 10 }}>
          <InfoRow text1={'Grade: '} text2={`${grades}`} />
          {!tutored && (
            <InfoRow
              text1={'Tuition:'}
              text2={`$${parseInt(tuitionCents / 100)}`}
            />
          )}
          {tutored && (
            <InfoRow
              text1={'Subscription:'}
              text2={`$${parseInt(tuitionCents / 100)}/month`}
            />
          )}
          {tutored && <InfoRow text1={'Frequency:'} text2={meetings} />}
          {mentored && <InfoRow text1={'Number of Lessons:'} text2={'10'} />}
          {!(tutored || mentored || self_paced) && (
            <InfoRow
              text1={'Schedule:'}
              text2={`${classroomStartDate} - ${classroomEndDate}`}
            />
          )}
          {tutored ||
            (self_paced && (
              <InfoRow text1={'Schedule:'} text2={weeklyMeetings} />
            ))}

          {!(tutored || mentored || self_paced) && (
            <InfoRow
              text1={'When:'}
              text2={`${classroomDays}: ${classroomStartTime} - ${classroomEndTime}`}
            />
          )}
          {self_paced && (
            <InfoRow text1={'Estimated Learning Time:'} text2={`30 hours`} />
          )}
          {tutored && (
            <InfoRow text1={'Time per Class:'} text2={`50 minutes`} />
          )}
          {mentored && (
            <InfoRow text1={'Time per Lesson:'} text2={`90 minutes`} />
          )}
          {tutored && <InfoRow text1={'Duration:'} text2={`15-20 classes`} />}
          <InfoRow text1={'Where:'} text2={newLocation} />
          {self_paced ||
            (mentored && (
              <InfoRow text1={'Video Lessons by:'} text2={instructorName} />
            ))}
          {!(tutored || mentored || self_paced) && (
            <InfoRow text1={instructorLabel} text2={instructorName} />
          )}
        </View>

        <View
          style={{
            flexDirection: 'row',
            position: 'absolute',
            bottom: 25,
            width: 280,
            justifyContent: 'space-evenly',
          }}
        >
          <TouchableOpacity
            disabled={enrollDisabled}
            style={[
              styles.button,
              {
                background: `linear-gradient(to right,  ${enrollColor2} 50%, ${enrollColor1} 90%)`,
              },
              videoId ? { width: '30%' } : { width: '40%' },
            ]}
            onPress={() => {
              onEnroll()
            }}
          >
            <Text disabled={true} style={styles.buttonText}>
              {enrollName}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              styles.button,
              {
                background: `linear-gradient(to right, #07c1eb 50%, ${solveColors.blue2} 90%)`,
              },
              videoId ? { width: '30%' } : { width: '40%' },
            ]}
            onPress={openSyllabus}
          >
            <Text style={styles.buttonText}>Syllabus</Text>
          </TouchableOpacity>
          {videoId !== null && (
            <TouchableOpacity
              style={[
                styles.button,
                {
                  background:
                    'linear-gradient(to right,  #f5a700 50%, #ff6a00 90%)',
                },
                { width: '30%' },
              ]}
              onPress={() => {
                openVideo(videoId)
              }}
            >
              <Text style={styles.buttonText}>Demo</Text>
            </TouchableOpacity>
          )}
          {hasPreview && (
            <TouchableOpacity
              style={[
                styles.button,
                {
                  background:
                    'linear-gradient(to right,  #f5a700 50%, #ff6a00 90%)',
                  width: '30%',
                },
              ]}
              onPress={() => {
                if (!cookies['token']) {
                  openLoginPopup()
                } else {
                  sessionStorage.setItem('classroomTitle', title)
                  sessionStorage.setItem('classroomId', classroomId)
                  sessionStorage.setItem('preview', 1)
                  history.push({
                    pathname: '/lessons',
                    search: `classroomId=${classroomId}`,
                  })
                }
              }}
            >
              <Text style={styles.buttonText}>Preview</Text>
            </TouchableOpacity>
          )}
        </View>
      </Card.Body>
    </Card>
  )
}
export default withRouter(ClassroomCard)

const styles = StyleSheet.create({
  buttonText: {
    color: 'white',
    fontSize: 'vmin',
    fontWeight: 'bold',
    textAlign: 'center',
    textAlignVertical: 'center',
  },
  button: {
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: 50,
    height: 30,
    alignSelf: 'center',
    alignItems: 'center',
    boxShadow: '5px 5px 3px rgba(0, 0, 0, 0.25)',
  },
})
