import { StyleSheet, View, Text, TouchableOpacity } from 'react-native'
import React, { Component } from 'react'
import Modal from 'modal-react-native-web'
import solveColors from './colors'

class MessageModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.visible !== props.visible) {
      return { visible: props.visible }
    }
    return null
  }

  render() {
    return (
      <Modal visible={this.state.visible} transparent={true}>
        <View style={styles.backdrop}>
          <View style={styles.results}>
            {this.props.message.includes('zoom.us/download') && (
              <div style={{ marginBottom: 20, width: '100%' }}>
                <Text
                  style={{
                    textAlign: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  {this.props.message.split('zoom.us/download')[0]}
                </Text>
                <a
                  href={'https://zoom.us/download'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  zoom.us/download
                </a>
              </div>
            )}
            {!this.props.message.includes('zoom.us/download') && (
              <Text
                style={{
                  textAlign: 'center',
                  marginBottom: 20,
                  flexWrap: 'wrap',
                }}
              >
                {this.props.message}
              </Text>
            )}

            <TouchableOpacity
              style={[
                styles.button,
                { backgroundColor: solveColors.blue5, bottom: 0 },
              ]}
              onPress={() => {
                this.props.onClose()
              }}
            >
              <Text style={{ color: 'white' }}>Close</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    )
  }
}

const styles = StyleSheet.create({
  results: {
    maxWidth: '50vw',
    borderRadius: '10px',
    margin: 'auto',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: 25,
  },
  backdrop: {
    backgroundColor: 'rgba(52, 52, 52, 0.2)',
    height: '100%',
    width: '100%',
  },
  button: {
    width: 'calc(150px + 5vw)',
    height: 40,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
})
export default MessageModal
