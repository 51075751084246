import { StyleSheet, View, Text, TouchableOpacity, Image } from 'react-native'
import React, { Component } from 'react'
import solveColors from '../../components/colors'
import { VideoModal, ChatBox, ImageModal } from '../../components/'
import ArdentLogo from './../../images/ardent-logo.png'
import {
  COUNT_MESSAGES,
  CREATE_ISSUE,
  GET_ISSUE,
  UPDATE_ISSUE,
  GenerateRequestHelpMessage,
} from '../../../services/queries'
import jwt from 'jsonwebtoken'
import SendRoundedIcon from '@material-ui/icons/SendRounded'
import KeyboardEventHandler from 'react-keyboard-event-handler'
import { withCookies } from 'react-cookie'

class RequestHelpForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      showHint: false,
      showReport: false,
      videoModalOpen: false,
      imageModalOpen: false,
      report: '',
      message: '',
      description: '',
      currentdescription: '',
      hintNumber: 1,
      issueID: '',
      videoId: null,
      image: null,
      messages: [],
      options: [],
      problemId: null,
    }
  }
  componentDidMount() {
    this.closeChat()
    this.enterMessageText(
      'Dr.Li',
      `Welcome ${this.props.cookies.get(
        'firstName'
      )}, What can I help you with today?`
    )
  }
  static getDerivedStateFromProps(props, state) {
    if (state.visible !== props.visible) {
      return {
        visible: props.visible,
        showReport: false,
        problemId: props.problemId,
      }
    }
    if (state.problemId !== props.problemId) {
      return {
        messages: [
          {
            user: 'Dr.Li',
            text: `Welcome ${props.cookies.get(
              'firstName'
            )}, What can I help you with today?`,
          },
        ],
        description: '',
        currentdescription: '',
        hintNumber: 1,
        issueID: '',
        showHint: false,
        report: '',
        videoModalOpen: false,
        imageModalOpen: false,
        videoId: null,
        image: null,
        showReport: false,
      }
    }
    return null
  }

  setDefaultOptions() {
    this.setState({
      options: [
        {
          text: 'Report a Problem',
          onClick: async () => {
            this.setState({ report: 'report' })
            this.enterMessageText(
              this.props.cookies.get('firstName'),
              'Report a Problem'
            )
            this.setState({ options: [] })

            this.enterMessageText('Dr.Li', 'Please describe the problem.')
          },
        },
        {
          text: 'I Need Help!',
          onClick: async () => {
            this.setState({ report: '' })
            this.enterMessageText(
              this.props.cookies.get('firstName'),
              'I Need Help!'
            )
            this.enterMessageText(
              'Dr.Li',
              'Did you make some progress on the problem?'
            )
            this.setState({
              options: [
                {
                  text: 'Yes',
                  onClick: this.openHelpInput,
                },
                {
                  text: 'No',
                  onClick: this.requestHint,
                },
              ],
            })
          },
        },
      ],
    })
  }
  closingMessage = () => {
    this.enterMessageText(
      'Dr.Li',
      'Thanks. We will work to get that problem resolved within 24 hours :)'
    )
    this.enterOptions({
      text: 'Okay',
      onClick: this.closeHelp,
    })
    this.setState({ report: '' })
  }
  closeChat = () => {
    this.props.close()
    this.setState({
      description: '',
      currentdescription: '',
      hintNumber: 1,
      issueID: '',
      showHint: false,
      report: '',
      videoModalOpen: false,
      imageModalOpen: false,
      videoId: null,
      image: null,
      showReport: false,
    })
    this.setDefaultOptions()
  }
  closeHintHelp = async e => {
    this.enterMessageText(this.props.cookies.get('firstName'), 'Yes')
    this.enterMessageText('Dr.Li', 'Great! Thanks for asking for help.')
    await this.props.client.mutate({
      mutation: UPDATE_ISSUE,
      variables: {
        issueId: this.state.issueID,
        issueInput: {
          description: this.state.currentdescription.concat(
            this.state.description
          ),
          dateResolved: new Date().toISOString(),
          issueStatus: 'RESOLVED',
          isRead: false,
          response: 'Resolved after studying hint',
          responsibleEmployeeId: 'b001d0de-a579-4535-ab3e-ac7ebe165484',
        },
      },
    })
    this.closeChat()
  }
  closeHelp = () => {
    this.enterMessageText(this.props.cookies.get('firstName'), 'Okay')
    this.closeChat()
  }
  rejectHint = async e => {
    this.enterMessageText(this.props.cookies.get('firstName'), 'No')
    await this.props.client.mutate({
      mutation: UPDATE_ISSUE,
      variables: {
        issueId: this.state.issueID,
        issueInput: {
          description: this.state.currentdescription.concat(
            this.state.description
          ),
          dateAssigned: new Date().toISOString(),
          issueStatus: 'INITIATED',
          isRead: false,
          response: null,
        },
      },
    })
    this.closingMessage()
  }
  requestHint = () => {
    this.enterMessageText(this.props.cookies.get('firstName'), 'No')
    this.setState({ showHint: true })

    this.createHelpIssue()
  }
  watchLectureVideo = () => {
    this.setState({
      options: [
        {
          text: 'Yes',
          onClick: this.openVideoModal,
        },
        {
          text: 'No',
          onClick: this.requestHint,
        },
      ],
    })
  }
  hintFollowUp = () => {
    this.setState({
      options: [
        {
          text: 'Yes',
          onClick: this.closeHintHelp,
        },
        {
          text: 'No',
          onClick: this.requestHint,
        },
      ],
    })
  }
  openHelpInput = () => {
    this.setState({ report: 'help' })
    this.enterMessageText(this.props.cookies.get('firstName'), 'Yes')
    this.enterMessageText('Dr.Li', 'What have you gotten so far?')
    this.setState({ options: [] })
  }
  enterMessageText = (user, text, latex = false) => {
    const temp = this.state.messages

    temp.push({ user, text, latex })

    this.setState({ messages: temp })
  }
  enterMessageOptions = (user, options) => {
    const temp = this.state.messages
    temp.push({ user, options })
    this.setState({ messages: temp })
  }
  enterOptions = option => {
    this.setState({ options: [option] })
  }
  openVideoModal = () => {
    this.enterMessageText(this.props.cookies.get('firstName'), 'Yes')
    this.setState({ videoModalOpen: true })
  }

  closeVideoModal = () => {
    this.setState({ videoModalOpen: false })
    this.enterMessageText('Dr.Li', 'Was that helpful?')
    this.hintFollowUp()
  }
  openImageModal = () => {
    this.setState({ imageModalOpen: true })
  }

  closeImageModal = () => {
    this.setState({ imageModalOpen: false })
    this.enterMessageText('Dr.Li', 'Was that helpful?')
    this.hintFollowUp()
  }

  openReportModal = () => {
    this.setState({ showReport: true })
  }

  closeReportModal = () => {
    this.setState({ showReport: false })
  }
  restoreDefault = () => {
    this.setState({ issueType: 'NEED_HELP', description: '' })
  }

  updateReportIssue = async e => {
    const { data } = await this.props.client.mutate({
      mutation: GET_ISSUE,
      variables: {
        filter: {
          assignmentId: this.props.assignmentId,
          problemId: this.props.problemId,
          studentId: jwt.decode(this.props.cookies.get('token')).id,
          issueType: 'REPORT_PROBLEM',
        },
      },
    })
    const issueId = data.issues[0].id
    await this.props.client.mutate({
      mutation: UPDATE_ISSUE,
      variables: {
        issueId,
        issueInput: {
          description: data.issues[0].description.concat(
            this.state.description
          ),
          dateAssigned: new Date().toISOString(),
          issueStatus: 'INITIATED',
          isRead: false,
          response: null,
        },
      },
    })
  }
  createReportIssue = async e => {
    const messages = await this.countReportIssues(this.props.client)
    if (messages === 0) {
      await this.props.client.mutate({
        mutation: CREATE_ISSUE,
        errorPolicy: 'all',
        variables: {
          assignmentId: this.props.assignmentId,
          problemId: this.props.problemId,
          studentId: jwt.decode(this.props.cookies.get('token')).id,
          description: this.state.description,
          issueType: 'REPORT_PROBLEM',
          dateAssigned: new Date().toISOString(),
        },
      })
    } else {
      await this.updateReportIssue()
    }
    if (this.state.description !== '') {
      this.enterMessageText(
        this.props.cookies.get('firstName'),
        this.state.description
      )
    }
    this.closingMessage()
  }
  createHelpIssue = async e => {
    const messages = await this.countHelpIssues(this.props.client)
    if (messages === 0) {
      const { data } = await this.props.client.mutate({
        mutation: CREATE_ISSUE,
        variables: {
          assignmentId: this.props.assignmentId,
          problemId: this.props.problemId,
          studentId: jwt.decode(this.props.cookies.get('token')).id,
          description: this.state.description,
          issueType: 'NEED_HELP',
          dateAssigned: new Date().toISOString(),
        },
      })
      if (this.state.description !== '' && this.state.report !== '') {
        this.enterMessageText(
          this.props.cookies.get('firstName'),

          this.state.description
        )
        this.setState({
          report: '',
          currentdescription: this.state.description,
          description: '',
        })
      }
      this.setState({ issueID: data.createIssue.id })
      this.receiveHelp()
    } else {
      this.updateHelpIssue()
    }
  }
  receiveHelp = async e => {
    const { data } = await this.props.client.mutate({
      mutation: GenerateRequestHelpMessage,
      variables: {
        issueId: this.state.issueID,
        helpOrder: this.state.hintNumber,
      },
    })
    if (
      data.generateRequestHelpMessage.messageText ===
      'Got it. We will send you a hint shortly.'
    ) {
      this.enterMessageText(
        'Dr.Li',
        'Thanks. We will get back to you within 24 hours :)'
      )
      await this.props.client.mutate({
        mutation: UPDATE_ISSUE,
        variables: {
          issueId: this.state.issueID,
          issueInput: {
            description: this.state.currentdescription.concat(
              this.state.description
            ),
            dateAssigned: new Date().toISOString(),
            issueStatus: 'INITIATED',
            isRead: false,
            response: null,
          },
        },
      })
      this.enterOptions({
        text: 'Okay',
        onClick: this.closeHelp,
      })
    } else if (
      data.generateRequestHelpMessage.messageText ===
      'I suggest you review the lecture video.'
    ) {
      this.enterMessageText(
        'Dr.Li',
        'Would you like to review the lecture video?'
      )
      this.setState({
        videoId: data.generateRequestHelpMessage.chatVideo,
        hintNumber: this.state.hintNumber + 1,
      })
      this.watchLectureVideo()
    } else {
      if (this.state.hintNumber === 1) {
        this.enterMessageText('Dr.Li', 'Would you like a hint?')
      } else {
        this.enterMessageText('Dr.Li', 'Would you like another hint?')
      }

      this.setState({
        options: [
          {
            text: 'Yes',
            onClick: this.displayHint,
          },
          {
            text: 'No',
            onClick: this.rejectHint,
          },
        ],
      })
    }
  }
  displayHint = async e => {
    const { data } = await this.props.client.mutate({
      mutation: GenerateRequestHelpMessage,
      variables: {
        issueId: this.state.issueID,
        helpOrder: this.state.hintNumber,
      },
    })
    this.setState({ hintNumber: this.state.hintNumber + 1 })
    this.enterMessageText(this.props.cookies.get('firstName'), 'Yes')
    this.enterMessageText(
      'Dr.Li',

      data.generateRequestHelpMessage.messageText
    )
    if (data.generateRequestHelpMessage.chatVideo !== null) {
      this.setState({
        videoId: data.generateRequestHelpMessage.chatVideo,
        videoModalOpen: true,
      })
    }
    if (data.generateRequestHelpMessage.chatImage !== null) {
      this.setState({
        imageId: data.generateRequestHelpMessage.chatImage,
      })
      this.openImageModal()
    }
    if (data.generateRequestHelpMessage.chatText !== null) {
      this.enterMessageText(
        'Dr.Li',

        data.generateRequestHelpMessage.chatText
      )
      this.enterMessageText('Dr.Li', 'Was that helpful?')
      this.hintFollowUp()
    }
  }
  updateHelpIssue = async e => {
    const { data } = await this.props.client.mutate({
      mutation: GET_ISSUE,
      variables: {
        filter: {
          assignmentId: this.props.assignmentId,
          problemId: this.props.problemId,
          studentId: jwt.decode(this.props.cookies.get('token')).id,
          issueType: 'NEED_HELP',
        },
      },
    })

    const issueId = data.issues[0].id
    this.setState({
      issueID: issueId,
      currentdescription: data.issues[0].description,
    })
    if (this.state.description !== '' && this.state.report !== '') {
      this.enterMessageText(
        this.props.cookies.get('firstName'),

        this.state.description
      )
      this.setState({ report: '' })
    }
    this.receiveHelp()
    this.hintFollowUp()
  }
  countReportIssues = async client => {
    const { data } = await client.query({
      query: COUNT_MESSAGES,
      context: { headers: { 'cache-control': 'no-cache' } },
      fetchPolicy: 'no-cache',
      variables: {
        filter: {
          studentId: jwt.decode(this.props.cookies.get('token')).id,
          problemId: this.props.problemId,
          issueType: 'REPORT_PROBLEM',
        },
      },
    })
    return data.countIssues
  }
  countHelpIssues = async client => {
    const { data } = await client.query({
      query: COUNT_MESSAGES,
      context: { headers: { 'cache-control': 'no-cache' } },
      fetchPolicy: 'no-cache',
      variables: {
        filter: {
          studentId: jwt.decode(this.props.cookies.get('token')).id,
          problemId: this.props.problemId,
          issueType: 'NEED_HELP',
        },
      },
    })
    return data.countIssues
  }

  render() {
    const { close } = this.props
    let renderContainer = false
    if (this.state.report !== '') {
      renderContainer = (
        <KeyboardEventHandler
          handleKeys={['enter']}
          onKeyEvent={(key, e) =>
            this.state.report === 'report'
              ? this.createReportIssue()
              : this.createHelpIssue()
          }
          isExclusive={true}
          style={{ width: '19%', position: 'fixed', bottom: 2 }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {' '}
            <input
              style={{
                borderRight: 'none',

                width: '100%',
                outline: 'none',
              }}
              className="form-control"
              value={this.state.description}
              onChange={e => this.setState({ description: e.target.value })}
              type="text"
              placeholder="Type your message..."
            />
            <button
              className="btn btn-primary"
              type="button"
              onClick={
                this.state.report === 'report'
                  ? this.createReportIssue
                  : this.createHelpIssue
              }
              style={{
                borderColor: solveColors.blue1,
                borderWidth: '1px',
                borderStyle: 'solid',
                fontSize: '16px',
                background: solveColors.blue1,
              }}
            >
              <SendRoundedIcon />{' '}
            </button>
          </View>
        </KeyboardEventHandler>
      )
    }
    return (
      <React.Fragment>
        <View />
        {this.state.visible && (
          <View style={styles.results}>
            <TouchableOpacity
              style={{ position: 'absolute', left: 10, top: 10 }}
              onPress={() => {
                this.restoreDefault()
                close()
              }}
            >
              <Text style={styles.exitButton}>X</Text>
            </TouchableOpacity>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {' '}
              <Image
                source={ArdentLogo}
                style={{
                  width: 50,
                  height: 75,
                  resizeMode: 'contain',
                }}
              />
              <Text style={styles.title}>Ardent Care</Text>{' '}
            </div>
            <ChatBox
              messages={this.state.messages}
              options={this.state.options}
            />
            {renderContainer}
          </View>
        )}
        <VideoModal
          visible={this.state.videoModalOpen}
          onClose={this.closeVideoModal}
          videoId={this.state.videoId}
        />
        <ImageModal
          visible={this.state.imageModalOpen}
          onClose={this.closeImageModal}
          image={this.state.image}
        />
      </React.Fragment>
    )
  }
}

const styles = StyleSheet.create({
  fieldDescriptor: {
    fontSize: '12px',
    marginBottom: '3px',
  },
  title: {
    color: solveColors.blue5,
    fontSize: 24,
    marginBottom: '9%',
    marginLeft: '5px',
  },
  issueType: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  results: {
    position: 'absolute',
    right: 0,
    top: 0,
    alignItems: 'center',
    backgroundColor: solveColors.grey1,
    padding: 25,
    width: 'calc(180px + 10vw)',
    boxShadow: '-5px 0px 10px rgba(0, 0, 0, 0.25)',
    height: '100%',
  },
  button: {
    width: 100,
    height: 40,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: solveColors.blue3,
  },
  exitButton: {
    fontWeight: 'bold',
    fontSize: 20,
    color: solveColors.grey4,
  },
})
export default withCookies(RequestHelpForm)
