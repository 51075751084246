import React from 'react'
import { Text } from 'react-native-web'
import solveColors from '../../components/colors'

//mui
import Divider from '@material-ui/core/Divider'
import Container from '@material-ui/core/Container'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Paper from '@material-ui/core/Paper'

const ConfirmParentForm = React.memo(props => {
  const {
    classes,
    handleConfirmParent,
    handleFormChange,
    error,
    parentState,
  } = props
  const { firstName, lastName, email, phoneNumber, relation } = parentState

  return (
    <Paper className={classes.mediumFormContainer} elevation={4}>
      <Container className={classes.headerContainer}>
        <Text
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
            color: solveColors.blue3,
          }}
        >
          Confirm Information
        </Text>
        {error && (
          <Text
            style={{
              marginLeft: 'auto',
              fontSize: '14px',
              color: solveColors.red1,
            }}
          >
            {error}
          </Text>
        )}
      </Container>
      <Divider orientation={'horizontal'} />
      <Container className={classes.fieldsContainer}>
        <TextField
          name={'firstName'}
          value={firstName}
          label={'First Name'}
          onChange={handleFormChange}
          InputLabelProps={{
            shrink: true,
          }}
          style={{ width: '44%' }}
        />
        <TextField
          name={'lastName'}
          value={lastName}
          label={'Last Name'}
          onChange={handleFormChange}
          style={{ width: '44%' }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Container>
      <Container className={classes.fieldsContainer}>
        <TextField
          name={'email'}
          value={email}
          label={'Email'}
          onChange={handleFormChange}
          style={{ width: '44%' }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          name={'phoneNumber'}
          value={phoneNumber}
          label={'Phone Number'}
          onChange={handleFormChange}
          InputLabelProps={{
            shrink: true,
          }}
          style={{ width: '44%' }}
        />
      </Container>
      <Container className={classes.fieldsContainer}>
        <Container
          style={{
            display: 'flex',
            width: '44%',
            padding: 0,
          }}
        >
          <Text
            style={{
              width: '20%',
              fontSize: '13px',
              fontWeight: 400,
              lineHeight: 1,
              color: 'rgba(0, 0, 0, 0.54)',
            }}
          >
            Relationship
          </Text>
          <FormControl component="fieldset" style={{ width: '80%' }}>
            <RadioGroup
              id={'student-relation-select'}
              name={'relation'}
              value={relation}
              onChange={handleFormChange}
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <FormControlLabel
                value={'Father'}
                control={<Radio color={'primary'} />}
                label={'Father'}
              />
              <FormControlLabel
                value={'Mother'}
                control={<Radio color={'secondary'} />}
                label={'Mother'}
              />
              <FormControlLabel
                value={'Other'}
                control={<Radio color={'primary'} />}
                label={'Other'}
              />
            </RadioGroup>
          </FormControl>
        </Container>
      </Container>
      <Divider orientation={'horizontal'} />
      <Container className={classes.actionContainer}>
        <Button
          className={classes.button}
          variant={'contained'}
          color={'primary'}
          onClick={handleConfirmParent}
        >
          Next
        </Button>
      </Container>
    </Paper>
  )
})

export default ConfirmParentForm
