import Modal from 'modal-react-native-web'
import { Prompt } from 'react-router-dom'
import React, { Component } from 'react'
import ResultsModal from './ResultsModal'
import { ScrollView, StyleSheet, View, Text, Image } from 'react-native'
import {
  CREATE_GRADED_PROBLEM_RESPONSE,
  CREATE_UNGRADED_PROBLEM_RESPONSE,
  UPDATE_SUBMISSION,
  UPDATE_RESPONSE_ATTACHMENT,
  GET_SIGNEDURL,
  UPDATE_RESPONSE_VALUE,
  UPDATE_RESPONSE_VALUE_GRADED,
  DELETE_ATTACHMENT,
  UPDATE_RESPONSE_SCRATCHPAD,
  CREATE_HINT,
} from '../../../services/queries'
import ProblemNavBar from './ProblemNavBar'
import problemStates from './ProblemStates'
import TimerBar from './TimerBar'
import {
  MessageModal,
  DialogModal,
  VideoPlayerForm,
  VideoModal,
} from '../../components/index'
import Canvas from './Canvas'
import _ from 'lodash'
import RequestHelpForm from './RequestHelpForm'
//import ProblemIssueForm from './ProblemIssueForm'
import { checkAnswer } from './../../../utils/answers'
import {
  KeyboardProblemForm,
  MultipleChoiceProblemForm,
  FreeformProblemForm,
  ScientificProblemForm,
  DisplayProblemForm,
  HotSpotProblemForm,
  RankingProblemForm,
  MatchProblemForm,
  EmbedProblemForm,
  MultipleTextProblemForm,
} from './ProblemForms'

class ProblemSetForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentProbNum: parseInt(sessionStorage.getItem('currentProbNum')) || 0,
      problemStates: props.problemStates,
      problemAnswers: props.problemAnswers,
      problemAttachments: props.problemAttachments,
      problemResponses: props.problemResponses,
      problemScratchpadUrls: props.problemScratchpads,
      dlgIsOpen: false,
      redirectDlgIsOpen: false,
      modalIsOpen: false,
      resultsIsOpen: false,
      modalMsg: '',
      dlgMsg: '',
      redirectDlgMsg: '',
      complete: false,
      answered: !props.problemStates.includes(problemStates.unanswered),
      problems: props.problems,
      problemStartTime: null,
      questionImgStyle: { height: 400, width: 400, resizeMode: 'contain' },
      solutionImgStyle: { height: 200, width: 200, resizeMode: 'contain' },
      scratchpadOpen: sessionStorage.getItem('scratch') === '1',
      updateScratchpadOnSubmit: false,
      showSolution: props.review,
      requestHelpVisible: false,
      videoModalId: sessionStorage.getItem('videoId'),
      changed: false,
    }
    this.multiSessWarningMsg =
      'We have detected that you have multiple sign-ins on SOLVE. This session is no longer valid. Please close this session and resume your work on the new session.'
    this.matchPF = React.createRef()
  }
  componentDidMount() {
    Modal.setAppElement('body')
    sessionStorage.removeItem('fullScreen')

    if (!this.props.review && this.props.randomizeOrder) {
      this.shuffleProblems()
    }
    if (!this.state.problemStartTime)
      this.setState({ problemStartTime: Date.now() })
    this.updateImage()
  }
  componentDidUpdate() {
    this.updateImage()
  }
  updateImage = () => {
    const { currentProbNum, problems } = this.state
    const { questionImageUrl, solutionImageUrl } = problems[currentProbNum]
    if (questionImageUrl) {
      Image.getSize(questionImageUrl, (width, height) => {
        if (
          width !== this.state.questionImgStyle.width ||
          height !== this.state.questionImgStyle.height
        ) {
          this.setState({
            questionImgStyle: { width, height, resizeMode: 'contain' },
          })
        }
      })
    }
    if (solutionImageUrl) {
      Image.getSize(solutionImageUrl, (width, height) => {
        if (
          width !== this.state.solutionImgStyle.width ||
          height !== this.state.solutionImgStyle.height
        ) {
          this.setState({
            solutionImgStyle: { width, height, resizeMode: 'contain' },
          })
        }
      })
    }
  }
  shuffleProblems = () => {
    const swapValues = (list, i, j) => {
      const temp = list[i]
      list[i] = list[j]
      list[j] = temp
    }
    let problems = this.state.problems
    let answers = this.state.problemAnswers
    let states = this.state.problemStates
    let attachments = this.state.problemAttachments
    let scratchpads = this.state.problemScratchpadUrls
    let responses = this.state.problemResponses
    for (let i = 0; i < problems.length; i++) {
      const j = Math.floor(Math.random() * problems.length)
      swapValues(problems, i, j)
      swapValues(answers, i, j)
      swapValues(states, i, j)
      swapValues(attachments, i, j)
      swapValues(scratchpads, i, j)
      swapValues(responses, i, j)
    }
    this.setState({ problems, answers })
  }

  incrementProblem = () => {
    sessionStorage.setItem(
      'currentProbNum',
      Math.min(this.state.currentProbNum + 1, this.props.problems.length - 1)
    )
    this.setState({
      currentProbNum: Math.min(
        this.state.currentProbNum + 1,
        this.props.problems.length - 1
      ),
    })
  }
  updateProblemState = (index, value) => {
    const temp = this.state.problemStates
    temp[index] = value
    this.setState({ problemStates: temp })
  }
  updateProblemResponse = (index, id, value, attachment, scratchpad) => {
    const temp = this.state.problemResponses
    temp[index] = { id, value, attachment, scratchpad }
    this.setState({ problemResponses: temp })
  }
  updateProblemAnswer = (index, value) => {
    const temp = this.state.problemAnswers
    temp[index] = value
    this.setState({ problemAnswers: temp })
  }
  updateProblemAttachments = (index, value) => {
    const temp = this.state.problemAttachments
    temp[index] = value
    this.setState({ problemAttachments: temp })
  }
  handleProblemChange = num => {
    this.setState({ currentProbNum: num })
  }
  toggleFullScreen = () => {
    sessionStorage.setItem('fullScreen', !this.props.fullScreen ? '1' : '0')
    sessionStorage.setItem('currentProbNum', this.state.currentProbNum)
    this.props.setFullScreen(!this.props.fullScreen)
    this.props.toggleMenu()
  }
  updateSubmission = _.throttle(async () => {
    const { autoGrading, client, gotoLessons } = this.props
    const sum = this.state.problemStates.reduce((total, cur) => {
      if (cur === 1) {
        return total + cur
      } else {
        return total
      }
    }, 0)

    const { errors } = await client.mutate({
      errorPolicy: 'all',
      mutation: UPDATE_SUBMISSION,
      context: {
        headers: { 'X-Ardent-Session-Id': sessionStorage.getItem('sessionId') },
      },
      variables: {
        submissionId: sessionStorage.getItem('submissionId'),
        date: new Date().toISOString(),
        sum,
        graded: autoGrading,
        attemptNumber: parseInt(sessionStorage.getItem('attemptNumber')),
      },
    })
    if (errors) {
      this.openModal(this.multiSessWarningMsg)
      return
    }
    gotoLessons()
  }, 2000)
  updateResponseAttachment = _.throttle(async () => {
    const { client } = this.props
    const { problemAttachments, problemResponses, currentProbNum } = this.state
    const response = problemResponses[currentProbNum]

    if (response.attachment === null && problemAttachments[currentProbNum]) {
      const file = problemAttachments[currentProbNum]
      const s3SignResult = await client.mutate({
        mutation: GET_SIGNEDURL,
        variables: {
          filename: file.name,
          contentType: file.type,
        },
      })
      if (
        !s3SignResult ||
        !s3SignResult.data ||
        !s3SignResult.data.s3Sign.success
      ) {
        this.openModal('Failed to upload attachment please try again.')
        return false
      }
      const { signedUrl, fileKey, publicUrl } = s3SignResult.data.s3Sign
      this.updateProblemAttachments(currentProbNum, {
        name: file.name,
        fileKey,
      })
      await fetch(signedUrl, {
        method: 'PUT',
        body: file,
      })
      const { errors } = await client.mutate({
        errorPolicy: 'all',
        mutation: UPDATE_RESPONSE_ATTACHMENT,
        context: {
          headers: {
            'X-Ardent-Session-Id': sessionStorage.getItem('sessionId'),
          },
        },
        variables: {
          responseId: response.id,
          attachmentKey: fileKey,
          attachmentUrl: publicUrl,
        },
      })
      if (errors) {
        this.openModal(this.multiSessWarningMsg)
      }
      this.updateProblemResponse(
        currentProbNum,
        response.id,
        response.value,
        fileKey,
        response.scratchpad
      )
    }
    return true
  }, 500)
  updateResponseValue = _.throttle(async (value, correct) => {
    const { client, autoGrading } = this.props
    const { problemResponses, currentProbNum, problems } = this.state
    const response = problemResponses[currentProbNum]
    if (!response.id) return
    if (response.value !== value) {
      if (autoGrading) {
        const { errors } = await client.mutate({
          mutation: UPDATE_RESPONSE_VALUE_GRADED,
          context: {
            headers: {
              'X-Ardent-Session-Id': sessionStorage.getItem('sessionId'),
            },
          },
          errorPolicy: 'all',
          variables: {
            responseId: response.id,
            value,
            sum: correct ? problems[currentProbNum].points : 0,
          },
        })
        if (errors) {
          this.openModal(this.multiSessWarningMsg)
          return
        }
      } else {
        const { errors } = await client.mutate({
          mutation: UPDATE_RESPONSE_VALUE,
          context: {
            headers: {
              'X-Ardent-Session-Id': sessionStorage.getItem('sessionId'),
            },
          },
          errorPolicy: 'all',
          variables: {
            responseId: response.id,
            value,
          },
        })
        if (errors) {
          this.openModal(this.multiSessWarningMsg)
          return
        }
      }
      this.updateProblemResponse(
        currentProbNum,
        response.id,
        value,
        response.attachment,
        response.scratchpad
      )
    }
  }, 500)

  updateResponseScratchpad = _.throttle(async (ind, id, forced) => {
    if (this.state.updateScratchpadOnSubmit || forced) {
      const { client } = this.props
      const { problemResponses } = this.state
      const response = problemResponses[ind]

      const scratchpadUrl = this.state.problemScratchpadUrls[ind]

      id = id ? id : response.id

      if (!id) return

      this.updateProblemResponse(
        ind,
        id,
        response.value,
        response.attachment,
        scratchpadUrl
      )

      const { errors } = await client.mutate({
        mutation: UPDATE_RESPONSE_SCRATCHPAD,
        context: {
          headers: {
            'X-Ardent-Session-Id': sessionStorage.getItem('sessionId'),
          },
        },
        errorPolicy: 'all',
        variables: {
          responseId: id,
          scratchpadUrl: scratchpadUrl,
        },
      })
      if (errors) {
        this.openModal(this.multiSessWarningMsg)
        return
      }
      this.setState({ updateScratchpadOnSubmit: false })
    }
  }, 500)

  saveScratchpadImg = _.throttle(async dataUrl => {
    const { client, instructor } = this.props
    const { currentProbNum, problems } = this.state

    if (this.state.problemScratchpadUrls[currentProbNum]) {
      this.deleteScratchpadImg(currentProbNum)
    }

    const blob = await fetch(dataUrl).then(res => res.blob())
    const s3SignResult = await client.mutate({
      mutation: GET_SIGNEDURL,
      variables: {
        filename: 'scratchpadImg',
        contentType: blob.type,
      },
    })
    if (
      !s3SignResult ||
      !s3SignResult.data ||
      !s3SignResult.data.s3Sign.success
    ) {
      this.openModal('Failed to save scratchpad please try again.')
      return false
    }
    const signedUrl = s3SignResult.data.s3Sign.signedUrl
    await fetch(signedUrl, {
      method: 'PUT',
      body: blob,
    })

    const publicUrl = s3SignResult.data.s3Sign.publicUrl

    if (instructor) {
      //Create a hint if instructor saves a scratchpad
      const image = publicUrl.split('/').slice(-1)[0]
      if (image) {
        await client.mutate({
          mutation: CREATE_HINT,
          variables: {
            input: {
              problemId: problems[currentProbNum].id,
              cost: 0,
              image: image,
            },
          },
        })
      }
    } else {
      let temp = this.state.problemScratchpadUrls
      temp[currentProbNum] = publicUrl
      this.setState({
        problemScratchpadUrls: temp,
        updateScratchpadOnSubmit: true,
      })
      if (this.state.problemResponses[currentProbNum].id) {
        this.updateResponseScratchpad(currentProbNum)
      }
    }
    return true
  }, 500)

  deleteScratchpadImg = _.throttle(async ind => {
    const { client } = this.props
    let url = this.state.problemScratchpadUrls[ind]
    let temp = this.state.problemScratchpadUrls
    temp[ind] = null
    this.setState({ problemScratchpadUrls: temp })

    await client.mutate({
      mutation: DELETE_ATTACHMENT,
      variables: { fileKey: url.split('/')[3] },
    })

    this.updateResponseScratchpad(ind, null, true)
  }, 500)

  createProblemResponse = _.throttle(async (problemId, value, correct) => {
    const { client, autoGrading, submissionId } = this.props
    let responseId = null
    const {
      currentProbNum,
      problemAttachments,
      problemStartTime,
      problems,
    } = this.state
    if (autoGrading) {
      const { data, errors } = await client.mutate({
        mutation: CREATE_GRADED_PROBLEM_RESPONSE,
        context: {
          headers: {
            'X-Ardent-Session-Id': sessionStorage.getItem('sessionId'),
          },
        },
        errorPolicy: 'all',
        variables: {
          sum: correct ? problems[currentProbNum].points : 0,
          submissionId,
          problemId,
          value,
          timeSpent: Math.round((Date.now() - problemStartTime) / 1000),
        },
      })
      if (errors) {
        this.openModal(this.multiSessWarningMsg)
        return
      }
      responseId = data.createProblemResponse.id
    } else {
      const { data, errors } = await client.mutate({
        mutation: CREATE_UNGRADED_PROBLEM_RESPONSE,
        context: {
          headers: {
            'X-Ardent-Session-Id': sessionStorage.getItem('sessionId'),
          },
        },
        errorPolicy: 'all',
        variables: {
          submissionId: submissionId,
          problemId: problemId,
          value: value,
          timeSpent: Math.round((Date.now() - problemStartTime) / 1000),
        },
      })
      if (errors) {
        this.openModal(this.multiSessWarningMsg)
        return
      }
      responseId = data.createProblemResponse.id
    }
    this.setState({ problemStartTime: Date.now() })
    this.updateProblemResponse(currentProbNum, responseId, value)
    if (
      problems[currentProbNum].problemType === 'FREE_FORM' &&
      problemAttachments[currentProbNum]
    ) {
      const file = problemAttachments[currentProbNum]
      const s3SignResult = await client.mutate({
        mutation: GET_SIGNEDURL,
        variables: {
          filename: file.name,
          contentType: file.type,
        },
      })
      if (
        !s3SignResult ||
        !s3SignResult.data ||
        !s3SignResult.data.s3Sign.success
      ) {
        this.openModal('Failed to upload attachment please try again.')
      } else {
        const { signedUrl, fileKey, publicUrl } = s3SignResult.data.s3Sign
        this.updateProblemAttachments(currentProbNum, {
          name: file.name,
          fileKey,
        })
        await fetch(signedUrl, {
          method: 'PUT',
          body: file,
        })
        const { errors } = await client.mutate({
          mutation: UPDATE_RESPONSE_ATTACHMENT,
          context: {
            headers: {
              'X-Ardent-Session-Id': sessionStorage.getItem('sessionId'),
            },
          },
          variables: {
            responseId,
            attachmentKey: fileKey,
            attachmentUrl: publicUrl,
          },
        })
        if (errors) {
          this.openModal(this.multiSessWarningMsg)
          return
        }
        this.updateProblemResponse(currentProbNum, responseId, value, fileKey)
      }
    }

    this.updateResponseScratchpad(currentProbNum, responseId)
  }, 500)
  deleteProblemAttachment = _.throttle(async fileKey => {
    const { client } = this.props
    const { problemResponses, currentProbNum } = this.state
    const response = problemResponses[currentProbNum]
    const { errors } = await client.mutate({
      errorPolicy: 'all',
      mutation: UPDATE_RESPONSE_ATTACHMENT,
      context: {
        headers: { 'X-Ardent-Session-Id': sessionStorage.getItem('sessionId') },
      },
      variables: {
        responseId: response.id,
        attachmentKey: null,
        attachmentUrl: null,
      },
    })
    if (errors) {
      this.openModal(this.multiSessWarningMsg)
      return
    }
    await client.mutate({
      mutation: DELETE_ATTACHMENT,
      variables: {
        fileKey,
      },
    })
    this.updateProblemResponse(
      currentProbNum,
      response.id,
      response.value,
      null,
      response.scratchpad
    )
  }, 500)
  handleAssignmentSubmit = async () => {
    const { autoGrading } = this.props
    this.setState({ complete: true })
    if (autoGrading) this.openResultsModal()
    else {
      await this.updateSubmission()
    }
  }
  handleProblemSubmit = _.throttle(
    async (studentAnswer, correctAnswer, fromSkip) => {
      const {
        problems,
        currentProbNum,
        problemAttachments,
        problemResponses,
        isLoading,
      } = this.state
      const { feedbackType, instructor, review } = this.props
      const currentProblem = problems[currentProbNum]
      const prevState = this.state.problemStates[currentProbNum]
      if (review || (!studentAnswer && !problemAttachments[currentProbNum])) {
        this.incrementProblem()
        return
      }
      if (isLoading) {
        return
      }
      this.setState({ isLoading: true })
      if (!fromSkip) {
        const correct = checkAnswer(
          studentAnswer,
          correctAnswer,
          currentProblem.caseSensitive,
          currentProblem.problemType,
          currentProblem.approximate
        )
        const currProb_id = currentProblem.id
        if (
          !instructor &&
          problemResponses[currentProbNum].id &&
          prevState !== problemStates.unanswered
        ) {
          let errorOccured = false
          this.updateResponseValue(studentAnswer, correct)
          if (currentProblem.problemType === 'FREE_FORM') {
            errorOccured = errorOccured || !this.updateResponseAttachment()
          }
          if (errorOccured) {
            console.error('FAILED TO UPDATE ATTACHMENT.')
          }
          await this.updateResponseScratchpad(currentProbNum)
        } else if (!instructor) {
          await this.createProblemResponse(currProb_id, studentAnswer, correct)
        }
        if (correct) {
          this.updateProblemState(currentProbNum, problemStates.correct)
        } else {
          this.updateProblemState(currentProbNum, problemStates.incorrect)
        }
      }

      if (!this.state.problemStates.includes(problemStates.unanswered)) {
        this.setState({ answered: true })
      }
      this.setState({ changed: true })

      if (
        feedbackType !== 'INSTANT' ||
        prevState !== problemStates.unanswered
      ) {
        this.incrementProblem()
      }
      this.setState({ isLoading: false })
    },
    500
  )
  openRequestHelpForm = () => {
    this.setState({ requestHelpVisible: true })
  }
  openModal = msg => {
    this.setState({ modalMsg: msg, modalIsOpen: true })
  }
  openResultsModal = () => {
    this.setState({ resultsIsOpen: true })
  }
  openDlgModal = msg => {
    this.setState({ dlgMsg: msg, dlgIsOpen: true })
  }
  openReDlgModal = msg => {
    this.setState({ redirectDlgMsg: msg, redirectDlgIsOpen: true })
  }
  closeRequestHelpForm = () => {
    this.setState({ requestHelpVisible: false })
  }
  closeModal = () => {
    this.setState({ modalIsOpen: false })
  }
  closeResultsModal = async () => {
    this.setState({ resultsIsOpen: false })
    await this.updateSubmission()
  }
  closeDlgModal = () => {
    this.setState({ dlgIsOpen: false })
  }
  closeReDlgModal = () => {
    this.setState({ redirectDlgIsOpen: false })
  }
  handleTimeOut = () => {
    this.openModal('Time is up!')
    this.setState({ complete: true })
  }
  toggleShowSolution = () => {
    this.setState({ showSolution: !this.state.showSolution })
  }
  toggleScratchpad = () => {
    if (this.state.scratchpadOpen) {
      this.setState({ scratchpadOpen: false })
      sessionStorage.removeItem('scratch')
    } else {
      this.setState({ scratchpadOpen: true })
      sessionStorage.setItem('scratch', 1)
    }
  }

  renderProblem = problemType => {
    const {
      currentProbNum,
      complete,
      modalIsOpen,
      problemAnswers,
      problemAttachments,
      showSolution,
      problems,
      answered,
      problemStates,
      isLoading,
    } = this.state
    const {
      allowSkip,
      assignmentId,
      feedbackType,
      length,
      instructor,
      review,
    } = this.props
    const currentProblem = problems[currentProbNum]
    const problemFormProps = {
      isLoading,
      instructor,
      currentProbNum,
      allowSkip,
      problemStates,
      incrementProblem: this.incrementProblem,
      problem: currentProblem,
      onProblemSubmit: this.handleProblemSubmit,
      onAssignmentSubmit: this.handleAssignmentSubmit,
      openModal: this.openModal,
      probState: this.state.problemStates[currentProbNum],
      lastProb: currentProbNum === length - 1,
      complete,
      answered,
      assignmentId: assignmentId,
      currentAnswer: problemAnswers[currentProbNum],
      updateProblemAnswer: studentAnswer => {
        this.updateProblemAnswer(this.state.currentProbNum, studentAnswer)
      },
      feedbackType,
      review,
      showSolution,
      solutionVideo: problems[currentProbNum].solutionVideo,
      solutionImageUrl: problems[currentProbNum].solutionImageUrl,
      scratchpadUrl: this.state.problemScratchpadUrls[currentProbNum],
      deleteScratchpadImg: this.deleteScratchpadImg,
      toggleShowSolution: this.toggleShowSolution,
      toggleScratchpad: this.toggleScratchpad,
      problemSetLength: problems
        .filter(p => p.problemType !== 'DISPLAY')
        .filter(p => p.problemType !== 'EMBED').length,
      gotoLessons: this.props.gotoLessons,
    }

    switch (problemType) {
      case 'MANY_HOTSPOTS':
      case 'ONE_HOTSPOT':
        return <HotSpotProblemForm {...problemFormProps} />
      case 'MATCHING':
        return (
          <MatchProblemForm
            ref={this.matchPF}
            {...problemFormProps}
            matchCounter={this.matchCounter}
          />
        )
      case 'EMBED':
        return <EmbedProblemForm {...problemFormProps} />
      case 'OFFLINE':
        return (
          <DisplayProblemForm
            {...problemFormProps}
            scratchpadOpen={this.state.scratchpadOpen}
          />
        )
      case 'DISPLAY':
        return (
          <DisplayProblemForm
            {...problemFormProps}
            scratchpadOpen={this.state.scratchpadOpen}
          />
        )
      case 'MULTIPLE_TEXT_RESPONSE':
        return (
          <MultipleTextProblemForm
            {...problemFormProps}
            scratchpadOpen={this.state.scratchpadOpen}
          />
        )
      case 'SCIENTIFIC_UNIT':
        return (
          <ScientificProblemForm
            {...problemFormProps}
            onClose={this.closeModal}
            scratchpadOpen={this.state.scratchpadOpen}
            modalIsOpen={modalIsOpen}
          />
        )
      case 'SHORT_TEXT_RESPONSE':
      case 'SHORT_NUMBER_RESPONSE':
        return (
          <KeyboardProblemForm
            {...problemFormProps}
            scratchpadOpen={this.state.scratchpadOpen}
            onClose={this.closeModal}
            modalIsOpen={modalIsOpen}
          />
        )
      case 'TRUE_FALSE':
      case 'MULTIPLE_CHOICE_ONE_ANSWER':
      case 'MULTIPLE_CHOICE_MANY_ANSWERS':
        return <MultipleChoiceProblemForm {...problemFormProps} />
      case 'FREE_FORM':
        return (
          <FreeformProblemForm
            {...problemFormProps}
            attachment={problemAttachments[currentProbNum]}
            updateProblemAttachments={file =>
              this.updateProblemAttachments(currentProbNum, file)
            }
            deleteProblemAttachment={this.deleteProblemAttachment}
          />
        )
      case 'RANKING':
        return (
          <RankingProblemForm
            gotoLessons={this.props.gotoLessons}
            {...problemFormProps}
          />
        )
      default:
        return <Text>This Problem Type is not supported :(</Text>
    }
  }

  render() {
    const {
      currentProbNum,
      complete,
      modalIsOpen,
      modalMsg,
      resultsIsOpen,
      redirectDlgIsOpen,
      redirectDlgMsg,
      problems,
      solutionImgStyle,
      questionImgStyle,
      problemScratchpadUrls,
      showSolution,
      requestHelpVisible,
      changed,
    } = this.state
    const {
      allowedAttempts,
      allowSkip,
      assignmentId,
      feedbackType,
      lessonPlan,
      level,
      randomized,
      timeLimit,
      problemSetOrder,
      title,
      totalTimeSpent,
      gotoLessons,
      autoGrading,
      instructor,
      review,
      client,
      fullScreen,
      problemSetId,
      problemResponses,
    } = this.props
    const currentProblem = problems[currentProbNum]
    const currentProblemResponse = problemResponses[currentProbNum]
    const savedScratchUrl = problemScratchpadUrls[currentProbNum]
    const {
      questionImageUrl,
      questionVideo,
      solutionImageUrl,
      solutionVideo,
      problemType,
      caseSensitive,
    } = currentProblem

    return (
      <View style={styles.container}>
        <Canvas
          instructor={instructor}
          toggleScratchpad={this.toggleScratchpad}
          visible={this.state.scratchpadOpen}
          onSaveScratchpad={this.saveScratchpadImg}
          currentProbNum={currentProbNum}
          currentProbUrl={savedScratchUrl}
          toggleFullScreen={this.toggleFullScreen}
          fullScreen={fullScreen}
        />
        <TimerBar
          problemSetId={problemSetId}
          lessonTitle={lessonPlan.title}
          lessonOrder={lessonPlan.order}
          lessonPlanType={lessonPlan.lessonPlanType}
          problemSetOrder={problemSetOrder}
          problemSetTitle={title}
          assignmentId={assignmentId}
          problemId={currentProblem.id}
          timeLimit={timeLimit}
          onTimeOut={this.handleTimeOut}
          complete={complete}
          redirect={gotoLessons}
          review={review}
          totalTimeSpent={totalTimeSpent}
          problems={problems}
          openRequestHelpForm={this.openRequestHelpForm}
          openModal={this.openModal}
          toggleFullScreen={this.toggleFullScreen}
          fullScreen={fullScreen}
        />
        <ScrollView
          contentContainerStyle={styles.row}
          scrollEventThrottle={1}
          onScroll={_.throttle(() => {
            if (currentProblem.problemType === 'MATCHING') {
              this.matchPF.current.forceUpdate()
            }
          }, 100)}
        >
          <View style={{ height: '100vh' }}>
            <ProblemNavBar
              feedbackType={feedbackType}
              currentProbNum={currentProbNum + 1}
              problems={problems}
              problemStates={this.state.problemStates}
              onSelectionChange={this.handleProblemChange}
              review={review}
            />
            {process.env.REACT_APP_ENV !== 'production' && (
              <View
                style={{
                  position: 'sticky',
                  bottom: 0,
                  margin: 10,
                }}
              >
                <Text>Current Problem: {currentProbNum + 1}</Text>
                <Text>Problem Type: {currentProblem.problemType}</Text>

                <Text>
                  Feedback Type: {feedbackType ? feedbackType : 'INSTANT'}
                </Text>
                <Text>
                  Allowed Attempts:{' '}
                  {allowedAttempts ? allowedAttempts : 'Unlimited'}
                </Text>
                <Text>Skips: {allowSkip ? 'YES' : 'NO'}</Text>
                <Text>Level: {level}</Text>
                <Text>Time Limit: {timeLimit ? timeLimit : 'Unlimited'}</Text>
                <Text>Randomize: {randomized ? 'YES' : 'NO'}</Text>
                <Text>Review: {review ? 'YES' : 'NO'}</Text>
                <Text>AutoGrading: {autoGrading ? 'YES' : 'NO'}</Text>
                <Text>Case Sensitive: {caseSensitive ? 'YES' : 'NO'}</Text>
                <Text>Answer: {currentProblem.answer}</Text>
                <Text>
                  Randomize Choices:
                  {currentProbNum.randomizeChoices ? 'YES' : 'NO'}
                </Text>
                <Text>
                  Approximate:
                  {currentProblem.approximate ? 'YES' : 'NO'}
                </Text>
                <Text>
                  TypesetMath:
                  {currentProblem.typesetMath ? 'YES' : 'NO'}
                </Text>
              </View>
            )}
          </View>
          <View style={styles.problems}>
            {/*
              <Text
              style={{
                marginTop: 10,
                fontStyle: 'bold',
                fontSize: 20,
                userSelect: 'none',
              }}
            >
              {`Question ${currentProbNum + 1} of ${problems.length}\n\n`}
            </Text>
            */}

            {questionVideo && (
              <VideoPlayerForm videoId={questionVideo} fit={true} />
            )}
            {questionImageUrl &&
              currentProblem.problemType !== 'ONE_HOTSPOT' &&
              currentProblem.problemType !== 'MANY_HOTSPOTS' && (
                <Image style={questionImgStyle} source={questionImageUrl} />
              )}

            {this.renderProblem(problemType)}
            {currentProblemResponse.comment && (
              <View style={styles.instructorCommentBox}>
                <Text>Instructor's Comment:</Text>
                <Text>{currentProblemResponse.comment}</Text>
              </View>
            )}
            {/* {review && (
              <ProblemIssueForm
                problemId={currentProblem.id}
                studentId={this.props.studentId}
              />
            )} */}
            {(review || showSolution) && solutionImageUrl && (
              <Image style={solutionImgStyle} source={solutionImageUrl} />
            )}
            {(review || showSolution) &&
              solutionVideo !== null &&
              solutionVideo !== undefined &&
              solutionVideo !== '' && (
                <VideoPlayerForm videoId={solutionVideo} />
              )}
          </View>
          <MessageModal
            visible={modalIsOpen}
            transparent={true}
            onClose={this.closeModal}
            message={modalMsg}
          />
          <DialogModal
            visible={redirectDlgIsOpen}
            message={redirectDlgMsg}
            yesText={'Accept'}
            noText={'Continue'}
            onYes={() => {
              this.handleAssignmentSubmit()
              this.closeReDlgModal()
            }}
            onNo={() => {
              this.closeReDlgModal()
            }}
          />
          <VideoModal
            visible={this.state.videoModalId !== null}
            onClose={() => {
              sessionStorage.removeItem('videoId')
              this.setState({ videoModalId: null })
            }}
            videoId={this.state.videoModalId}
          />
          <ResultsModal
            visible={resultsIsOpen}
            closeModal={this.closeResultsModal}
            problemStates={this.state.problemStates}
            assignmentId={assignmentId}
            problemSetLength={
              problems
                .filter(p => p.problemType !== 'DISPLAY')
                .filter(p => p.problemType !== 'EMBED').length
            }
          />
          <Prompt
            message={(location, action) => {
              if (review || complete || instructor || !changed) {
                return true
              }
              if (
                this.state.problemStates.every(
                  state => state !== problemStates.unanswered
                )
              ) {
                this.openReDlgModal(
                  'You have answered all of the problem, but have not submitted your assignment yet. Do you want to accept these answers or continue to work on the problem set??'
                )
                return false
              }
              if (
                timeLimit > 0 &&
                !this.state.problemStates.every(
                  state => state === problemStates.unanswered
                )
              ) {
                if (autoGrading) {
                  const currGrade =
                    this.state.problemStates.filter(
                      state => state === problemStates.correct
                    ).length / this.state.problemStates.length
                  this.openReDlgModal(
                    `You have not finished solving all the problems. Your current grade for this problem set is ${Math.round(
                      currGrade * 100
                    )}%. Do you want to accept this grade or continue to work on the problem set?`
                  )
                  return false
                }
                const currAnswered = this.state.problemStates.filter(
                  state => state !== problemStates.unanswered
                ).length
                this.openReDlgModal(
                  `You have not finished solving all the problems. You answered ${currAnswered}/${this.state.problemStates.length} questions. Do you want to accept these answers or continue to work on the problem set??`
                )
                return false
              }
              return 'Are you sure you want to leave? Your assignment is not complete.'
            }}
          />
        </ScrollView>
        <RequestHelpForm
          visible={requestHelpVisible}
          problemId={problems[currentProbNum].id}
          problemHints={problems[currentProbNum].hints}
          assignmentId={assignmentId}
          client={client}
          close={this.closeRequestHelpForm}
          openComfirmation={() => {
            this.openModal('Your request has been sent!')
          }}
          openWarning={() => {
            this.openModal(
              'Sorry! you already created an issue of this type for this problem!'
            )
          }}
        />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    // justifyContent: 'space-between',
  },
  problems: {
    alignItems: 'center',
    marginTop: 20,
    justifyContent: 'center',
    flex: 1,
  },
  container: {
    backgroundColor: 'white',
    marginLeft: 5,
    marginTop: 5,
    borderTopLeftRadius: 10,
    flex: 1,
  },
  instructorCommentBox: {
    alignItems: 'center',
    marginTop: 20,
    justifyContent: 'center',
    flex: 1,
  },
})

export default ProblemSetForm
