import gql from 'graphql-tag'

export const GET_POSTS = gql`
  query getPosts($filter: Filters) {
    posts(filter: $filter, sort: { publishDate: -1 }) {
      id
      title
      description
      imageUrl
      postType
      favoriteCount
      viewCount
      published
      publishDate
      expirationDate
    }
  }
`

export const GET_POST_CONTENT = gql`
  query getPostContent($id: ID!) {
    post(id: $id) {
      id
      title
      description
      imageUrl
      postType
      favoriteCount
      viewCount
      content
      video
      classroomCodes
    }
  }
`

export const GET_POST_CLASSROOMS = gql`
  query getPostClassroom($filter: Filters) {
    classrooms(filter: $filter, sort: { code: 1 }) {
      id
      title
      code
      status
      year
      quarter
      startDate
      endDate
      startTime
      endTime
      meets
      videoId
      tuitionCents
      earlyBirdtuitionCents
      enrollmentMax
      enrollments {
        id
      }
      course {
        id
        title
        imageUrl
        description
        startGrade
        endGrade
      }
    }
  }
`

export const GET_STUDENTS = gql`
  query getFamilyStudents($id: ID!) {
    familyAccount(id: $id) {
      id
      students {
        id
        firstName
      }
    }
  }
`

export const CHECK_ENROLLMENTS = gql`
  query checkEnrollment($filter: Filters) {
    enrollments(filter: $filter) {
      classroom {
        id
      }
      student {
        id
      }
    }
  }
`

export const UPDATE_POST = gql`
  mutation updatePost($id: ID!, $input: UpdatePostInput!) {
    updatePost(id: $id, input: $input) {
      id
    }
  }
`

export const CREATE_POST_VIEW = gql`
  mutation createPostView($input: CreatePostViewInput!) {
    createPostView(input: $input) {
      id
    }
  }
`

export const CREATE_POST_FAVORITE = gql`
  mutation createPostFavorite($input: CreatePostFavoriteInput!) {
    createPostFavorite(input: $input) {
      id
      favorited
    }
  }
`

export const GET_POST_FAVORITE = gql`
  query getPostFavorite($filter: Filters!) {
    postFavorites(filter: $filter) {
      id
      favorited
    }
  }
`

export const UPDATE_POST_FAVORITE = gql`
  mutation updatePostFavorite($id: ID!, $input: UpdatePostFavoriteInput!) {
    updatePostFavorite(id: $id, input: $input) {
      id
    }
  }
`

export const ENROLL_STUDENT = gql`
  mutation EnrollStudent($input: CreateEnrollmentInput!) {
    createEnrollment(input: $input) {
      id
    }
  }
`
