import gql from 'graphql-tag'

export const GET_ACCOUNT_INFO = gql`
  query AccountInfo($id: ID!) {
    student(id: $id) {
      id
      firstName
      lastName
      imageUrl
      newImageUrl
      school
      grade
      username
      birthdate
      gradeUpdated
      familyAccount {
        id
        location {
          id
          street
          city
          state
          country
          zip
        }
      }
    }
  }
`

export const GET_GUEST_ACCOUNT_INFO = gql`
  query GuestAccountInfo(
    $id: ID!
    $filter: Filters
    $getStudent: Boolean!
    $getFamilyMember: Boolean!
  ) {
    student(id: $id) @include(if: $getStudent) {
      id
      firstName
      lastName
      imageUrl
      birthdate
      opportunities(filter: $filter) {
        id
        stage
        status
        trials {
          id
          toLesson {
            id
            classroom {
              id
            }
          }
        }
      }
    }
    familyMember(id: $id) @include(if: $getFamilyMember) {
      id
      firstName
      lastName
      imageUrl
      familyAccount {
        id
      }
    }
  }
`

export const UPDATE_ACCOUNT_INFO = gql`
  mutation($input: UpdateStudentInput!, $id: ID!) {
    updateStudent(id: $id, input: $input) {
      school
      grade
      birthdate
    }
  }
`

export const UPDATE_FAMILY_ACCOUNT_INFO = gql`
  mutation($input: UpdateStudentInput!, $id: ID!) {
    updateStudent(id: $id, input: $input) {
      school
      grade
      birthdate
    }
  }
`

export const UPDATE_LOCATION_INFO = gql`
  mutation($input: UpdateLocationInput!, $id: ID!) {
    updateLocation(id: $id, input: $input) {
      street
      city
      state
      country
      zip
    }
  }
`

export const UPDATE_FAMILY_ACCOUNT = gql`
  mutation($input: UpdateFamilyAccountInput!, $id: ID!) {
    updateFamilyAccount(id: $id, input: $input) {
      id
    }
  }
`

export const CREATE_LOCATION_INFO = gql`
  mutation createLocation($input: CreateLocationInput!) {
    createLocation(input: $input) {
      id
    }
  }
`

export const UPDATE_INSTRUCTOR_INFO = gql`
  mutation($input: UpdateEmployeeInput!, $id: ID!) {
    updateEmployee(id: $id, input: $input) {
      firstName
      lastName
      gender
      company
      workEmail
      personalEmail
      mobileNumber
      birthdate
    }
  }
`

export const GET_INSTRUCTOR_ACC_INFO = gql`
  query getEmployee($id: ID!) {
    employee(id: $id) {
      firstName
      lastName
      gender
      birthdate
      imageUrl
      isOfficeUser
      workEmail
      personalEmail
      mobileNumber
      position
      company
    }
  }
`

export const GET_FAMILY_ACC_INFO = gql`
  query getFamily($id: ID!) {
    familyMember(id: $id) {
      id
      imageUrl
      firstName
      lastName
      familyAccount {
        id
        location {
          street
          city
          state
          zip
          country
        }
      }
    }
  }
`
