import {
  Text,
  View,
  FlatList,
  TouchableOpacity,
  StyleSheet,
} from 'react-native-web'
import React, { useReducer } from 'react'
import ProgressForm from './ProgressForm'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import solveColors from './colors'
import { numToLetterGrade, getGradeColor } from '../../utils/grades'
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

const GradebookForm = props => {
  const { lessonGrades, lessons } = props

  const [selected, setSelected] = useReducer((selected, { index, value }) => {
    selected[index] = value
    return [...selected]
  }, new Array(lessons.length).fill(false))
  return (
    <FlatList
      data={lessons}
      extraData={selected}
      keyExtractor={item => item.lessonPlan.order.toString()}
      renderItem={({ item, index }) => {
        const lessonGrade = lessonGrades.find(l => l.lessonId === item.id)
        return (
          item.lessonPlan.order !== 0 && (
            <TouchableOpacity
              onPress={() => {
                setSelected({ index: index, value: !selected[index] })
              }}
              style={selected[index] ? { marginVertical: 10 } : {}}
            >
              <View
                style={[
                  styles.progress_row,
                  selected[index]
                    ? {
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }
                    : {},
                ]}
              >
                <Text
                  style={{
                    marginLeft: '1%',
                    fontSize: 16,
                    fontWeight: 'bold',
                    width: '50%',
                  }}
                >
                  {item.lessonPlan.order}. {item.lessonPlan.title}
                </Text>
                <div
                  style={{
                    display: 'flex',
                    width: '50%',
                    justifyContent: 'flex-end',
                  }}
                >
                  {lessonGrade && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexBasis: '40%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <div style={{ width: '50px' }}>
                          <CircularProgressbar
                            value={lessonGrade.percentCompleted}
                            text={`${lessonGrade.percentCompleted}%`}
                            styles={{
                              text: {
                                // Text size
                                fontSize: 30,
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          flex: 1,
                          minWidth: '55px',
                        }}
                      >
                        <Text
                          style={{
                            backgroundColor: getGradeColor(
                              lessonGrade.overallGrade
                            ),
                            paddingVertical: 2,
                            paddingHorizontal: 8,
                            borderRadius: 20,
                            marginLeft: 5,
                          }}
                        >{`${
                          lessonGrade.overallGrade
                            ? lessonGrade.overallGrade +
                              '%' +
                              ' ' +
                              numToLetterGrade(lessonGrade.overallGrade)
                            : 'N/A'
                        }`}</Text>
                      </div>
                    </div>
                  )}

                  {!selected[index] && (
                    <View>
                      <KeyboardArrowDownIcon />
                    </View>
                  )}
                  {selected[index] && (
                    <View>
                      <ExpandLessIcon />
                    </View>
                  )}
                </div>
              </View>

              {selected[index] && (
                <ProgressForm lessonId={item.id} studentId={props.studentId} />
              )}
            </TouchableOpacity>
          )
        )
      }}
    />
  )
}

const styles = StyleSheet.create({
  progress_row: {
    backgroundColor: 'white',
    flexDirection: 'row',
    minHeight: 50,
    width: '100%',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: solveColors.grey1,
    paddingTop: '8px',
    paddingBottom: '8px',
  },
})

export default GradebookForm
