import React from 'react'
import { View, Text, Image, TouchableOpacity } from 'react-native-web'
import styles from './styles'
import practiceIcon from './../../images/practice.svg'
import { InfoRow } from './../../components/'
import { withRouter } from 'react-router-dom'

const WebinarCard = props => {
  const {
    studentId,
    lessonId,
    webinarReplay,
    webinarUrl,
    primaryOfficeHourOn,
    makeupOfficeHourOn,
    officeHourDuration,
    officeHourMeetingId,
    webinarId,
    lessonStartTime,
    lessonEndTime,
    learningObjective,
    history,
  } = props
  const officeHourStart = new Date(primaryOfficeHourOn)
  const officeHourEnd = new Date(
    Date.parse(primaryOfficeHourOn) + officeHourDuration * 60 * 1000
  )
  const makeupOfficeHourStart = new Date(makeupOfficeHourOn)
  const makeupOfficeHourEnd = new Date(
    Date.parse(makeupOfficeHourOn) + officeHourDuration * 60 * 1000
  )

  const now = new Date().toISOString()
  console.log('WebinarCard: ', lessonStartTime, lessonEndTime, now)

  const timeOptions = { hour: 'numeric', minute: '2-digit' }
  return (
    <View style={styles.container}>
      <Image source={practiceIcon} style={styles.type_icon} />
      <Text>Webinar Schedule</Text>

      <React.Fragment>
        {/* <Text style={[styles.problem_title, styles.text]}>
          Webinar Schedule
        </Text> */}
        {learningObjective && learningObjective.includes('//') && (
          <React.Fragment>
            <InfoRow
              style={styles.info_row}
              text1={learningObjective.split('//')[0]}
              text2={``}
            />
            <InfoRow
              style={styles.info_row}
              text1={learningObjective.split('//')[1].replace('\n', '')}
              text2={``}
            />
          </React.Fragment>
        )}
        {learningObjective && !learningObjective.includes('//') && (
          <React.Fragment>
            <InfoRow
              style={styles.info_row}
              text1={learningObjective}
              text2={``}
            />
          </React.Fragment>
        )}
        {primaryOfficeHourOn && officeHourDuration && (
          <React.Fragment>
            <InfoRow
              style={styles.info_row}
              text1={'Office Hour: '}
              text2={`${officeHourStart.getMonth() +
                1}/${officeHourStart.getDate()}, ${officeHourStart.toLocaleTimeString(
                'en-US',
                timeOptions
              )} - ${officeHourEnd.toLocaleTimeString('en-US', timeOptions)}`}
            />
          </React.Fragment>
        )}
        {makeupOfficeHourOn && officeHourDuration && (
          <React.Fragment>
            <InfoRow
              style={styles.info_row}
              text1={'Office Hour: '}
              text2={`${makeupOfficeHourStart.getMonth() +
                1}/${makeupOfficeHourStart.getDate()}, ${makeupOfficeHourStart.toLocaleTimeString(
                'en-US',
                timeOptions
              )} - ${makeupOfficeHourEnd.toLocaleTimeString(
                'en-US',
                timeOptions
              )}`}
            />
          </React.Fragment>
        )}
        {lessonStartTime && lessonEndTime && (
          <React.Fragment>
            <InfoRow
              style={styles.info_row}
              text1={'Lecture: '}
              text2={`${new Date(lessonStartTime).getMonth() + 1}/${new Date(
                lessonStartTime
              ).getDate()}, ${new Date(lessonStartTime).toLocaleTimeString(
                'en-US',
                timeOptions
              )} - ${new Date(lessonEndTime).toLocaleTimeString(
                'en-US',
                timeOptions
              )}`}
            />
          </React.Fragment>
        )}
      </React.Fragment>

      <View
        style={{
          marginTop: 15,
          flex: 1,
          flexDirection: 'row',
          alignItems: 'flex-end',
          flexWrap: 'wrap',
        }}
      >
        {(webinarId || webinarUrl) && webinarReplay === null && (
          <TouchableOpacity
            onPress={() => {
              console.log('IDs', studentId, lessonId)
              const minutesBeforeClassStart = 10
              const minutesAfterClassStart = 15
              const now = new Date().getTime()
              if (
                new Date(lessonStartTime).getTime() -
                  minutesBeforeClassStart * 60000 <
                  now &&
                now <
                  new Date(lessonStartTime).getTime() +
                    minutesAfterClassStart * 60000
              ) {
                console.log('Mark Attendance Now')
              } else {
                console.log('Do not Mark Attendance')
              }
              window.open(
                webinarId ? `https://zoom.us/j/${webinarId}` : webinarUrl
              )
            }}
          >
            <Text style={styles.start_button}>Live</Text>
          </TouchableOpacity>
        )}
        {webinarReplay && (
          <TouchableOpacity
            onPress={() => {
              if (isNaN(webinarReplay)) {
                window.open(webinarReplay)
                return
              }
              history.push({
                pathname: '/video',
                search: `videoId=${webinarReplay}`,
              })
            }}
          >
            <Text style={styles.learn_button}>Replay</Text>
          </TouchableOpacity>
        )}
        {officeHourMeetingId && (
          <TouchableOpacity
            onPress={() => {
              window.open(`https://zoom.us/j/${officeHourMeetingId}`)
            }}
          >
            <Text style={styles.start_button}>Office Hours</Text>
          </TouchableOpacity>
        )}
      </View>
    </View>
  )
}

export default withRouter(WebinarCard)
