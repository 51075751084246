import React, { Component } from 'react'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native-web'
import ProblemSubmitButton from './ProblemSubmitButton'
import AssignmentSubmitButton from './AssignmentSubmitButton'
import SkipButton from './SkipButton'
import AttachmentButton from './AttachmentButton'
import ScratchpadButton from './ScratchpadButton'
import solveColors from '../../../components/colors'
import { DialogModal, Latex } from '../../../components/index'
import SolutionButton from './SolutionButton'
import draftToHtml from 'draftjs-to-html'

class FreeformProblemForm extends Component {
  state = {
    studentAnswer: this.props.currentAnswer,
    dlgModalOpen: false,
    attachment: null,
    partial: true,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.currentAnswer !== prevState.studentAnswer) {
      return { studentAnswer: nextProps.currentAnswer }
    }
    return null
  }
  setAttachment = file => {
    this.setState({ attachment: file })
  }
  openDialogModal() {
    this.setState({ dlgModalOpen: true })
  }
  closeDialogModal() {
    this.setState({ dlgModalOpen: false })
  }

  handleProblemSubmit = async (fromSkip = false) => {
    const {
      onProblemSubmit,
      problem,
      review,
      lastProb,
      gotoLessons,
    } = this.props
    const { answer } = problem
    let { studentAnswer } = this.state
    if (review && !lastProb) {
      onProblemSubmit(studentAnswer, answer)
      return
    } else if (review && lastProb) {
      gotoLessons()
      return
    }
    onProblemSubmit(studentAnswer, answer, fromSkip)
  }

  handleAssignmentSubmit = () => {
    const { answered } = this.props
    if (answered) {
      this.setState({ partial: false })
    }
    this.openDialogModal()
  }
  render() {
    const {
      probState,
      complete,
      allowSkip,
      incrementProblem,
      problem,
      feedbackType,
      review,
      lastProb,
      updateProblemAttachments,
      attachment,
      updateProblemAnswer,
      onAssignmentSubmit,
      deleteProblemAttachment,
      scratchpadUrl,
      currentProbNum,
      problemStates,
      instructor,
      showSolution,
      toggleShowSolution,
      problemSetLength,
    } = this.props

    const { questionText, answer, solutionText, typesetMath } = problem
    const hasSolution = answer !== undefined && answer !== null
    let htmlDisplay = ''
    if (!typesetMath) {
      try {
        htmlDisplay = draftToHtml(JSON.parse(questionText))
      } catch (e) {
        console.error(e)
      }
    }
    return (
      <View style={styles.problem}>
        <View style={styles.question}>
          <View style={styles.questionText}>
            {typesetMath && <Latex latex={questionText} />}
            {!typesetMath && htmlDisplay && (
              <View style={styles.displayOuter}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: htmlDisplay,
                  }}
                />
              </View>
            )}
            {!typesetMath && !htmlDisplay && (
              <Text style={{ marginVertical: 10 }}>{htmlDisplay}</Text>
            )}
          </View>
          <View>
            <Text style={styles.instructions}>
              Answer with text and/or attachments:
            </Text>
            <textarea
              autoFocus
              style={{
                minHeight: 100,
                borderRadius: 5,
                borderColor: solveColors.grey3,
                alignSelf: 'center',
                minWidth: 450,
              }}
              value={this.state.studentAnswer}
              onChange={event => {
                this.setState({ studentAnswer: event.target.value })
                updateProblemAnswer(event.target.value)
              }}
            />
          </View>
          <AttachmentButton
            review={review}
            complete={complete}
            setAttachment={this.setAttachment}
            updateProblemAttachments={updateProblemAttachments}
            attachment={attachment}
            deleteProblemAttachment={deleteProblemAttachment}
          />

          <DialogModal
            visible={this.state.dlgModalOpen}
            transparent={true}
            onYes={async () => {
              if (this.state.studentAnswer) await this.handleProblemSubmit()
              onAssignmentSubmit()
              this.closeDialogModal()
            }}
            onNo={() => {
              this.closeDialogModal()
            }}
            message={
              this.state.partial
                ? `You have answered ${
                    problemStates.filter(p => p !== 0).length
                  }/${problemSetLength} problems. Are you sure you want to submit the assignment for grading?`
                : 'Once submitted, you cannot change your answers. Are you sure you want to submit?'
            }
          />
        </View>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          {scratchpadUrl && (
            <TouchableOpacity
              style={{ marginRight: '0.5vh' }}
              onPress={() => this.props.deleteScratchpadImg(currentProbNum)}
            >
              <Text>{'X'}</Text>
            </TouchableOpacity>
          )}
          <Text>{scratchpadUrl}</Text>
        </View>

        <View style={styles.buttonBar}>
          <ScratchpadButton toggleScratchpad={this.props.toggleScratchpad} />
          <ProblemSubmitButton
            probState={probState}
            handleProblemSubmit={this.handleProblemSubmit}
            complete={complete}
            feedbackType={feedbackType}
            review={review}
            lastProb={lastProb}
          />
          {allowSkip && !instructor && !review && (
            <SkipButton
              probState={probState}
              allowSkip={allowSkip}
              lastProb={lastProb}
              handleProblemSubmit={this.handleProblemSubmit}
              incrementProblem={incrementProblem}
            />
          )}
          {!review && !instructor && (
            <AssignmentSubmitButton
              handleAssignmentSubmit={this.handleAssignmentSubmit}
              locked={problemStates.filter(s => s !== 0).length === 0}
            />
          )}
          {!review && instructor && (
            <SolutionButton
              showSolution={showSolution}
              toggleShowSolution={toggleShowSolution}
            />
          )}
        </View>
        {(review || showSolution) && (
          <View style={styles.review}>
            <Text>Correct Answer:</Text>
            {typesetMath && hasSolution && <Latex latex={answer} />}
            {!typesetMath && hasSolution && <Text>{answer}</Text>}
            {!hasSolution && (
              <Text>Solution is not available for this question.</Text>
            )}
            {solutionText !== null &&
              solutionText !== undefined &&
              solutionText !== '' && (
                <View style={{ alignItems: 'center' }}>
                  <Text>{'\n'}Solution:</Text>
                  {typesetMath && <Latex latex={solutionText} />}
                  {!typesetMath && <Text>{solutionText}</Text>}
                </View>
              )}
          </View>
        )}
      </View>
    )
  }
}

const styles = StyleSheet.create({
  instructions: {
    padding: 10,
    alignSelf: 'center',
  },
  question: {
    alignItems: 'center',
    userSelect: 'none',
    maxWidth: '40vw',
  },
  image: {
    height: 200,
    width: 700,
    resizeMode: 'contain',
  },
  review: {
    alignItems: 'center',
  },
  textarea: {
    minHeight: 100,
    borderRadius: 5,
    borderColor: solveColors.grey3,
    alignSelf: 'center',
    minWidth: 300,
  },
  buttonBar: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  problem: {
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '60vw',
  },
  repl: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 'calc(100vw - 300px)',
    height: 'calc(100vh - 250px)',
  },
  questionText: {
    fontSize:
      sessionStorage.getItem('size') === 'large'
        ? 24
        : sessionStorage.getItem('size') === 'med'
        ? 20
        : 14,
  },
  displayOuter: {
    backgroundColor: 'white',
    marginBottom: 20,
    alignSelf: 'center',
    minWidth: '512px',
    maxWidth: '864px',
  },
})

export default FreeformProblemForm
