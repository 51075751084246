import React, { useState } from 'react'
import { Text, View } from 'react-native'
import { Query, ApolloConsumer } from 'react-apollo'
import {
  GET_ASSIGNMENT,
  GET_SUBMISSION_ASSIGNMENT,
  GET_SUBMISSION_RESPONSES,
} from '../../../services/queries'
import ProblemSetForm from './ProblemSetForm'
import LoadingPage from '../Loading/LoadingPage'
import { withRouter } from 'react-router-dom'
import { encodeChoices } from './ProblemForms/MultipleChoiceProblemForm'
import { checkAnswer } from './../../../utils/answers'
import { useCookies } from 'react-cookie'
import jwt from 'jsonwebtoken'

const ProblemSetPage = props => {
  const [cookies] = useCookies([])
  const [fullScreen, setFullScreen] = useState(
    sessionStorage.getItem('fullScreen') !== '0' &&
      sessionStorage.getItem('fullScreen') !== null
  )
  const employee = cookies['instructor'] === '1' || cookies['admin'] === '1'
  const studentId = cookies['token'] ? jwt.decode(cookies['token']).id : ''
  // if (!fullScreen) {
  //   props.displayBars()
  // } else {
  //   props.hideBars()
  // }
  props.changeTitles(sessionStorage.getItem('classroomTitle'))
  const assignmentId = props.location.search.split('=')[1]
  const checkRequirements = async client => {
    if (!cookies['token']) {
      props.history.push({
        pathname: '/',
      })
      return
    }
    if (!sessionStorage.getItem('classroomId')) {
      props.history.push({
        pathname: '/dashboard',
      })
      return
    }

    if (employee) {
      return
    }

    if (!sessionStorage.getItem('submissionId')) {
      gotoLessons()
      return
    }

    const { data } = await client.query({
      query: GET_SUBMISSION_ASSIGNMENT,
      variables: {
        submissionId: sessionStorage.getItem('submissionId'),
      },
    })

    const assignmentId = data.submission.assignment.id
    if (assignmentId !== props.location.search.split('=')[1]) {
      gotoLessons()
      return
    }
  }
  const gotoLessons = () => {
    const classroomId = sessionStorage.getItem('classroomId')
    props.history.push({
      pathname: '/lessons',
      search: `classroomId=${classroomId}`,
    })
  }

  return (
    <ApolloConsumer>
      {client => {
        checkRequirements(client)

        return (
          <Query query={GET_ASSIGNMENT} variables={{ id: assignmentId }}>
            {({ data, loading, error }) => {
              if (error) return `${error}`
              if (loading) return <LoadingPage />
              let {
                allowedAttempts,
                timeLimit,
                allowSkip,
                problems,
                title,
                lessonPlan,
                level,
                feedbackType,
                randomizeOrder,
                kind,
                order,
                id: problemSetId,
              } = data.assignment.problemSet
              if (lessonPlan.lessonPlanType === 'INTRODUCTION') {
                timeLimit = 0
              }
              let autoGrading = problems.some(problem => {
                return !(
                  problem.problemType === 'OFFLINE' ||
                  problem.problemType === 'FREE_FORM'
                )
              })
              const skipsAllowed =
                allowSkip &&
                sessionStorage.getItem('teachingStyle') !== 'SELF_PACED'
              if (problems.length === 0) {
                return (
                  <View>
                    <Text style={{ alignSelf: 'center', marginTop: '30vh' }}>
                      This Problem Set is under development. Comeback later.
                    </Text>
                  </View>
                )
              }
              const review = sessionStorage.getItem('review') === '1'
              problems.forEach(p => {
                if (p.randomizeChoices) {
                  p.choices.sort(() => Math.random() - 0.5)
                }
                if (p.problemType === 'MATCHING') {
                  let choices1 = new Array(p.choices.length)
                  let choices2 = new Array(p.choices.length)

                  for (let i = 0; i < p.choices.length; i++) {
                    const temp = p.choices[i].text.split(';')
                    choices1[i] = { text: temp[0], order: p.choices[i].order }
                    choices2[i] = { text: temp[1], order: p.choices[i].order }
                  }
                  choices1.sort(() => Math.random() - 0.5)
                  choices2.sort(() => Math.random() - 0.5)

                  p.choices1 = choices1
                  p.choices2 = choices2
                }
              })
              return (
                <Query
                  query={GET_SUBMISSION_RESPONSES}
                  context={{ headers: { 'cache-control': 'no-cache' } }}
                  variables={{
                    submissionId: sessionStorage.getItem('submissionId'),
                  }}
                  skip={employee}
                >
                  {({ data, loading, error }) => {
                    if (error) return `${error}`
                    if (loading) return <LoadingPage />
                    //const graded = data ? data.submission.graded : true
                    let problemStates = new Array(problems.length).fill(0)
                    let problemAnswers = new Array(problems.length).fill('')
                    let problemAttachments = new Array(problems.length).fill(
                      null
                    )
                    let problemResponses = new Array(problems.length).fill({
                      id: null,
                      value: '',
                    })
                    let problemScratchpads = new Array(problems.length).fill(
                      null
                    )

                    let totalTimeSpent = 0
                    if (!employee) {
                      const { responses } = data.submission
                      for (let i = 0; i < responses.length; i++) {
                        const response = responses[i]
                        const orderInd = response.problem.order - 1
                        problemResponses[orderInd] = {
                          id: response.id,
                          value: response.value,
                          attachment: response.attachment,
                          scratchpad: response.scratchpad,
                          sum: response.sum,
                          count: response.count,
                          graded: response.graded,
                          comment: response.comment,
                        }
                        problemAttachments[orderInd] = response.attachment
                          ? {
                              name: response.attachment
                                .split('_')
                                .slice(1)
                                .join('_'),
                              fileKey: response.attachment,
                            }
                          : null
                        problemAnswers[orderInd] = response.value
                        problemScratchpads[orderInd] = response.scratchpadUrl
                          ? response.scratchpadUrl
                          : null
                        totalTimeSpent += response.timeSpent
                        if (
                          problems[orderInd].problemType === 'ONE_HOTSPOT' ||
                          problems[orderInd].problemType === 'MANY_HOTSPOTS'
                        ) {
                          let corrAnswer = ''
                          for (
                            let i = 0;
                            i < problems[orderInd].choices.length;
                            i++
                          ) {
                            corrAnswer += String.fromCharCode((i % 26) + 65)
                          }
                          const studentAnswer = response.value
                          const correct = checkAnswer(
                            studentAnswer,
                            corrAnswer,
                            false,
                            problems[orderInd].problemType,
                            problems[orderInd].approximate
                          )
                          if (correct) problemStates[orderInd] = 1
                          else problemStates[orderInd] = 2
                        }
                        if (problems[orderInd].problemType === 'MATCHING') {
                          const studentAnswer = response.value
                          const correct = checkAnswer(
                            studentAnswer,
                            '',
                            false,
                            problems[orderInd].problemType,
                            problems[orderInd].approximate
                          )
                          if (correct) problemStates[orderInd] = 1
                          else problemStates[orderInd] = 2
                        }
                        if (
                          problems[orderInd].problemType ===
                            'MULTIPLE_CHOICE_ONE_ANSWER' ||
                          problems[orderInd].problemType ===
                            'MULTIPLE_CHOICE_MANY_ANSWERS' ||
                          problems[orderInd].problemType === 'TRUE_FALSE'
                        ) {
                          const corrAnswer = encodeChoices(
                            problems[orderInd].choices.map(x => ({
                              selected: x.correct,
                              order: x.order,
                            }))
                          )
                          const studentAnswer = response.value
                          const correct = checkAnswer(
                            studentAnswer,
                            corrAnswer,
                            false,
                            problems[orderInd].problemType,
                            problems[orderInd].approximate
                          )
                          if (correct) problemStates[orderInd] = 1
                          else problemStates[orderInd] = 2
                        }
                        if (
                          problems[orderInd].problemType ===
                            'SHORT_TEXT_RESPONSE' ||
                          problems[orderInd].problemType ===
                            'SHORT_NUMBER_RESPONSE'
                        ) {
                          const {
                            caseSensitive,
                            problemType,
                            answer,
                            approximate,
                          } = problems[orderInd]
                          const studentAnswer = response.value

                          const corrAnswers = answer
                            ? answer.indexOf('&') === -1
                              ? answer
                              : answer.split('&')
                            : []
                          const correct = checkAnswer(
                            studentAnswer,
                            corrAnswers,
                            caseSensitive,
                            problemType,
                            approximate
                          )
                          if (correct) problemStates[orderInd] = 1
                          else problemStates[orderInd] = 2
                        }
                        if (
                          problems[orderInd].problemType === 'SCIENTIFIC_UNIT'
                        ) {
                          const { problemType, answer, approximate } = problems[
                            orderInd
                          ]
                          const studentAnswer = response.value

                          const correct = checkAnswer(
                            studentAnswer,
                            answer,
                            false,
                            problemType,
                            approximate
                          )
                          if (correct) problemStates[orderInd] = 1
                          else problemStates[orderInd] = 2
                        }
                        if (
                          problems[orderInd].problemType === 'FREE_FORM' ||
                          problems[orderInd].problemType === 'OFFLINE' ||
                          problems[orderInd].problemType === 'EMBED'
                        ) {
                          const { count, sum, graded } = response
                          if (graded) {
                            if (sum === count) problemStates[orderInd] = 5
                            else if (sum === 0) problemStates[orderInd] = 6
                            else problemStates[orderInd] = 3
                          } else problemStates[orderInd] = 4
                        }
                      }
                    }
                    return (
                      <ProblemSetForm
                        instructor={employee}
                        kind={kind}
                        problems={problems}
                        randomizeOrder={randomizeOrder}
                        allowedAttempts={allowedAttempts}
                        timeLimit={timeLimit}
                        allowSkip={skipsAllowed}
                        randomize={randomizeOrder}
                        title={title}
                        lessonPlan={lessonPlan}
                        length={problems.length}
                        problemStates={problemStates}
                        problemAnswers={problemAnswers}
                        problemAttachments={problemAttachments}
                        problemResponses={problemResponses}
                        problemScratchpads={problemScratchpads}
                        level={level ? level : 'None'}
                        feedbackType={feedbackType}
                        client={client}
                        assignmentId={assignmentId}
                        gotoLessons={gotoLessons}
                        submissionId={sessionStorage.getItem('submissionId')}
                        review={review}
                        totalTimeSpent={totalTimeSpent}
                        autoGrading={autoGrading}
                        setFullScreen={setFullScreen}
                        fullScreen={fullScreen}
                        problemSetId={problemSetId}
                        problemSetOrder={order}
                        toggleMenu={props.toggleMenu}
                        studentId={studentId}
                      />
                    )
                  }}
                </Query>
              )
            }}
          </Query>
        )
      }}
    </ApolloConsumer>
  )
}
export default withRouter(ProblemSetPage)
