import gql from 'graphql-tag'

export const GET_LEADERBOARD = gql`
  query GetLeaderboard(
    $classroomId: String!
    $lessonId: String
    $studentId: String
  ) {
    leaderboard(
      classroomId: $classroomId
      lessonId: $lessonId
      studentId: $studentId
    ) {
      students {
        totalPoints
        studentName
        profilePicture
        ranking
      }
      onLeaderboard
    }
  }
`
