import React from 'react'
import { Query } from 'react-apollo'
import { GET_INVOICE } from '../../../services/queries/dashboard_queries'
import CartForm from '../ShoppingCart/CartForm'

function PopupInvoiceDetails({ invoiceId, setInvoiceId, history }) {
  React.useMemo(
    () => [
      {
        Header: '#',
        Cell: ({ row }) => row.index + 1,
        width: 30,
      },
      {
        id: 'student',
        Header: 'Student',
        accessor: row =>
          row.student
            ? row.student.firstName + ' ' + row.student.lastName
            : 'Account',
      },
      {
        id: 'item',
        Header: 'Item',
        accessor: row => row.description,
      },
      {
        Header: 'Early Bird',
        accessor: 'earlyBird',
        Cell: ({ row }) => (row.value === null ? 'N/A' : row.value ? 'Y' : 'N'),
        width: 100,
        style: {
          textAlign: 'center',
        },
      },
      {
        id: 'unitPrice',
        Header: 'Unit Price',
        accessor: row => row.unitPrice.toFixed(2),
        width: 100,
        style: {
          textAlign: 'right',
        },
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
        width: 100,
        style: {
          textAlign: 'center',
        },
      },
      {
        id: 'price',
        Header: 'Price',
        Cell: ({ row }) => <span>{`${row.original.price.toFixed(2)}`}</span>,
        width: 100,
        style: {
          textAlign: 'right',
        },
      },
      {
        Header: 'Enrolled',
        accessor: 'enrolled',
        width: 100,
        Cell: ({ row }) => {
          if (row.original.itemType === 'CLASSROOM') {
            return (
              <span style={{ color: row.value ? 'green' : 'red' }}>
                {row.value ? 'Yes' : 'No'}
              </span>
            )
          } else {
            return <span>{'n/a'}</span>
          }
        },
        style: {
          textAlign: 'center',
        },
      },
    ],
    []
  )

  if (!invoiceId) {
    return null
  }

  return (
    <Query query={GET_INVOICE} variables={{ id: invoiceId }}>
      {({ data, loading, error }) => {
        if (loading) return null
        if (error) return error
        const { invoice } = data

        return (
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              zIndex: 999,
              backgroundColor: 'white',
            }}
          >
            <CartForm
              invoice={invoice}
              onBack={() => setInvoiceId(null)}
              // archiveItem={this.archiveItem}
              // refetch={refetch}
              openModal={() => {
                if (false) this.props.history.push('/purchase')
                else {
                  this.setState({ openModal: true })
                }
              }}
            />
          </div>
        )
      }}
    </Query>
  )
}
export default PopupInvoiceDetails
