import { ScrollView, Text, FlatList } from 'react-native'
import React, { useState } from 'react'
import { GET_NEWS } from './../../../services/queries'
import { Query } from 'react-apollo'
import LoadingPage from './../Loading/LoadingPage'
import AnnouncementCard from './AnnouncementCard'
import { CreateAnnouncementModal } from './../../components/'
import { TouchableOpacity } from 'react-native-web'
import solveColors from './../../components/colors'
import { useCookies } from 'react-cookie'
const AnnouncementsPage = props => {
  const [cookies] = useCookies([])

  const { classroomId } = props
  const [modalVisible, setModalVisible] = useState(false)
  const [newsId, setNewsId] = useState(null)
  const employee = cookies['instructor'] === '1' || cookies['admin'] === '1'
  return (
    <Query query={GET_NEWS} variables={{ filter: { classroomId } }}>
      {({ data, loading, error, refetch }) => {
        if (loading) return <LoadingPage />
        if (error) return error
        return (
          <ScrollView style={{ paddingTop: 10 }}>
            {employee && (
              <TouchableOpacity
                style={{
                  backgroundColor: solveColors.blue1,
                  padding: 10,
                  borderRadius: 10,
                  margin: 20,
                  marginLeft: '5%',
                  alignSelf: 'flex-start',
                }}
                onPress={() => {
                  setNewsId(null)
                  setModalVisible(true)
                }}
              >
                <Text
                  style={{ color: 'white', fontWeight: 'bold', fontSize: 16 }}
                >
                  Create Announcement +
                </Text>
              </TouchableOpacity>
            )}
            {data.news.length === 0 && (
              <Text style={{ marginLeft: '5%' }}>
                There is no news for this class
              </Text>
            )}
            {data.news.length > 0 && (
              <FlatList
                data={data.news}
                renderItem={({ item }) => {
                  const {
                    announceDateTime,
                    announcer,
                    message,
                    subject,
                    likes,
                    id,
                  } = item
                  return (
                    <AnnouncementCard
                      newsId={id}
                      dateTime={announceDateTime}
                      announcerName={`${announcer.firstName} ${announcer.lastName}`}
                      message={message}
                      subject={subject}
                      likes={likes}
                      openEditModal={() => {
                        setNewsId(id)
                        setModalVisible(true)
                      }}
                      refetch={refetch}
                    />
                  )
                }}
              />
            )}
            <CreateAnnouncementModal
              visible={modalVisible}
              classroomId={classroomId}
              refetch={refetch}
              newsId={newsId}
              close={() => setModalVisible(false)}
            />
          </ScrollView>
        )
      }}
    </Query>
  )
}

export default AnnouncementsPage
