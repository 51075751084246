import React, { Component } from 'react'
import { withCookies } from 'react-cookie'
import { View } from 'react-native-web'
import { Tabs, solveColors } from '../../components'
import RecommendationPage from '../Recommendations/RecommendationPage'
import SchedulePage from '../Schedule/SchedulePage'
import TransactionPage from '../TransactionView/TransactionPage'
import PostsPage from '../Posts/PostsPage'
import FamilyProfilePage from '../Profile/FamilyProfilePage'
import HomeIcon from '@material-ui/icons/Home'
import AnnouncementIcon from '@material-ui/icons/Announcement'
import ScheduleIcon from '@material-ui/icons/Schedule'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import PersonIcon from '@material-ui/icons/Person'

class FamilyDashboardPage extends Component {
  constructor(props) {
    super(props)
    const defaultTab =
      new URLSearchParams(window.location.search).get('defaultTab') || 0
    this.state = {
      tabInfo: [
        {
          label: 'HOME',
          iconLabel: <HomeIcon style={{ color: solveColors.blue1 }} />,
          filter: () => {
            this.setState({ selected: 0 })
          },
        },
        {
          label: 'POST',
          iconLabel: <AnnouncementIcon style={{ color: solveColors.blue1 }} />,
          filter: () => {
            this.setState({ selected: 1 })
          },
        },
        {
          label: 'SCHEDULE',
          iconLabel: <ScheduleIcon style={{ color: solveColors.blue1 }} />,
          filter: () => {
            this.setState({ selected: 2 })
          },
        },
        {
          label: 'FINANCE',
          iconLabel: <AttachMoneyIcon style={{ color: solveColors.blue1 }} />,
          filter: () => {
            this.setState({ selected: 3 })
          },
        },
        {
          label: 'PROFILE',
          iconLabel: <PersonIcon style={{ color: solveColors.blue1 }} />,
          filter: () => {
            this.setState({ selected: 4 })
          },
        },
      ],
      selected: defaultTab,
    }
  }
  render() {
    const { selected, tabInfo } = this.state
    if (this.props.cookies.get('family') !== '1') {
      this.props.history.push('/')
    }
    return (
      <View style={{ height: '100%' }}>
        <Tabs defaultTab={selected} tabInfo={tabInfo} />
        {tabInfo[selected].label === 'HOME' && <RecommendationPage />}
        {tabInfo[selected].label === 'POST' && <PostsPage />}
        {/* {tabInfo[selected].label === 'STUDENT' && <RecommendationPage />} */}
        {tabInfo[selected].label === 'SCHEDULE' && <SchedulePage />}
        {tabInfo[selected].label === 'FINANCE' && <TransactionPage />}
        {tabInfo[selected].label === 'PROFILE' && (
          <FamilyProfilePage changeTitles={this.props.changeTitles} />
        )}
      </View>
    )
  }
}

export default withCookies(FamilyDashboardPage)
