import React, { useRef } from 'react'
import { Card } from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { formatDate } from '../../../utils/dateTime'
import { getGradeColor } from '../../../utils/grades'

export default function ClassCard({ goToDetails, s, e, published }) {
  const imageRef = useRef(null)
  return (
    <Card
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px',
        paddingTop: '16px',
        paddingBottom: '16px',
        alignItems: 'center',
      }}
      onClick={() => goToDetails(e.id, s.id)}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          flexBasis: '80%',
        }}
      >
        <img
          src={s.imageUrl}
          alt={'Grade'}
          onError={() => {
            imageRef.current.src = `https://ui-avatars.com/api/?background=${Math.floor(
              Math.random() * 16777215
            ).toString(16)}&name=${s.firstName}+${s.lastName}`
          }}
          ref={imageRef}
          width={30}
          height={30}
          style={{
            borderRadius: '50%',
            marginRight: '16px',
          }}
        />
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div style={{ marginRight: '16px' }}>
            {
              e.classroom.title.split(':')[
                e.classroom.title.split(':').length > 1 ? 1 : 0
              ]
            }
          </div>
          <div style={{ color: 'grey' }}>{`${formatDate(
            e.classroom.startDate,
            false
          )} - ${formatDate(e.classroom.endDate, false)}`}</div>
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        {!published && (
          <div
            style={{
              color: getGradeColor(e.overallGrade),
              marginRight: '16px',
            }}
          >{`${e.overallGrade}%`}</div>
        )}
        <ChevronRightIcon />
      </div>
    </Card>
  )
}
