export const solveColors = {
  //Text Coloration
  offWhite: 'rgba(255, 255, 255, 0.7)',

  //Gradient
  blueGreen: '#06CCAF',
  turquoise: '#1CAECA',
  blue2: '#25A2D5',
  blue4: '#2D97DF',

  //Buttons
  red1: '#EA4B36',
  green: '#20CE6D',
  green2: '#69e002',
  orange: '#FF9900',
  blue5: '#4A90E2',

  //Pop Up
  navy: '#2B3E51',
  grey4: '#4A4A4A',
  blue3: '#008EED',

  //ProblemSet
  red2: '#FF3B30',
  blue1: '#00AFF0',

  //Background
  grey1: '#E1E1E1',
  grey2: '#DADADA',
  grey3: '#C4C4C4',

  //grades
  yellow: '#FDA50F',
  pink: '#FF69B4',
}

export default solveColors
