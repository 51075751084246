import { StyleSheet, View, Text, TouchableOpacity, Image } from 'react-native'
import React, { Component } from 'react'
import Modal from 'modal-react-native-web'
import solveColors from './colors'

class ImageModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      width: 640,
      height: 480,
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.visible !== props.visible) {
      return { visible: props.visible }
    }
    Image.getSize(props.image, (width, height) => {
      if (width !== state.width || height !== state.height) {
        return {
          width,
          height,
          resizeMode: 'contain',
        }
      }
    })
    return null
  }

  render() {
    return (
      <Modal visible={this.state.visible} transparent={true}>
        <View style={styles.backdrop}>
          <View style={styles.results}>
            {this.state.visible && (
              <Image
                source={this.props.image}
                style={{
                  width: this.state.width,
                  height: this.state.height,
                  resizeMode: 'contain',
                }}
              />
            )}
            <TouchableOpacity
              style={[
                styles.button,
                { backgroundColor: solveColors.blue3, bottom: 0 },
              ]}
              onPress={() => {
                this.props.onClose()
              }}
            >
              <Text style={{ color: 'white' }}>Close</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    )
  }
}

const styles = StyleSheet.create({
  results: {
    maxWidth: '50vw',
    borderRadius: '10px',
    margin: 'auto',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: 25,
  },
  backdrop: {
    backgroundColor: 'rgba(52, 52, 52, 0.2)',
    height: '100%',
    width: '100%',
  },
  button: {
    width: 'calc(150px + 5vw)',
    height: 40,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
  },
})
export default ImageModal
