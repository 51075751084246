const checkAnswer = (
  studentAnswer,
  correctAnswer,
  caseSensitive,
  problemType,
  approximate
) => {
  if (problemType === 'FREE_FORM' || problemType === 'OFFLINE') {
    return true
  }
  if (problemType === 'MANY_HOTSPOTS' || problemType === 'ONE_HOTSPOT') {
    return studentAnswer.length === correctAnswer.length
  }
  if (problemType === 'MATCHING') {
    for (let i = 0; i < studentAnswer.length / 2; i++) {
      const choice1 = parseInt(studentAnswer.substring(i * 2, i * 2 + 1))
      const choice2 = parseInt(studentAnswer.substring(i * 2 + 1, i * 2 + 2))
      if (choice1 !== choice2) return false
    }
    return true
  }
  const marginOfError = 0.01 // 1% margin of error
  if (problemType === 'SCIENTIFIC_UNIT') {
    if (!correctAnswer) return false
    if (!studentAnswer) return false
    const template = extractAnswersFromTemplate(correctAnswer)
    const studentAnswers = extractAnswersFromTemplate(studentAnswer).answers
    const correctAnswers = template.answers
    const { templateType } = template
    for (let i = 0; i < studentAnswers.length; i++) {
      if (i > correctAnswers.length - 1) continue
      if (
        !approximate ||
        (templateType === 1 && i === 1) ||
        (templateType === 8 && (i === 1 || i === 3)) ||
        (templateType === 9 && i === 1) ||
        templateType === 5
      ) {
        if (!isEqualNumbers(studentAnswers[i], correctAnswers[i])) return false
      } else {
        if (
          !isApproxNumbers(studentAnswers[i], correctAnswers[i], marginOfError)
        ) {
          return false
        }
      }
    }
    return true
  }
  if (typeof correctAnswer === 'string') {
    if (problemType === 'SHORT_NUMBER_RESPONSE') {
      if (approximate) {
        return isApproxNumbers(studentAnswer, correctAnswer, marginOfError)
      }
      return isEqualNumbers(studentAnswer, correctAnswer)
    }
    return isEqualShortText(studentAnswer, correctAnswer, caseSensitive)
  }
  if (problemType === 'SHORT_NUMBER_RESPONSE') {
    return isEqualNumberChoices(correctAnswer, studentAnswer)
  }
  return isEqualTextChoices(correctAnswer, studentAnswer, caseSensitive)
}

const isEqualShortText = (text1, text2, caseSensitive) => {
  if (!text1 || !text2) return false

  return (
    text1.replace(/\s/g, '') === text2.replace(/\s/g, '') ||
    (!caseSensitive &&
      text1.replace(/\s/g, '').toLowerCase() ===
        text2.replace(/\s/g, '').toLowerCase())
  )
}

const isEqualNumbers = (num1, num2) => {
  if (num1 == null || num2 == null) return false
  return (
    parseFloat(num1.replace(/\s/g, '')) === parseFloat(num2.replace(/\s/g, ''))
  )
}

const isApproxNumbers = (n1, n2, marginOfError) => {
  const num1 = parseFloat(n1.replace(/\s/g, ''))
  const num2 = parseFloat(n2.replace(/\s/g, ''))
  const error = Math.abs(num2 * marginOfError)
  return num2 - error <= num1 && num2 + error >= num1
}

const isEqualTextChoices = (choices, answer, caseSensitive) => {
  return (
    (answer &&
      choices
        .map(a => a.replace(/\s/g, ''))
        .includes(answer.replace(/\s/g, ''))) ||
    (!caseSensitive &&
      answer &&
      choices
        .map(a => a.replace(/\s/g, '').toLowerCase())
        .includes(answer.replace(/\s/g, '').toLowerCase()))
  )
}

const isEqualNumberChoices = (choices, answer) => {
  return choices
    .map(a => parseFloat(a.replace(/\s/g, '')))
    .includes(parseFloat(answer))
}

//SCIENTIFIC UNIT

const extractAnswersFromTemplate = template => {
  if (template.includes('\\(\\sqrt')) {
    return {
      answers: [
        template
          .substring(template.indexOf('{') + 1, template.indexOf('}'))
          .trim(),
      ],
      templateType: 21,
    }
  }
  if (template.includes('\\sqrt')) {
    return {
      answers: [
        template
          .substring(template.indexOf('\\(') + 2, template.indexOf('\\sqrt'))
          .trim(),
        template
          .substring(template.indexOf('{') + 1, template.indexOf('}'))
          .trim(),
      ],
      templateType: 19,
    }
  }
  if (
    template.includes('\\cdot') &&
    template.includes('\\cdot', template.indexOf('\\cdot') + 1) &&
    !template.includes('text')
  ) {
    return {
      answers: [
        template
          .substring(template.indexOf('^{') + 2, template.indexOf('}'))
          .trim(),
        template
          .substring(
            template.indexOf('^{', template.indexOf('^{') + 1) + 2,
            template.indexOf('}', template.indexOf('}') + 1)
          )
          .trim(),
        template
          .substring(
            template.indexOf(
              '^{',
              template.indexOf('^{', template.indexOf('^{') + 1) + 1
            ) + 2,
            template.indexOf(
              '}',
              template.indexOf('}', template.indexOf('}') + 1) + 1
            )
          )
          .trim(),
      ],
      templateType: 18,
    }
  }
  if (template.includes('\\cdot') && !template.includes('text')) {
    return {
      answers: [
        template
          .substring(template.indexOf('^{') + 2, template.indexOf('}'))
          .trim(),
        template
          .substring(
            template.indexOf('^{', template.indexOf('^{') + 1) + 2,
            template.indexOf('}', template.indexOf('}') + 1)
          )
          .trim(),
      ],
      templateType: 17,
    }
  }
  if (template.includes(':') && template.includes('\\text')) {
    return {
      answers: [
        template
          .substring(template.indexOf('\\(') + 2, template.indexOf(':'))
          .trim(),
        template
          .substring(template.indexOf(':') + 1, template.indexOf('\\text'))
          .trim(),
      ],
      templateType: 20,
    }
  }
  if (template.includes(':')) {
    return {
      answers: [
        template
          .substring(template.indexOf('\\(') + 2, template.indexOf(':'))
          .trim(),
        template
          .substring(template.indexOf(':') + 1, template.indexOf('\\)'))
          .trim(),
      ],
      templateType: 15,
    }
  }
  if (template.replace(/\s/g, '').includes('\\(\\frac')) {
    return {
      answers: [
        template
          .substring(template.indexOf('\\frac{') + 6, template.indexOf('}{'))
          .trim(),
        template
          .substring(
            template.indexOf('}{') + 2,
            template.indexOf('}', template.indexOf('}') + 1)
          )
          .trim(),
      ],
      templateType: 13,
    }
  }
  if (template.replace(/\s/g, '').includes('\\frac')) {
    return {
      answers: [
        template
          .substring(template.indexOf('\\(') + 2, template.indexOf('\\frac'))
          .trim(),
        template
          .substring(template.indexOf('\\frac{') + 6, template.indexOf('}'))
          .trim(),
        template
          .substring(
            template.indexOf('}{') + 2,
            template.indexOf('}', template.indexOf('}') + 1)
          )
          .trim(),
      ],
      templateType: 12,
    }
  }
  if (template.replace(/\s/g, '').includes('\\(\\$')) {
    return {
      answers: [
        template.includes('\\text{')
          ? template
              .substring(
                template.indexOf('\\$') + 2,
                template.indexOf('\\text')
              )
              .trim()
          : template
              .substring(template.indexOf('\\$') + 2, template.indexOf('\\)'))
              .trim(),
      ],
      templateType: 10,
    }
  }
  if (
    template.includes('\\times') &&
    template.includes('\\circ', template.indexOf(',') + 1)
  ) {
    return {
      answers: [
        template
          .substring(template.indexOf('\\(') + 2, template.indexOf('\\times'))
          .trim(),
        template
          .substring(template.indexOf('{') + 1, template.indexOf('}'))
          .trim(),
        template
          .substring(
            template.indexOf(',') + 1,
            template.indexOf('^{', template.indexOf(','))
          )
          .trim(),
      ],
      templateType: 8,
    }
  }
  if (
    template.includes('\\times') &&
    template.includes('\\times', template.indexOf('\\times') + 1)
  ) {
    return {
      answers: [
        template
          .substring(template.indexOf('\\(') + 2, template.indexOf('\\times'))
          .trim(),
        template
          .substring(template.indexOf('{') + 1, template.indexOf('}'))
          .trim(),
        template
          .substring(
            template.indexOf(',') + 1,
            template.indexOf('\\times', template.indexOf(','))
          )
          .trim(),
        template
          .substring(
            template.indexOf('{', template.indexOf(',') + 1) + 1,
            template.indexOf('}', template.indexOf(',') + 1)
          )
          .trim(),
      ],
      templateType: 8,
    }
  }
  if (template.includes('\\times')) {
    return {
      answers: [
        template
          .substring(template.indexOf('\\(') + 2, template.indexOf('\\times'))
          .trim(),
        template
          .substring(template.indexOf('{') + 1, template.indexOf('}'))
          .trim(),
      ],
      templateType: 1,
    }
  }
  if (template.includes('10^') && !template.includes('10^{\\circ}')) {
    return {
      answers: [
        template
          .substring(template.indexOf('{') + 1, template.indexOf('}'))
          .trim(),
      ],
      templateType: 5,
    }
  }
  if (template.includes('\\hat{k}') || template.includes('\\hat{z}')) {
    const firstHat = template.indexOf('\\hat')
    const secondHat = template.indexOf('\\hat', firstHat + 1)
    const thirdHat = template.indexOf('\\hat', secondHat + 1)

    const op1 =
      template.indexOf('+') === -1 ||
      template.indexOf('+') < template.indexOf('-', template.indexOf('}'))
        ? '-'
        : '+'
    const op2 =
      template.indexOf('+') === -1 ||
      template.indexOf('+') < template.indexOf('-', template.indexOf('}'))
        ? '-'
        : '+'
    return {
      answers: [
        template.substring(template.indexOf('\\(') + 2, firstHat).trim(),
        template
          .substring(template.indexOf(op1, firstHat) + 1, secondHat)
          .trim(),
        template
          .substring(template.indexOf(op2, secondHat) + 1, thirdHat)
          .trim(),
      ],
      templateType: 7,
    }
  }
  if (template.includes('\\hat')) {
    const op = template.includes('+') ? '+' : '-'
    return {
      answers: [
        template
          .substring(template.indexOf('\\((') + 3, template.indexOf('\\hat{i}'))
          .trim(),
        template
          .substring(
            template.indexOf(op, template.indexOf('\\hat{i}')) + 1,
            template.indexOf('\\hat{j}')
          )
          .trim(),
      ],
      templateType: 3,
    }
  }
  if (template.includes('\\circ') && template.includes('\\text')) {
    return {
      answers: [
        template
          .substring(template.indexOf('\\((') + 3, template.indexOf('\\text'))
          .trim(),
        template
          .substring(template.indexOf(',') + 1, template.indexOf('^{\\circ}'))
          .trim(),
      ],
      templateType: 4,
    }
  }
  if (
    template.includes('text') &&
    template.includes('text', template.indexOf('text') + 1) &&
    template.includes(',')
  ) {
    return {
      answers: [
        template
          .substring(
            template.indexOf('\\(') + template.includes('\\((') ? 3 : 2,
            template.indexOf('\\text')
          )
          .trim(),
        template
          .substring(
            template.indexOf(',') + 1,
            template.indexOf('\\text', template.indexOf('\\text') + 1)
          )
          .trim(),
      ],
      templateType: 6,
    }
  }
  if (template.includes('^{') && !template.includes('\\circ')) {
    if (template.includes('\\({')) {
      return {
        answers: [
          template
            .substring(template.indexOf('\\({') + 3, template.indexOf('}^{'))
            .trim(),
          template
            .substring(template.indexOf('}^{') + 3, template.indexOf('}\\)'))
            .trim(),
        ],
        templateType: 14,
      }
    }
    return {
      answers: [
        template
          .substring(template.indexOf('^{') + 2, template.indexOf('}\\)'))
          .trim(),
      ],
      templateType: 11,
    }
  }
  if (template.includes(',') && template.includes('\\((')) {
    return {
      answers: [
        template
          .substring(template.indexOf('\\((') + 3, template.indexOf(','))
          .trim(),
        template
          .substring(template.indexOf(',') + 1, template.indexOf(')\\)'))
          .trim(),
      ],
      templateType: 16,
    }
  }
  if (template.includes('\\text{')) {
    return {
      answers: [
        template
          .substring(template.indexOf('\\(') + 2, template.indexOf('\\text'))
          .trim(),
      ],
      templateType: 2,
    }
  }
  if (template.includes('\\circ')) {
    return {
      answers: [
        template
          .substring(template.indexOf('\\(') + 2, template.indexOf('^'))
          .trim(),
      ],
      templateType: 2,
    }
  }
  if (template.includes('\\pi')) {
    return {
      answers: [
        template
          .substring(template.indexOf('\\(') + 2, template.indexOf('\\pi'))
          .trim(),
      ],
      templateType: 2,
    }
  }
  return {
    answers: [
      template
        .substring(template.indexOf('\\(') + 2, template.indexOf('\\)'))
        .trim(),
    ],
    templateType: 2,
  }
}

export { checkAnswer, extractAnswersFromTemplate }
