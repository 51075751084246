import {
  FlatList,
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
} from 'react-native'
import React, { Component } from 'react'
import ProblemSubmitButton from './ProblemSubmitButton'
import AssignmentSubmitButton from './AssignmentSubmitButton'
import SkipButton from './SkipButton'
import Choice from './Choice'
import { DialogModal, Latex } from '../../../components/'
import ScratchpadButton from './ScratchpadButton'
import SolutionButton from './SolutionButton'

const numToLetterChoice = num => {
  return String.fromCharCode(((num - 1) % 26) + 65)
}
const letterToNumChoice = letter => {
  return letter.charCodeAt() - 64
}
const encodeChoices = choices => {
  let value = ''
  for (let i = 1; i <= choices.length; i++) {
    if (choices.find(c => c.order === i).selected) value += i - 1
  }
  return value
}
const encodeLetterChoices = choices => {
  let value = ''

  for (let i = 1; i <= choices.length; i++) {
    if (choices[i - 1].selected) value += numToLetterChoice(i)
  }
  return value
}
const decodeChoices = (value, ordering) => {
  let choices = ordering.map(o => ({ selected: false, order: o }))
  if (!value || value.length > choices.length) return choices
  for (let i = 0; i < value.length; i++) {
    const order = parseInt(value.charAt(i)) + 1
    if (choices.findIndex(c => c.order === order) === -1)
      return ordering.map(o => ({ selected: false, order: o }))
    choices[choices.findIndex(c => c.order === order)].selected = true
  }
  return choices
}

class MultipleChoiceProblemForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: decodeChoices(
        props.currentAnswer,
        props.problem.choices.map(x => x.order)
      ),
      numChoices: 0,
      dlgModalOpen: false,
      partial: true,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextSelected = decodeChoices(
      nextProps.currentAnswer,
      nextProps.problem.choices.map(x => x.order)
    )
    if (nextSelected !== prevState.selected) {
      return { selected: nextSelected }
    }
    return null
  }
  setSelected = index => {
    const { problemType } = this.props.problem
    let newSelected = this.state.selected
    newSelected[index].selected = !newSelected[index].selected
    if (problemType !== 'MULTIPLE_CHOICE_MANY_ANSWERS')
      for (let i = 0; i < newSelected.length; i++) {
        if (i !== index) newSelected[i].selected = false
      }
    this.setState({ selected: newSelected })
    this.props.updateProblemAnswer(encodeChoices(newSelected))
  }

  handleProblemSubmit = async (fromSkip = false) => {
    const {
      problem,
      onProblemSubmit,
      review,
      lastProb,
      gotoLessons,
    } = this.props
    const corrAnswer = encodeChoices(
      problem.choices.map(x => ({ selected: x.correct, order: x.order }))
    )
    const studentAnswer = encodeChoices(this.state.selected)
    if (review && !lastProb) {
      onProblemSubmit(studentAnswer, corrAnswer)
      return
    }
    if (review && lastProb) {
      gotoLessons()
      return
    }
    onProblemSubmit(studentAnswer, corrAnswer, fromSkip)
  }

  handleAssignmentSubmit = () => {
    const { answered } = this.props
    if (answered) {
      this.setState({ partial: false })
    }
    this.openDialogModal()
  }

  openDialogModal = () => {
    this.setState({ dlgModalOpen: true })
  }

  closeDialogModal = () => {
    this.setState({ dlgModalOpen: false })
  }

  render() {
    const {
      problem,
      probState,
      complete,
      allowSkip,
      incrementProblem,
      feedbackType,
      review,
      lastProb,
      onAssignmentSubmit,
      scratchpadUrl,
      currentProbNum,
      problemStates,
      instructor,
      showSolution,
      toggleShowSolution,
      problemSetLength,
      isLoading,
    } = this.props

    const { questionText, solutionText } = problem
    const choices = problem.choices
    const typesetMath = true

    return (
      <View style={styles.problem}>
        <View style={styles.question}>
          <View style={styles.choices}>
            <View style={styles.questionText}>
              {typesetMath && <Latex latex={questionText} />}
              {!typesetMath && <Text>{questionText}</Text>}
            </View>

            <FlatList
              data={choices}
              extraData={this.props}
              key={this.state.selected}
              keyExtractor={item => item.text}
              renderItem={({ item, index }) => (
                <Choice
                  feedbackType={feedbackType}
                  typesetMath={typesetMath}
                  selected={this.state.selected}
                  setSelected={() => this.setSelected(index)}
                  index={index}
                  text={item.text}
                  probState={probState}
                />
              )}
            />
          </View>
          <DialogModal
            visible={this.state.dlgModalOpen}
            transparent={true}
            onYes={async () => {
              if (this.state.studentAnswer) await this.handleProblemSubmit()
              onAssignmentSubmit()
              this.closeDialogModal()
            }}
            onNo={() => {
              this.closeDialogModal()
            }}
            message={
              this.state.partial
                ? `You have answered ${
                    problemStates.filter(p => p !== 0).length
                  }/${problemSetLength} problems. Are you sure you want to submit the assignment for grading?`
                : 'Once submitted, you cannot change your answers. Are you sure you want to submit?'
            }
          />
        </View>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          {scratchpadUrl && (
            <TouchableOpacity
              style={{ marginRight: '0.5vh' }}
              onPress={() => this.props.deleteScratchpadImg(currentProbNum)}
            >
              <Text>{'X'}</Text>
            </TouchableOpacity>
          )}
          <Text>{scratchpadUrl}</Text>
        </View>
        <View style={styles.buttonBar}>
          <ScratchpadButton toggleScratchpad={this.props.toggleScratchpad} />
          <ProblemSubmitButton
            isLoading={isLoading}
            probState={probState}
            feedbackType={feedbackType}
            complete={complete}
            handleProblemSubmit={this.handleProblemSubmit}
            review={review}
            lastProb={lastProb}
          />
          {allowSkip && !instructor && !review && (
            <SkipButton
              probState={probState}
              allowSkip={allowSkip}
              lastProb={lastProb}
              handleProblemSubmit={this.handleProblemSubmit}
              incrementProblem={incrementProblem}
            />
          )}
          {!review && !instructor && (
            <AssignmentSubmitButton
              handleAssignmentSubmit={this.handleAssignmentSubmit}
              locked={problemStates.filter(s => s !== 0).length === 0}
            />
          )}
          {!review && instructor && (
            <SolutionButton
              showSolution={showSolution}
              toggleShowSolution={toggleShowSolution}
            />
          )}
        </View>

        {(review || showSolution) && (
          <View style={styles.answerText}>
            <Text>Correct Answer:</Text>
            <Text>
              {encodeLetterChoices(
                this.props.problem.choices.map(x => ({
                  selected: x.correct,
                  order: x.order,
                }))
              )}
            </Text>
            {solutionText !== null &&
              solutionText !== undefined &&
              solutionText !== '' && (
                <View style={{ alignItems: 'center' }}>
                  <Text>{'\n'}Solution:</Text>
                  {typesetMath && <Latex latex={solutionText} />}
                  {!typesetMath && <Text>{solutionText}</Text>}
                </View>
              )}
          </View>
        )}
      </View>
    )
  }
}

const styles = StyleSheet.create({
  answerText: {
    alignItems: 'center',
  },
  row: {
    flexDirection: 'row',
    padding: 10,
    alignSelf: 'center',
  },
  question: {
    alignItems: 'center',
    userSelect: 'none',
    maxWidth: '40vw',
  },
  questionText: {
    fontSize:
      sessionStorage.getItem('size') === 'large'
        ? 24
        : sessionStorage.getItem('size') === 'med'
        ? 20
        : 14,
  },
  textinput: {
    borderColor: '#EDEDED',
    borderWidth: 1,
    borderRadius: 10,
    padding: 10,
  },
  buttonBar: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  problem: {
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '60vw',
  },
  choices: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export {
  decodeChoices,
  encodeLetterChoices,
  encodeChoices,
  letterToNumChoice,
  numToLetterChoice,
}
export default MultipleChoiceProblemForm
