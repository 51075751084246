import { Text, View, ScrollView } from 'react-native'
import React from 'react'
import styles from './styles'
import GradebookForm from './../../components/GradebookForm'
import { numToLetterGrade } from './../../../utils/grades'
import LoadingPage from './../Loading/LoadingPage'
import jwt from 'jsonwebtoken'
import { Query } from 'react-apollo'
import { GET_CLASSROOM_GRADES } from './../../../services/queries'
import { useCookies } from 'react-cookie'

const GradebookPage = props => {
  const [cookies] = useCookies([])
  const instructor = cookies['instructor'] === '1'
  const admin = cookies['admin'] === '1'
  const trialToLesson = sessionStorage.getItem('trialToLesson')

  return (
    <Query
      query={GET_CLASSROOM_GRADES}
      context={{ headers: { 'cache-control': 'no-cache' } }}
      variables={{
        classroomId: props.classroomId,
        enrollmentFilter: {
          studentId: jwt.decode(cookies['token']).id,
        },
        attendeeFilter: { lesson: { classroomId: props.classroomId } },
      }}
      skip={instructor || admin || trialToLesson}
    >
      {({ data, loading, error }) => {
        if (error) return `${error}`
        if (loading) return <LoadingPage />
        if (instructor) {
          return <Text>Gradebook is not supported in instructor mode :(</Text>
        }
        if (admin) {
          return <Text>Gradebook is not supported in admin mode :(</Text>
        }
        const { overallGrade: classrooomGrade } = data.classroom.enrollments[0]
        const lessonGrades = data.classroom.enrollments[0].student.attendees.map(
          a => ({
            lessonId: a.lesson.id,
            overallGrade: a.overallGrade,
            percentCompleted: a.percentCompleted,
          })
        )
        return (
          <ScrollView style={{ margin: 20, paddingRight: 30 }}>
            <View
              style={{
                backgroundColor: 'white',
                padding: 10,
                marginBottom: 10,
                borderRadius: 10,
                alignSelf: 'flex-end',
                width: 190,
                alignItems: 'center',
              }}
            >
              {classrooomGrade > 0 ? (
                <Text
                  style={styles.class_grade}
                >{`Total Grade: ${classrooomGrade}% (${numToLetterGrade(
                  classrooomGrade
                )})`}</Text>
              ) : (
                <Text style={styles.class_grade}>{`Total Grade: N/A`}</Text>
              )}
            </View>

            <GradebookForm
              lessons={props.lessons}
              classroomId={props.classroomId}
              lessonGrades={lessonGrades}
            />
          </ScrollView>
        )
      }}
    </Query>
  )
}

export default GradebookPage
