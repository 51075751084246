import React from 'react'
import { View, Text, StyleSheet } from 'react-native-web'

const InfoRow = props => {
  return (
    <View style={[styles.infoRow, props.style]}>
      <Text style={{ textAlign: 'left' }}>{props.text1}</Text>
      <Text style={{ position: 'absolute', right: 5 }}>{props.text2}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  infoRow: {
    borderBottomWidth: 1,
    borderColor: 'rgba(52, 52, 52, 0.3)',
    paddingTop: 5,
    marginBottom: 5,
    width: 280,
    flexDirection: 'row',
  },
})

export default InfoRow
