// CheckoutForm.js
import React from 'react'
import { withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import AuthorizeForm from './AuthorizeForm'
import {
  CREATE_ENROLLMENT,
  CREATE_PAYMENT,
  UPDATE_ITEM,
} from '../../../services/queries/payment_queries'

class CheckoutForm extends React.Component {
  enrollSingle = async (client, itemId, studentId, classroomId) => {
    const { errors } = await client.mutate({
      mutation: CREATE_ENROLLMENT,
      variables: { input: { studentId, classroomId, status: 'PAID' } },
      errorPolicy: 'all',
    })
    if (errors && errors.length !== 0) {
      for (let error of errors) {
        alert(`${error.message}, student: ${studentId}, class: ${classroomId}`)
      }
    }
    await client.mutate({
      mutation: UPDATE_ITEM,
      variables: {
        id: itemId,
        input: { enrolled: true },
      },
    })
  }

  enrollStudents = async (client, items) => {
    //loop through items, if isClassroom && not yet enrolled, enroll.
    for (let item of items) {
      if (item.itemType === 'CLASSROOM' && !item.enrolled) {
        await this.enrollSingle(
          client,
          item.id,
          item.student.id,
          item.classroom.id
        )
      }
    }
  }

  applyCredits = async (payee, invoiceBalance) => {
    const { invoice, credits, client } = this.props

    let creditIndex = 0
    // payment.create handles adjustment to credits balance and invoice balance
    while (invoiceBalance > 0 && creditIndex < credits.length) {
      const amount =
        invoiceBalance >= credits[creditIndex].balance
          ? credits[creditIndex].balance
          : invoiceBalance
      invoiceBalance -= amount
      await client.mutate({
        mutation: CREATE_PAYMENT,
        variables: {
          input: {
            invoiceId: invoice.id,
            creditId: credits[creditIndex].id,
            dateReceived: new Date().toISOString(),
            payee,
            method: 'CREDIT',
            identification: credits[creditIndex].creditNumber,
            amount: amount,
          },
        },
      })
      creditIndex++
    }
  }

  getPaymentAmount(invoiceBalance) {
    const { credits } = this.props
    let creditIndex = 0
    while (invoiceBalance > 0 && creditIndex < credits.length) {
      const amount =
        invoiceBalance >= credits[creditIndex].balance
          ? credits[creditIndex].balance
          : invoiceBalance
      invoiceBalance -= amount
      creditIndex++
    }
    return invoiceBalance
  }

  onSuccessPayment = async (authNum, payee) => {
    const { invoice } = this.props
    let invoiceBalance = invoice.price
    await this.applyCredits(payee, invoiceBalance)

    const amount = this.getPaymentAmount(invoiceBalance)
    // if there is leftover balance after applying credits
    if (invoiceBalance > 0) {
      await this.props.client.mutate({
        mutation: CREATE_PAYMENT,
        variables: {
          input: {
            invoiceId: invoice.id,
            dateReceived: new Date().toISOString(),
            payee,
            method: 'AUTHORIZE',
            identification: authNum,
            amount: amount,
          },
        },
      })
    }

    await this.enrollStudents(this.props.client, invoice.items)
    this.props.history.replace('/payment-success', {
      authNum,
      invoiceNum: invoice.invoiceNumber,
      summary: invoice.summary,
    })
    this.props.handleConfirmPayment()
  }

  render() {
    const { invoice, credits } = this.props
    const amount = this.getPaymentAmount(invoice.price)
    return (
      <AuthorizeForm
        legal={this.props.legal}
        legalGuide={this.props.legalGuide}
        amount={amount}
        invoice={invoice}
        credits={credits}
        onSuccessPayment={this.onSuccessPayment}
        applyCredits={this.applyCredits}
      />
    )
  }
}

export default withRouter(withApollo(CheckoutForm))
