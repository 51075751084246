import { View, Text, TouchableOpacity } from 'react-native'
import { Mutation, Query } from 'react-apollo'
import {
  LIKE_NEWS,
  GET_NEWS_LIKES,
  ARCHIVE_NEWS,
} from './../../../services/queries'
import React, { useState } from 'react'
import solveColors from './../../components/colors'
import styles from './styles'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import draftToHtml from 'draftjs-to-html'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { useSpring, animated } from 'react-spring'
import Tooltip from '@material-ui/core/Tooltip'
import EditIcon from '@material-ui/icons/Edit'
import { useCookies } from 'react-cookie'

const AnnouncementCard = props => {
  const [cookies] = useCookies([])

  let {
    announcerName,
    dateTime,
    subject,
    newsId,
    message,
    refetch,
    openEditModal,
  } = props
  const messageDiv = React.createRef()
  const [liked, setLiked] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [contentHeight, setContentHeight] = useState(500)
  const spring = useSpring({
    maxHeight: expanded ? contentHeight : 250,
  })
  const employee = cookies['instructor'] === '1' || cookies['admin'] === '1'
  let htmlMessage = ''
  try {
    htmlMessage = draftToHtml(JSON.parse(message))
  } catch (e) {
    console.error(e)
  }
  return (
    <Query query={GET_NEWS_LIKES} variables={{ filter: { id: newsId } }}>
      {({ data, loading }) => {
        if (loading) return 'Loading...'
        return (
          <Mutation mutation={ARCHIVE_NEWS} variables={{ newsId }}>
            {archiveNews => (
              <Mutation
                mutation={LIKE_NEWS}
                variables={{ newsId, likes: data.news[0].likes + 1 }}
              >
                {likeNews => (
                  <View
                    style={{
                      width: '90%',
                      backgroundColor: 'white',
                      borderRadius: 10,
                      paddingBottom: 10,
                      marginBottom: 20,
                      boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.25)',
                      alignSelf: 'center',
                    }}
                  >
                    <View
                      style={{
                        backgroundColor: solveColors.blue1,
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        flexDirection: 'row',
                        paddingHorizontal: 20,
                        paddingVertical: 10,
                        fontWeight: 'bold',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Text style={styles.announcement_title}>{subject}</Text>
                      <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.announcement_title}>
                          {announcerName}
                        </Text>
                        {employee && openEditModal && (
                          <Tooltip title="Edit Announcement">
                            <TouchableOpacity
                              style={{
                                color: 'white',
                                marginHorizontal: 5,
                              }}
                              onPress={async () => {
                                openEditModal()
                              }}
                            >
                              <EditIcon style={{ height: 22, width: 22 }} />
                            </TouchableOpacity>
                          </Tooltip>
                        )}
                        {employee && (
                          <Tooltip title="Archive Announcement">
                            <TouchableOpacity
                              style={{
                                marginHorizontal: 5,
                              }}
                              onPress={async () => {
                                await archiveNews()
                                refetch()
                              }}
                            >
                              <Text
                                style={{
                                  color: 'white',
                                  fontSize: 18,
                                  fontWeight: 'bold',
                                }}
                              >
                                X
                              </Text>
                            </TouchableOpacity>
                          </Tooltip>
                        )}
                      </View>
                    </View>
                    <animated.div
                      ref={messageDiv}
                      style={{ ...spring, overflowY: 'scroll', margin: 20 }}
                    >
                      {htmlMessage && (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: htmlMessage,
                          }}
                        />
                      )}
                      {!htmlMessage && (
                        <Text style={{ marginVertical: 10 }}>{message}</Text>
                      )}
                    </animated.div>
                    <TouchableOpacity
                      style={{ marginVertical: 5, alignSelf: 'center' }}
                      onPress={() => {
                        setExpanded(!expanded)
                        setContentHeight(
                          Math.max(messageDiv.current.scrollHeight),
                          250
                        )
                      }}
                    >
                      {!expanded && <KeyboardArrowDownIcon />}
                      {expanded && <ExpandLessIcon />}
                    </TouchableOpacity>

                    <View
                      style={{
                        flexDirection: 'row',
                        marginHorizontal: 20,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <TouchableOpacity
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                          onPress={async () => {
                            if (!liked) {
                              setLiked(true)
                              await likeNews()
                            }
                          }}
                          disabled={loading}
                        >
                          {liked && (
                            <FavoriteIcon style={{ color: solveColors.red1 }} />
                          )}
                          {!liked && <FavoriteBorderIcon />}
                          <Text style={{ fontSize: 12, marginLeft: 2 }}>{`${
                            loading ? '...' : data.news[0].likes || 0
                          } Likes`}</Text>
                        </TouchableOpacity>
                      </View>

                      <Text style={{ fontSize: 12 }}>
                        {new Date(dateTime).toDateString()}
                      </Text>
                    </View>
                  </View>
                )}
              </Mutation>
            )}
          </Mutation>
        )
      }}
    </Query>
  )
}

export default AnnouncementCard
