import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Image,
  Dimensions,
} from 'react-native'
import React, { Component } from 'react'
import ProblemSubmitButton from './ProblemSubmitButton'
import AssignmentSubmitButton from './AssignmentSubmitButton'
import SkipButton from './SkipButton'
import { DialogModal, Latex } from '../../../components'
import ScratchpadButton from './ScratchpadButton'
import SolutionButton from './SolutionButton'

const encodeChoices = hotspots => {
  let value = ''
  for (const h of hotspots) {
    value += String.fromCharCode((h % 26) + 65)
  }
  return value
}

const decodeChoices = encodedHotSpots => {
  let hotspots = []
  for (const h of encodedHotSpots) {
    hotspots.push(h.charCodeAt() - 65)
  }
  return hotspots
}

class HotSpotProblemForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dlgModalOpen: false,
      partial: true,
      imageHeight: null,
      imageWidth: null,
      questionImgStyle: {},
      showHotSpots: true,
      hotSpotsFound: decodeChoices(props.currentAnswer),
    }
  }

  updateImageStyle = questionImageUrl => {
    Image.getSize(questionImageUrl, (width, height) => {
      const windowWidth = Dimensions.get('window').width
      const w = width / windowWidth > 0.5 ? windowWidth / 2 : width
      const h =
        width / windowWidth > 0.5
          ? ((windowWidth / 2) * height) / width
          : height
      this.setState({
        imageWidth: w,
        imageHeight: h,
        questionImgStyle: {
          width: w,
          height: h,
          resizeMode: 'contain',
        },
      })
    })
  }
  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
    this.updateImageStyle(this.props.problem.questionImageUrl)
  }
  componentDidUpdate(prevProps) {
    if (this.props.currentProbNum !== prevProps.currentProbNum) {
      this.updateImageStyle(this.props.problem.questionImageUrl)
    }

    if (
      decodeChoices(this.props.currentAnswer) !==
      decodeChoices(prevProps.currentAnswer)
    ) {
      this.setState({ hotSpotsFound: decodeChoices(this.props.currentAnswer) })
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }
  handleResize = () => {
    this.updateImageStyle(this.props.problem.questionImageUrl)
  }

  handleProblemSubmit = async (fromSkip = false) => {
    const {
      problem,
      onProblemSubmit,
      review,
      lastProb,
      gotoLessons,
    } = this.props

    const studentAnswer = encodeChoices(this.state.hotSpotsFound)
    let corrAnswer = ''
    for (let i = 0; i < problem.choices.length; i++) {
      corrAnswer += String.fromCharCode((i % 26) + 65)
    }
    if (review && lastProb) {
      gotoLessons()
      return
    }
    onProblemSubmit(studentAnswer, corrAnswer, fromSkip)
  }

  handleAssignmentSubmit = () => {
    const { answered } = this.props
    if (answered) {
      this.setState({ partial: false })
    }
    this.openDialogModal()
  }

  openDialogModal = () => {
    this.setState({ dlgModalOpen: true })
  }

  closeDialogModal = () => {
    this.setState({ dlgModalOpen: false })
  }

  render() {
    const {
      problem,
      probState,
      complete,
      allowSkip,
      incrementProblem,
      feedbackType,
      review,
      lastProb,
      onAssignmentSubmit,
      scratchpadUrl,
      currentProbNum,
      problemStates,
      instructor,
      showSolution,
      toggleShowSolution,
      problemSetLength,
    } = this.props

    const { questionText, solutionText, questionImageUrl } = problem
    const choices = problem.choices
    const typesetMath = true
    const rad = 20
    return (
      <View style={styles.problem}>
        <View style={styles.question}>
          <View style={styles.choices}>
            {typesetMath && <Latex latex={questionText} />}
            {!typesetMath && <Text>{questionText}</Text>}
            <Text>Find the Hot Spots in the Image!</Text>
            <View>
              <View>
                <Image
                  source={questionImageUrl}
                  style={this.state.questionImgStyle}
                />
                {choices.map((choice, index) => {
                  const coords = choice.text.split(',').map(coord => coord)
                  return (
                    <TouchableWithoutFeedback
                      onPress={() => {
                        if (!this.state.hotSpotsFound.includes(index)) {
                          const temp = this.state.hotSpotsFound
                          temp.push(index)
                          this.setState({ hotSpotsFound: temp })
                        }
                      }}
                    >
                      <View
                        style={[
                          {
                            position: 'absolute',
                            borderColor: 'red',
                            width: rad * 2,
                            height: rad * 2,
                            top: coords[1] * this.state.imageHeight - rad,
                            left: coords[0] * this.state.imageWidth - rad,
                          },
                          this.state.hotSpotsFound.includes(index) ||
                          showSolution
                            ? { borderWidth: 3 }
                            : { borderWidth: 0 },
                        ]}
                      />
                    </TouchableWithoutFeedback>
                  )
                })}
              </View>
            </View>
          </View>
          <DialogModal
            visible={this.state.dlgModalOpen}
            transparent={true}
            onYes={async () => {
              if (this.state.studentAnswer) await this.handleProblemSubmit()
              onAssignmentSubmit()
              this.closeDialogModal()
            }}
            onNo={() => {
              this.closeDialogModal()
            }}
            message={
              this.state.partial
                ? `You have answered ${
                    problemStates.filter(p => p !== 0).length
                  }/${problemSetLength} problems. Are you sure you want to submit the assignment for grading?`
                : 'Once submitted, you cannot change your answers. Are you sure you want to submit?'
            }
          />
        </View>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          {scratchpadUrl && (
            <TouchableOpacity
              style={{ marginRight: '0.5vh' }}
              onPress={() => this.props.deleteScratchpadImg(currentProbNum)}
            >
              <Text>{'X'}</Text>
            </TouchableOpacity>
          )}
          <Text>{scratchpadUrl}</Text>
        </View>
        <View style={styles.buttonBar}>
          <ScratchpadButton toggleScratchpad={this.props.toggleScratchpad} />
          <ProblemSubmitButton
            probState={probState}
            feedbackType={feedbackType}
            complete={complete}
            handleProblemSubmit={this.handleProblemSubmit}
            review={review}
            lastProb={lastProb}
          />
          {allowSkip && !instructor && !review && (
            <SkipButton
              probState={probState}
              allowSkip={allowSkip}
              lastProb={lastProb}
              handleProblemSubmit={this.handleProblemSubmit}
              incrementProblem={incrementProblem}
            />
          )}
          {!review && !instructor && (
            <AssignmentSubmitButton
              handleAssignmentSubmit={this.handleAssignmentSubmit}
              locked={problemStates.filter(s => s !== 0).length === 0}
            />
          )}
          {!review && instructor && (
            <SolutionButton
              showSolution={showSolution}
              toggleShowSolution={toggleShowSolution}
            />
          )}
        </View>

        {(review || showSolution) && (
          <View style={styles.answerText}>
            <Text>Correct Answer:</Text>
            <Text>N/A</Text>
            {solutionText !== null &&
              solutionText !== undefined &&
              solutionText !== '' && (
                <View style={{ alignItems: 'center' }}>
                  <Text>{'\n'}Solution:</Text>
                  {typesetMath && <Latex latex={solutionText} />}
                  {!typesetMath && <Text>{solutionText}</Text>}
                </View>
              )}
          </View>
        )}
      </View>
    )
  }
}

const styles = StyleSheet.create({
  answerText: {
    alignItems: 'center',
  },
  row: {
    flexDirection: 'row',
    padding: 10,
    alignSelf: 'center',
  },
  question: {
    alignItems: 'center',
    userSelect: 'none',
    maxWidth: '40vw',
  },
  textinput: {
    borderColor: '#EDEDED',
    borderWidth: 1,
    borderRadius: 10,
    padding: 10,
  },
  buttonBar: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  problem: {
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '60vw',
  },
  choices: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default HotSpotProblemForm
