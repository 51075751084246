import { Text, View, FlatList, StyleSheet } from 'react-native-web'
import React from 'react'
import { GET_GRADEBOOK_ASSIGNMENTS } from '../../services/queries'
import { withRouter } from 'react-router-dom'
import { Query } from 'react-apollo'
import jwt from 'jsonwebtoken'
import { formatDate, secondsToMMSS } from '../../utils/dateTime'
import { numToLetterGrade, getGradeColor } from '../../utils/grades'
import solveColors from './colors'
import { isMobile } from '../../config'
import { useCookies } from 'react-cookie'

const SectionHeader = props => (
  <View style={styles.progress_section_header}>
    <Text style={{ fontWeight: 'bold' }}>{props.title}</Text>
  </View>
)

const ProgressForm = props => {
  const [cookies] = useCookies([])

  return (
    <Query
      query={GET_GRADEBOOK_ASSIGNMENTS}
      variables={{
        id: props.lessonId,
        sort: {
          sum: -2,
          submittedAt: -1,
        },
        filter: {
          studentId: props.studentId || jwt.decode(cookies['token']).id,
        },
      }}
    >
      {({ data, loading, error }) => {
        if (error) return `${error}`
        if (loading) return <SectionHeader title={'Loading...'} />
        let { assignments } = data.lesson
        assignments.sort((a, b) => a.problemSet.order - b.problemSet.order)
        const homeworks = assignments.filter(
          a => a.problemSet.kind === 'HOMEWORK'
        )
        const exercises = assignments.filter(
          a => a.problemSet.kind === 'EXERCISE'
        )
        const quizzes = assignments.filter(a => a.problemSet.kind === 'QUIZ')
        const instructions = assignments.filter(
          a => a.problemSet.kind === 'INSTRUCTION'
        )
        // const practices = assignments.filter(
        //   a => a.problemSet.kind === 'PRACTICE'
        // )
        // const contests = assignments.filter(
        //   a => a.problemSet.kind === 'CONTEST'
        // )

        return (
          <>
            <View
              style={[
                styles.progress_problemset_row,
                {
                  height: 30,
                  backgroundColor: 'white',
                  justifyContent: 'flex-end',
                },
              ]}
            >
              <div style={{ display: 'flex', flexBasis: '50%' }}>
                <Text style={{ flex: 1, textAlign: 'center' }}>Grade</Text>
                {!isMobile && (
                  <Text style={{ flex: 1, textAlign: 'center' }}>Time</Text>
                )}
                {!isMobile && (
                  <Text style={{ flex: 1, textAlign: 'center' }}>Due Date</Text>
                )}
                {!isMobile && (
                  <Text style={{ flex: 1, textAlign: 'center' }}>
                    Date Submitted
                  </Text>
                )}
              </div>
            </View>
            <FlatList
              keyExtractor={(item, index) => {
                return index.toString()
              }}
              horizontal={false}
              initialNumToRender={100}
              data={[
                'HOMEWORK',
                ...homeworks,
                'EXERCISE',
                ...exercises,
                'QUIZ',
                ...quizzes,
                'INSTRUCTION (OPTIONAL)',
                ...instructions,
                // 'PRACTICE (OPTIONAL)',
                // ...practices,
                // 'CONTEST (OPTIONAL)',
                // ...contests,
              ]}
              renderItem={({ item }) => {
                if (typeof item === 'string') {
                  return <SectionHeader title={item} />
                }

                const { problemSet, submissions } = item
                const { dueOn } = item

                const { submittedAt, overallGrade, totalTimeSpent, excused } =
                  submissions.length > 0 ? submissions[0] : {}
                const late =
                  dueOn &&
                  submittedAt &&
                  new Date(dueOn).getTime() < new Date(submittedAt).getTime()
                return (
                  <View style={styles.progress_problemset_row}>
                    <Text
                      style={[
                        styles.progress_problemset_title,
                        { maxWidth: '40%' },
                      ]}
                    >
                      {problemSet.title}
                    </Text>
                    <div style={{ width: '50%', display: 'flex' }}>
                      <View style={{ flex: 1, alignItems: 'center' }}>
                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: ' center',
                            justifyContent: 'center',
                          }}
                        >
                          {submissions.length > 0 && overallGrade !== 0 && (
                            <View
                              style={{
                                backgroundColor: getGradeColor(overallGrade),
                                paddingVertical: 2,
                                paddingHorizontal: 8,
                                borderRadius: 20,
                                marginRight: 10,
                              }}
                            >
                              <Text>{numToLetterGrade(overallGrade)}</Text>
                            </View>
                          )}
                          <Text>
                            {excused
                              ? 'Excused'
                              : overallGrade >= 0
                              ? `${overallGrade}%`
                              : overallGrade === -1
                              ? ''
                              : 'N/A'}
                          </Text>
                        </View>
                      </View>

                      {!isMobile && (
                        <Text style={{ flex: 1, textAlign: 'center' }}>
                          {totalTimeSpent
                            ? secondsToMMSS(totalTimeSpent)
                            : 'N/A'}
                        </Text>
                      )}

                      {!isMobile && (
                        <Text style={{ flex: 1, textAlign: 'center' }}>
                          {dueOn ? formatDate(dueOn, false) : 'N/A'}
                        </Text>
                      )}

                      {!isMobile && (
                        <Text
                          style={{
                            color: late ? 'red' : 'inherit',
                            flex: 1,
                            textAlign: 'center',
                          }}
                        >
                          {submittedAt ? formatDate(submittedAt, false) : 'N/A'}
                        </Text>
                      )}
                    </div>
                  </View>
                )
              }}
            />
          </>
        )
      }}
    </Query>
  )
}
const styles = StyleSheet.create({
  progress_problemset_row: {
    backgroundColor: 'white',
    flexDirection: 'row',
    height: 40,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: solveColors.grey1,
  },
  progress_problemset_title: {
    color: solveColors.grey4,
    marginLeft: '5%',
  },
  progress_section_header: {
    paddingLeft: '3%',
    backgroundColor: 'white',
    height: 40,
    width: '100%',
    justifyContent: 'center',
    borderBottomWidth: 1,
    borderBottomColor: solveColors.grey1,
  },
})
export default withRouter(ProgressForm)
