import React, { Component } from 'react'
import {
  View,
  TouchableOpacity,
  TextInput,
  StyleSheet,
  Text,
} from 'react-native'
import { solveColors } from '../../components/colors'

class FamilyLoginInput extends Component {
  constructor(props) {
    super(props)
    this.codeInput = React.createRef()
  }

  render() {
    return (
      <View>
        <div>
          <TextInput
            style={styles.textinput}
            placeholder={'Phone Number'}
            autoFocus={true}
            onChangeText={this.props.onPhoneChange}
            placeholderTextColor={solveColors.offWhite}
            onSubmitEditing={() => {
              this.props.onLogin()
              this.codeInput.current.focus()
            }}
          />
          <TouchableOpacity style={styles.button} onPress={this.props.onLogin}>
            <Text style={{ color: 'white' }}>Send code</Text>
          </TouchableOpacity>
        </div>
        <TextInput
          ref={this.codeInput}
          style={[styles.textinput, { marginBottom: 20 }]}
          placeholder={'code'}
          secureTextEntry={true}
          onChangeText={this.props.onCodeChange}
          placeholderTextColor={solveColors.offWhite}
          onSubmitEditing={this.props.onVerify}
        />
        <TouchableOpacity
          style={styles.button}
          disabled={this.props.disabled}
          onPress={this.props.onVerify}
        >
          <Text style={{ color: 'white' }}>Login</Text>
        </TouchableOpacity>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  textinput: {
    padding: 10,
    color: 'white',
    borderBottomWidth: 1,
    borderBottomColor: 'white',
    margin: 5,
    textAlign: 'center',
  },
  button: {
    alignItems: 'center',
    backgroundColor: '#237FBB',
    padding: 10,
    borderRadius: 10,
    marginBottom: 10,
  },
})

export default FamilyLoginInput
