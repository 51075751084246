import { Route, Switch } from 'react-router-dom'
import React from 'react'

import LoginPage from './web/screens/Login/LoginPage'
import RecoveryPage from './web/screens/Login/Recovery'
import ChangePasswordPage from './web/screens/ChangePassword/ChangePassword'
import LessonPage from './web/screens/Lesson/LessonPage'
import DashboardPage from './web/screens/Dashboard/DashboardPage'
import ProblemSetPage from './web/screens/ProblemSet/ProblemSetPage'
import ProfilePage from './web/screens/Profile/ProfilePage'
import VideoPage from './web/screens/Video/VideoPage'
import SyllabusPage from './web/screens/Syllabus/SyllabusPage'
import TranscriptPage from './web/screens/Transcript/TranscriptPage'
import InstructorLoginPage from './web/screens/InstructorLogin/InstructorLoginPage'
import CatalogPage from './web/screens/Catalog/CatalogPage'
import HomePage from './web/screens/Home/HomePage'
import GuestLoginPage from './web/screens/GuestLogin/GuestLoginPage'
import AdminLoginPage from './web/screens/AdminLogin/AdminLoginPage'
import FamilyLoginPage from './web/screens/FamilyLogin/FamilyLoginPage'
import CoursePage from './web/screens/Catalog/CoursePage'
import PaymentPage from './web/screens/Payment/PaymentPage'
import CartPage from './web/screens/ShoppingCart/CartPage'
import PaymentSuccess from './web/screens/Payment/PaymentSuccess'
import FamilyDashboardPage from './web/screens/FamilyDashboard/FamilyDashboardPage'
import LandingPage from './web/screens/Landing/LandingPage'
import ReportsView from './web/screens/Reports/ReportsView'
import PostsPage from './web/screens/Posts/PostsPage'
import PostPage from './web/screens/Posts/PostPage'
import ReferralPage from './web/screens/FamilyDashboard/ReferralPage.js'
import Verification from './web/screens/Verification'
import { withCookies } from 'react-cookie'

const Router = props => {
  const IS_FAMILY = props.cookies.get('family') === '1'
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => <HomePage hideBars={props.hideBars} />}
      />
      <Route
        exact
        path="/login"
        render={() => <LoginPage hideBars={props.hideBars} />}
      />
      <Route
        path="/instructor"
        render={() => <InstructorLoginPage hideBars={props.hideBars} />}
      />
      <Route
        path="/admin"
        render={() => <AdminLoginPage hideBars={props.hideBars} />}
      />
      <Route
        path="/family"
        render={() => <FamilyLoginPage hideBars={props.hideBars} />}
      />
      <Route
        path="/recovery"
        render={() => <RecoveryPage hideBars={props.hideBars} />}
      />
      <Route
        path="/changepassword"
        render={() => <ChangePasswordPage hideBars={props.hideBars} />}
      />
      <Route
        path="/dashboard"
        render={
          IS_FAMILY
            ? () => <FamilyDashboardPage changeTitles={props.updateTitles} />
            : () => <DashboardPage changeTitles={props.updateTitles} />
        }
      />
      <Route
        path="/problemSets"
        render={
          IS_FAMILY
            ? () => <CatalogPage changeTitles={props.updateTitles} />
            : () => (
                <ProblemSetPage
                  displayBars={props.displayBars}
                  changeTitles={props.updateTitles}
                  hideBars={props.hideBars}
                  toggleMenu={props.toggleMenu}
                />
              )
        }
      />
      <Route
        exact
        path="/lessons"
        render={
          IS_FAMILY
            ? () => <CatalogPage changeTitles={props.updateTitles} />
            : () => <LessonPage changeTitles={props.updateTitles} />
        }
      />

      <Route
        path="/profile"
        render={() => <ProfilePage changeTitles={props.updateTitles} />}
      />
      <Route
        path="/video"
        render={() => (
          <VideoPage
            displayBars={props.displayBars}
            changeTitles={props.updateTitles}
          />
        )}
      />
      <Route
        path="/syllabus"
        render={() => (
          <SyllabusPage
            displayBars={props.displayBars}
            changeTitles={props.updateTitles}
          />
        )}
      />
      <Route
        path="/transcript"
        render={
          IS_FAMILY
            ? () => (
                <CatalogPage
                  displayBars={props.displayBars}
                  changeTitles={props.updateTitles}
                />
              )
            : () => (
                <TranscriptPage
                  displayBars={props.displayBars}
                  changeTitles={props.updateTitles}
                />
              )
        }
      />
      <Route
        path="/courses"
        render={() => (
          <CatalogPage
            displayBars={props.displayBars}
            changeTitles={props.updateTitles}
            courselist={true}
          />
        )}
      />
      <Route
        path="/catalog"
        render={() => (
          <CatalogPage
            displayBars={props.displayBars}
            changeTitles={props.updateTitles}
            courselist={false}
          />
        )}
      />
      <Route
        path="/guestLogin"
        render={() => <GuestLoginPage hideBars={props.hideBars} />}
      />
      <Route
        path="/checkout"
        render={() => <CoursePage displayBars={props.displayBars} />}
      />
      <Route
        path="/purchase"
        render={() => <PaymentPage displayBars={props.displayBars} />}
      />
      <Route
        path="/payment-success"
        render={() => <PaymentSuccess displayBars={props.displayBars} />}
      />
      <Route
        path="/cart"
        render={() => <CartPage displayBars={props.displayBars} />}
      />
      <Route path="/landing" component={LandingPage} />

      <Route path="/reports" component={ReportsView} />

      <Route path="/posts" component={PostsPage} />
      <Route path="/post" component={PostPage} />
      <Route path="/referral" component={ReferralPage} />

      <Route path="/verify" component={Verification} />
    </Switch>
  )
}

export default withCookies(Router)
