import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Text, Image, TouchableOpacity, ScrollView } from 'react-native-web'
import solveColors from './colors'
import styles from './styles'
import ardent from '../images/ardent-logo.png'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import DashboardIcon from '@material-ui/icons/Dashboard'
import PersonIcon from '@material-ui/icons/Person'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import AssessmentIcon from '@material-ui/icons/Assessment'
import CloseIcon from '@material-ui/icons/Close'
import { Button, IconButton } from '@material-ui/core'
import { isMobile } from '../../config'
import SettingsIcon from '@material-ui/icons/Settings'
import ScheduleIcon from '@material-ui/icons/Schedule'
import ReceiptIcon from '@material-ui/icons/Receipt'
import FiberNewIcon from '@material-ui/icons/FiberNew'
import { useCookies } from 'react-cookie'
import jwt from 'jsonwebtoken'

export const NavigationBar = props => {
  const { hide, history } = props
  const [cookies] = useCookies([])
  // let [selected, setSelected] = useState(history.location.pathname.substring(1))
  let [selected] = useState('')
  const token = cookies['token']
  const isStudent = token && jwt.decode(token).userType === 'Student'
  const isParent = token && jwt.decode(token).userType === 'FamilyMember'
  const isInstructor = token && jwt.decode(token).userType === 'Employee'
  const navigation = [
    {
      title: 'home',
      noLogin: true,
      component: () => (
        <Image style={styles.logo} resizeMode="contain" source={ardent} />
      ),
    },

    {
      title: 'dashboard',
      forStudent: true,
      forInstructor: true,
      component: styles => <DashboardIcon style={styles} />,
    },
    {
      title: 'news',
      forStudent: true,
      forParent: true,
      forInstructor: true,
      component: styles => <FiberNewIcon style={styles} />,
    },
    {
      title: 'students',
      forParent: true,
      component: styles => <PersonIcon style={styles} />,
    },
    {
      title: 'grades',
      forParent: true,
      component: styles => <AssessmentIcon style={styles} />,
    },
    {
      title: 'schedule',
      forParent: true,
      component: styles => <ScheduleIcon style={styles} />,
    },
    {
      title: 'orders',
      forParent: true,
      component: styles => <ReceiptIcon style={styles} />,
    },
    {
      title: 'catalog',
      noLogin: true,
      component: styles => <MenuBookIcon style={styles} />,
    },
    {
      title: 'settings',
      forParent: true,
      forStudent: true,
      forInstructor: true,
      component: styles => <SettingsIcon style={styles} />,
    },
    {
      title: 'help',
      noLogin: true,
      component: styles => <HelpOutlineIcon style={styles} />,
    },
  ]

  const execute = button => {
    props.toggleBars()
    switch (button.toLowerCase()) {
      case 'home':
        history.push('/')
        return
      case 'settings':
        history.push('/profile')
        return
      case 'dashboard':
        history.push('/dashboard')
        return
      case 'catalog':
        history.push('/catalog')
        return
      case 'students':
        history.push('/dashboard')
        return
      case 'grades':
        history.push('/reports')
        return
      case 'schedule':
        history.push('/dashboard?defaultTab=2')
        return
      case 'orders':
        if (isParent) {
          history.push('/dashboard?defaultTab=3')
        } else {
          history.push('/dashboard?defaultTab=1')
        }
        return
      case 'help':
        props.openVideoModal()
        return
      case 'news':
        history.push('/posts')
        return
      default:
        return
    }
  }

  if (!isMobile) {
    return (
      <ScrollView
        style={styles.navigationbar}
        contentContainerStyle={{ alignItems: 'center' }}
      >
        {navigation.map((nav, index) => {
          if (
            nav.noLogin ||
            (isParent && nav.forParent) ||
            (isStudent && nav.forStudent) ||
            (isInstructor && nav.forInstructor)
          ) {
            return (
              <TouchableOpacity
                key={`route${index}`}
                style={[
                  styles.navbutton,
                  selected === nav.title
                    ? { backgroundColor: solveColors.grey1 }
                    : null,
                ]}
                onPress={() => {
                  execute(nav.title)
                }}
              >
                {React.createElement(() =>
                  nav.component({ fill: 'white', height: 65, width: 65 })
                )}
                {nav.title !== 'home' && (
                  <Text style={[styles.text]}>{nav.title}</Text>
                )}
              </TouchableOpacity>
            )
          }
          return null
        })}
      </ScrollView>
    )
  }

  return (
    <ScrollView
      style={[
        styles.mobileNavigationbar,
        hide ? { left: '-100%' } : { left: 0 },
      ]}
      contentContainerStyle={{ alignItems: 'stretch' }}
    >
      <IconButton
        style={{
          alignSelf: 'flex-end',
          padding: '16px',
        }}
        onClick={props.toggleBars}
      >
        <CloseIcon htmlColor="white" />
      </IconButton>
      {navigation.map(nav => {
        if (
          nav.noLogin ||
          (isParent && nav.forParent) ||
          (isStudent && nav.forStudent)
        ) {
          return (
            <Button
              style={{
                paddingTop: '25px',
                paddingBottom: '25px',
                color: 'white',
                backgroundColor:
                  selected === nav.title
                    ? solveColors.blue1
                    : solveColors.blue3,
                borderTop: '1px solid white',
              }}
              onClick={() => execute(nav.title)}
            >
              {nav.title}
            </Button>
          )
        }
        return null
      })}
    </ScrollView>
  )
}

export default withRouter(NavigationBar)
