import React, { Component } from 'react'

import './VideoModal.css'
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'
import { withApollo } from 'react-apollo'
import AnimatedModal from './AnimatedModal'
import { CREATE_STUDENT } from '../../services/queries/add_student_queries'
import { withCookies } from 'react-cookie'
import { getUsername } from '../../utils/username'
import { VERIFY_STUDENT_USERNAME } from '../../services/queries'

class AddStudentPopup extends Component {
  state = {
    visible: false,
    loading: false,
    errorMsg: '',
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.visible !== nextProps.visible) {
      return { visible: nextProps.visible }
    }
    return null
  }
  handleChange = event => {
    const { name, value } = event.target
    const birthdate = new Date(value)
    const ageDifMs = Date.now() - birthdate.getTime()
    const ageDate = new Date(ageDifMs) // miliseconds from epoch
    this.setState(
      name === 'birthdate'
        ? {
            [name]: value,
            over13: Math.abs(ageDate.getUTCFullYear() - 1970) >= 13,
          }
        : {
            [name]: name === 'phoneNumber' ? value.replace(/\D/g, '') : value,
          }
    )
  }

  handleSubmit = async () => {
    const { gender, birthdate, grade, firstName, lastName, school } = this.state
    if (!firstName || !lastName || !birthdate || !grade || !gender || !school) {
      this.setState({ errorMsg: 'Please enter all required fields' })
      return
    }

    if (
      birthdate &&
      (!birthdate._isValid ||
        birthdate._pf.parsedDateParts[0] < 1900 ||
        birthdate._pf.parsedDateParts[0] > new Date().getFullYear() + 1)
    ) {
      this.setState({ errorMsg: 'Please enter a valid birthdate' })
    }
    this.setState({ loading: true, errorMsg: '' })

    const password = 'student'
    let validUsername = false
    let username
    while (!validUsername) {
      username = getUsername(firstName, lastName)
      const response = await this.props.client.query({
        query: VERIFY_STUDENT_USERNAME,
        variables: {
          filter: {
            username: username,
          },
        },
      })
      validUsername = !response.data.countStudents
    }

    const input = {
      firstName,
      lastName,
      username,
      password,
      grade,
      school,
      gender,
      birthdate: new Date(birthdate).toISOString(),
      familyAccountId: this.props.cookies.get('familyAccountId'),
    }

    let response
    try {
      response = await this.props.client.mutate({
        mutation: CREATE_STUDENT,
        variables: { input },
      })
    } catch (e) {
      this.setState({
        loading: false,
        errorMsg: e.graphQLErrors[0].message,
      })
      return
    }
    this.setState({ loading: false })
    const { data } = response
    if (data.createStudent.id) {
      this.props.onSuccess()
    } else {
      this.setState({ errorMsg: 'Unsuccessful, please try again later' })
    }
  }

  render() {
    const {
      loading,
      gender,
      birthdate,
      grade,
      firstName,
      lastName,
      school,
      errorMsg,
    } = this.state

    if (!this.state.visible) {
      return null
    }

    return (
      <AnimatedModal
        visible={this.props.visible}
        onClose={this.props.onClose}
        title="Add a student"
      >
        <div style={{ color: 'red' }}>{errorMsg}</div>
        <form
          onSubmit={e => {
            e.preventDefault()
            this.handleSubmit()
          }}
        >
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-around',
            }}
          >
            <div style={styles.columnStyle}>
              <TextField
                label="First Name"
                disabled={loading}
                name="firstName"
                value={firstName || ''}
                onChange={this.handleChange}
                required
                className="create-student-input"
              />
              <br />
              <FormControl component="fieldset">
                <FormLabel component="legend">Gender</FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="gender"
                  value={gender || ''}
                  required
                  onChange={this.handleChange}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <FormControlLabel
                    value="MALE"
                    control={<Radio color="primary" />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="FEMALE"
                    control={<Radio color="secondary" />}
                    label="Female"
                  />
                </RadioGroup>
              </FormControl>
              <br />
              <FormControl>
                <FormLabel>Birthdate</FormLabel>
                <TextField
                  id="date"
                  type="date"
                  name="birthdate"
                  value={birthdate || ''}
                  onChange={this.handleChange}
                  required
                />
              </FormControl>
            </div>
            <div style={styles.columnStyle}>
              <TextField
                label="Last Name"
                disabled={loading}
                name="lastName"
                value={lastName || ''}
                onChange={this.handleChange}
                required
                className="create-student-input"
              />
              <br />
              <TextField
                id="grade"
                name="grade"
                label="Grade"
                select
                value={grade || ''}
                onChange={this.handleChange}
                required
                disabled={loading}
              >
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(option => (
                  <MenuItem key={option} value={option}>
                    {option === 0 ? 'K' : option.toString()}
                  </MenuItem>
                ))}
              </TextField>
              <br />
              <TextField
                label="School"
                disabled={loading}
                name="school"
                value={school || ''}
                required
                onChange={this.handleChange}
              />
            </div>
          </div>
          <br />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              color="secondary"
              variant="contained"
              onClick={this.props.onClose}
              style={{ marginRight: '16px' }}
            >
              Cancel
            </Button>

            <Button type="submit" color="primary" variant="contained">
              Submit
            </Button>
          </div>
        </form>
      </AnimatedModal>
    )
  }
}

export default withApollo(withCookies(AddStudentPopup))

const styles = {
  columnStyle: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
    minWidth: '195px',
    paddingLeft: '32px',
    paddingRight: '32px',
  },
}
