import React, { useState, useRef, useCallback, useEffect } from 'react'
import solveColors from './colors'
import { animated, useTransition } from 'react-spring'

const BirthdayMessage = props => {
  const name = props.firstname
  const ref = useRef([])
  const [items, set] = useState([])
  const transitions = useTransition(items, null, {
    from: {
      opacity: 0,
      height: 0,
      width: '100%',
      innerHeight: 0,
      transform: 'perspective(600px) rotateX(0deg)',
      color: solveColors.red1,
      alignItems: 'center',
      overflow: 'hidden',
      fontFamily: 'arial',
      fontSize: '2em',
      fontWeight: 800,
      textTransform: 'uppercase',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    enter: [
      { opacity: 1, height: 80, innerHeight: 80 },
      {
        transform: 'perspective(600px) rotateX(180deg)',
        color: solveColors.blueGreen,
      },
      { transform: 'perspective(600px) rotateX(0deg)' },
    ],
    leave: [
      { color: solveColors.green },
      { innerHeight: 0 },
      { opacity: 0, height: 0 },
    ],
    update: { color: solveColors.blue1 },
  })

  const restartAnimation = useCallback(() => {
    ref.current.map(clearTimeout)
    ref.current = []
    set([])
    ref.current.push(setTimeout(() => set(['Happy', 'Birthday', 'From']), 2000))
    ref.current.push(setTimeout(() => set(['Ardent', 'Academy']), 5000))
    ref.current.push(
      setTimeout(() => set(['Happy', 'Birthday', name + '!']), 8000)
    )
  }, [name])
  useEffect(() => void restartAnimation(), [restartAnimation])

  return (
    <div>
      {transitions.map(({ item, props: { innerHeight, ...rest }, key }) => (
        <animated.div key={key} style={rest} onClick={restartAnimation}>
          <animated.div style={rest}>{item}</animated.div>
        </animated.div>
      ))}
    </div>
  )
}

export default BirthdayMessage
