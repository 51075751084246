import gql from 'graphql-tag'

export const CREATE_NEWS = gql`
  mutation createNews(
    $classroomId: String!
    $subject: String!
    $message: String!
    $announceDateTime: DateTime!
    $announcerId: String!
    $headline: Boolean!
  ) {
    createNews(
      input: {
        classroomId: $classroomId
        headline: $headline
        subject: $subject
        message: $message
        announceDateTime: $announceDateTime
        announcerId: $announcerId
      }
    ) {
      id
    }
  }
`
export const UPDATE_NEWS = gql`
  mutation updateNews(
    $newsId: ID!
    $classroomId: String!
    $subject: String!
    $message: String!
    $announceDateTime: DateTime!
    $announcerId: String!
    $headline: Boolean!
  ) {
    updateNews(
      id: $newsId
      input: {
        classroomId: $classroomId
        headline: $headline
        subject: $subject
        message: $message
        announceDateTime: $announceDateTime
        announcerId: $announcerId
      }
    ) {
      id
    }
  }
`

export const ARCHIVE_NEWS = gql`
  mutation archiveNews($newsId: ID!) {
    archiveNews(id: $newsId) {
      archivedOn
    }
  }
`
export const GET_CLASSROOM_LESSONS = gql`
  query GetClassroomLessons($id: ID!) {
    classroom(id: $id) {
      lessons(sort: { lessonPlan: { order: 1 } }) {
        id
        lessonPlan {
          title
          order
        }
      }
    }
  }
`
