import React from 'react'
import { Text, TouchableOpacity, View } from 'react-native-web'
import styles from './styles'
import solveColors from './../../components/colors'
import { CircularProgress } from '@material-ui/core'
import { useCookies } from 'react-cookie'

export const Button = props => {
  const { status, progress, disabled, onPress, allowAccess } = props
  const [cookies] = useCookies([])
  const employee = cookies['instructor'] === '1' || cookies['admin'] === '1'
  const getText = () => {
    if (employee || allowAccess) {
      return 'Enter'
    }
    if (isEnrolled(status)) {
      return 'Start'
      // return progress.completion === 0
      //   ? 'Start'
      //   : `${progress.completion}% Resume`
    }
    if (isCompleted(status)) return 'Transcript'
  }

  const isEnrolled = () => status === 'OPENED' || status === 'PUBLISHED'
  const isCompleted = () => status === 'CLOSED'
  const getStyle = () => {
    if (disabled) return buttonStyle.lockedButton
    if (isEnrolled(status) || allowAccess) return buttonStyle.activeButton
    if (isCompleted(status)) return buttonStyle.transcriptButton
    return {
      background: `linear-gradient(to right, #07c1eb 50%, ${solveColors.blue2} 90%)`,
      position: 'absolute',
      bottom: 25,
    }
  }
  return (
    <TouchableOpacity
      style={[styles.button, getStyle()]}
      onPress={onPress}
      disabled={disabled}
    >
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        {!employee && status === 'OPENED' && progress.completion > 0 && (
          <View
            style={{
              background: 'white',
              borderRadius: 50,
              alignItems: 'center',
              justifyContent: 'center',
              width: 20,
              marginRight: 10,
            }}
          >
            <CircularProgress
              variant="static"
              style={{ color: solveColors.blue2 }}
              size={20}
              thickness={10}
              value={0}
              // value={progress.completion}
            />
          </View>
        )}
        <Text style={styles.buttonText}>{getText(props.status)}</Text>
      </View>
    </TouchableOpacity>
  )
}

const buttonStyle = {
  transcriptButton: {
    background: 'linear-gradient(to right,  #f5a700 50%, #ff6a00 90%)',
  },
  activeButton: {
    background: `linear-gradient(to right, ${solveColors.green2} 50%, ${solveColors.green} 90%)`,
  },
  previewButton: {
    background: 'linear-gradient(to right,  #f5a700 50%, #ff6a00 90%)',
  },
}

export default Button
