import gql from 'graphql-tag'

export const GET_ASSIGNMENT = gql`
  query GetAssignment($id: ID!) {
    assignment(id: $id) {
      problemSet {
        id
        kind
        order
        title
        feedbackType
        allowedAttempts
        timeLimit
        allowSkip
        randomizeOrder
        level
        autoGrading
        problems(sort: { order: 1 }) {
          hints(sort: { order: 1 }) {
            order
            text
            image
            video
          }
          approximate
          order
          id
          points
          caseSensitive
          problemType
          questionText
          questionImageUrl
          questionVideo
          solutionText
          solutionImageUrl
          solutionVideo
          answer
          typesetMath
          randomizeChoices
          choices(sort: { order: 1 }) {
            order
            text
            correct
          }
        }
        lessonPlan {
          order
          title
          lessonPlanType
        }
      }
    }
  }
`

export const CREATE_GRADED_PROBLEM_RESPONSE = gql`
  mutation CreateProblemResponse(
    $submissionId: String!
    $problemId: String!
    $value: String!
    $timeSpent: Int!
    $sum: Int!
  ) {
    createProblemResponse(
      input: {
        submissionId: $submissionId
        problemId: $problemId
        value: $value
        timeSpent: $timeSpent
        sum: $sum
      }
    ) {
      id
    }
  }
`

export const CREATE_UNGRADED_PROBLEM_RESPONSE = gql`
  mutation CreateProblemResponse(
    $submissionId: String!
    $problemId: String!
    $value: String!
    $timeSpent: Int!
  ) {
    createProblemResponse(
      input: {
        submissionId: $submissionId
        problemId: $problemId
        value: $value
        timeSpent: $timeSpent
      }
    ) {
      id
    }
  }
`

export const UPDATE_RESPONSE_ATTACHMENT = gql`
  mutation UpdateResponseAttachment(
    $responseId: ID!
    $attachmentKey: String
    $attachmentUrl: String
  ) {
    updateProblemResponse(
      id: $responseId
      input: { attachment: $attachmentKey, attachmentUrl: $attachmentUrl }
    ) {
      id
    }
  }
`

export const UPDATE_SUBMISSION = gql`
  mutation UpdateSubmissionDateCountSum(
    $submissionId: ID!
    $date: Date!
    $count: Int
    $sum: Int
    $graded: Boolean
    $attemptNumber: Int!
  ) {
    updateSubmission(
      id: $submissionId
      input: {
        submittedAt: $date
        count: $count
        sum: $sum
        graded: $graded
        attemptNumber: $attemptNumber
      }
    ) {
      id
      count
      sum
    }
  }
`
export const UPDATE_SUBMISSION_ATTEMPTS = gql`
  mutation UpdateSubmissionDateCountSum(
    $submissionId: ID!
    $attemptNumber: Int!
  ) {
    updateSubmission(
      id: $submissionId
      input: { attemptNumber: $attemptNumber }
    ) {
      id
      count
      sum
    }
  }
`

export const GET_SUBMISSION_ASSIGNMENT = gql`
  query GetSubmissionAssignment($submissionId: ID!) {
    submission(id: $submissionId) {
      assignment {
        id
      }
    }
  }
`

export const GET_SUBMISSION_RESPONSES = gql`
  query GetSubmissionResponses($submissionId: ID!) {
    submission(id: $submissionId) {
      graded
      responses {
        id
        count
        sum
        value
        timeSpent
        attachment
        problem {
          order
        }
        scratchpadUrl
        graded
        comment
      }
    }
  }
`

export const GET_SIGNEDURL = gql`
  mutation GetSignedUrl($filename: String!, $contentType: String!) {
    s3Sign(filename: $filename, contentType: $contentType) {
      success
      signedUrl
      fileKey
      publicUrl
    }
  }
`

export const DELETE_ATTACHMENT = gql`
  mutation DeleteAttachment($fileKey: String!) {
    s3Delete(fileKey: $fileKey) {
      success
    }
  }
`
export const ARCHIVE_SUBMISSION = gql`
  mutation ArchiveSubmission($submissionId: ID!) {
    archiveSubmission(id: $submissionId) {
      id
    }
  }
`
export const UPDATE_RESPONSE_VALUE = gql`
  mutation UpdateResponseValue($responseId: ID!, $value: String!) {
    updateProblemResponse(id: $responseId, input: { value: $value }) {
      id
    }
  }
`

export const UPDATE_RESPONSE_VALUE_GRADED = gql`
  mutation UpdateResponseValue($responseId: ID!, $value: String!, $sum: Int!) {
    updateProblemResponse(
      id: $responseId
      input: { value: $value, sum: $sum }
    ) {
      id
      value
      sum
    }
  }
`

export const UPDATE_RESPONSE_SCRATCHPAD = gql`
  mutation UpdateResponseScratchpad($responseId: ID!, $scratchpadUrl: String) {
    updateProblemResponse(
      id: $responseId
      input: { scratchpadUrl: $scratchpadUrl }
    ) {
      id
    }
  }
`

export const DELETE_SCRATCHPAD = gql`
  mutation DeleteScratchpad($fileKey: String!) {
    s3Delete(fileKey: $fileKey) {
      success
    }
  }
`

export const CREATE_ISSUE = gql`
  mutation CreateIssue(
    $assignmentId: String!
    $problemId: String!
    $studentId: String!
    $description: String!
    $issueType: IssueType
    $dateAssigned: DateTime
  ) {
    createIssue(
      input: {
        assignmentId: $assignmentId
        problemId: $problemId
        studentId: $studentId
        description: $description
        issueType: $issueType
        issueStatus: INITIATED
        dateAssigned: $dateAssigned
      }
    ) {
      id
    }
  }
`
export const CREATE_ATTENDANCE_ISSUE = gql`
  mutation CreateAttendanceIssue(
    $attendeeId: String!
    $studentId: String!
    $description: String!
    $issueType: IssueType
    $dateAssigned: DateTime
  ) {
    createIssue(
      input: {
        attendeeId: $attendeeId
        studentId: $studentId
        description: $description
        issueType: $issueType
        issueStatus: INITIATED
        dateAssigned: $dateAssigned
      }
    ) {
      id
    }
  }
`
export const CREATE_HINT_ISSUE = gql`
  mutation CreateIssue(
    $assignmentId: String!
    $problemId: String!
    $studentId: String!
    $description: String!
    $dateAssigned: DateTime
    $dateResolved: DateTime
    $response: String!
    $responsibleEmployeeId: String!
  ) {
    createIssue(
      input: {
        assignmentId: $assignmentId
        problemId: $problemId
        studentId: $studentId
        description: $description
        issueType: NEED_HELP
        issueStatus: RESOLVED
        dateAssigned: $dateAssigned
        dateResolved: $dateResolved
        response: $response
        responsibleEmployeeId: $responsibleEmployeeId
      }
    ) {
      id
    }
  }
`

export const CREATE_MESSAGE_ISSUE = gql`
  mutation CreateIssue(
    $classroomId: String!
    $responsibleEmployeeId: String!
    $studentId: String!
    $description: String!
    $dateAssigned: DateTime
  ) {
    createIssue(
      input: {
        studentId: $studentId
        responsibleEmployeeId: $responsibleEmployeeId
        classroomId: $classroomId
        description: $description
        issueType: MESSAGE
        issueStatus: INITIATED
        dateAssigned: $dateAssigned
      }
    ) {
      id
    }
  }
`

export const PRINT_QUERY = gql`
  query PrintQuery($problemSetId: ID!) {
    problemSet(id: $problemSetId) {
      title
      lessonPlan {
        title
      }
      problems(sort: { order: 1 }) {
        questionImageUrl
        questionText
        problemType
        choices {
          text
        }
      }
    }
  }
`

export const GET_ISSUES_FOR_PROBLEM = gql`
  query GetProblemIssues($filter: Filters, $sort: Sorts) {
    issues(filter: $filter, sort: $sort) {
      id
      student {
        id
      }
      problem {
        id
      }
      issueStatus
      resolution
      response
    }
  }
`

export const UPDATE_ISSUES_FOR_PROBLEM = gql`
  mutation UpdateIssue($id: ID!, $input: UpdateIssueInput!) {
    updateIssue(id: $id, input: $input) {
      id
    }
  }
`

export const CREATE_HINT = gql`
  mutation createHint($input: CreateHintInput!) {
    createHint(input: $input) {
      id
    }
  }
`
