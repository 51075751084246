import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import { Query, withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import {
  ARCHIVE_ITEM,
  GET_INVOICE_AND_CREDITS,
} from '../../../services/queries/payment_queries'
import LoadingPage from '../Loading/LoadingPage'
import CartForm from './CartForm'
import Modal from 'modal-react-native-web'
import PaymentPage from '../Payment/PaymentPage'
import PaymentSuccess from '../Payment/PaymentSuccess'
import { Button } from '@material-ui/core'
import { withCookies } from 'react-cookie'

class CartPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      invoice: null,
      credits: null,
      orderTotal: 0,
      openModal: false,
      confirmedPayment: false,
    }
  }

  componentDidMount() {
    if (!this.props.cookies.get('familyAccountId')) {
      this.props.history.push('/family')
    }
  }

  archiveItem = async id => {
    await this.props.client.mutate({
      mutation: ARCHIVE_ITEM,
      variables: { id },
    })
  }

  removeSelectedCredit = id => {
    const { credits } = this.state
    if (credits !== null) {
      const updatedCredits = credits.filter(credit => credit.id !== id)
      this.setState({ credits: updatedCredits })
    }
  }

  updateOrderTotal = (invoice, credits) => {
    const appliedCreditBalance = credits.reduce((a, b) => a + b.balance, 0)
    let orderTotal = invoice.price
    if (appliedCreditBalance >= invoice.price) {
      orderTotal = 0
    } else {
      orderTotal -= appliedCreditBalance
    }
    this.setState({
      orderTotal: orderTotal,
    })
  }

  handleConfirmPayment = () => {
    this.setState({ confirmedPayment: true })
  }

  render() {
    return (
      <div style={{ backgroundColor: 'white', display: 'flex', flexGrow: 1 }}>
        <Query
          query={GET_INVOICE_AND_CREDITS}
          variables={{
            filter: {
              familyAccountId: this.props.cookies.get('familyAccountId'),
              $or: [{ status: 'PENDING' }, { status: 'OPEN' }],
            },
            creditFilter: {
              familyAccountId: this.props.cookies.get('familyAccountId'),
              status: 'OPEN',
            },
          }}
          fetchPolicy="cache-and-network"
        >
          {({ loading, data, refetch }) => {
            if (loading) {
              return <LoadingPage />
            }
            const { invoices, credits } = data
            if (invoices.length !== 1) {
              return <div>Cart empty</div>
            }

            if (this.state.credits === null) {
              this.updateOrderTotal(invoices[0], credits)
              this.setState({ credits: credits })
            }

            const { orderTotal } = this.state
            return (
              <>
                <Modal visible={this.state.openModal} transparent={true}>
                  <View style={styles.backdrop}>
                    {this.state.confirmedPayment ? (
                      <View style={styles.success}>
                        <PaymentSuccess orderTotal={orderTotal} />
                      </View>
                    ) : (
                      <View style={styles.results}>
                        <PaymentPage
                          credits={credits}
                          handleConfirmPayment={this.handleConfirmPayment}
                        />
                        <Button
                          onClick={() => this.setState({ openModal: false })}
                          color="primary"
                          variant="contained"
                          style={{ alignSelf: 'flex-end', margin: '15px' }}
                        >
                          Close
                        </Button>
                      </View>
                    )}
                  </View>
                </Modal>
                <CartForm
                  invoice={invoices[0]}
                  credits={credits}
                  orderTotal={orderTotal}
                  updateOrderTotal={this.updateOrderTotal}
                  archiveItem={this.archiveItem}
                  removeSelectedCredit={this.removeSelectedCredit}
                  refetch={refetch}
                  openModal={() => {
                    if (false) this.props.history.push('/purchase')
                    else {
                      this.setState({ openModal: true })
                    }
                  }}
                />
              </>
            )
          }}
        </Query>
      </div>
    )
  }
}

const styles = StyleSheet.create({
  results: {
    borderRadius: '10px',
    margin: 'auto',
    backgroundColor: 'white',
    maxWidth: '600px',
    maxHeight: '800px',
    flex: 1,
    paddingTop: 25,
  },
  success: {
    borderRadius: '10px',
    margin: 'auto',
    backgroundColor: 'white',
    maxWidth: '600px',
    maxHeight: '180px',
    flex: 1,
  },
  backdrop: {
    backgroundColor: 'rgba(52, 52, 52, 0.2)',
    height: '100%',
    width: '100%',
  },
  button: {
    width: 100,
    height: 40,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default withRouter(withApollo(withCookies(CartPage)))
