import { StyleSheet } from 'react-native-web'
import { solveColors } from '../../components/colors'

export const styles = StyleSheet.create({
  bold: {
    fontWeight: 'bold',
  },

  green: {
    backgroundColor: solveColors.green,
  },

  white: {
    backgroundColor: 'white',
  },

  defaultButton: {
    borderRadius: 10,
    paddingVertical: '10px',
    paddingHorizontal: '30px',
    marginVertical: '5px',
    width: 'calc(5% + 110px)',
    marginHorizontal: '20px',
  },

  buttonText: {
    color: 'white',
    textAlign: 'center',
  },

  left: {
    fontWeight: 'bold',
    paddingTop: 30,
    paddingBottom: 5,
  },

  right: {
    paddingTop: 30,
    paddingBottom: 5,
  },

  icon: {
    width: 120,
    height: 120,
    borderRadius: 60,
    marginVertical: 30,
  },

  name: {
    fontSize: 'vh',
    paddingLeft: '15px',
  },

  pwButton: {
    borderRadius: 10,
    paddingVertical: '10px',
    width: '200px',
    height: '50%',
    marginTop: 'auto',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomStyle: 'solid',
    borderColor: solveColors.grey3,
    borderBottomWidth: '1px',
    paddingBottom: 20,
  },
  split: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '2%',
  },
  table: {
    width: '50%',
    marginVertical: '3%',
  },
  view: {
    alignItems: 'center',
  },
  editButtons: {
    margin: 10,
    flexDirection: 'row',
    width: '100%',
  },
  editProfileView: {
    backgroundColor: 'white',
    minWidth: 'calc(500px + 5%)',
    alignItems: 'center',
    borderRadius: '5px',
    padding: '20px',
  },
  resetButton: {
    marginLeft: '20px',
  },
  saveButton: {
    marginLeft: 'auto',
  },
  SelectableMenu: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  fieldRow: {
    flexDirection: 'row',
    width: '100%',
    minWidth: 'calc(500px + 5%)',
    justifyContent: 'space-between',
  },
  fieldText: {
    fontSize: 11,
    color: 'dimgrey',
    marginBottom: 10,
    alignSelf: 'flex-start',
  },
  inputs: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  subtitle: {
    fontSize: '14px',
    marginBottom: '20px',
  },
  header: {
    alignItems: 'center',
    borderBottomWidth: '1px',
    borderColor: solveColors.grey1,
    marginBottom: '10px',
    width: '100%',
  },
})

export default styles
