import {
  AUTH_NET_API_URL,
  AUTH_NET_API_NAME,
  AUTH_NET_TRANSACT_KEY,
} from '../../../config'

export async function createCustomerPaymentProfile(
  customerProfileId,
  billingInfo,
  cardInfo
) {
  const data = {
    createCustomerPaymentProfileRequest: {
      merchantAuthentication: {
        name: AUTH_NET_API_NAME,
        transactionKey: AUTH_NET_TRANSACT_KEY,
      },
      customerProfileId: customerProfileId,
      paymentProfile: {
        billTo: billingInfo,
        payment: {
          creditCard: cardInfo,
        },
        defaultPaymentProfile: false,
      },
      validationMode: 'testMode',
    },
  }

  return await fetch(AUTH_NET_API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    cache: 'no-cache',
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  })
    .then(response => response.json())
    .then(data => {
      return data.messages
    })
    .catch(() => {
      return {
        messages: {
          resultCode: 'Error',
          message: [
            {
              code: '111111',
              text: 'Unsuccessful.',
            },
          ],
        },
      }
    })
}

export async function getCustomerPaymentProfiles(customerProfileId) {
  const data = {
    getCustomerProfileRequest: {
      merchantAuthentication: {
        name: AUTH_NET_API_NAME,
        transactionKey: AUTH_NET_TRANSACT_KEY,
      },
      customerProfileId: customerProfileId,
    },
  }

  return await fetch(AUTH_NET_API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    cache: 'no-cache',
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(() => {
      return {
        messages: {
          resultCode: 'Error',
          message: [
            {
              code: '111111',
              text: 'Unsuccessful.',
            },
          ],
        },
      }
    })
}

export async function chargeCustomerProfile(
  customerProfileId,
  paymentProfileId,
  amount,
  orderDetails,
  customerId
) {
  const data = {
    createTransactionRequest: {
      merchantAuthentication: {
        name: AUTH_NET_API_NAME,
        transactionKey: AUTH_NET_TRANSACT_KEY,
      },
      transactionRequest: {
        transactionType: 'authCaptureTransaction',
        amount: amount,
        profile: {
          customerProfileId: customerProfileId,
          paymentProfile: { paymentProfileId: paymentProfileId },
        },
        order: orderDetails,
        customer: {
          id: customerId,
        },
        authorizationIndicatorType: {
          authorizationIndicator: 'final',
        },
      },
    },
  }

  return await fetch(AUTH_NET_API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    cache: 'no-cache',
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(() => {
      return {
        messages: {
          resultCode: 'Error',
          message: [
            {
              code: '111111',
              text: 'Unsuccessful.',
            },
          ],
        },
      }
    })
}

export async function getTransaction(id) {
  const data = {
    getTransactionDetailsRequest: {
      merchantAuthentication: {
        name: AUTH_NET_API_NAME,
        transactionKey: AUTH_NET_TRANSACT_KEY,
      },
      transId: id,
    },
  }

  return await fetch(AUTH_NET_API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    cache: 'no-cache',
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(() => {
      return {
        messages: {
          resultCode: 'Error',
          message: [
            {
              code: '111111',
              text: 'Unsuccessful.',
            },
          ],
        },
      }
    })
}

export async function doRefund(invNUm, id, fourDigCC, amount) {
  const data = {
    createTransactionRequest: {
      merchantAuthentication: {
        name: AUTH_NET_API_NAME,
        transactionKey: AUTH_NET_TRANSACT_KEY,
      },
      transactionRequest: {
        transactionType: 'refundTransaction',
        amount: amount,
        payment: {
          creditCard: {
            cardNumber: fourDigCC,
            expirationDate: 'XXXX',
          },
        },
        refTransId: id,
        order: {
          invoiceNumber: invNUm,
        },
      },
    },
  }

  return await fetch(AUTH_NET_API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    cache: 'no-cache',
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(() => {
      return {
        messages: {
          resultCode: 'Error',
          message: [
            {
              code: '111111',
              text: 'Unsuccessful.',
            },
          ],
        },
      }
    })
}
