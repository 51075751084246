import { FlatList, Dimensions } from 'react-native-web'
import { View } from 'react-native'
import React, { useState, useEffect, useCallback } from 'react'
import { PromoCard } from '../../components'
import {
  ENROLL_STUDENT,
  GET_STUDENTS,
  CHECK_ENROLLMENTS,
} from '../../../services/queries'

import _ from 'lodash'
import { withApollo } from 'react-apollo'
import { useCookies } from 'react-cookie'
import jwt from 'jsonwebtoken'

const PromoForm = props => {
  const [cookies] = useCookies([])
  const { promoClassrooms, openVideo, client } = props
  const [width, setWidth] = useState(Dimensions.get('window').width)
  const [data, setData] = useState([])
  const token = cookies['token'] && jwt.decode(cookies['token'])
  const familyAccountId = cookies['familyAccountId']

  const isStudent = token.userType === 'Student'
  const isFamily = token.userType === 'FamilyMember'

  const handleResize = useCallback(
    () => setWidth(Dimensions.get('window').width),
    []
  )

  const enrollStudent = _.throttle(async input => {
    try {
      const { data } = await client.mutate({
        mutation: ENROLL_STUDENT,
        variables: {
          input: input,
        },
      })
      if (data.createEnrollment.id) return true
      return false
    } catch (e) {
      alert('This class is full!')
      return false
    }
  }, 500)

  useEffect(() => {
    const getData = async () => {
      let tempData = []
      let tempStudents
      let studentsFromFamily
      if (isFamily) {
        const { data: familyData } = await client.query({
          query: GET_STUDENTS,
          variables: {
            id: familyAccountId,
          },
          skip: !isFamily,
        })
        studentsFromFamily = familyData.familyAccount.students
      } else {
        studentsFromFamily = [{ id: token.id }]
      }
      for (const i in promoClassrooms.classrooms) {
        const classroom = promoClassrooms.classrooms[i]
        const orMap = studentsFromFamily.map(student => {
          return {
            studentId: student.id,
            classroomId: classroom.id,
          }
        })
        const { data: enData } = await client.query({
          query: CHECK_ENROLLMENTS,
          variables: {
            filter: {
              $or: orMap,
            },
          },
        })
        const invalidStudents = new Set(
          enData.enrollments.map(en => en.student.id)
        )
        tempStudents = studentsFromFamily.map(student => {
          return {
            ...student,
            enrolled: invalidStudents.has(student.id),
          }
        })
        tempData.push({
          classroom: classroom,
          students: tempStudents,
        })
      }
      setData(tempData)
    }
    getData()
    window.addEventListener('resize', _.debounce(handleResize, 100))
  }, [
    client,
    familyAccountId,
    handleResize,
    isFamily,
    promoClassrooms.classrooms,
    token.id,
  ])

  return (
    <View>
      {data && (
        <FlatList
          data={data}
          numColumns={
            Math.floor(width / 400) >= 1 ? Math.floor(width / 400) : 1
          }
          key={Math.floor(width / 400)}
          listKey={'promo-classrooms'}
          keyExtractor={item => item.classroom.id}
          renderItem={({ item }) => {
            const { students, classroom } = item
            const {
              course,
              id,
              tuitionCents,
              startTime,
              endTime,
              startDate,
              endDate,
              meets,
              videoId,
            } = classroom
            const { imageUrl, startGrade, endGrade } = course
            return (
              <PromoCard
                videoId={videoId}
                openVideo={videoId => {
                  openVideo(videoId)
                }}
                onEnroll={enrollStudent}
                isFamily={isFamily}
                isStudent={isStudent}
                students={students}
                classroomId={id}
                imageUrl={imageUrl}
                title={course.title}
                tuitionCents={tuitionCents}
                startTime={startTime}
                endTime={endTime}
                startDate={startDate}
                endDate={endDate}
                hasPreview={false}
                classroomDays={meets}
                startGrade={startGrade}
                endGrade={endGrade}
              />
            )
          }}
        />
      )}
    </View>
  )
}

export default withApollo(PromoForm)
