import React from 'react'
import { Text, View, TextInput, StyleSheet } from 'react-native'
import solveColors from './../components/colors'

const TextField = props => {
  const { name, field, onChange, focus, setFocus, keyboardType, style } = props
  return (
    <View style={style || styles.textField}>
      <Text style={styles.fieldText}>{name}</Text>
      <TextInput
        onFocus={() => {
          if (setFocus) setFocus(name)
        }}
        keyboardType={keyboardType}
        autoFocus={focus === name}
        style={styles.textInput}
        value={field}
        onChangeText={onChange}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  textField: {
    margin: 10,
  },
  fieldText: {
    fontSize: 11,
    color: 'dimgrey',
    marginBottom: 10,
    alignSelf: 'flex-start',
  },
  textInput: {
    borderWidth: '1px',
    borderColor: solveColors.grey1,
    borderRadius: 5,
    fontSize: 16,
    padding: 5,
    width: '100%',
  },
})

export default TextField
