//login popup for homepage, for parent and student login

import React, { Component } from 'react'
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native'
import Modal from 'modal-react-native-web'
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core'
import solveColors from '../../components/colors'
import CloseIcon from '@material-ui/icons/Close'
import StudentEmployeeLoginForm from './StudentEmployeeLoginForm'
import ParentForm from '../Register/ParentForm'
import { withRouter } from 'react-router-dom'
import FamilyLoginForm from './FamilyLoginForm'
import { isMobile } from 'react-device-detect'
import {
  GET_FAMILY_STUDENTS,
  GET_RECENT_PAID_INVOICE,
} from '../../../services/queries/login_queries'
import { withApollo } from 'react-apollo'

class CombinedLoginPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loginMode: props.defaultMode
        ? props.defaultMode
        : isMobile
        ? 'parent'
        : 'studentAndEmployee',
      fromCheckout: props.fromCheckout,
    }
  }

  //Handler for register family form
  onJoinSuccessHandler = () => {
    this.props.history.push(
      this.props.redirectTo ? this.props.redirectTo : '/posts'
    )
  }

  onSuccessHandler = async res => {
    if (this.state.loginMode === 'parent') {
      const { data } = await this.props.client.query({
        query: GET_FAMILY_STUDENTS,
        variables: { id: res.familyAccountId },
      })
      if (data.familyAccount.students.length === 0) {
        this.props.history.push(
          this.props.redirectTo ? this.props.redirectTo : '/posts'
        )
        return
      } else {
        let monthAgo = new Date()
        monthAgo.setMonth(monthAgo.getMonth() - 1)
        const { data } = await this.props.client.query({
          query: GET_RECENT_PAID_INVOICE,
          variables: {
            filter: {
              familyAccountId: res.familyAccountId,
              status: 'PAID',
              issueDate: { $gtDate: monthAgo.toISOString() },
            },
          },
        })
        if (data.invoices.length !== 0) {
          this.props.history.push(
            this.props.redirectTo ? this.props.redirectTo : '/posts'
          )
          return
        }
      }
    }

    this.props.history.push(
      this.props.redirectTo ? this.props.redirectTo : '/dashboard'
    )
  }

  displayForm = () => {
    switch (this.state.loginMode) {
      case 'parent':
        return <FamilyLoginForm onSuccess={this.onSuccessHandler} />
      case 'studentAndEmployee':
        return (
          <StudentEmployeeLoginForm
            onSuccess={(userType, gradeUpdated) => {
              if (userType === 'Student' && !gradeUpdated) {
                sessionStorage.setItem('showStudentEdit', true)
                this.props.history.push({
                  pathname: '/profile',
                })
              } else {
                this.props.history.push({ pathname: '/dashboard' })
              }
            }}
          />
        )
      case 'registerFamily':
        return <ParentForm onSuccess={this.onJoinSuccessHandler} />
      default:
        return <></>
    }
  }

  render() {
    if (!this.props.visible) {
      return null
    }
    return (
      <Modal
        visible={this.props.visible}
        transparent={true}
        animationType="fade"
        appElement={
          document.getElementById('app') || document.getElementById('root')
        }
        // onShow={() => this.textInput.current.focus()}
      >
        <View style={styles.backdrop}></View>
        <View style={styles.results}>
          <View style={styles.resultTopBar}>
            <Text style={{ color: 'white' }}>
              {this.props.fromCheckout ? 'Log in/Join' : 'Log in'}
            </Text>
            <TouchableOpacity
              onPress={this.props.close}
              style={styles.exitButton}
            >
              <CloseIcon htmlColor="white" />
            </TouchableOpacity>
          </View>
          <View style={styles.loginContainer}>
            <FormControl color="primary">
              <RadioGroup
                row
                aria-label="login"
                name="login"
                value={this.state.loginMode}
                onChange={e => this.setState({ loginMode: e.target.value })}
              >
                <FormControlLabel
                  value="parent"
                  control={<Radio />}
                  label="Parent"
                />
                {this.props.fromCheckout ? (
                  <FormControlLabel
                    value="registerFamily"
                    control={<Radio />}
                    label="Register Family"
                  />
                ) : (
                  <FormControlLabel
                    value="studentAndEmployee"
                    control={<Radio />}
                    label="Student/Instructor"
                  />
                )}
              </RadioGroup>
            </FormControl>
            {this.displayForm()}
          </View>
        </View>
      </Modal>
    )
  }
}

const styles = StyleSheet.create({
  results: {
    borderRadius: '10px',
    minWidth: 220,
    margin: 'auto',
    backgroundColor: 'white',
    flexDirection: 'column',
  },
  resultTopBar: {
    height: '32px',
    backgroundColor: solveColors.blue1,
    borderTopRightRadius: '10px',
    borderTopLeftRadius: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px',
  },
  backdrop: {
    position: 'absolute',
    backgroundColor: 'rgba(52, 52, 52, 0.2)',
    height: '100%',
    width: '100%',
  },
  loginBtn: {
    width: 100,
    height: 40,
    color: 'white',
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: solveColors.blue5,
  },

  loginContainer: {
    margin: '16px',
  },

  exitButton: {
    padding: '5px',
  },
})

export default withRouter(withApollo(CombinedLoginPage))
