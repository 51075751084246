import { FlatList, Text, View } from 'react-native-web'
import Card from 'react-bootstrap/Card'
import React, { useState } from 'react'
import Button from './Button'
import { withRouter } from 'react-router-dom'
import { formatDate, getMonth, getYear } from './../../../utils/dateTime'
import styles from './styles'
import solveColors from '../../components/colors'

export const InstructorForm = props => {
  const { history, windowWidth, filteredInstructors, filter } = props
  const [yearFilter] = useState(null)
  const [monthFilter] = useState(null)
  let allInstructors = []
  const years = new Set(
    filteredInstructors
      .map(e => getYear(e.classroom.endDate))
      .sort()
      .reverse()
  )
  if (filter === 'OPENED') {
    const openInstructors = filteredInstructors
      .filter(item => item.classroom.status === 'OPENED')
      .sort((a, b) =>
        a.classroom.code.charAt(0) < b.classroom.code.charAt(0)
          ? -1
          : a.classroom.code.charAt(0) > b.classroom.code.charAt(0)
          ? 1
          : new Date(b.classroom.endDate).getTime() -
            new Date(a.classroom.endDate).getTime()
      )
    if (openInstructors.length > 0) {
      allInstructors.push('OPENED CLASSES:')
      allInstructors.push(openInstructors)
    }

    const publishedInstructors = filteredInstructors
      .filter(item => item.classroom.status === 'PUBLISHED')
      .sort((a, b) =>
        a.classroom.code.charAt(0) < b.classroom.code.charAt(0)
          ? -1
          : a.classroom.code.charAt(0) > b.classroom.code.charAt(0)
          ? 1
          : new Date(b.classroom.endDate).getTime() -
            new Date(a.classroom.endDate).getTime()
      )
    if (publishedInstructors.length > 0) {
      allInstructors.push('PUBLISHED CLASSES:')
      allInstructors.push(publishedInstructors)
    }
  } else {
    let sortedDateInstructors = filteredInstructors.concat()
    if (monthFilter !== null && monthFilter !== undefined) {
      sortedDateInstructors = sortedDateInstructors.filter(
        e => new Date(e.classroom.endDate).getMonth() === monthFilter
      )
    }
    if (yearFilter) {
      sortedDateInstructors = sortedDateInstructors.filter(
        e =>
          new Date(e.classroom.endDate).getYear() + 1900 ===
          parseInt(yearFilter)
      )
    }
    for (const year of years) {
      const yearInstructors = sortedDateInstructors.filter(
        r => getYear(r.classroom.startDate) === year
      )
      for (let m = 11; m >= 0; m--) {
        let monthInstructors = yearInstructors
          .filter(r => new Date(r.classroom.endDate).getMonth() === m)
          .sort((a, b) =>
            a.classroom.code.charAt(0) < b.classroom.code.charAt(0)
              ? -1
              : a.classroom.code.charAt(0) > b.classroom.code.charAt(0)
              ? 1
              : new Date(b.classroom.endDate).getTime() -
                new Date(a.classroom.endDate).getTime()
          )

        if (monthInstructors.length > 0) {
          const { endDate } = monthInstructors[0].classroom
          allInstructors.push(`${getMonth(endDate)} ${year}`)
          allInstructors.push(monthInstructors)
        }
      }
    }
  }

  return (
    <React.Fragment>
      {/*filter === 'CLOSED' && (
        <View
          style={{
            alignSelf: 'flex-start',
            marginLeft: '10vw',
            flexDirection: 'row',
            marginVertical: 10,
          }}
        >
          <View
            style={[
              {
                background: `linear-gradient(to right, #07c1eb 50%, ${solveColors.blue2} 90%)`,
                alignSelf: 'center',
                marginRight: 10,
                marginTop: 25,
              },

              styles.sortButton,
            ]}
          >
            <Text style={{ fontSize: 18, color: 'white' }}>Filter:</Text>
          </View>

          <DropDownMenu
            name={'Year'}
            width={50}
            field={null}
            options={[null, ...Array.from(years)]}
            onChange={year => {
              setYearFitler(year)
            }}
          />
          <DropDownMenu
            name={'Months'}
            width={50}
            field={null}
            options={[null, ...moment.months()]}
            onChange={month => {
              setMonthFilter(
                !month
                  ? null
                  : moment()
                      .month(month)
                      .format('M') - 1
              )
            }}
          />
        </View>
      )*/}

      <FlatList
        data={allInstructors}
        keyExtractor={(item, index) => index}
        renderItem={({ item }) => {
          if (typeof item === 'string') {
            return (
              <View
                style={[
                  {
                    background: `linear-gradient(to right, #07c1eb 50%, ${solveColors.blue2} 90%)`,
                    minWidth: (Math.floor(windowWidth / 350) - 1) * 350,
                  },
                  styles.sortButton,
                ]}
              >
                <Text style={{ fontSize: 20, color: 'white' }}>{item}</Text>
              </View>
            )
          } else {
            return (
              <FlatList
                numColumns={
                  Math.floor(windowWidth / 350) >= 1
                    ? Math.floor(windowWidth / 350)
                    : 1
                }
                initialNumToRender={item.length}
                data={item}
                key={Math.floor(windowWidth / 350)}
                keyExtractor={item => item.classroom.id}
                renderItem={({ item }) => {
                  const { classroom } = item
                  const {
                    startDate,
                    endDate,
                    course,
                    status,
                    title,
                    teachingStyle,
                    id: classroomId,
                    title: classroomTitle,
                  } = classroom
                  const classroomStartDate = formatDate(startDate)
                  const classroomEndDate = formatDate(endDate)

                  return (
                    <Card
                      title="CLASSROOM CARD"
                      style={{
                        backgroundColor: 'white',
                        margin: 10,
                        width: 250,
                        boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.25)',
                      }}
                    >
                      <Card.Img style={{ width: 250 }} src={course.imageUrl} />
                      <Card.Body
                        style={{
                          padding: 10,
                          display: 'flex',
                          flexDirection: 'column',
                          paddingBottom: 100,
                        }}
                      >
                        <Card.Title
                          style={{
                            fontWeight: 'bold',
                            fontSize: '16px',
                            paddingBottom: 10,
                          }}
                        >
                          {title.split(':')[1].trim()}
                        </Card.Title>
                        <Text style={{ alignSelf: 'center' }}>ONLINE</Text>
                        {/*
                <Text>{`${classroomDays}: ${classroomStartTime} - ${classroomEndTime}`}</Text>
              */}
                        <Text>{`${classroomStartDate} - ${classroomEndDate}`}</Text>
                        <View
                          style={{
                            position: 'absolute',
                            bottom: 20,
                            width: 230,
                          }}
                        >
                          <Button
                            status={status}
                            onPress={() => {
                              sessionStorage.setItem('classroomId', classroomId)
                              sessionStorage.setItem(
                                'classroomTitle',
                                classroomTitle.split(':')[1].trim()
                              )
                              history.push({
                                pathname: '/lessons',
                                search: `classroomId=${classroomId}`,
                              })
                              if (filter === 'OPENED') {
                                sessionStorage.removeItem('teachingSyle')
                                sessionStorage.setItem(
                                  'teachingStyle',
                                  teachingStyle
                                )
                              }
                            }}
                          />
                        </View>
                      </Card.Body>
                    </Card>
                  )
                }}
              />
            )
          }
        }}
      />
    </React.Fragment>
  )
}

export default withRouter(InstructorForm)
