import React, { useState } from 'react'

import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  InputAdornment,
  Button,
  TextField,
  InputLabel,
  Input,
} from '@material-ui/core'

import {
  CREATE_FAMILY_ACCOUNT,
  CREATE_FAMILY_MEMBER,
  COUNT_FAMILY_MEMBERS,
} from '../../../services/queries/register_queries'
import _ from 'lodash'
import { View, Text } from 'react-native-web'
import { withApollo } from 'react-apollo'
import { useCookies } from 'react-cookie'
import {
  FAMILY_LOGIN,
  FAMILY_VERIFY,
} from '../../../services/queries/login_queries'

const ParentForm = props => {
  const [, setCookie] = useCookies([''])
  const { parentData } = props
  const [firstName, setFirstName] = useState(
    parentData ? parentData.firstName : ''
  )
  const [lastName, setLastName] = useState(
    parentData ? parentData.lastName : ''
  )
  const [relationship, setRelationship] = useState(
    parentData ? parentData.relationship : ''
  )
  const [phone, setPhone] = useState(parentData ? parentData.phoneNumber : '')
  const [email, setEmail] = useState(parentData ? parentData.email : '')
  const [error, setError] = useState('')
  const [code, setCode] = useState('')
  const [successMsg] = useState('')
  const [loading, setLoading] = useState(false)
  const [getCodeText, setGetCodeText] = useState('GET CODE')

  const onSubmit = async () => {
    if (getCodeText === 'SEND AGAIN') {
      sendVerification()
      return
    }

    const { client } = props
    if (!(firstName && lastName && relationship && phone && email)) {
      setError('Please enter all required fields.')
      return
    }

    setError('')
    setLoading(true)
    try {
      sessionStorage.setItem(
        'tempToken',
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImIwMDFkMGRlLWE1NzktNDUzNS1hYjNlLWFjN2ViZTE2NTQ4NCIsInVzZXJUeXBlIjoiRW1wbG95ZWUiLCJ2ZXJzaW9uIjoiVXNlciB2MS4wIiwiaWF0IjoxNjE0MzcxMjc5LCJleHAiOjE2MTQ5NzYwNzl9.GUQxGUyKzIoDloVtQQIVOnx6Rnu_d6ZhAqyjSlwr6fo'
      )

      const { data: phoneChk } = await client.query({
        query: COUNT_FAMILY_MEMBERS,
        variables: {
          filter: {
            phoneNumber: phone,
          },
        },
        fetchPolicy: 'no-cache',
      })

      const { data: emailChk } = await client.query({
        query: COUNT_FAMILY_MEMBERS,
        variables: {
          filter: {
            email,
          },
        },
        fetchPolicy: 'no-cache',
      })

      if (phoneChk.countFamilyMembers) {
        setError('Phone number already in use')
        setLoading(false)
        sessionStorage.clear()

        // Object.keys(cookies).forEach(k => removeCookie(k))
        console.log('phone number in use')
        return
      }
      if (emailChk.countFamilyMembers) {
        setError('Email already in use')
        setLoading(false)
        sessionStorage.clear()
        // Object.keys(cookies).forEach(k => removeCookie(k))
        return
      }

      const familyAcc = await client.mutate({
        mutation: CREATE_FAMILY_ACCOUNT,
        variables: { input: { status: 'PROSPECT' } },
      })
      const famAccId = familyAcc.data.createFamilyAccount.id

      await client.mutate({
        mutation: CREATE_FAMILY_MEMBER,
        variables: {
          input: {
            firstName: firstName,
            lastName: lastName,
            relation: relationship,
            phoneNumber: phone,
            email,
            familyAccountId: famAccId,
            lastLogin: new Date(),
          },
        },
      })
    } catch (e) {
      setError('Error occurred, please try again later')
      console.log(e)
      sessionStorage.clear()
      // Object.keys(cookies).forEach(k => removeCookie(k))
      setLoading(false)
      return
    }

    sessionStorage.clear()
    // Object.keys(cookies).forEach(k => removeCookie(k))

    sendVerification()
  }

  const sendVerification = async () => {
    const { client } = props
    setLoading(true)
    sessionStorage.clear()

    const { data } = await client.mutate({
      mutation: FAMILY_LOGIN,
      variables: {
        phone: phone,
        callInstead: false,
      },
    })

    if (data && data.familyLogin.verificationCodeSent) {
      setGetCodeText('SEND AGAIN')
      setError('')
    } else {
      setError(data.familyLogin.message)
    }
    setLoading(false)
  }

  const throttledFamilyVerify = _.throttle(async () => {
    const { client } = props
    if (!code || !phone) {
      setError('Please enter phone number and code')
      return
    }
    setLoading(true)
    setError('')
    const { data } = await client.mutate({
      mutation: FAMILY_VERIFY,
      variables: {
        phone: phone,
        code: code,
      },
    })

    if (data && data.familyVerify.success) {
      const { firstName, lastName, imageUrl } = data.familyVerify.familyMember

      setCookie('token', data.familyVerify.token, { maxAge: 86400 })
      setCookie('firstName', firstName, { maxAge: 86400 })
      setCookie('lastName', lastName, { maxAge: 86400 })
      setCookie('imageUrl', imageUrl, { maxAge: 86400 })
      setCookie('family', 1, { maxAge: 86400 })
      setCookie('familyAccountId', data.familyVerify.familyAccountId, {
        maxAge: 86400,
      })
      setLoading(false)
      props.onSuccess()
    } else {
      setLoading(false)
      setError(data.familyVerify.message)
    }
  }, 3000)

  return (
    <React.Fragment>
      <form
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
        }}
        onSubmit={e => e.preventDefault()}
      >
        <div style={{ color: 'red' }}>{error}</div>
        <div style={{ color: 'green' }}>{successMsg}</div>

        <Text style={{ fontWeight: 'bold', fontSize: 20 }}>
          Parent Information
        </Text>
        <br />

        <View>
          <TextField
            value={firstName}
            label={'First Name'}
            required
            onChange={v => {
              setFirstName(v.target.value)
            }}
            fullWidth
            disabled={loading}
          />
          <br />
          <TextField
            value={lastName}
            label={'Last Name'}
            required
            onChange={v => {
              setLastName(v.target.value)
            }}
            fullWidth
            disabled={loading}
          />
          <br />
          <TextField
            value={email}
            label={'Email'}
            required
            onChange={v => {
              setEmail(v.target.value)
            }}
            fullWidth
            disabled={loading}
          />
          <br />

          <FormControl color="primary" required>
            <RadioGroup
              row
              aria-label="login"
              name="login"
              value={relationship}
              onChange={e => setRelationship(e.target.value)}
              disabled={loading}
            >
              <FormControlLabel
                value="Mother"
                control={<Radio size="small" />}
                label="Mother"
              />
              <FormControlLabel
                value="Father"
                control={<Radio size="small" />}
                label="Father"
              />
              <FormControlLabel
                value="Guardian"
                control={<Radio size="small" />}
                label="Guardian"
              />
            </RadioGroup>
          </FormControl>
          <br />
          <TextField
            label="Phone Number"
            value={phone}
            required
            onChange={v => {
              setPhone(v.target.value)
            }}
            autoComplete="tel-national"
            disabled={loading}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">+1</InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end" onClick={onSubmit}>
                  <Button>{getCodeText}</Button>
                </InputAdornment>
              ),
            }}
          />
          <FormControl required>
            <InputLabel htmlFor="standard-adornment-password">Code</InputLabel>
            <Input
              id="standard-adornment-password"
              value={code}
              onChange={e => setCode(e.target.value)}
              style={{ width: '50%' }}
              required
            />
          </FormControl>
        </View>
        <br />
        <Button
          color="primary"
          variant="contained"
          onClick={throttledFamilyVerify}
          disabled={loading}
          type="submit"
        >
          Join
        </Button>
      </form>
    </React.Fragment>
  )
}

export default withApollo(ParentForm)
