import { makeStyles } from '@material-ui/core'
import React from 'react'
import { Text, View } from 'react-native-web'
import solveColors from './colors'
import { Query } from 'react-apollo'
import jwt from 'jsonwebtoken'
import { GET_EARNED_BADGES } from '../../services/queries/badge_queries'
import { isMobile } from '../../config'
import { useCookies } from 'react-cookie'

const topstudent =
  'https://ardent-shared-media.s3-us-west-2.amazonaws.com/system/topstudent.png'
const useStyle = makeStyles({
  container: {
    backgroundColor: solveColors.turquoise,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px',
  },
})

function getMedal(ranking) {
  const topstudentgold =
    'https://ardent-shared-media.s3-us-west-2.amazonaws.com/system/topstudentgold.png'
  const topstudentsilver =
    'https://ardent-shared-media.s3-us-west-2.amazonaws.com/system/topstudentsilver.png'
  const topstudentbronze =
    'https://ardent-shared-media.s3-us-west-2.amazonaws.com/system/topstudentbronze.png'
  const topstudenthonor =
    'https://ardent-shared-media.s3-us-west-2.amazonaws.com/system/topstudenthonor.png'
  const topstudent =
    'https://ardent-shared-media.s3-us-west-2.amazonaws.com/system/topstudent.png'
  switch (ranking) {
    case 'HONOR':
      return topstudenthonor
    case 'BRONZE':
      return topstudentbronze
    case 'SILVER':
      return topstudentsilver
    case 'GOLD':
      return topstudentgold
    default:
      return topstudent
  }
}

export default function BadgeBanner(props) {
  const classes = useStyle()
  const [cookies] = useCookies([])
  let fromParent = null //indicates whethere this is rendered from the parent dashboard
  if (props.selectedStudent) {
    fromParent = props.selectedStudent
  }

  if (
    !cookies['token'] ||
    (jwt.decode(cookies['token']).userType !== 'Student' && !fromParent)
  ) {
    return null
  }

  return (
    <Query
      query={GET_EARNED_BADGES}
      variables={{
        filter: {
          studentId: fromParent || jwt.decode(cookies['token']).id,
          classroom: { quarter: 'Spring', year: 2021 },
        },
      }}
    >
      {({ data, loading, error }) => {
        if (loading || error) return null
        const { earnedBadges } = data
        return (
          <View>
            {earnedBadges.length === 0 && (
              <div className={classes.container} style={{ flexWrap: 'wrap' }}>
                <img src={topstudent} alt={'Badge'} height={70} />
                <div
                  style={{
                    padding: '8px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Text
                    style={{
                      color: 'white',
                      fontSize: '110%',
                    }}
                  >
                    How to Win a Top Student Badge?
                  </Text>
                  <Text
                    style={{
                      color: 'white',
                      textAlign: 'center',
                    }}
                  >
                    Complete all homework assignments and quiz by 5/31/2021 with
                    a class grade of at least 70%.
                  </Text>
                </div>
              </div>
            )}
            {earnedBadges.map(badgeInfo => {
              return (
                <div className={classes.container}>
                  <img
                    src={getMedal(badgeInfo.badge.ranking)}
                    alt={'Earned Badges'}
                    height={isMobile ? 80 : 120}
                  />
                  <Text
                    style={{
                      color: 'white',
                      fontSize: isMobile ? 'default' : '200%',
                    }}
                  >
                    {badgeInfo.tentative
                      ? `Congratulations! ${
                          fromParent ? badgeInfo.student.firstName : 'You'
                        } are eligible to receive the Top Student Award if you complete Lesson 10 quiz and homework by March 1, 2021.`
                      : `Congratulations! ${
                          fromParent ? badgeInfo.student.firstName : 'You'
                        } have won the Top Student award in ${
                          badgeInfo.classroom.title.split(':')[
                            badgeInfo.classroom.title.split(':').length > 1
                              ? 1
                              : 0
                          ]
                        }`}
                  </Text>
                </div>
              )
            })}
          </View>
        )
      }}
    </Query>
  )
}
