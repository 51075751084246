import React from 'react'
import { useTable } from 'react-table'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import solveColors from '../../components/colors'
import { convertToYMD } from '../../../utils/dateTime'
import { isMobile } from '../../../config'
import { withRouter } from 'react-router-dom'

function InvoiceTable(props) {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Invoice #',
        accessor: 'invoiceNumber',
        width: 20,
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 20,
      },

      {
        id: 'amount',
        Header: 'Amount',
        accessor: 'amount',
        width: 20,
        Cell: row => {
          return <span>{`$${row.row.original.price.toFixed(2)}`}</span>
        },
      },
      {
        id: 'date',
        Header: 'Paid Date',
        width: 20,
        Cell: row => {
          if (
            row.row.original.status === 'PENDING' ||
            row.row.original.status === 'OPEN'
          ) {
            return <div style={{ color: 'red' }}>{'UNPAID'}</div>
          }
          if (row.row.original.payments.length === 0) {
            return <div>{'N/A'}</div>
          }
          return (
            <div>
              {convertToYMD(
                new Date(row.row.original.payments[0].dateReceived)
              )}
            </div>
          )
        },
      },
      {
        id: 'classes',
        Header: 'Items',
        accessor: row => row.items.length,
        width: 20,
      },
      {
        Header: 'Notes',
        accessor: 'shortSummary',
      },
    ],
    []
  )

  const { getTableProps, headerGroups, prepareRow, rows } = useTable({
    columns,
    data: props.invoices,
    initialState: {
      hiddenColumns: isMobile ? ['shortSummary', 'classes', 'Invoices'] : [],
    },
  })

  return (
    <div
      style={{
        display: 'block',
        maxWidth: '100%',
        overflowX: 'hidden',
        overflowY: 'hidden',
        backgroundColor: 'white',
        height: '100%',
      }}
    >
      <Table {...getTableProps()}>
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => {
                return (
                  <TableCell
                    {...column.getHeaderProps()}
                    style={{
                      borderBottom: `solid 3px ${solveColors.blue1}`,
                      color: solveColors.blue1,
                      fontWeight: 'bold',
                      width: column.width,
                    }}
                  >
                    {column.render('Header')}
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <TableRow
                {...row.getRowProps()}
                onClick={() => {
                  if (
                    row.original.status === 'PENDING' ||
                    row.original.status === 'OPEN'
                  ) {
                    props.history.push('/cart')
                  }
                  props.setInvoiceId(row.original.id)
                }}
              >
                {row.cells.map(cell => {
                  return (
                    <TableCell {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </div>
  )
}

export default withRouter(InvoiceTable)
