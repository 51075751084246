import { View, StyleSheet, Text } from 'react-native-web'
import React, { Component } from 'react'
import ProblemSubmitButton from './ProblemSubmitButton'
import KeyboardEventHandler from 'react-keyboard-event-handler'
import draftToHtml from 'draftjs-to-html'
import { Latex } from '../../../components'

class DisplayProblemForm extends Component {
  componentDidMount() {
    // window.addEventListener('keypress', this.onEnter)
  }
  componentWillUnmount() {
    // window.removeEventListener('keypress', this.onEnter)
  }
  onEnter = async e => {
    if (this.props.scratchpadOpen) return
    const key = e.which || e.keyCode
    if (key === 13 && !this.props.isLoading) {
      this.handleProblemSubmit()
    }
  }
  handleProblemSubmit = async () => {
    this.props.onProblemSubmit('', '', true)
  }
  render() {
    const { problem, feedbackType, review, lastProb, isLoading } = this.props

    const { questionText, typesetMath } = problem
    let htmlDisplay = ''
    try {
      htmlDisplay = draftToHtml(JSON.parse(questionText))
    } catch (e) {
      console.error(e)
    }
    return (
      <View style={styles.problem}>
        <KeyboardEventHandler
          handleKeys={['enter']}
          handleFocusableElements={true}
          onKeyEvent={(key, e) => this.handleProblemSubmit()}
        />
        <View style={styles.displayOuter}>
          {typesetMath && <Latex latex={questionText} />}
          {!typesetMath && htmlDisplay && (
            <p
              dangerouslySetInnerHTML={{
                __html: htmlDisplay,
              }}
            />
          )}
          {!typesetMath && !htmlDisplay && (
            <Text style={{ marginVertical: 10 }}>{htmlDisplay}</Text>
          )}
        </View>
        <View style={styles.buttonBar}>
          <ProblemSubmitButton
            probState={1}
            isLoading={isLoading}
            handleProblemSubmit={this.handleProblemSubmit}
            feedbackType={feedbackType}
            review={review}
            lastProb={lastProb}
          />
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  answerText: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  row: {
    flexDirection: 'row',
    padding: 20,
    alignSelf: 'center',
  },
  displayOuter: {
    backgroundColor: 'white',
    marginBottom: 20,
    alignSelf: 'center',
    maxWidth: '864px',
  },
  question: {
    alignItems: 'center',
    userSelect: 'none',
    maxWidth: '40vw',
  },
  textinput: {
    borderColor: '#EDEDED',
    borderWidth: 1,
    width: 'calc(100px + 15vw)',
    borderRadius: 10,
    padding: 10,
  },
  buttonBar: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  problem: {
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '60vw',
  },
  questionText: {
    fontSize:
      sessionStorage.getItem('size') === 'large'
        ? 24
        : sessionStorage.getItem('size') === 'med'
        ? 20
        : 14,
  },
})

export default DisplayProblemForm
