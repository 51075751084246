import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import solveColors from './colors'
const styles = StyleSheet.create({
  banner: {
    width: '100%',
    height: 50,
    backgroundColor: '#EA4B36',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.9,
    position: 'fixed',
  },
})

const BannerMessage = props => {
  return (
    <View
      style={[
        styles.banner,
        {
          backgroundColor: props.success ? solveColors.green : solveColors.red1,
        },
      ]}
    >
      <Text style={{ color: 'white' }}>{props.msg}</Text>
    </View>
  )
}

export default BannerMessage
