import { StyleSheet, Text, TouchableOpacity } from 'react-native'
import React, { Component } from 'react'
import { solveColors } from '../../../components/colors'

class SkipButton extends Component {
  constructor(props) {
    super(props)
    this.state = { showSolution: props.showSolution }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.showSolution !== prevState.showSolution) {
      return { showSolution: nextProps.showSolution }
    }
    return null
  }
  render() {
    return (
      <TouchableOpacity
        style={[styles.solution_button]}
        onPress={this.props.toggleShowSolution}
      >
        <Text style={styles.buttonText}>
          {this.state.showSolution ? 'HIDE SOLUTION' : 'SHOW SOLUTION'}
        </Text>
      </TouchableOpacity>
    )
  }
}

const styles = StyleSheet.create({
  solution_button: {
    alignItems: 'center',
    paddingVertical: 10,
    borderRadius: 10,
    margin: 10,
    width: 160,
    backgroundColor: solveColors.blueGreen,
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
  },
})

export default SkipButton
