import gql from 'graphql-tag'

//queries
export const GET_REFERRALS = gql`
  query GetReferrals($filter: Filters, $sort: Sorts, $page: Pagination) {
    referrals(filter: $filter, sort: $sort, page: $page) {
      id
      refereeFirstName
      refereeLastName
      email
      status
    }
  }
`

export const VERIFY_FAMILY_ACCOUNT = gql`
  query VerifyFamilyAccount($filter: Filters) {
    countFamilyMembers(filter: $filter)
  }
`

export const VERIFY_STUDENT_USERNAME = gql`
  query VerifyStudentUsername($filter: Filters) {
    countStudents(filter: $filter)
  }
`

//mutations
export const CREATE_REFERRAL = gql`
  mutation CreateReferral($input: CreateReferralInput!) {
    createReferral(input: $input) {
      id
      refereeFirstName
      refereeLastName
      email
      status
    }
  }
`

export const UPDATE_REFERRAL = gql`
  mutation UpdateReferral($id: ID!, $input: UpdateReferralInput!) {
    updateReferral(id: $id, input: $input) {
      id
    }
  }
`

export const UPDATE_FAMILY_MEMBER = gql`
  mutation UpdateFamilyMember($id: ID!, $input: UpdateFamilyMemberInput!) {
    updateFamilyMember(id: $id, input: $input) {
      id
    }
  }
`
