import {
  StyleSheet,
  Text,
  TouchableOpacity,
  ScrollView,
  View,
  Image,
} from 'react-native'
import Modal from 'modal-react-native-web'
import React, { useRef } from 'react'
import ReactToPrint from 'react-to-print'
import solveColors from '../../components/colors'
import PrintPage from './PrintPage'
import ardent from '../../images/ardent-logo.png'
import logoText from '../../images/ardent-logo-text.webp'
import { Query } from 'react-apollo'
import { PRINT_QUERY } from './../../../services/queries'
import LoadingPage from './../Loading/LoadingPage'

const PrintModal = props => {
  const componentRef = useRef()
  const { problemSetId, visible } = props
  if (!visible) {
    return <View />
  }
  return (
    <Query query={PRINT_QUERY} variables={{ problemSetId }}>
      {({ error, loading, data }) => {
        if (loading) {
          return (
            <Modal visible={visible} transparent={true}>
              <ScrollView contentContainerStyle={styles.backdrop}>
                <ScrollView contentContainerStyle={styles.results}>
                  <LoadingPage />
                </ScrollView>
              </ScrollView>
            </Modal>
          )
        }
        if (error) {
          return error
        }

        const { lessonPlan, title, problems } = data.problemSet

        return (
          <Modal visible={visible} transparent={true}>
            <ScrollView contentContainerStyle={styles.backdrop}>
              <ScrollView contentContainerStyle={styles.results}>
                <View
                  style={{
                    flexDirection: 'row',
                    marginRight: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <View style={{ flexDirection: 'row' }}>
                    <Image
                      style={{
                        width: 70,
                        height: 100,
                        marginTop: -20,
                      }}
                      resizeMode="contain"
                      source={ardent}
                    />
                    <Image
                      source={logoText}
                      style={{
                        width: 210,
                        height: 40,
                        resizeMode: 'contain',
                        marginTop: 8,
                        marginLeft: 10,
                      }}
                    />
                  </View>
                  <View style={{ flexDirection: 'row' }}>
                    <ReactToPrint
                      trigger={() => (
                        <button
                          style={{
                            width: 50,
                            height: 30,
                            borderRadius: 10,
                            marginRight: 10,
                            backgroundColor: solveColors.blue5,
                            color: 'white',
                            borderWidth: 0,
                          }}
                        >
                          Print
                        </button>
                      )}
                      content={() => componentRef.current}
                    />
                    <TouchableOpacity
                      style={[
                        styles.button,
                        { backgroundColor: solveColors.blue5, bottom: 0 },
                      ]}
                      onPress={() => {
                        props.close()
                      }}
                    >
                      <Text style={{ color: 'white' }}>X</Text>
                    </TouchableOpacity>
                  </View>
                </View>
                <PrintPage
                  ref={componentRef}
                  problems={problems}
                  problemSetTitle={title}
                  lessonTitle={lessonPlan.title}
                />
              </ScrollView>
            </ScrollView>
          </Modal>
        )
      }}
    </Query>
  )
}

const styles = StyleSheet.create({
  backdrop: {
    backgroundColor: 'rgba(52, 52, 52, 0.2)',
    height: '100%',
    width: '100%',
  },
  results: {
    borderRadius: '10px',
    margin: 'auto',
    backgroundColor: 'white',
    padding: 25,
    width: '60%',
  },
  button: {
    width: 30,
    height: 30,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 20,
  },
  printbtn: {
    width: 50,
    height: 30,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
})

export default PrintModal
