import React, { useState, useEffect, useMemo, useCallback } from 'react'
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  TextInput,
} from 'react-native-web'
import ProblemSubmitButton from './ProblemSubmitButton'
import AssignmentSubmitButton from './AssignmentSubmitButton'
import SkipButton from './SkipButton'
import ScratchpadButton from './ScratchpadButton'
import { DialogModal, Latex } from '../../../components/index'
import SolutionButton from './SolutionButton'

const MultipleTextProblemForm = props => {
  const {
    probState,
    complete,
    allowSkip,
    incrementProblem,
    problem,
    feedbackType,
    review,
    lastProb,
    updateProblemAnswer,
    answered,
    onAssignmentSubmit,
    scratchpadUrl,
    currentProbNum,
    problemStates,
    instructor,
    showSolution,
    toggleShowSolution,
    problemSetLength,
    currentAnswer,
  } = props

  const { answer, solutionText, typesetMath } = problem
  const hasSolution = answer !== undefined && answer !== null

  const splitMultipleTextQuestion = useCallback(questionText => {
    return questionText.split(new RegExp('\\<(.*?)\\>', 'g'))
  }, [])

  const formatMultipleTextAnswer = useCallback(choices => {
    return Object.keys(choices)
      .sort(choice => parseInt(choice.order))
      .map(choiceOrder => {
        if (choices[choiceOrder].studentAnswer)
          return choices[choiceOrder].studentAnswer
        return ''
      })
      .join(',')
  }, [])

  const decodedQuestionText = useMemo(() => {
    return splitMultipleTextQuestion(props.problem.questionText)
  }, [props.problem.questionText, splitMultipleTextQuestion])

  const [studentAnswer, setStudentAnswer] = useState(currentAnswer)
  const [choices, setChoices] = useState(() => {
    let savedAnswer
    if (currentAnswer) {
      savedAnswer = currentAnswer.split(',')
    }
    return props.problem.choices.reduce(
      (rest, choice, index) => ({
        ...rest,
        [choice.order]: {
          ...choice,
          studentAnswer:
            savedAnswer && savedAnswer[index] ? savedAnswer[index] : '',
        },
      }),
      {}
    )
  })
  const [dlgModalOpen, setDlgModal] = useState(false)
  const [partial, setPartial] = useState(true)

  useEffect(() => {
    if (props.currentAnswer !== studentAnswer)
      setStudentAnswer(props.currentAnswer)
  }, [props.currentAnswer, studentAnswer])

  const openDialogModal = () => {
    setDlgModal(true)
  }
  const closeDialogModal = () => {
    setDlgModal(false)
  }

  const handleProblemSubmit = async (fromSkip = false) => {
    const { onProblemSubmit, problem, review, lastProb, gotoLessons } = props
    const { answer } = problem
    if (review && !lastProb) {
      onProblemSubmit(studentAnswer, answer)
      return
    }
    if (review && lastProb) {
      gotoLessons()
      return
    }
    onProblemSubmit(studentAnswer, answer, fromSkip)
  }

  const handleAssignmentSubmit = () => {
    if (answered) {
      setPartial(false)
    }
    openDialogModal()
  }

  return (
    <View style={styles.problem}>
      <View style={styles.question}>
        <View>
          <Text style={styles.instructions}>Fill in the blanks</Text>
          <View style={styles.questionText}>
            {decodedQuestionText.map(partialText => {
              const choiceOrder = parseInt(partialText)
              if (choices[choiceOrder]) {
                return (
                  <TextInput
                    style={styles.textInputs}
                    value={choices[choiceOrder].studentAnswer}
                    onChange={e => {
                      choices[choiceOrder].studentAnswer = e.target.value
                      const newStudentAnswer = formatMultipleTextAnswer(choices)
                      updateProblemAnswer(newStudentAnswer)
                      setChoices({ ...choices })
                    }}
                  />
                )
              } else {
                return <Text style={{ fontSize: 18 }}>{partialText}</Text>
              }
            })}
          </View>
        </View>

        <DialogModal
          visible={dlgModalOpen}
          transparent={true}
          onYes={async () => {
            if (studentAnswer) await handleProblemSubmit()
            onAssignmentSubmit()
            closeDialogModal()
          }}
          onNo={() => {
            closeDialogModal()
          }}
          message={
            partial
              ? `You have answered ${
                  problemStates.filter(p => p !== 0).length
                }/${problemSetLength} problems. Are you sure you want to submit the assignment for grading?`
              : 'Once submitted, you cannot change your answers. Are you sure you want to submit?'
          }
        />
      </View>
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        {scratchpadUrl && (
          <TouchableOpacity
            style={{ marginRight: '0.5vh' }}
            onPress={() => props.deleteScratchpadImg(currentProbNum)}
          >
            <Text>{'X'}</Text>
          </TouchableOpacity>
        )}
        <Text>{scratchpadUrl}</Text>
      </View>

      <View style={styles.buttonBar}>
        <ScratchpadButton toggleScratchpad={props.toggleScratchpad} />
        <ProblemSubmitButton
          probState={probState}
          handleProblemSubmit={handleProblemSubmit}
          complete={complete}
          feedbackType={feedbackType}
          review={review}
          lastProb={lastProb}
        />
        {allowSkip && !instructor && !review && (
          <SkipButton
            probState={probState}
            allowSkip={allowSkip}
            lastProb={lastProb}
            handleProblemSubmit={handleProblemSubmit}
            incrementProblem={incrementProblem}
          />
        )}
        {!review && !instructor && (
          <AssignmentSubmitButton
            handleAssignmentSubmit={handleAssignmentSubmit}
            locked={problemStates.filter(s => s !== 0).length === 0}
          />
        )}
        {!review && instructor && (
          <SolutionButton
            showSolution={showSolution}
            toggleShowSolution={toggleShowSolution}
          />
        )}
      </View>
      {(review || showSolution) && (
        <View style={styles.review}>
          <Text>Correct Answer:</Text>
          {typesetMath && hasSolution && <Latex latex={answer} />}
          {!typesetMath && hasSolution && <Text>{answer}</Text>}
          {!hasSolution && (
            <Text>Solution is not available for this question.</Text>
          )}
          {solutionText !== null &&
            solutionText !== undefined &&
            solutionText !== '' && (
              <View style={{ alignItems: 'center' }}>
                <Text>{'\n'}Solution:</Text>
                {typesetMath && <Latex latex={solutionText} />}
                {!typesetMath && <Text>{solutionText}</Text>}
              </View>
            )}
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  instructions: {
    padding: 10,
    alignSelf: 'center',
    fontSize: 18,
    marginBottom: 5,
  },
  question: {
    alignItems: 'center',
    userSelect: 'none',
    maxWidth: '40vw',
    marginBottom: 20,
  },
  questionText: {
    display: 'flex',
    flexDirection: 'row',
  },
  textInputs: {
    borderWidth: 2,
    borderColor: '#EDEDED',
    borderRadius: 5,
    marginHorizontal: 5,
    width: '120px',
    flex: 1,
    fontSize: 18,
  },
  image: {
    height: 200,
    width: 700,
    resizeMode: 'contain',
  },
  review: {
    alignItems: 'center',
  },
  buttonBar: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  problem: {
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '60vw',
  },
  repl: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 'calc(100vw - 300px)',
    height: 'calc(100vh - 250px)',
  },
  displayOuter: {
    backgroundColor: 'white',
    marginBottom: 20,
    alignSelf: 'center',
    minWidth: '512px',
    maxWidth: '864px',
  },
})

export default MultipleTextProblemForm
