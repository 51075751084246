import React from 'react'
import { withRouter } from 'react-router-dom'
import { GET_CLASSROOM_INFO } from './../../../services/queries'
import SyllabusForm from './SyllabusForm'
import { Query } from 'react-apollo'
import LoadingPage from './../Loading/LoadingPage'

const SyllabusPage = props => {
  const classroomId = props.location.search.split('=')[1]
  return (
    <Query variables={{ id: classroomId }} query={GET_CLASSROOM_INFO}>
      {({ data, loading, error }) => {
        if (error) return `${error}`
        if (loading) return <LoadingPage />

        const rawSplit = data.classroom.title.split(':')
        const title = rawSplit.length > 1 ? rawSplit[1].trim() : rawSplit[0]
        props.changeTitles(title)

        return <SyllabusForm title={title} classroom={data.classroom} />
      }}
    </Query>
  )
}

export default withRouter(SyllabusPage)
