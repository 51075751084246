import React from 'react'
import { Text } from 'react-native-web'
import solveColors from '../../components/colors'

//mui
import Divider from '@material-ui/core/Divider'
import Container from '@material-ui/core/Container'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Paper from '@material-ui/core/Paper'

const DEFAULT_GRADES = [
  'K',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
]

const AddStudentForm = React.memo(props => {
  const {
    classes,
    handleAddStudent,
    handleFormChange,
    error,
    studentState,
  } = props
  const { firstName, lastName, gender, birthDate, grade, school } = studentState

  return (
    <Paper className={classes.formContainer} elevation={4}>
      <Container className={classes.headerContainer}>
        <Text
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
            color: solveColors.blue3,
          }}
        >
          Add a Student
        </Text>
        {error && (
          <Text
            style={{
              marginLeft: 'auto',
              fontSize: '14px',
              color: solveColors.red1,
            }}
          >
            {error}
          </Text>
        )}
      </Container>
      <Divider orientation={'horizontal'} />
      <Container className={classes.fieldsContainer}>
        <TextField
          name={'firstName'}
          value={firstName}
          label={'First Name'}
          onChange={handleFormChange}
          InputLabelProps={{
            shrink: true,
          }}
          style={{ width: '44%' }}
        />
        <TextField
          name={'lastName'}
          value={lastName}
          label={'Last Name'}
          onChange={handleFormChange}
          style={{ width: '44%' }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Container>
      <Container className={classes.fieldsContainer}>
        <TextField
          name={'school'}
          value={school}
          label={'School'}
          onChange={handleFormChange}
          style={{ width: '78%' }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <FormControl style={{ width: '10%' }}>
          <InputLabel htmlFor={'student-grades-select'}>Grade</InputLabel>
          <Select
            id={'student-grades-select'}
            name={'grade'}
            value={grade}
            onChange={handleFormChange}
          >
            {DEFAULT_GRADES.map((grade, index) => {
              return (
                <MenuItem value={grade} key={index}>
                  {grade}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Container>
      <Container
        className={classes.fieldsContainer}
        style={{ marginBottom: '30px' }}
      >
        <TextField
          name={'birthDate'}
          label={'Birth Date'}
          type={'date'}
          value={`${birthDate.getFullYear()}-${
            birthDate.getMonth() + 1 < 10 ? '0' : ''
          }${birthDate.getMonth() + 1}-${
            birthDate.getDate() < 10 ? '0' : ''
          }${birthDate.getDate()}`}
          onChange={handleFormChange}
          InputLabelProps={{
            shrink: true,
          }}
          style={{ width: '44%' }}
        />
        <Container
          style={{
            display: 'flex',
            width: '44%',
            margin: 0,
            padding: 0,
          }}
        >
          <Text
            style={{
              width: '20%',
              fontSize: '13px',
              fontWeight: 400,
              lineHeight: 1,
              color: 'rgba(0, 0, 0, 0.54)',
            }}
          >
            Gender
          </Text>
          <FormControl component="fieldset" style={{ width: '80%' }}>
            <RadioGroup
              id={'student-grades-select'}
              name={'gender'}
              value={gender}
              onChange={handleFormChange}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <FormControlLabel
                value={'MALE'}
                control={<Radio color={'primary'} />}
                label={'Male'}
              />
              <FormControlLabel
                value={'FEMALE'}
                control={<Radio color={'secondary'} />}
                label={'Female'}
              />
            </RadioGroup>
          </FormControl>
        </Container>
      </Container>
      <Divider orientation={'horizontal'} />
      <Container className={classes.actionContainer}>
        <Button
          className={classes.button}
          variant={'contained'}
          color={'primary'}
          onClick={handleAddStudent}
        >
          Next
        </Button>
      </Container>
    </Paper>
  )
})

export default AddStudentForm
