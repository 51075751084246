import gql from 'graphql-tag'

export const GET_CLASSROOM_DETAILS = gql`
  query GetClassroomDetails($id: ID!, $studentFilter: Filters!) {
    classroom(id: $id) {
      title
      year
      quarter
      startDate
      endDate
      lessons(sort: { lessonPlan: { order: 1 } }) {
        id
        lessonPlan {
          title
          order
        }
        attendees(filter: $studentFilter) {
          lesson {
            id
          }
          overallGrade
        }
      }
      enrollments(filter: $studentFilter) {
        overallGrade
      }
    }
  }
`
