const problemStates = {
  unanswered: 0,
  correct: 1,
  incorrect: 2,
  partialCorrect: 3,
  notGraded: 4,
  gradedCorrect: 5,
  gradedIncorrect: 6,
}

export default problemStates
