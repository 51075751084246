import React from 'react'
import { Text, View, FlatList, ScrollView, Image } from 'react-native'
import { formatDate, formatTime } from './../../../utils/dateTime'
import { gradeLevel, instructorNames } from './../../../utils/gradeLevel'
import { StyleSheet, TouchableOpacity } from 'react-native-web'
import { solveColors } from '../../components/colors'
import draftToHtml from 'draftjs-to-html'

const Syllabus = props => {
  const { title, classroom } = props
  const {
    startDate,
    endDate,
    startTime,
    endTime,
    meets,
    course,
    lessons,
    instructors,
    quarter,
    teachingStyle,
  } = classroom
  const {
    description,
    longDescription,
    endGrade,
    startGrade,
    imageUrl,
  } = course

  const classroomDays = meets
  const classroomStartDate = formatDate(startDate)
  const classroomEndDate = formatDate(endDate)
  const classroomStartTime = formatTime(startTime, true)
  const classroomEndTime = formatTime(endTime, false)
  const instructorText = instructorNames(instructors)
  const grades = gradeLevel(startGrade, endGrade)
  const tutored = quarter === 'Tutoring'
  const mentored = teachingStyle === 'MENTORED'
  const instructorLabel =
    quarter === 'Contest' ? 'Contest Manager:' : 'Instructor:'
  const selfPaced = quarter === 'OnDemand'
  const htmlLongDescription = draftToHtml(JSON.parse(longDescription))
  const newTitle = title.slice(0, 5) + title.slice(7)
  return (
    <ScrollView style={styles.container}>
      <View style={styles.header}>
        <Image style={styles.headerImage} source={imageUrl} />
        <View style={styles.headerTextBox}>
          <Text style={styles.headerText}>Syllabus: {newTitle}</Text>
          {/* <Text style={styles.headerText}>{newTitle}</Text> */}
          <Text style={styles.headerSubText}>{description}</Text>
        </View>
      </View>
      <View style={styles.body}>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ fontWeight: 'bold' }}>Grades: </Text>
          <Text>{grades}</Text>
        </View>
        {!(tutored || selfPaced) && (
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ fontWeight: 'bold' }}>Duration: </Text>
            <Text>{`${classroomStartDate} - ${classroomEndDate}`}</Text>
          </View>
        )}
        {mentored && (
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ fontWeight: 'bold' }}>Number of Lessons: </Text>
            <Text>{`10`}</Text>
          </View>
        )}
        {tutored && !mentored && (
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ fontWeight: 'bold' }}>Duration: </Text>
            <Text>{`15 - 20 classes`}</Text>
          </View>
        )}
        {selfPaced && (
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ fontWeight: 'bold' }}>
              Estimated Completion Time:{' '}
            </Text>
            <Text>{`30 hours`}</Text>
          </View>
        )}
        {!(tutored || mentored || selfPaced) && (
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ fontWeight: 'bold' }}>Times: </Text>
            <Text>{`${classroomDays}: ${classroomStartTime} - ${classroomEndTime}`}</Text>
          </View>
        )}
        {mentored && (
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ fontWeight: 'bold' }}>Times per Lesson: </Text>
            <Text>{`90 minutes`}</Text>
          </View>
        )}
        {tutored && !mentored && (
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ fontWeight: 'bold' }}>Times: </Text>
            <Text>{`50 minutes per class`}</Text>
          </View>
        )}
        {!(tutored || selfPaced) && (
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ fontWeight: 'bold' }}>{instructorLabel} </Text>
            <Text>{instructorText}</Text>
          </View>
        )}
        {(mentored || selfPaced) && (
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ fontWeight: 'bold' }}>Video Lessons by: </Text>
            <Text>{instructorText}</Text>
          </View>
        )}
      </View>
      <p
        style={{
          marginVertical: 5,
          backgroundColor: solveColors.grey1,
          borderRadius: 10,
          padding: 10,
          width: '90%',
        }}
        dangerouslySetInnerHTML={{
          __html: htmlLongDescription,
        }}
      />
      <View style={styles.body}>
        <Text style={styles.lessonPlanHeader}>Lessons</Text>
        <FlatList
          data={lessons}
          keyExtractor={item => item.id}
          renderItem={({ item }) => {
            const lessonStartDate = formatDate(item.startOn)
            const { title, order } = item.lessonPlan
            return (
              <TouchableOpacity style={styles.lessonRow}>
                <Text style={styles.lessonTitle}>{`${order}. ${title}`}</Text>
                <div style={{ display: 'flex' }}>
                  {!(tutored || selfPaced) && (
                    <View style={styles.lessonDate}>
                      <Text>{lessonStartDate}</Text>
                    </View>
                  )}
                  {/* <View style={styles.lessonArrow}>
                    <KeyboardArrowDownIcon />
                  </View> */}
                </div>
              </TouchableOpacity>
            )
          }}
        />
      </View>
    </ScrollView>
  )
}

export const styles = StyleSheet.create({
  page: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  bar: {
    backgroundColor: 'white',
    width: '100%',
  },
  backButton: {
    flexDirection: 'row',
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 10,
  },
  backText: {
    fontSize: '15',
  },
  container: {
    borderRadius: 10,
    backgroundColor: 'white',
  },
  header: {
    paddingBottom: 20,
    borderBottomWidth: 1,
    borderColor: solveColors.grey1,
    marginBottom: 10,
    minHeight: 170,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
  },

  headerText: {
    fontSize: '1.5rem',
    color: solveColors.blue1,
    flexWrap: 'wrap',
  },
  headerTextBox: {
    display: 'flex',
    maxWidth: '100%',
    flexShrink: 1,
  },
  headerImage: {
    width: 180,
    height: 135,
    resizeMode: 'contain',
  },
  headerSubText: {
    fontSize: '1rem',
    padding: 5,
    flexWrap: 'wrap',
  },
  body: {
    width: '100%',
    paddingBottom: 20,
  },
  lessonPlanHeader: {
    fontWeight: 'bold',
    fontSize: 20,
    paddingBottom: 10,
  },
  lessonRow: {
    flexDirection: 'row',
    padding: 10,
    borderBottomWidth: 1,
    borderColor: solveColors.grey1,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  lessonTitle: {
    marginLeft: '3%',
    fontWeight: 'bold',
  },
  lessonDate: {
    fontWeight: 'bold',
    flexDirection: 'row',
  },
})

export default Syllabus
