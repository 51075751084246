import { FlatList, View, StyleSheet, Text } from 'react-native'
import React from 'react'
import { TouchableOpacity } from 'react-native-web'
import problemStates from './ProblemStates'

const getNavItemColor = (itemNum, currentProbNum, state, feedback, review) => {
  if (currentProbNum === itemNum) return styles.selected_item

  if (state === problemStates.partialCorrect) return styles.partialCorrect_item
  if (state === problemStates.gradedCorrect) return styles.correct_item
  if (state === problemStates.gradedIncorrect) return styles.incorrect_item

  if (
    !review &&
    sessionStorage.getItem('corrections') !== '1' &&
    (feedback === 'GRADED' || feedback === 'DELAYED')
  ) {
    if (state !== problemStates.unanswered) return styles.answered_item
    return styles.unanswered_item
  }
  if (state === problemStates.correct) return styles.correct_item
  if (state === problemStates.incorrect) return styles.incorrect_item
  if (state === problemStates.partialCorrect) return styles.partialCorrect_item
  if (state === problemStates.notGraded) return styles.answered_item
  if (currentProbNum === itemNum) return styles.selected_item
  return styles.unanswered_item
}

const getTextColor = (itemNum, currentProbNum, state) => {
  if (currentProbNum !== itemNum && state === problemStates.unanswered)
    return { color: 'black' }
  return { color: 'white' }
}

const ProblemNavBar = props => {
  return (
    <View>
      <View style={styles.problem_navbar}>
        <FlatList
          data={props.problems}
          extraData={props}
          keyExtractor={item => item.id}
          renderItem={({ index }) => (
            <TouchableOpacity
              style={[
                getNavItemColor(
                  props.currentProbNum,
                  index + 1,
                  props.problemStates[index],
                  props.feedbackType,
                  props.review
                ),
                styles.navbar_item,
              ]}
              onPress={() => {
                props.onSelectionChange(index)
              }}
            >
              <Text
                style={[
                  getTextColor(
                    props.currentProbNum,
                    index + 1,
                    props.problemStates[index]
                  ),
                  styles.itemText,
                ]}
              >
                {index + 1}
              </Text>
            </TouchableOpacity>
          )}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  problem_navbar: {
    borderColor: '#EDEDED',
    borderWidth: 1,
    borderRadius: 5,
    width: 50,
    marginLeft: 10,
    marginVertical: 20,
  },
  navbar_item: {
    borderRadius: 100,
    padding: 5,
    marginVertical: 5,
    marginHorizontal: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemText: {
    textAlign: 'center',
  },
  unanswered_item: {
    backgroundColor: '#EDEDED',
  },
  correct_item: {
    backgroundColor: '#20CE6D',
  },
  partialCorrect_item: {
    backgroundColor: '#BAFC03',
  },
  selected_item: {
    backgroundColor: '#F5A623',
  },
  incorrect_item: {
    backgroundColor: '#FF3B30',
  },
  answered_item: {
    backgroundColor: '#4A90E2',
  },
})

export default ProblemNavBar
