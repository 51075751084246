import solveColors from './../web/components/colors'

const numToLetterGrade = num => {
  if (num === -1) return 'In Progress'
  if (num >= 95) return 'A+'
  if (num >= 85) return 'A'
  if (num >= 75) return 'A-'
  if (num >= 74) return 'B+'
  if (num >= 67) return 'B'
  if (num >= 60) return 'B-'
  if (num >= 59) return 'C+'
  if (num >= 49.5) return 'C'
  if (num >= 40) return 'C-'
  if (num >= 39) return 'D+'
  if (num >= 29.5) return 'D'
  if (num >= 20) return 'D-'
  if (num > 0) return 'F'
  return 'N/A'
}

const getGradeColor = num => {
  if (num >= 75) return solveColors.green
  if (num >= 60) return solveColors.orange
  else return solveColors.yellow
}

export { numToLetterGrade, getGradeColor }
