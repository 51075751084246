import { Dimensions } from 'react-native-web'
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import InstructorForm from './InstructorForm'

export const DashboardForm = props => {
  const { filter, instructors } = props
  const [windowWidth, setWindowWidth] = useState(Dimensions.get('window').width)
  useEffect(() => {
    const getWidth = () => setWindowWidth(Dimensions.get('window').width)
    window.addEventListener('resize', getWidth)
    return () => {
      window.removeEventListener('resize', getWidth)
    }
  })

  let filteredInstructors = instructors.filter(item => {
    return filter === 'CLOSED'
      ? item.classroom.status === filter
      : item.classroom.status === 'OPENED' ||
          item.classroom.status === 'PUBLISHED'
  })

  return (
    <InstructorForm
      filter={filter}
      windowWidth={windowWidth}
      filteredInstructors={filteredInstructors}
    />
  )
}

export default withRouter(DashboardForm)
