import { StyleSheet, Text, View } from 'react-native'
import React, { Component } from 'react'
import PrintProblem from './PrintProblem'

class PrintPage extends Component {
  constructor(props) {
    super(props)
    this.problems = props.problems
    this.title = props.title
    this.lessonTitle = props.lessonTitle
  }

  render() {
    return (
      <View style={styles.page}>
        <Text style={styles.title}>
          {this.props.lessonTitle} : {this.props.problemSetTitle} {'\n'}
          {sessionStorage.getItem('classroomTitle')}
        </Text>
        <View style={styles.results}>
          {this.problems.map((problem, questionIndex) => {
            questionIndex += 1
            return (
              <PrintProblem
                questionIndex={questionIndex}
                questionImageUrl={problem.questionImageUrl}
                questionText={problem.questionText}
                problemType={problem.problemType}
                choices={problem.choices}
              />
            )
          })}
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  page: {
    marginVertical: '30px',
  },
  results: {
    borderRadius: '10px',
    marginTop: '40px',
    marginLeft: '40px',
    backgroundColor: 'white',
  },
  title: {
    fontSize: '25px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: '20px',
  },
  subtitle: {
    fontSize: '15px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
})

export default PrintPage
