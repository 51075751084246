import React from 'react'
import { Text, Image, View } from 'react-native'
import { withRouter } from 'react-router-dom'
import ProfileTable from './ProfileTable'
import Button from './Button'
import styles from './styles'
import { solveColors } from '../../components/colors'
import { version } from './../../../../package.json'
import { useCookies } from 'react-cookie'

const InstructorProfileForm = props => {
  const { history, familyAccount } = props
  const [cookies, , removeCookie] = useCookies([])
  const handleSignOut = props => {
    if (props.text === 'SIGN OUT') {
      sessionStorage.clear()
      Object.keys(cookies).forEach(k => removeCookie(k))
      history.push({ pathname: '/' })
    }
  }

  const profileInfo = [
    {
      left: 'Street',
      right: familyAccount.location ? familyAccount.location.street : 'N/A',
    },
    {
      left: 'City',
      right: familyAccount.location ? familyAccount.location.city : 'N/A',
    },
    {
      left: 'State',
      right: familyAccount.location ? familyAccount.location.state : 'N/A',
    },
  ]

  return (
    <View style={styles.view}>
      <Image style={styles.icon} source={props.imageUrl} />
      <Text style={[styles.name, styles.bold]}>
        {props.firstName} {props.lastName}
      </Text>
      {/* <Button
        buttonStyling={[styles.defaultButton, styles.green]}
        text="EDIT PROFILE"
        onPress={handlePress}
      /> */}
      <Button
        buttonStyling={[styles.defaultButton, styles.white]}
        textStyling={{ color: solveColors.grey3 }}
        text="SIGN OUT"
        onPress={handleSignOut}
      />
      <Text>Ardent SOLVE Version {version}</Text>
      <ProfileTable info={profileInfo} />
    </View>
  )
}

export default withRouter(InstructorProfileForm)
