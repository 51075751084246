import {
  Text,
  ScrollView,
  FlatList,
  TouchableOpacity,
  Image,
  StyleSheet,
  Dimensions,
} from 'react-native'
import React, { useEffect, useState } from 'react'
import { Query } from 'react-apollo'
import LoadingPage from './../Loading/LoadingPage'
import { GET_CLASSROOM_INSTRUCTORS } from './../../../services/queries'
import default_profile from './../../images/dummy_profile.png'
import solveColors from './../../components/colors'

const TeachersPage = props => {
  const [windowWidth, setWindowWidth] = useState(Dimensions.get('window').width)

  useEffect(() => {
    const getWidth = () => setWindowWidth(Dimensions.get('window').width)

    window.addEventListener('resize', getWidth)

    return () => window.removeEventListener('resize', getWidth)
  })
  return (
    <ScrollView
      style={{ margin: 20, paddingRight: 30 }}
      contentContainerStyle={{
        alignItems: 'center',
      }}
    >
      {/*
        <TouchableOpacity style={styles.message_button} disabled={true}>
          <Text style={{ color: 'white' }}>Message</Text>
        </TouchableOpacity>
      */}
      <Query
        query={GET_CLASSROOM_INSTRUCTORS}
        variables={{
          classroomId: sessionStorage.getItem('classroomId'),
        }}
      >
        {({ data, loading, error }) => {
          if (error) return `${error}`
          if (loading) return <LoadingPage />
          return (
            <FlatList
              data={data.classroom.instructors}
              keyExtractor={item => item.id}
              numColumns={
                Math.floor(windowWidth / 400) >= 1
                  ? Math.floor(windowWidth / 400)
                  : 1
              }
              key={Math.floor(windowWidth / 400)}
              renderItem={({ item }) => {
                const { firstName, lastName, imageUrl } = item.employee
                return (
                  <TouchableOpacity style={styles.profile_container}>
                    <Image
                      style={styles.profile_img}
                      resizeMode="cover"
                      source={imageUrl || default_profile}
                    />
                    <Text
                      style={styles.profile_name}
                    >{`${firstName} ${lastName}`}</Text>
                  </TouchableOpacity>
                )
              }}
            />
          )
        }}
      </Query>
    </ScrollView>
  )
}

export const styles = StyleSheet.create({
  profile_img: {
    width: 100,
    height: 100,
    borderRadius: 60,
    marginBottom: 10,
  },
  profile_container: {
    backgroundColor: 'white',
    borderRadius: 5,
    padding: 20,
    margin: 10,
    width: 300,
    alignItems: 'center',
    justifyContent: 'center',
    shadowColor: '#000',
    shadowOffset: { width: 5, height: 5 },
    shadowOpacity: 0.25,
    shadowRadius: 2,
  },
  profile_name: {
    fontSize: 14,
    alignSelf: 'center',
    flexWrap: 'wrap',
  },
  message_button: {
    shadowColor: '#000',
    shadowOffset: { width: 5, height: 5 },
    shadowOpacity: 0.25,
    shadowRadius: 2,
    backgroundColor: solveColors.blue4,
    borderRadius: 10,
    padding: 5,
    paddingHorizontal: 15,
    alignSelf: 'flex-end',
    marginRight: 100,
    marginVertical: 10,
  },
})

export default TeachersPage
