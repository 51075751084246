import gql from 'graphql-tag'

export const GET_ISSUE = gql`
  query GetIssue($filter: Filters!) {
    issues(filter: $filter, sort: { dateAssigned: -1 }) {
      id
      dateAssigned
      issueStatus
      isRead
      problem {
        questionText
        questionImageUrl
        order
      }
      assignment {
        classroom {
          id
          title
        }
        problemSet {
          title
          order
        }
        lesson {
          lessonPlan {
            title
            order
          }
        }
      }
      attendee {
        lesson {
          classroom {
            id
            title
          }
          lessonPlan {
            title
            order
          }
        }
      }
      issueType
      description
      response
    }
  }
`

export const GET_ISSUES = gql`
  query GetIssue($filter: Filters!) {
    issues(filter: $filter, sort: { dateAssigned: -1 }) {
      id
      dateAssigned
      issueStatus
      isRead
      issueType
      description
      response
      lapCode
      responsibleEmployee {
        id
        firstName
        lastName
      }
      student {
        id
        firstName
        lastName
      }
      classroom {
        id
        code
      }
      problem {
        questionText
        questionImageUrl
        order
      }
    }
  }
`

export const UPDATE_ISSUE = gql`
  mutation UPDATE_ISSUE($issueId: ID!, $issueInput: UpdateIssueInput!) {
    updateIssue(id: $issueId, input: $issueInput) {
      id
      issueStatus
    }
  }
`
export const GenerateRequestHelpMessage = gql`
  mutation GenerateRequestHelpMessage($issueId: String!, $helpOrder: Int!) {
    generateRequestHelpMessage(issueId: $issueId, helpOrder: $helpOrder) {
      messageText
      chatVideo
      chatText
      chatImage
    }
  }
`
