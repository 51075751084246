import React, { Component } from 'react'
import { View, Text } from 'react-native-web'
import {
  TextField,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  Input,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import _ from 'lodash'
import { withApollo } from 'react-apollo'
import {
  FAMILY_LOGIN,
  FAMILY_VERIFY,
} from '../../../services/queries/login_queries'
import { withCookies } from 'react-cookie'

class FamilyLoginForm extends Component {
  constructor(props) {
    super(props)
    this.timer = 0
    this.state = {
      phoneNum: '',
      code: '',
      loading: false,
      errorMsg: '',
      getCodeText: 'Get Code',
      getCodeTime: 0,
      remember: false,
    }
  }

  startTimer = () => {
    if (this.timer === 0) {
      this.timer = setInterval(this.countDown, 1000)
    }
  }

  countDown = () => {
    if (this.state.getCodeTime <= 0) {
      clearInterval(this.timer)
      this.timer = 0
      this.setState({ getCodeText: 'Get Code' })
      return
    }

    const newTime = this.state.getCodeTime - 1
    this.setState({ getCodeTime: newTime, getCodeText: `${newTime}s` })
  }

  throttledSendVerification = _.throttle(async () => {
    const { client } = this.props
    if (!this.state.phoneNum) {
      this.setState({ errorMsg: 'Enter a phone number' })
      return
    }
    this.setState({ loading: true })

    const { data } = await client.mutate({
      mutation: FAMILY_LOGIN,
      variables: {
        phone: this.state.phoneNum,
        callInstead: false,
      },
    })

    if (data && data.familyLogin.verificationCodeSent) {
      const time = data.familyLogin.secondsToExpire
      this.setState({ getCodeTime: time })
      this.startTimer()
      this.setState({ errorMsg: '' })
    } else {
      this.setState({ errorMsg: data.familyLogin.message })
    }
    this.setState({ loading: false })
  }, 3000)

  throttledFamilyVerify = _.throttle(async () => {
    const { remember } = this.state
    const { client, cookies } = this.props
    if (!this.state.code || !this.state.phoneNum) {
      this.setState({ errorMsg: 'you must enter all fields' })
      return
    }
    this.setState({ loading: true, errorMsg: '' })
    const { data } = await client.mutate({
      mutation: FAMILY_VERIFY,
      variables: {
        phone: this.state.phoneNum,
        code: this.state.code,
      },
    })

    if (data && data.familyVerify.success) {
      const { firstName, lastName, imageUrl } = data.familyVerify.familyMember
      sessionStorage.clear()
      Object.keys(cookies.getAll()).forEach(k => cookies.remove(k))
      cookies.set(
        'token',
        data.familyVerify.token,
        remember ? { maxAge: 2629743 } : {}
      )
      cookies.set('firstName', firstName, remember ? { maxAge: 2629743 } : {})
      cookies.set('lastName', lastName, remember ? { maxAge: 2629743 } : {})
      cookies.set('imageUrl', imageUrl, remember ? { maxAge: 2629743 } : {})
      cookies.set('family', 1, remember ? { maxAge: 2629743 } : {})
      cookies.set(
        'familyAccountId',
        data.familyVerify.familyAccountId,
        remember ? { maxAge: 2629743 } : {}
      )
      this.setState({ loading: false })
      this.props.onSuccess(data.familyVerify)
    } else {
      this.setState({ loading: false, errorMsg: data.familyVerify.message })
    }
  }, 3000)

  render() {
    const {
      phoneNum,
      code,
      loading,
      errorMsg,
      getCodeText,
      remember,
    } = this.state
    return (
      <View>
        <Text style={{ color: 'red' }}>{errorMsg}</Text>
        <br />
        <form onSubmit={e => e.preventDefault()}>
          <View>
            <TextField
              label="Phone Number"
              value={phoneNum}
              onChange={e => this.setState({ phoneNum: e.target.value })}
              autoComplete="tel-national"
              disabled={loading}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+1</InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={this.throttledSendVerification}
                  >
                    <Button>{getCodeText}</Button>
                  </InputAdornment>
                ),
              }}
            />
            <FormControl>
              <InputLabel htmlFor="standard-adornment-password">
                Code
              </InputLabel>
              <Input
                id="standard-adornment-password"
                value={code}
                onChange={e => this.setState({ code: e.target.value })}
              />
            </FormControl>
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={remember}
                  onChange={e => this.setState({ remember: e.target.checked })}
                  name="rememberLogin"
                  color="primary"
                />
              }
              label="Remember me"
            />
            <br />
            <Button
              type="submit"
              color="primary"
              variant="contained"
              onClick={this.throttledFamilyVerify}
              disabled={loading || !code || !phoneNum}
            >
              Log in
            </Button>
          </View>
        </form>
      </View>
    )
  }
}

export default withApollo(withCookies(FamilyLoginForm))
