import React, { useCallback, useEffect, useState, useMemo } from 'react'
import {
  View,
  Text,
  Image,
  ImageBackground,
  TouchableOpacity,
} from 'react-native-web'
import {
  GET_GUEST_ACCOUNT_INFO,
  START_SESSION,
} from '../../../services/queries'
import login_background from './../../images/login_background.svg'
import logo from '../../images/solve-logo.png'
import { access } from '../../components/styles'
import solveColors from '../../components/colors'
import { Query, withApollo } from 'react-apollo'
import BannerMessage from '../../components/BannerMessage'
import jwt from 'jsonwebtoken'
import LoadingPage from './../Loading/LoadingPage'
import { withRouter } from 'react-router-dom'
import { useCookies } from 'react-cookie'

const SECONDS_PER_WEEK = 604800

const GuestLoginPage = props => {
  const [running, setRunning] = useState(false)
  const [, setCookie, removeCookie] = useCookies([])
  const token = props.location.search.substr(1)

  useEffect(() => {
    if (token) {
      sessionStorage.setItem('tempToken', token)
    }
  }, [token])

  const decodedToken = useMemo(() => (token ? jwt.decode(token) : null), [
    token,
  ])

  const saveUser = useCallback(
    user => {
      const { firstName, lastName, imageUrl } = user

      removeCookie('instructor')
      sessionStorage.removeItem('tempToken')

      setCookie('token', token, { maxAge: SECONDS_PER_WEEK })
      setCookie('firstName', firstName, { maxAge: SECONDS_PER_WEEK })
      setCookie('lastName', lastName, { maxAge: SECONDS_PER_WEEK })
      setCookie('imageUrl', imageUrl, { maxAge: SECONDS_PER_WEEK })
    },
    [token, setCookie, removeCookie]
  )

  const guestLoginStudent = useCallback(
    async student => {
      saveUser(student)

      const { id: studentId } = student

      const { data } = await props.client.mutate({
        mutation: START_SESSION,
        variables: {
          studentId,
        },
      })

      sessionStorage.setItem('sessionId', data.startStudentSession)

      const trials = []
      for (const opportunity of student.opportunities) {
        for (const trial of opportunity.trials) {
          trials.push({
            toLesson: trial.toLesson.id,
            classroomId: trial.toLesson.classroom.id,
          })
        }
      }
      sessionStorage.setItem('trials', JSON.stringify(trials))

      let today = new Date()
      let bday = new Date(student.birthdate)
      if (
        today.getMonth() === bday.getMonth() &&
        today.getDate() === bday.getDate()
      ) {
        sessionStorage.setItem('birthdate', 'true')
      } else {
        sessionStorage.removeItem('birthdate')
      }
    },
    [saveUser, props.client]
  )

  const guestLoginFamilyMember = useCallback(
    async familyMember => {
      saveUser(familyMember)
      setCookie('family', 1)
      setCookie('familyAccountId', familyMember.familyAccount.id)
    },
    [saveUser, setCookie]
  )

  return (
    <Query
      query={GET_GUEST_ACCOUNT_INFO}
      variables={{
        id: decodedToken?.id ?? '',
        getStudent: decodedToken?.userType === 'Student',
        getFamilyMember: decodedToken?.userType === 'FamilyMember',
      }}
      skip={!token}
    >
      {({ data, loading, error }) => {
        if (loading) return <LoadingPage />
        const { firstName, lastName, imageUrl } =
          (decodedToken?.userType === 'Student'
            ? data?.student
            : data?.familyMember) ?? {}

        return (
          <ImageBackground source={login_background} style={access.background}>
            {(!token || error) && (
              <BannerMessage msg="Invalid guest login URL." />
            )}
            <View style={access.header}>
              <Image style={access.logo} source={imageUrl || logo} />
              <View>
                <TouchableOpacity
                  style={[
                    access.button,
                    !token || error
                      ? { backgroundColor: solveColors.red1 }
                      : {},
                  ]}
                  disabled={!token || error || running}
                  onPress={async () => {
                    setRunning(true)
                    if (
                      decodedToken.userType === 'Student' &&
                      data.student != null
                    ) {
                      await guestLoginStudent(data.student)
                    } else if (
                      decodedToken.userType === 'FamilyMember' &&
                      data.familyMember != null
                    ) {
                      await guestLoginFamilyMember(data.familyMember)
                    } else {
                      throw new Error(
                        `User type is ${
                          decodedToken.userType
                        } but did not receive the corresponding user in data ${JSON.stringify(
                          data
                        )}`
                      )
                    }
                    setRunning(false)
                    props.history.push('/dashboard')
                  }}
                >
                  <Text style={{ color: 'white' }}>
                    {!token || error
                      ? 'Invalid Login'
                      : `Login as ${firstName} ${lastName}`}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </ImageBackground>
        )
      }}
    </Query>
  )
}

export default withRouter(withApollo(GuestLoginPage))
