import React, { Component } from 'react'

import './VideoModal.css'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

class MessageModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.visible !== props.visible) {
      return { visible: props.visible }
    }
    return null
  }

  render() {
    // return (
    //   <ModalVideo
    //     isOpen={this.props.visible}
    //     onClose={this.props.onClose}
    //     channel="vimeo"
    //     videoId={this.props.videoId}
    //   />
    // )
    if (!this.state.visible) {
      return null
    }

    return (
      <div className="modal-video" onClick={this.props.onClose}>
        <div className="modal-video-body" style={{ width: '100%' }}>
          <div className="modal-video-inner">
            <div
              className="modal-video-movie-wrap"
              style={{ width: '100%', height: '0px', paddingBottom: '50%' }}
            >
              <ArrowBackIcon
                style={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  zIndex: 999,
                }}
                htmlColor="white"
                fontSize="large"
              />
              <iframe
                className="modal-video-movie-wrap-iframe"
                title={this.props.videoId}
                src={`https://player.vimeo.com/video/${this.props.videoId}?title=0&portrait=0&byline=0`}
                width="460"
                height="320"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen="true"
                tabIndex="-1"
                style={{ position: 'absolute', width: '100%', height: '100%' }}
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MessageModal
