import React from 'react'
import { useTable } from 'react-table'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import solveColors from '../../components/colors'
import { convertToYMD } from '../../../utils/dateTime'
import { isMobile } from '../../../config'
import { withRouter } from 'react-router-dom'

function CreditTable(props) {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Credits',
        Cell: row => row.row.index + 1,
        width: 30,
      },
      {
        Header: 'Type',
        accessor: 'creditType',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        id: 'balance',
        Header: 'Balance',
        accessor: 'balance',
        Cell: row => {
          return <span>{`$${row.row.original.balance.toFixed(2)}`}</span>
        },
      },
      {
        id: 'expirationDate',
        Header: 'Expiration',
        Cell: row => {
          if (!row.row.original.expirationData) {
            return <div>N/A</div>
          } else {
            return (
              <div>
                {convertToYMD(new Date(row.row.original.expirationDate))}
              </div>
            )
          }
        },
      },
      {
        Header: 'Notes',
        accessor: 'notes',
      },
    ],
    []
  )

  const { getTableProps, headerGroups, prepareRow, rows } = useTable({
    columns,
    data: props.credits,
    initialState: {
      hiddenColumns: isMobile ? ['notes', 'expirationDate', 'Credits'] : [],
    },
  })
  return (
    <div
      style={{
        display: 'block',
        maxWidth: '100%',
        overflowX: 'hidden',
        overflowY: 'hidden',
        backgroundColor: 'white',
        height: '100%',
      }}
    >
      <Table {...getTableProps()}>
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => {
                return (
                  <TableCell
                    {...column.getHeaderProps()}
                    style={{
                      borderBottom: `solid 3px ${solveColors.blue1}`,
                      color: solveColors.blue1,
                      fontWeight: 'bold',
                      width: column.width,
                    }}
                  >
                    {column.render('Header')}
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <TableRow
                {...row.getRowProps()}
                onClick={() => {
                  if (
                    row.original.status === 'PENDING' ||
                    row.original.status === 'OPEN'
                  ) {
                    props.history.push('/cart')
                  }
                  props.setInvoiceId(row.original.id)
                }}
              >
                {row.cells.map(cell => {
                  return (
                    <TableCell {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </div>
  )
}

export default withRouter(CreditTable)
