import React from 'react'
import { useTable } from 'react-table'
import ComputerIcon from '@material-ui/icons/Computer'
import HouseIcon from '@material-ui/icons/House'
import MaUTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import solveColors from '../../components/colors'
import { formatTime } from '../../../utils/dateTime'
import { Button } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { gradeLevel } from './../../../utils/gradeLevel'

const ClassOverviewForm = props => {
  const columns = [
    {
      id: 'grade',
      Header: 'Grade',
      accessor: r => {
        const grades = gradeLevel(r.course.startGrade, r.course.endGrade)
        return `${grades}`
      },
    },

    {
      id: 'code',
      Header: 'Code',
      accessor: r => r.course.code,
    },
    {
      Header: 'Course',
      // accessor: 'title',
      accessor: r => r.course.title,
    },
    {
      id: 'days',
      Header: 'Days',
      accessor: r => r.meets,
    },
    {
      id: 'time',
      Header: 'Time',
      accessor: r => `${formatTime(r.startTime)} - ${formatTime(r.endTime)}`,
    },
    {
      id: 'location',
      Header: 'Location',
      accessor: r => {
        if (r.centerLocation.name === 'ONLINE') return <ComputerIcon />
        else return <HouseIcon />
      },
    },
    {
      id: 'enroll',
      Header: 'Enroll',
      Cell: r => {
        return (
          <Button
            onClick={() =>
              props.history.push('/checkout?classroomId=' + r.row.original.id)
            }
          >
            GO
          </Button>
        )
      },
    },
  ]

  const { getTableProps, headerGroups, prepareRow, rows } = useTable({
    columns,
    data: props.classes,
  })

  return (
    <div style={{ display: 'block', maxWidth: '100%' }}>
      <div
        style={{
          display: 'block',
          maxWidth: '100%',
          overflowX: 'scroll',
          overflowY: 'hidden',
          borderBottom: '1px solid black',
        }}
      >
        <MaUTable {...getTableProps()}>
          <TableHead>
            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableCell
                    {...column.getHeaderProps()}
                    style={{
                      borderBottom: `solid 3px ${solveColors.blue1}`,
                      color: solveColors.blue1,
                      fontWeight: 'bold',
                    }}
                  >
                    {column.render('Header')}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {rows.map((row, i) => {
              prepareRow(row)
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <TableCell {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </MaUTable>
      </div>
    </div>
  )
}

export default withRouter(ClassOverviewForm)
