import React, { Component } from 'react'
import { ScrollView } from 'react-native-web'
import LargePostCard from './LargePostCard'
import { isMobile } from '../../../config'
import { GET_POSTS } from '../../../services/queries/post_queries'
import LoadingPage from '../Loading/LoadingPage'
import { Query, withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'

class PostsPage extends Component {
  componentDidMount() {
    if (!this.props.cookies.get('token')) {
      this.props.history.goBack()
    }
  }

  render() {
    const today = new Date()
    return (
      <Query
        query={GET_POSTS}
        variables={{
          filter: {
            published: true,
            publishDate: { $ltDate: today.toISOString() },
            expirationDate: { $gtDate: today.toISOString() },
          },
        }}
      >
        {({ data, loading, error, refetch }) => {
          if (loading) return <LoadingPage />
          if (error) return `${error}`

          const remainingPosts = data.posts
          return (
            <ScrollView contentContainerStyle={{ alignItems: 'center' }}>
              <div
                style={{
                  width: isMobile ? '100%' : '80%',
                  alignSelf: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                  }}
                >
                  {remainingPosts.map((post, ind) => {
                    if (!post.published) {
                      if (sessionStorage.getItem('admin') === '1') {
                        return <LargePostCard key={ind} post={post} />
                      }
                      return null
                    } else {
                      return <LargePostCard key={ind} post={post} />
                    }
                  })}
                </div>
              </div>
            </ScrollView>
          )
        }}
      </Query>
    )
  }
}

export default withApollo(withRouter(withCookies(PostsPage)))
