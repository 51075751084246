import React, { useState } from 'react'
import {
  Text,
  View,
  FlatList,
  TouchableOpacity,
  Image,
  ScrollView,
} from 'react-native-web'
import { Query } from 'react-apollo'
import LoadingPage from './../Loading/LoadingPage'
import { GET_ATTENDANCE } from './../../../services/queries'
import jwt from 'jsonwebtoken'
import solveColors from './../../components/colors'
import { ReportProblemModal, MessageModal } from './../../components'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import lateIcon from './../../images/arriveLate.png'
import earlyIcon from './../../images/leaveEarly.png'
import MakeupDialog from './MakeupDialog'
import ScheduleIcon from '@material-ui/icons/Schedule'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import Tooltip from '@material-ui/core/Tooltip'
import { useCookies } from 'react-cookie'

const AttendancePage = props => {
  const [makeupDialogVisible, setMakeupDialogVisible] = useState(false)
  const [message, setMessage] = useState('')
  const [reportDialogVisible, setReportDialogVisible] = useState(false)
  const [currLesson, setCurrLesson] = useState(null)
  const [currAttendeeId, setAttendeeId] = useState(null)
  const [cookies] = useCookies([])

  if (cookies['instructor'] === '1') {
    return <Text>Attendance is not supported in instructor mode :(</Text>
  }
  return (
    <Query
      query={GET_ATTENDANCE}
      context={{ headers: { 'cache-control': 'no-cache' } }}
      variables={{
        classroomId: props.classroomId,
        attendeeFilter: {
          studentId: jwt.decode(cookies['token']).id,
        },
      }}
    >
      {({ data, loading, error }) => {
        if (loading) {
          return <LoadingPage />
        }
        if (error) {
          return `${error}`
        }
        let { makeups, refetch } = props
        makeups = makeups.filter(
          m => m.fromLesson.classroom.id === props.classroomId
        )
        const location = data.classroom.centerLocation.name
        return (
          <ScrollView showsHorizontalScrollIndicator={false}>
            <HeaderRow
              title={'LESSON'}
              date={'DATE'}
              status={'STATUS'}
              makeup={'MAKEUP'}
              location={'LOCATION'}
            />
            <FlatList
              data={data.classroom.lessons}
              renderItem={({ item }) => {
                const { title, order } = item.lessonPlan
                const date = item.startOn
                const { status, id: attendeeId } =
                  item.attendees && item.attendees.length > 0
                    ? item.attendees[0]
                    : { status: 'UNKNOWN' }
                return (
                  <AttendanceRow
                    title={title}
                    order={order}
                    date={date}
                    status={status}
                    location={location}
                    last={order === data.classroom.lessons.length}
                    openMakeup={() => setMakeupDialogVisible(true)}
                    setLesson={() => setCurrLesson(item)}
                    setAttendeeId={() => setAttendeeId(attendeeId)}
                    attendeeId={attendeeId}
                    openMakeupDetails={makeup => {
                      const { classroom, startOn } = makeup.toLesson

                      setMessage(
                        `You have a makeup scheduled for ${new Date(
                          startOn
                        ).toLocaleDateString()}\n\n Classroom: ${
                          classroom.title
                        }\n Lesson: ${title} \n Location: ${
                          classroom.centerLocation.name
                        }`
                      )
                    }}
                    openReport={() => {
                      setReportDialogVisible(true)
                    }}
                    makeup={makeups.find(m => m.fromLesson.id === item.id)}
                  />
                )
              }}
            />
            <MakeupDialog
              visible={makeupDialogVisible}
              lesson={currLesson}
              attendeeId={currAttendeeId}
              openComfirmation={() => {
                setMessage('Your make up has been scheduled!')
                refetch()
              }}
              openError={() =>
                setMessage('You need to select a makeup lesson!')
              }
              closeDialog={() => setMakeupDialogVisible(false)}
            />
            <ReportProblemModal
              visible={reportDialogVisible}
              lessonId={currLesson ? currLesson.id : null}
              attendeeId={currAttendeeId}
              attendance={true}
              openComfirmation={() =>
                setMessage(
                  'Your problem has been sent. We will get back to you soon!'
                )
              }
              openWarning={() =>
                setMessage('You have already opened an issue for this issue!')
              }
              closeModal={() => setReportDialogVisible(false)}
            />
            <MessageModal
              visible={message !== ''}
              message={message}
              onClose={() => {
                setMessage('')
              }}
            />
          </ScrollView>
        )
      }}
    </Query>
  )
}

const AttendanceRow = props => {
  const {
    title,
    order,
    status,
    date,
    location,
    last,
    openMakeup,
    setLesson,
    openReport,
    setAttendeeId,
    attendeeId,
    makeup,
    openMakeupDetails,
  } = props
  const [hover, setHover] = useState(null)
  return (
    <TouchableOpacity
      disabled={true}
      style={{
        flexDirection: 'row',
        marginHorizontal: 10,
        padding: 10,
        marginBottom: 2,
        backgroundColor: hover === order ? '#e6fdff' : 'white',
        justifyContent: 'space-between',
        borderBottomRightRadius: last ? 5 : 0,
        borderBottomLeftRadius: last ? 5 : 0,
      }}
      onMouseEnter={() => {
        setHover(order)
      }}
      onMouseLeave={() => {
        setHover(null)
      }}
    >
      <Text style={{ width: 200 }}>{order + '. ' + title}</Text>
      <View style={{ width: 150, flexDirection: 'row', alignItems: 'center' }}>
        {status === 'UNKNOWN' && (
          <RadioButtonUncheckedIcon style={{ color: solveColors.green }} />
        )}
        {status === 'ABSENT' && (
          <HighlightOffIcon style={{ color: solveColors.red1 }} />
        )}
        {status === 'ONTIME' && (
          <CheckCircleOutlineIcon style={{ color: solveColors.green }} />
        )}
        {status === 'LATE' && (
          <Image
            style={{ width: 25, height: 25, resizeMode: 'stretch' }}
            source={lateIcon}
          />
        )}
        {status === 'EARLY' && (
          <Image
            style={{
              width: 25,
              height: 25,
              resizeMode: 'stretch',
            }}
            source={earlyIcon}
          />
        )}
        <Text style={{ marginLeft: 10 }}>{status}</Text>
      </View>

      <Text style={{ width: 150 }}>
        {date === 'DATE' ? 'DATE' : new Date(date).toDateString()}
      </Text>
      <Text style={{ width: 100 }}>{location}</Text>
      <View style={{ width: 100, flexDirection: 'row' }}>
        {(!makeup || !makeup.toLesson) &&
          (status === 'UNKNOWN' || status === 'ABSENT') &&
          new Date() < new Date(date) && (
            <Tooltip title="Schedule a Makeup">
              <TouchableOpacity
                style={{
                  backgroundColor: solveColors.blue1,
                  borderRadius: 5,
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: 3,
                  marginRight: 10,
                }}
                onPress={() => {
                  openMakeup()
                  setLesson()
                  setAttendeeId()
                }}
              >
                <ScheduleIcon
                  style={{ color: 'white', height: 20, width: 20 }}
                />
              </TouchableOpacity>
            </Tooltip>
          )}
        {makeup !== undefined && makeup.toLesson && (
          <Tooltip title="Show Makeup Details">
            <TouchableOpacity
              style={{
                backgroundColor: solveColors.blue1,
                borderRadius: 5,
                alignItems: 'center',
                justifyContent: 'center',
                padding: 3,
                marginRight: 10,
                maxWidth: 100,
              }}
              onPress={() => {
                openMakeupDetails(makeup)
              }}
            >
              <Text style={{ color: 'white' }}>
                {`${new Date(makeup.toLesson.startOn).toLocaleDateString()}`}
              </Text>
            </TouchableOpacity>
          </Tooltip>
        )}
        {new Date() > new Date(date) && attendeeId && (
          <Tooltip title="Request Attendance Correction">
            <TouchableOpacity
              style={{
                backgroundColor: solveColors.blue1,
                borderRadius: 5,
                alignItems: 'center',
                justifyContent: 'center',
                padding: 3,
              }}
              onPress={() => {
                setLesson()
                setAttendeeId()
                openReport()
              }}
            >
              <MailOutlineIcon
                style={{ color: 'white', height: 20, width: 20 }}
              />
            </TouchableOpacity>
          </Tooltip>
        )}
      </View>
    </TouchableOpacity>
  )
}
const HeaderRow = props => {
  const { title, status, date, location, makeup } = props
  return (
    <View
      style={{
        flexDirection: 'row',
        marginHorizontal: 10,
        padding: 10,
        marginTop: 10,
        marginBottom: 2,
        backgroundColor: solveColors.blue1,
        justifyContent: 'space-between',
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
      }}
    >
      <Text style={{ color: 'white', width: 200 }}>{title}</Text>
      <Text style={{ color: 'white', width: 150 }}>{status}</Text>
      <Text style={{ color: 'white', width: 150 }}>
        {date === 'DATE' ? 'DATE' : new Date(date).toDateString()}
      </Text>
      <Text style={{ color: 'white', width: 100 }}>{location}</Text>
      <Text style={{ color: 'white', width: 100 }}>{makeup}</Text>
    </View>
  )
}

export default AttendancePage
