import {
  TextInput,
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
} from 'react-native-web'
import React, { Component } from 'react'
import ProblemSubmitButton from './ProblemSubmitButton'
import AssignmentSubmitButton from './AssignmentSubmitButton'
import SkipButton from './SkipButton'
import { DialogModal, Latex } from '../../../components/index'
import ScratchpadButton from './ScratchpadButton'
import SolutionButton from './SolutionButton'
import { extractAnswersFromTemplate } from '../../../../utils/answers'
import KeyboardEventHandler from 'react-keyboard-event-handler'

const encodeScientificAnswer = (answer, template) => {
  let encodedAnswer = template[0]
  for (let i = 1; i < template.length; i++) {
    encodedAnswer += (answer.length + 1 > i ? answer[i - 1] : '') + template[i]
  }
  return encodedAnswer
}

const getLatexTemplate = answer => {
  let template1 = ['\\(', '\\times10^{']
  let template2 = ['\\(']
  let template3 = ['\\((']
  let template4 = ['\\((']
  let template5 = ['\\(10^{']
  let template6 = []
  let template7 = ['\\(']
  let template8 = ['\\(', '\\times10^{']
  let template9 = ['\\(', '\\times10^{']
  let template10 = ['\\(\\$'] //dollar sign
  let template11 = [] //exponent 1 inputs
  let template12 = ['\\(', '\\frac{', '}{'] // mixed fraction
  let template13 = ['\\(\\frac{', '}{'] // fraction
  let template14 = [] //exponent 2 inputs
  let template15 = ['\\(', ':', '\\)'] //Ratio
  let template16 = ['\\((', ',', ')\\)'] //Coordinates
  let template17 = [] //Prime Factorization 2 Input
  let template18 = [] //Prime Factorization 3 Input
  let template19 = ['\\('] //Square root 2 input
  let template20 = ['\\(', ':'] //Time
  let template21 = ['\\(\\sqrt{', '}\\)'] //Square root no factor

  if (answer.includes('\\(\\sqrt')) {
    return { template: template21, templateType: 21 }
  }
  if (answer.includes('sqrt')) {
    const root = answer.substring(
      answer.indexOf('\\sqrt'),
      answer.indexOf('{') + 1
    )
    template19.push(root)
    template19.push('}\\)')
    return { template: template19, templateType: 19 }
  }
  if (
    answer.includes('\\cdot') &&
    answer.includes('\\cdot', answer.indexOf('\\cdot') + 1) &&
    !answer.includes('text')
  ) {
    const factor1 = answer.substring(
      answer.indexOf('\\(') + 2,
      answer.indexOf('^{')
    )
    const factor2 = answer.substring(
      answer.indexOf('\\cdot') + 5,
      answer.indexOf('^{', answer.indexOf('^{') + 1)
    )
    const factor3 = answer.substring(
      answer.indexOf('\\cdot', answer.indexOf('\\cdot') + 1) + 5,
      answer.indexOf('^{', answer.indexOf('^{', answer.indexOf('^{') + 1) + 1)
    )
    template18.push('\\(' + factor1 + '^{')
    template18.push('}\\cdot' + factor2 + '^{')
    template18.push('}\\cdot' + factor3 + '^{')
    template18.push('}\\)')
    return { template: template18, templateType: 18 }
  }
  if (answer.includes('\\cdot') && !answer.includes('text')) {
    const factor1 = answer.substring(
      answer.indexOf('\\(') + 2,
      answer.indexOf('^{')
    )
    const factor2 = answer.substring(
      answer.indexOf('\\cdot') + 5,
      answer.indexOf('^{', answer.indexOf('^{') + 1)
    )
    template17.push('\\(' + factor1 + '^{')
    template17.push('}\\cdot' + factor2 + '^{')
    template17.push('}\\)')
    return { template: template17, templateType: 17 }
  }
  if (answer.includes(':') && answer.includes('\\text')) {
    const ampm = answer.substring(
      answer.indexOf('\\text'),
      answer.indexOf('\\)') + 2
    )
    template20.push(ampm)
    return { template: template20, templateType: 20 }
  }
  if (answer.includes(':')) {
    return { template: template15, templateType: 15 }
  }
  if (answer.replace(/\s/g, '').includes('\\(\\frac')) {
    template13.push(
      answer.substring(
        answer.indexOf('}', answer.indexOf('}') + 1),
        answer.indexOf('\\)') + 2
      )
    )
    return { template: template13, templateType: 13 }
  }
  if (answer.replace(/\s/g, '').includes('\\frac')) {
    template12.push(
      answer.substring(
        answer.indexOf('}', answer.indexOf('}') + 1),
        answer.indexOf('\\)') + 2
      )
    )
    return { template: template12, templateType: 12 }
  }
  if (answer.replace(/\s/g, '').includes('\\(\\$')) {
    if (answer.includes('\\text')) {
      const units = answer.substring(
        answer.indexOf('\\text{'),
        answer.indexOf('\\)')
      )
      template10.push(units + '\\)')
    } else {
      template10.push('\\)')
    }

    return { template: template10, templateType: 10 }
  }
  if (
    answer.includes('\\times') &&
    answer.includes('\\circ', answer.indexOf(',') + 1)
  ) {
    if (answer.substring(0, answer.indexOf(',')).includes('\\text{')) {
      const units = answer.substring(
        answer.indexOf('\\text'),
        answer.indexOf(',')
      )
      template9.push('}' + units + ',')
    } else {
      template9.push('},')
    }
    template9.push('^{\\circ}\\)')
    return { template: template9, templateType: 9 }
  }
  if (
    answer.includes('\\times') &&
    answer.includes('\\times', answer.indexOf('\\times') + 1)
  ) {
    if (answer.substring(0, answer.indexOf(',')).includes('\\text{')) {
      const units1 = answer.substring(
        answer.indexOf('\\text'),
        answer.indexOf(',')
      )
      template8.push('}' + units1 + ',')
    } else {
      template8.push('},')
    }
    template8.push('\\times10^{')
    if (answer.includes('\\text{', answer.indexOf(',' + 1))) {
      const units2 = answer.substring(
        answer.indexOf('\\text', answer.indexOf(',') + 1),
        answer.indexOf('\\)')
      )
      template8.push('}' + units2)
    } else {
      template8.push('}')
    }
    template8.push('\\)')
    return { template: template8, templateType: 8 }
  }
  if (answer.includes('\\times')) {
    if (answer.includes('\\text{')) {
      const units = answer.substring(
        answer.indexOf('\\text'),
        answer.indexOf('\\)')
      )
      template1.push('}' + units + '\\)')
    } else {
      template1.push('}\\)')
    }
    return { template: template1, templateType: 1 }
  }
  if (answer.includes('10^') && !answer.includes('10^{\\circ}')) {
    if (answer.includes('\\text{')) {
      const units = answer.substring(
        answer.indexOf('\\text'),
        answer.indexOf('\\)')
      )
      template5.push('}' + units + '\\)')
    } else {
      template5.push('}\\)')
    }
    return { template: template5, templateType: 5 }
  }
  if (answer.includes('\\circ') && answer.includes('\\text')) {
    const units = answer.substring(
      answer.indexOf('\\text'),
      answer.indexOf(',') + 1
    )
    template4.push(units)
    template4.push('^{\\circ})\\)')
    return { template: template4, templateType: 4 }
  }
  if (
    answer.includes('text') &&
    answer.includes('text', answer.indexOf('text') + 1) &&
    answer.includes(',')
  ) {
    template6.push(answer.includes('\\((') ? '\\((' : '\\(')
    const units1 = answer.substring(
      answer.indexOf('\\text'),
      answer.indexOf(',')
    )
    template6.push(units1 + ',')
    const units2 = answer.substring(
      answer.indexOf('\\text', answer.indexOf('\\text') + 1),
      answer.indexOf('\\)')
    )
    template6.push(units2 + '\\)')
    return { template: template6, templateType: 6 }
  }
  if (answer.includes('\\hat{k}') || answer.includes('\\hat{z}')) {
    const units = answer.includes('\\text{')
      ? answer.substring(answer.indexOf('\\text'), answer.indexOf('\\)'))
      : ''
    const op1 =
      answer.indexOf('+') === -1 ||
      answer.indexOf('+') < answer.indexOf('-', answer.indexOf('}'))
        ? '-'
        : '+'
    const op2 =
      answer.indexOf('+') === -1 ||
      answer.indexOf('+') < answer.indexOf('-', answer.indexOf('}'))
        ? '-'
        : '+'
    if (answer.includes('\\hat{k}')) {
      template7.push('\\hat{i} ' + op1)
      template7.push('\\hat{j} ' + op2)
      template7.push('\\hat{k}' + units + '\\)')
    } else {
      template7.push('\\hat{x} + ')
      template7.push('\\hat{y} - ')
      template7.push('\\hat{z}' + units + '\\)')
    }
    return {
      template: template7,
      templateType: 7,
    }
  }
  if (answer.includes('\\hat')) {
    const op = answer.includes('+') ? '+' : '-'
    template3.push(`\\hat{i} ${op} `)
    const units = answer.includes('\\text{')
      ? answer.substring(answer.indexOf('\\text'), answer.indexOf('\\)'))
      : ''
    template3.push('\\hat{j} )' + units + '\\)')
    return { template: template3, templateType: 3 }
  }

  if (answer.includes('^{') && !answer.includes('\\circ')) {
    if (answer.includes('\\({')) {
      template14.push('\\({')
      template14.push('}^{')
      template14.push('}\\)')
      return { template: template14, templateType: 14 }
    }
    template11.push(answer.substring(0, answer.indexOf('^{') + 2))
    template11.push('}\\)')
    return { template: template11, templateType: 11 }
  }

  if (answer.includes(',') && answer.includes('\\((')) {
    return { template: template16, templateType: 16 }
  }

  if (answer.includes('\\text{')) {
    const units = answer.substring(
      answer.indexOf('\\text'),
      answer.indexOf('\\)')
    )
    template2.push(units + '\\)')
  } else if (answer.includes('\\circ')) {
    template2.push('^{\\circ}\\)')
  } else if (answer.includes('\\pi')) {
    template2.push('\\pi\\)')
  } else {
    template2.push('\\)')
  }

  return { template: template2, templateType: 2 }
}

class ScientificProblemForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      studentAnswer:
        props.currentAnswer && props.currentAnswer.includes('\\(')
          ? extractAnswersFromTemplate(props.currentAnswer).answers
          : ['', '', '', ''],
      dlgModalOpen: false,
      partial: true,
      latex: getLatexTemplate(props.problem.answer),
    }
    this.textInput = React.createRef()
  }

  static getDerivedStateFromProps(props, state) {
    if (state.studentAnswer !== props.currentAnswer) {
      return {
        studentAnswer:
          props.currentAnswer && props.currentAnswer.includes('\\(')
            ? extractAnswersFromTemplate(props.currentAnswer).answers
            : ['', '', '', ''],
      }
    }
    if (
      state.latex.template !== getLatexTemplate(props.problem.answer).template //nextProps.problem.answer
    ) {
      return {
        latex: getLatexTemplate(props.problem.answer),
      }
    }
    return null
  }
  componentDidMount() {
    //this.textInput.current.focus()
    // window.addEventListener('keypress', this.onEnter)
  }
  componentDidUpdate(prevProps, prevState) {
    //this.textInput.current.focus()
  }

  componentWillUnmount() {
    // window.removeEventListener('keypress', this.onEnter)
  }
  openDialogModal() {
    this.setState({ dlgModalOpen: true })
  }
  closeDialogModal() {
    this.setState({ dlgModalOpen: false })
  }
  onEnter = async e => {
    if (this.props.scratchpadOpen) return
    const key = e.which || e.keyCode
    if (key === 13) {
      if (this.props.modalIsOpen) this.props.onClose()
      else if (!this.props.isLoading) this.handleProblemSubmit()
    }
  }
  handleProblemSubmit = async (fromSkip = false) => {
    const {
      onProblemSubmit,
      problem,
      review,
      lastProb,
      gotoLessons,
    } = this.props
    const { answer } = problem
    let { studentAnswer, latex } = this.state
    const { template } = latex
    if (review && !lastProb) {
      onProblemSubmit(studentAnswer, answer)
      return
    }
    if (review && lastProb) {
      gotoLessons()
      return
    }
    onProblemSubmit(
      encodeScientificAnswer(studentAnswer, template),
      answer,
      fromSkip
    )
  }
  handleAssignmentSubmit = () => {
    const { answered } = this.props
    if (answered) {
      this.setState({ partial: false })
    }
    this.openDialogModal()
  }

  renderProblemInput = (template, templateType) => {
    const { review, complete, updateProblemAnswer } = this.props
    if (templateType === 21) {
      return (
        <View style={{ flexDirection: 'row', height: 50 }}>
          <View style={{ fontSize: 30 }}>
            <Latex latex={template[0] + '000}\\)'} />
          </View>
          <TextInput
            editable={!complete && !review}
            style={[
              styles.textInputs,
              styles.exponent2Input,
              {
                position: 'absolute',
                backgroundColor: 'white',
                right: -5,
                top: 5,
                width: 55,
                height: 35,
              },
            ]}
            keyboardType={'numeric'}
            value={this.state.studentAnswer[0]}
            onChangeText={answer => {
              const temp = this.state.studentAnswer
              temp[0] = answer
              this.setState({ studentAnswer: temp })
              updateProblemAnswer(encodeScientificAnswer(temp, template))
            }}
          />
        </View>
      )
    }
    if (templateType === 19) {
      return (
        <View style={{ flexDirection: 'row', height: 50 }}>
          <TextInput
            editable={!complete && !review}
            style={[
              styles.textInputs,
              styles.exponent2Input,
              { marginBottom: 10, marginTop: 5 },
            ]}
            keyboardType={'numeric'}
            value={this.state.studentAnswer[0]}
            onChangeText={answer => {
              const temp = this.state.studentAnswer
              temp[0] = answer
              this.setState({ studentAnswer: temp })
              updateProblemAnswer(encodeScientificAnswer(temp, template))
            }}
          />
          <View style={{ fontSize: 30 }}>
            <Latex
              latex={
                '\\(' + template[1].substring(0, template[1].length) + '000}\\)'
              }
            />
          </View>
          <TextInput
            editable={!complete && !review}
            style={[
              styles.textInputs,
              styles.exponent2Input,
              {
                position: 'absolute',
                backgroundColor: 'white',
                right: -5,
                top: 5,
                width: 55,
                height: 35,
              },
            ]}
            keyboardType={'numeric'}
            value={this.state.studentAnswer[1]}
            onChangeText={answer => {
              const temp = this.state.studentAnswer
              temp[1] = answer
              this.setState({ studentAnswer: temp })
              updateProblemAnswer(encodeScientificAnswer(temp, template))
            }}
          />
        </View>
      )
    }
    if (templateType === 18) {
      return (
        <View style={{ flexDirection: 'row' }}>
          <View style={{ marginTop: 40 }}>
            <Latex
              latex={template[0].substring(0, template[0].length - 2) + '\\)'}
            />
          </View>
          <TextInput
            editable={!complete && !review}
            style={[styles.textInputs, styles.exponent2Input]}
            keyboardType={'numeric'}
            value={this.state.studentAnswer[0]}
            onChangeText={answer => {
              const temp = this.state.studentAnswer
              temp[0] = answer
              this.setState({ studentAnswer: temp })
              updateProblemAnswer(encodeScientificAnswer(temp, template))
            }}
          />
          <View style={{ marginTop: 40 }}>
            <Latex
              latex={
                '\\(' + template[1].substring(1, template[1].length - 2) + '\\)'
              }
            />
          </View>
          <TextInput
            editable={!complete && !review}
            style={[styles.textInputs, styles.exponent2Input]}
            keyboardType={'numeric'}
            value={this.state.studentAnswer[1]}
            onChangeText={answer => {
              const temp = this.state.studentAnswer
              temp[1] = answer
              this.setState({ studentAnswer: temp })
              updateProblemAnswer(encodeScientificAnswer(temp, template))
            }}
          />
          <View style={{ marginTop: 40 }}>
            <Latex
              latex={
                '\\(' + template[2].substring(1, template[2].length - 2) + '\\)'
              }
            />
          </View>
          <TextInput
            editable={!complete && !review}
            style={[styles.textInputs, styles.exponent2Input]}
            keyboardType={'numeric'}
            value={this.state.studentAnswer[2]}
            onChangeText={answer => {
              const temp = this.state.studentAnswer
              temp[2] = answer
              this.setState({ studentAnswer: temp })
              updateProblemAnswer(encodeScientificAnswer(temp, template))
            }}
          />
        </View>
      )
    }
    if (templateType === 17) {
      return (
        <View style={{ flexDirection: 'row' }}>
          <View style={{ marginTop: 40 }}>
            <Latex
              latex={template[0].substring(0, template[0].length - 2) + '\\)'}
            />
          </View>
          <TextInput
            editable={!complete && !review}
            style={[styles.textInputs, styles.exponent2Input]}
            keyboardType={'numeric'}
            value={this.state.studentAnswer[0]}
            onChangeText={answer => {
              const temp = this.state.studentAnswer
              temp[0] = answer
              this.setState({ studentAnswer: temp })
              updateProblemAnswer(encodeScientificAnswer(temp, template))
            }}
          />
          <View style={{ marginTop: 40 }}>
            <Latex
              latex={
                '\\(' + template[1].substring(1, template[1].length - 2) + '\\)'
              }
            />
          </View>
          <TextInput
            editable={!complete && !review}
            style={[styles.textInputs, styles.exponent2Input]}
            keyboardType={'numeric'}
            value={this.state.studentAnswer[1]}
            onChangeText={answer => {
              const temp = this.state.studentAnswer
              temp[1] = answer
              this.setState({ studentAnswer: temp })
              updateProblemAnswer(encodeScientificAnswer(temp, template))
            }}
          />
        </View>
      )
    }
    if (templateType === 16) {
      return (
        <View style={{ flexDirection: 'row' }}>
          <View style={{ marginTop: 40 }}>
            <Latex latex={'\\((\\)'} />
          </View>
          <TextInput
            editable={!complete && !review}
            style={[
              styles.textInputs,
              styles.normalInput,
              { height: 50, marginTop: 20 },
            ]}
            keyboardType={'numeric'}
            value={this.state.studentAnswer[0]}
            onChangeText={answer => {
              const temp = this.state.studentAnswer
              temp[0] = answer
              this.setState({ studentAnswer: temp })
              updateProblemAnswer(encodeScientificAnswer(temp, template))
            }}
          />
          <View style={{ marginTop: 40 }}>
            <Latex latex={'\\(,\\)'} />
          </View>
          <TextInput
            editable={!complete && !review}
            style={[
              styles.textInputs,
              styles.normalInput,
              { height: 50, marginTop: 20 },
            ]}
            keyboardType={'numeric'}
            value={this.state.studentAnswer[1]}
            onChangeText={answer => {
              const temp = this.state.studentAnswer
              temp[1] = answer
              this.setState({ studentAnswer: temp })
              updateProblemAnswer(encodeScientificAnswer(temp, template))
            }}
          />
          <View style={{ marginTop: 40 }}>
            <Latex latex={'\\()\\)'} />
          </View>
        </View>
      )
    }
    if (templateType === 20) {
      return (
        <View style={{ flexDirection: 'row' }}>
          <TextInput
            editable={!complete && !review}
            style={[
              styles.textInputs,
              styles.normalInput,
              { height: 50, marginTop: 20 },
            ]}
            keyboardType={'numeric'}
            value={this.state.studentAnswer[0]}
            onChangeText={answer => {
              const temp = this.state.studentAnswer
              temp[0] = answer
              this.setState({ studentAnswer: temp })
              updateProblemAnswer(encodeScientificAnswer(temp, template))
            }}
          />
          <View style={{ marginTop: 40 }}>
            <Latex latex={'\\(:\\)'} />
          </View>
          <TextInput
            editable={!complete && !review}
            style={[
              styles.textInputs,
              styles.normalInput,
              { height: 50, marginTop: 20 },
            ]}
            keyboardType={'numeric'}
            value={this.state.studentAnswer[1]}
            onChangeText={answer => {
              const temp = this.state.studentAnswer
              temp[1] = answer
              this.setState({ studentAnswer: temp })
              updateProblemAnswer(encodeScientificAnswer(temp, template))
            }}
          />
          <View style={{ marginTop: 40 }}>
            <Latex latex={'\\(' + template[2]} />
          </View>
        </View>
      )
    }
    if (templateType === 15) {
      return (
        <View style={{ flexDirection: 'row' }}>
          <TextInput
            editable={!complete && !review}
            style={[
              styles.textInputs,
              styles.normalInput,
              { height: 50, marginTop: 20 },
            ]}
            keyboardType={'numeric'}
            value={this.state.studentAnswer[0]}
            onChangeText={answer => {
              const temp = this.state.studentAnswer
              temp[0] = answer
              this.setState({ studentAnswer: temp })
              updateProblemAnswer(encodeScientificAnswer(temp, template))
            }}
          />
          <View style={{ marginTop: 40 }}>
            <Latex latex={'\\(:\\)'} />
          </View>
          <TextInput
            editable={!complete && !review}
            style={[
              styles.textInputs,
              styles.normalInput,
              { height: 50, marginTop: 20 },
            ]}
            keyboardType={'numeric'}
            value={this.state.studentAnswer[1]}
            onChangeText={answer => {
              const temp = this.state.studentAnswer
              temp[1] = answer
              this.setState({ studentAnswer: temp })
              updateProblemAnswer(encodeScientificAnswer(temp, template))
            }}
          />
        </View>
      )
    }
    if (templateType === 13) {
      return (
        <View style={{ flexDirection: 'row' }}>
          <View>
            <TextInput
              editable={!complete && !review}
              style={[styles.textInputs, styles.normalInput, { height: 50 }]}
              keyboardType={'numeric'}
              value={this.state.studentAnswer[0]}
              onChangeText={answer => {
                const temp = this.state.studentAnswer
                temp[0] = answer
                this.setState({ studentAnswer: temp })
                updateProblemAnswer(encodeScientificAnswer(temp, template))
              }}
            />
            <View
              style={{
                backgroundColor: 'black',
                height: 2,
                marginVertical: 10,
              }}
            />
            <TextInput
              editable={!complete && !review}
              style={[styles.textInputs, styles.normalInput, { height: 50 }]}
              keyboardType={'numeric'}
              value={this.state.studentAnswer[1]}
              onChangeText={answer => {
                const temp = this.state.studentAnswer
                temp[1] = answer
                this.setState({ studentAnswer: temp })
                updateProblemAnswer(encodeScientificAnswer(temp, template))
              }}
            />
          </View>
          {template[2] !== '}\\)' && (
            <View style={{ margin: 'auto' }}>
              <Latex
                latex={
                  '\\(' +
                  template[2].substring(1, template[2].indexOf('\\)') + 2)
                }
              />
            </View>
          )}
        </View>
      )
    }
    if (templateType === 12) {
      return (
        <View style={{ flexDirection: 'row' }}>
          <TextInput
            editable={!complete && !review}
            style={[
              styles.textInputs,
              styles.normalInput,
              { height: 50, marginTop: 20 },
            ]}
            keyboardType={'numeric'}
            value={this.state.studentAnswer[0]}
            onChangeText={answer => {
              const temp = this.state.studentAnswer
              temp[0] = answer
              this.setState({ studentAnswer: temp })
              updateProblemAnswer(encodeScientificAnswer(temp, template))
            }}
          />
          <View>
            <TextInput
              editable={!complete && !review}
              style={[styles.textInputs, styles.normalInput, { height: 50 }]}
              keyboardType={'numeric'}
              value={this.state.studentAnswer[1]}
              onChangeText={answer => {
                const temp = this.state.studentAnswer
                temp[1] = answer
                this.setState({ studentAnswer: temp })
                updateProblemAnswer(encodeScientificAnswer(temp, template))
              }}
            />
            <View
              style={{
                backgroundColor: 'black',
                height: 2,
                marginVertical: 10,
              }}
            />
            <TextInput
              editable={!complete && !review}
              style={[styles.textInputs, styles.normalInput, { height: 50 }]}
              keyboardType={'numeric'}
              value={this.state.studentAnswer[2]}
              onChangeText={answer => {
                const temp = this.state.studentAnswer
                temp[2] = answer
                this.setState({ studentAnswer: temp })
                updateProblemAnswer(encodeScientificAnswer(temp, template))
              }}
            />
          </View>
          {template[3] !== '}\\)' && (
            <View style={{ margin: 'auto' }}>
              <Latex
                latex={
                  '\\(' +
                  template[3].substring(1, template[3].indexOf('\\)') + 2)
                }
              />
            </View>
          )}
        </View>
      )
    }
    if (templateType === 14) {
      return (
        <View style={{ flexDirection: 'row' }}>
          <TextInput
            editable={!complete && !review}
            style={[
              styles.textInputs,
              styles.normalInput,
              { height: 50, marginTop: 20 },
            ]}
            keyboardType={'numeric'}
            value={this.state.studentAnswer[0]}
            onChangeText={answer => {
              const temp = this.state.studentAnswer
              temp[0] = answer
              this.setState({ studentAnswer: temp })
              updateProblemAnswer(encodeScientificAnswer(temp, template))
            }}
          />
          <TextInput
            editable={!complete && !review}
            style={[
              styles.textInputs,
              styles.normalInput,
              { height: 35, width: 35, marginBottom: 20 },
            ]}
            keyboardType={'numeric'}
            value={this.state.studentAnswer[1]}
            onChangeText={answer => {
              const temp = this.state.studentAnswer
              temp[1] = answer
              this.setState({ studentAnswer: temp })
              updateProblemAnswer(encodeScientificAnswer(temp, template))
            }}
          />
        </View>
      )
    }
    if (templateType === 10) {
      return (
        <View style={{ flexDirection: 'row' }}>
          <View
            style={{
              height: 50,
              paddingTop: 15,
            }}
          >
            <Latex latex={'\\(\\$\\)'} />
          </View>
          <TextInput
            editable={!complete && !review}
            style={[styles.textInputs, styles.normalInput]}
            keyboardType={'numeric'}
            value={this.state.studentAnswer[0]}
            onChangeText={answer => {
              const temp = this.state.studentAnswer
              temp[0] = answer
              this.setState({ studentAnswer: temp })
              updateProblemAnswer(encodeScientificAnswer(temp, template))
            }}
          />
          <View
            style={{
              height: 50,
              paddingTop: 15,
            }}
          >
            <Latex latex={'\\(' + template[1]} />
          </View>
        </View>
      )
    }
    if (templateType === 2) {
      return (
        <View style={{ flexDirection: 'row' }}>
          <TextInput
            editable={!complete && !review}
            style={[styles.textInputs, styles.normalInput]}
            keyboardType={'numeric'}
            value={this.state.studentAnswer[0]}
            onChangeText={answer => {
              const temp = this.state.studentAnswer
              temp[0] = answer
              this.setState({ studentAnswer: temp })
              updateProblemAnswer(encodeScientificAnswer(temp, template))
            }}
          />
          <View
            style={{
              height: 50,
              paddingTop: template[1].includes('circ') ? 5 : 25,
            }}
          >
            <Latex latex={template[0] + template[1]} />
          </View>
        </View>
      )
    }
    if (templateType === 5 || templateType === 11) {
      return (
        <View style={{ flexDirection: 'row' }}>
          <View style={{ marginTop: 40 }}>
            <Latex
              latex={template[0].substring(0, template[0].indexOf('^')) + '\\)'}
            />
          </View>
          <TextInput
            editable={!complete && !review}
            style={[styles.textInputs, styles.exponent2Input]}
            keyboardType={'numeric'}
            value={this.state.studentAnswer[0]}
            onChangeText={answer => {
              const temp = this.state.studentAnswer
              temp[0] = answer
              this.setState({ studentAnswer: temp })
              updateProblemAnswer(encodeScientificAnswer(temp, template))
            }}
          />
          {template[1].includes('text') && (
            <View style={{ marginTop: 40 }}>
              <Latex
                latex={
                  '\\(' +
                  template[1].substring(
                    template[1].indexOf('\\text{'),
                    template[1].indexOf('}\\)') + 3
                  )
                }
              />
            </View>
          )}
        </View>
      )
    }
    if (templateType === 1) {
      return (
        <View style={{ flexDirection: 'row' }}>
          <KeyboardEventHandler
            handleKeys={['enter']}
            handleFocusableElements={true}
            onKeyEvent={(key, e) => {
              if (this.props.modalIsOpen) this.props.onClose()
              else this.handleProblemSubmit()
            }}
          />
          <TextInput
            editable={!complete && !review}
            style={[styles.textInputs, styles.exponent1Input]}
            keyboardType={'numeric'}
            value={this.state.studentAnswer[0]}
            onChangeText={answer => {
              const temp = this.state.studentAnswer
              temp[0] = answer
              this.setState({ studentAnswer: temp })
              updateProblemAnswer(encodeScientificAnswer(temp, template))
            }}
          />
          <View style={{ marginTop: 40 }}>
            <Latex
              latex={
                '\\(' + template[1].substring(0, template[1].length - 2) + '\\)'
              }
            />
          </View>
          <TextInput
            editable={!complete && !review}
            style={[styles.textInputs, styles.exponent2Input]}
            keyboardType={'numeric'}
            value={this.state.studentAnswer[1]}
            onChangeText={answer => {
              const temp = this.state.studentAnswer
              temp[1] = answer
              this.setState({ studentAnswer: temp })
              updateProblemAnswer(encodeScientificAnswer(temp, template))
            }}
          />
          <View style={{ marginTop: 40 }}>
            <Latex
              latex={'\\(' + template[2].substring(1, template[2].length)}
            />
          </View>
        </View>
      )
    }
    if (templateType === 8) {
      return (
        <View style={{ flexDirection: 'row' }}>
          <TextInput
            editable={!complete && !review}
            style={[styles.textInputs, styles.exponent1Input]}
            keyboardType={'numeric'}
            value={this.state.studentAnswer[0]}
            onChangeText={answer => {
              const temp = this.state.studentAnswer
              temp[0] = answer
              this.setState({ studentAnswer: temp })
              updateProblemAnswer(encodeScientificAnswer(temp, template))
            }}
          />
          <View style={{ marginTop: 40 }}>
            <Latex
              latex={
                '\\(' + template[1].substring(0, template[1].length - 2) + '\\)'
              }
            />
          </View>
          <TextInput
            editable={!complete && !review}
            style={[styles.textInputs, styles.exponent2Input]}
            keyboardType={'numeric'}
            value={this.state.studentAnswer[1]}
            onChangeText={answer => {
              const temp = this.state.studentAnswer
              temp[1] = answer
              this.setState({ studentAnswer: temp })
              updateProblemAnswer(encodeScientificAnswer(temp, template))
            }}
          />
          <View style={{ marginTop: 40 }}>
            <Latex
              latex={
                '\\(' + template[2].substring(1, template[2].length) + '\\)'
              }
            />
          </View>
          <TextInput
            editable={!complete && !review}
            style={[styles.textInputs, styles.exponent1Input]}
            keyboardType={'numeric'}
            value={this.state.studentAnswer[2]}
            onChangeText={answer => {
              const temp = this.state.studentAnswer
              temp[2] = answer
              this.setState({ studentAnswer: temp })
              updateProblemAnswer(encodeScientificAnswer(temp, template))
            }}
          />
          <View style={{ marginTop: 40 }}>
            <Latex
              latex={
                '\\(' + template[3].substring(0, template[3].length - 2) + '\\)'
              }
            />
          </View>
          <TextInput
            editable={!complete && !review}
            style={[styles.textInputs, styles.exponent2Input]}
            keyboardType={'numeric'}
            value={this.state.studentAnswer[3]}
            onChangeText={answer => {
              const temp = this.state.studentAnswer
              temp[3] = answer
              this.setState({ studentAnswer: temp })
              updateProblemAnswer(encodeScientificAnswer(temp, template))
            }}
          />
          <View style={{ marginTop: 40 }}>
            <Latex
              latex={
                '\\(' + template[4].substring(1, template[4].length) + '\\)'
              }
            />
          </View>
        </View>
      )
    }
    if (templateType === 9) {
      return (
        <View style={{ flexDirection: 'row' }}>
          <TextInput
            editable={!complete && !review}
            style={[styles.textInputs, styles.exponent1Input]}
            keyboardType={'numeric'}
            value={this.state.studentAnswer[0]}
            onChangeText={answer => {
              const temp = this.state.studentAnswer
              temp[0] = answer
              this.setState({ studentAnswer: temp })
              updateProblemAnswer(encodeScientificAnswer(temp, template))
            }}
          />
          <View style={{ marginTop: 40 }}>
            <Latex
              latex={
                '\\(' + template[1].substring(0, template[1].length - 2) + '\\)'
              }
            />
          </View>
          <TextInput
            editable={!complete && !review}
            style={[styles.textInputs, styles.exponent2Input]}
            keyboardType={'numeric'}
            value={this.state.studentAnswer[1]}
            onChangeText={answer => {
              const temp = this.state.studentAnswer
              temp[1] = answer
              this.setState({ studentAnswer: temp })
              updateProblemAnswer(encodeScientificAnswer(temp, template))
            }}
          />
          <View style={{ marginTop: 40 }}>
            <Latex
              latex={
                '\\(' + template[2].substring(1, template[2].length) + '\\)'
              }
            />
          </View>
          <TextInput
            editable={!complete && !review}
            style={[styles.textInputs, styles.exponent1Input]}
            keyboardType={'numeric'}
            value={this.state.studentAnswer[2]}
            onChangeText={answer => {
              const temp = this.state.studentAnswer
              temp[2] = answer
              this.setState({ studentAnswer: temp })
              updateProblemAnswer(encodeScientificAnswer(temp, template))
            }}
          />
          <View style={{ marginTop: 20 }}>
            <Latex
              latex={
                '\\(' + template[3].substring(0, template[3].length - 2) + '\\)'
              }
            />
          </View>
        </View>
      )
    }
    if (templateType === 3 || templateType === 4) {
      return (
        <View style={{ flexDirection: 'row' }}>
          <View style={{ paddingTop: 15, height: 50 }}>
            <Latex latex={'\\((\\)'} />
          </View>
          <TextInput
            editable={!complete && !review}
            style={[styles.textInputs, styles.normalInput]}
            keyboardType={'numeric'}
            value={this.state.studentAnswer[0]}
            onChangeText={answer => {
              const temp = this.state.studentAnswer
              temp[0] = answer
              this.setState({ studentAnswer: temp })
              updateProblemAnswer(encodeScientificAnswer(temp, template))
            }}
          />
          <View style={{ paddingTop: 15, height: 50 }}>
            <Latex latex={'\\(' + template[1] + '\\)'} />
          </View>
          <TextInput
            editable={!complete && !review}
            style={[styles.textInputs, styles.normalInput]}
            keyboardType={'numeric'}
            value={this.state.studentAnswer[1]}
            onChangeText={answer => {
              const temp = this.state.studentAnswer
              temp[1] = answer
              this.setState({ studentAnswer: temp })
              updateProblemAnswer(encodeScientificAnswer(temp, template))
            }}
          />
          <View style={{ paddingTop: 15, height: 50 }}>
            <Latex latex={'\\(' + template[2]} />
          </View>
        </View>
      )
    }
    if (templateType === 7) {
      return (
        <View style={{ flexDirection: 'row' }}>
          <TextInput
            editable={!complete && !review}
            style={[styles.textInputs, styles.normalInput]}
            keyboardType={'numeric'}
            value={this.state.studentAnswer[0]}
            onChangeText={answer => {
              const temp = this.state.studentAnswer
              temp[0] = answer
              this.setState({ studentAnswer: temp })
              updateProblemAnswer(encodeScientificAnswer(temp, template))
            }}
          />
          <View style={{ paddingTop: 15, height: 50 }}>
            <Latex latex={'\\(' + template[1] + '\\)'} />
          </View>
          <TextInput
            editable={!complete && !review}
            style={[styles.textInputs, styles.normalInput]}
            keyboardType={'numeric'}
            value={this.state.studentAnswer[1]}
            onChangeText={answer => {
              const temp = this.state.studentAnswer
              temp[1] = answer
              this.setState({ studentAnswer: temp })
              updateProblemAnswer(encodeScientificAnswer(temp, template))
            }}
          />
          <View style={{ paddingTop: 15, height: 50 }}>
            <Latex latex={'\\(' + template[2] + '\\)'} />
          </View>
          <TextInput
            editable={!complete && !review}
            style={[styles.textInputs, styles.normalInput]}
            keyboardType={'numeric'}
            value={this.state.studentAnswer[2]}
            onChangeText={answer => {
              const temp = this.state.studentAnswer
              temp[2] = answer
              this.setState({ studentAnswer: temp })
              updateProblemAnswer(encodeScientificAnswer(temp, template))
            }}
          />
          <View style={{ paddingTop: 15, height: 50 }}>
            <Latex latex={'\\(' + template[3]} />
          </View>
        </View>
      )
    }
    if (templateType === 6) {
      return (
        <View style={{ flexDirection: 'row' }}>
          {template[0].includes('\\((') && (
            <View style={{ paddingTop: 15, height: 50 }}>
              <Latex latex={'\\((\\)'} />
            </View>
          )}
          <TextInput
            editable={!complete && !review}
            style={[styles.textInputs, styles.normalInput]}
            keyboardType={'numeric'}
            value={this.state.studentAnswer[0]}
            onChangeText={answer => {
              const temp = this.state.studentAnswer
              temp[0] = answer
              this.setState({ studentAnswer: temp })
              updateProblemAnswer(encodeScientificAnswer(temp, template))
            }}
          />
          <View style={{ paddingTop: 15, height: 50 }}>
            <Latex latex={'\\(' + template[1] + '\\)'} />
          </View>
          <TextInput
            editable={!complete && !review}
            style={[styles.textInputs, styles.normalInput]}
            keyboardType={'numeric'}
            value={this.state.studentAnswer[1]}
            onChangeText={answer => {
              const temp = this.state.studentAnswer
              temp[1] = answer
              this.setState({ studentAnswer: temp })
              updateProblemAnswer(encodeScientificAnswer(temp, template))
            }}
          />
          <View style={{ paddingTop: 15, height: 50 }}>
            <Latex latex={'\\(' + template[2]} />
          </View>
        </View>
      )
    } else {
      return <Text>Template not yet supported</Text>
    }
  }

  render() {
    const {
      probState,
      allowSkip,
      incrementProblem,
      problem,
      feedbackType,
      review,
      lastProb,
      scratchpadUrl,
      currentProbNum,
      problemStates,
      instructor,
      showSolution,
      toggleShowSolution,
      problemSetLength,
      isLoading,
    } = this.props
    const { questionText, answer, solutionText } = problem
    const typesetMath = true
    const hasSolution = answer !== undefined && answer !== null
    const { template, templateType } = getLatexTemplate(answer)

    return (
      <View style={styles.problem}>
        <View style={styles.question}>
          <View style={styles.fontSize}>
            {typesetMath && <Latex latex={questionText} />}
            {!typesetMath && <Text>{questionText}</Text>}
          </View>
          <View style={[styles.row]}>
            <Text
              style={{
                padding: 10,
                marginTop: templateType === 1 || templateType === 5 ? 25 : 0,
              }}
            >
              Answer:{' '}
            </Text>
            {this.renderProblemInput(template, templateType)}
          </View>

          <DialogModal
            visible={this.state.dlgModalOpen}
            transparent={true}
            onYes={async () => {
              if (this.state.studentAnswer) {
                this.handleProblemSubmit()
              }
              this.props.onAssignmentSubmit()
              this.closeDialogModal()
            }}
            onNo={() => {
              this.closeDialogModal()
            }}
            message={
              this.state.partial
                ? `You have answered ${
                    problemStates.filter(p => p !== 0).length
                  }/${problemSetLength} problems. Are you sure you want to submit the assignment for grading?`
                : 'Once submitted, you cannot change your answers. Are you sure you want to submit?'
            }
          />
        </View>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          {scratchpadUrl && (
            <TouchableOpacity
              style={{ marginRight: '0.5vh' }}
              onPress={() => this.props.deleteScratchpadImg(currentProbNum)}
            >
              <Text>{'X'}</Text>
            </TouchableOpacity>
          )}
          <Text>{scratchpadUrl}</Text>
        </View>

        <View style={styles.buttonBar}>
          <ScratchpadButton toggleScratchpad={this.props.toggleScratchpad} />
          <ProblemSubmitButton
            isLoading={isLoading}
            probState={probState}
            handleProblemSubmit={this.handleProblemSubmit}
            feedbackType={feedbackType}
            review={review}
            lastProb={lastProb}
          />
          {allowSkip && !instructor && !review && (
            <SkipButton
              probState={probState}
              allowSkip={allowSkip}
              lastProb={lastProb}
              handleProblemSubmit={this.handleProblemSubmit}
              incrementProblem={incrementProblem}
            />
          )}
          {!review && !instructor && (
            <AssignmentSubmitButton
              handleAssignmentSubmit={this.handleAssignmentSubmit}
              locked={problemStates.filter(s => s !== 0).length === 0}
            />
          )}
          {!review && instructor && (
            <SolutionButton
              showSolution={showSolution}
              toggleShowSolution={toggleShowSolution}
            />
          )}
        </View>
        {(review || showSolution) && (
          <View style={styles.answerText}>
            <Text>Correct Answer:</Text>
            {typesetMath && hasSolution && <Latex latex={answer} />}
            {!typesetMath && hasSolution && <Text>{answer}</Text>}
            {!hasSolution && (
              <Text>Solution is not available for this question.</Text>
            )}
            {solutionText !== null &&
              solutionText !== undefined &&
              solutionText !== '' && (
                <View style={{ alignItems: 'center' }}>
                  <Text>{'\n'}Solution:</Text>
                  {typesetMath && <Latex latex={solutionText} />}
                  {!typesetMath && <Text>{solutionText}</Text>}
                </View>
              )}
          </View>
        )}
      </View>
    )
  }
}

const styles = StyleSheet.create({
  answerText: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  row: {
    flexDirection: 'row',
    padding: 20,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  question: {
    alignItems: 'center',
    userSelect: 'none',
    maxWidth: '40vw',
  },
  fontSize: {
    fontSize:
      sessionStorage.getItem('size') === 'large'
        ? 24
        : sessionStorage.getItem('size') === 'med'
        ? 20
        : 14,
  },
  textInputs: {
    borderWidth: 2,
    borderColor: '#EDEDED',
    borderRadius: 5,
    textAlign: 'center',
    marginHorizontal: 5,
  },
  normalInput: {
    width: '70px',
  },
  exponent1Input: {
    marginTop: 20,
    width: '70px',
  },
  exponent2Input: {
    marginBottom: 20,
    width: 50,
    borderWidth: 2,
    borderColor: '#EDEDED',
    borderRadius: 5,
    textAlign: 'center',
  },
  buttonBar: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  problem: {
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '60vw',
  },
})

export default ScientificProblemForm
