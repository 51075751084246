import React from 'react'
import { ScrollView } from 'react-native'
import { Tabs } from '../../components/'
import jwt from 'jsonwebtoken'
import { Query } from 'react-apollo'
import { GET_FAMILY_ACC_INFO } from '../../../services/queries'
import LoadingPage from '../Loading/LoadingPage'
import dummy_profile from '../../images/dummy_profile.png'
import FamilyProfileForm from './FamilyProfileForm'
import { useCookies } from 'react-cookie'

const tabInfo = [{ label: 'ACCOUNT', filter: () => {} }]

const FamilyProfilePage = props => {
  const [cookies] = useCookies([])
  const id = jwt.decode(cookies['token']).id
  const firstName = cookies['firstName']
  const lastName = cookies['lastName']
  props.changeTitles(`${firstName} ${lastName}`, 'Profile')

  return (
    <Query
      query={GET_FAMILY_ACC_INFO}
      variables={{ id: id }}
      context={{ headers: { 'cache-control': 'no-cache' } }}
    >
      {({ data, loading }) => {
        if (loading) return <LoadingPage />
        const { familyAccount, imageUrl } = data.familyMember

        const url = imageUrl ? imageUrl : dummy_profile
        return (
          <ScrollView>
            <Tabs tabInfo={tabInfo} />

            <FamilyProfileForm
              familyAccount={familyAccount}
              firstName={firstName}
              lastName={lastName}
              imageUrl={url}
            />
          </ScrollView>
        )
      }}
    </Query>
  )
}

export default FamilyProfilePage
