import React, { useState } from 'react'
import { Query } from 'react-apollo'
import { useCookies } from 'react-cookie'
import { GET_INVOICES } from '../../../services/queries/dashboard_queries'
import { View, ScrollView } from 'react-native-web'
import LoadingPage from '../Loading/LoadingPage'
import InvoiceTable from './InvoiceTable'
import CreditTable from './CreditTable'
import PopupInvoiceDetails from './PopupInvoiceDetails'

export default function TransactionPage(props) {
  const [invoiceId, setInvoiceId] = useState(null)
  const [cookies] = useCookies([])
  const familyAccountId = cookies['familyAccountId']

  return (
    <>
      <Query
        query={GET_INVOICES}
        variables={{
          filter: {
            familyAccountId: familyAccountId,
          },
          sort: { issueDate: -1 },
        }}
      >
        {({ data, error, refetch, loading }) => {
          if (loading) return <LoadingPage />
          if (error) return <div>{JSON.stringify(error)}</div>
          const { invoices, credits } = data
          return (
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              {/* {invoices.length===0 && credits.length===0 && (
              )} */}
              {invoices.length > 0 && (
                <ScrollView
                  style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
                >
                  <InvoiceTable
                    invoices={invoices}
                    setInvoiceId={setInvoiceId}
                  />
                </ScrollView>
              )}
              {credits.length > 0 && (
                <ScrollView
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <CreditTable credits={credits} />
                </ScrollView>
              )}
            </View>
          )
        }}
      </Query>
      <PopupInvoiceDetails invoiceId={invoiceId} setInvoiceId={setInvoiceId} />
    </>
  )
}
