import gql from 'graphql-tag'

export const GET_CLASSROOMS = gql`
  query getClassrooms($filter: Filters!) {
    classrooms(filter: $filter) {
      id
      code
      meets
      startTime
      endTime
      title
      course {
        id
        code
        title
        startGrade
        endGrade
        subject {
          name
        }
      }
      centerLocation {
        name
      }
    }
  }
`
