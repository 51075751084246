import { StyleSheet, Text, View, TouchableOpacity } from 'react-native'
import React, { Component } from 'react'
import solveColors from './../../components/colors'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import PrintModal from './PrintModal'
import { withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'

class TimerBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasTimeLimit: props.timeLimit !== null && props.timeLimit > 0,
      currTime: props.totalTimeSpent,
      timer: null,
      complete: props.complete,
      problemId: props.problemId,
      printDialogVisible: false,
    }
    this.tick = this.tick.bind(this)
  }
  componentDidMount() {
    if (!this.state.timer) {
      let timer = setInterval(this.tick, 1000)
      this.setState({ timer: timer })
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.complete !== props.complete) {
      return { complete: props.complete }
    }
    if (state.problemId !== props.problemId) {
      return { problemId: props.problemId }
    }
    return null
  }

  openPrintDialog = () => {
    this.setState({ printDialogVisible: true })
  }
  closePrintDialog = () => {
    this.setState({ printDialogVisible: false })
  }
  adjustTime = time => {
    if (time >= 60) {
      return (
        Math.floor(time / 60) +
        ' m ' +
        ('0' + (time - Math.floor(time / 60) * 60)).slice(-2) +
        ' s'
      )
    }
    return ('0' + time).slice(-2) + ' s'
  }
  tick() {
    if (!this.state.complete && !this.props.review) {
      this.setState({ currTime: this.state.currTime + 1 })
      sessionStorage.setItem(
        `timeSpent:${this.props.assignmentId}`,
        this.state.currTime
      )
      if (
        this.state.hasTimeLimit &&
        this.state.currTime >= this.props.timeLimit
      ) {
        this.props.onTimeOut()
      }
    }
  }
  render() {
    const {
      lessonOrder,
      problemSetOrder,
      problemSetTitle,
      redirect,
      review,
      openRequestHelpForm,
      timeLimit,
      lessonPlanType,
      toggleFullScreen,
      problemSetId,
    } = this.props
    const { printDialogVisible, complete, hasTimeLimit, currTime } = this.state

    return (
      <View style={styles.bar}>
        <PrintModal
          visible={printDialogVisible}
          close={this.closePrintDialog}
          problemSetId={problemSetId}
        />
        <TouchableOpacity
          style={styles.back_button}
          onPress={() => {
            redirect()
          }}
        >
          <ArrowBackIosIcon />
          <Text style={{ fontSize: 20 }}>Lessons</Text>
        </TouchableOpacity>
        <View
          style={{
            maxWidth: '30vw',
          }}
        >
          <Text style={styles.lesson_title}>
            {review ? 'REVIEW: ' : ''}
            Problem Set {lessonOrder}.{problemSetOrder}: {problemSetTitle}{' '}
            {'\n'}
          </Text>
        </View>
        <View style={styles.time}>
          {(this.props.cookies.get('instructor') === '1' ||
            this.props.cookies.get('admin') === '1') && (
            <TouchableOpacity
              style={styles.report_problem}
              onPress={toggleFullScreen}
            >
              <Text style={{ color: solveColors.blue1 }}>{`Full Screen: ${
                this.props.fullScreen ? 'O' : 'X'
              }`}</Text>
            </TouchableOpacity>
          )}
          {this.props.cookies.get('instructor') !== '1' &&
            this.props.cookies.get('admin') !== '1' && (
              <TouchableOpacity
                style={styles.report_problem}
                onPress={() => {
                  openRequestHelpForm()
                }}
              >
                <Text style={{ color: solveColors.blue1 }}>Request Help</Text>
              </TouchableOpacity>
            )}

          {lessonPlanType !== 'INTRODUCTION' && (
            <TouchableOpacity
              style={styles.report_problem}
              onPress={this.openPrintDialog}
            >
              <Text style={{ color: solveColors.blue1 }}>Display All</Text>
            </TouchableOpacity>
          )}
          {hasTimeLimit && !complete && !review && (
            <Text>Time Left: {timeLimit - this.state.currTime}</Text>
          )}
          {hasTimeLimit && complete && !review && (
            <Text style={styles.time}>Times Up!</Text>
          )}
          {!hasTimeLimit && !review && (
            <Text style={{ fontSize: 20 }}>
              Timer: {this.adjustTime(currTime)}
            </Text>
          )}
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  back_button: {
    position: 'absolute',
    left: '2%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  lesson_title: {
    flexWrap: 'wrap',
    alignSelf: 'center',
    fontSize: 20,
  },
  report_problem: {
    borderRadius: 20,
    borderWidth: 2,
    borderColor: solveColors.blue1,
    marginRight: 10,
    paddingVertical: 3,
    paddingHorizontal: 10,
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
  },
  time: {
    position: 'absolute',
    right: '2%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bar: {
    flexDirection: 'row',
    backgroundColor: solveColors.grey2,
    borderTopLeftRadius: 10,
    paddingVertical: 10,
    alignItems: 'center',
    justifyContent: 'center',
    height: '50px',
  },
})

export default withRouter(withCookies(TimerBar))
