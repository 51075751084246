import React from 'react'
import { View, Text, FlatList } from 'react-native-web'
import Button from './Button'
import styles from './styles'

const ProfileTable = props => {
  return (
    <React.Fragment>
      <View style={styles.table}>
        <FlatList
          data={props.info}
          keyExtractor={item => item.left}
          renderItem={({ item }) => {
            return (
              <View style={styles.row}>
                <Text style={styles.left}>{item.left}</Text>
                <Text style={styles.right}>{item.right}</Text>
              </View>
            )
          }}
        />
        {props.onRemindPW && (
          <View style={styles.row}>
            <Text style={styles.left}>Password</Text>
            <Button
              buttonStyling={[styles.pwButton, styles.green]}
              text="CHANGE PASSWORD"
              onPress={props.onRemindPW}
            />
          </View>
        )}
      </View>
    </React.Fragment>
  )
}

export default ProfileTable
