import React, { Component } from 'react'
import { View, Image, ImageBackground, Text, StyleSheet } from 'react-native'
import BannerMessage from '../../components/BannerMessage'
import logo from './../../images/solve-logo.png'
import login_background from './../../images/login_background.svg'
import { access } from '../../components/styles'
import { ApolloConsumer } from 'react-apollo'
import { version } from './../../../../package.json'
import { EMPLOYEE_LOGIN } from '../../../services/queries'
import solveColors from '../../components/colors'
import _ from 'lodash'
import InstructorLoginInput from './InstructorLoginInput'
import { withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'

class InstructorLoginPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      invalidCredentials: false,
      notInstructor: false,
    }
  }
  handleEmailChange = email => {
    this.setState({ email })
  }

  handlePWChange = password => {
    this.setState({ password })
  }

  throttledInstructorLogin = _.throttle(async client => {
    const { data } = await client.mutate({
      mutation: EMPLOYEE_LOGIN,
      variables: {
        email: this.state.email,
        pw: this.state.password,
      },
    })
    if (data && data.employeeLogin.success) {
      if (data.employeeLogin.employee.isTeacher) {
        const { firstName, lastName, imageUrl } = data.employeeLogin.employee
        sessionStorage.clear()
        Object.keys(this.props.cookies.getAll()).forEach(k =>
          this.props.cookies.remove(k)
        )

        this.props.cookies.set('token', data.employeeLogin.token, {
          maxAge: 604800,
        })
        this.props.cookies.set('firstName', firstName, { maxAge: 604800 })
        this.props.cookies.set('lastName', lastName, { maxAge: 604800 })
        this.props.cookies.set('imageUrl', imageUrl, { maxAge: 604800 })
        this.props.cookies.set('instructor', 1, { maxAge: 604800 })
        this.setState({
          invalidCredentials: false,
          notInstructor: false,
        })
        this.props.history.push({ pathname: '/dashboard' })
      } else {
        this.setState({ notInstructor: true })
      }
    } else {
      this.setState({ invalidCredentials: true })
    }
  }, 3000)

  render() {
    return (
      <ApolloConsumer>
        {client => (
          <ImageBackground source={login_background} style={access.background}>
            {this.state.invalidCredentials && (
              <BannerMessage msg="Whoops, either your email or password is invalid" />
            )}
            {this.state.notInstructor && (
              <BannerMessage msg="Sorry! it looks like account is not an instructor!" />
            )}

            <View style={access.header}>
              <Image
                style={access.logo}
                source={
                  this.state.validUN ? this.props.cookies.get('imageUrl') : logo
                }
              />
              <Text style={styles.basicText}>Version {version}</Text>
              <InstructorLoginInput
                onEmailChange={this.handleEmailChange}
                onPWChange={this.handlePWChange}
                onSubmit={() => {
                  this.throttledInstructorLogin(client)
                }}
              />
              <Text style={styles.basicText}>Instructor Login</Text>
            </View>
          </ImageBackground>
        )}
      </ApolloConsumer>
    )
  }
}

const styles = StyleSheet.create({
  basicText: {
    color: solveColors.offWhite,
    textAlign: 'center',
  },
  problemSolvedText: {
    color: solveColors.offWhite,
    textAlign: 'center',
    marginBottom: 15,
    fontSize: 25,
  },
  solvedCount: {
    color: solveColors.offWhite,
    textAlign: 'center',
    fontSize: 100,
  },
})

export default withRouter(withCookies(InstructorLoginPage))
