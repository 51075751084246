import { withRouter } from 'react-router-dom'
import React from 'react'
import { Query } from 'react-apollo'
import { GET_VIDEO } from './../../../services/queries'
import VideoForm from './VideoForm'
import LoadingPage from './../Loading/LoadingPage'
const VideoPage = props => {
  props.changeTitles(sessionStorage.getItem('classroomTitle'))
  const id = props.location.search.split('=')[1]
  if (props.location.search.indexOf('videoId') !== -1) {
    return <VideoForm title={'Lecture Replay'} videoId={id} />
  }
  return (
    <Query query={GET_VIDEO} variables={{ id }}>
      {({ data, loading, error }) => {
        if (error) return error
        if (loading) return <LoadingPage />

        const { title, videoId } = data.assignment.problemSet

        return <VideoForm title={title} videoId={videoId} />
      }}
    </Query>
  )
}

export default withRouter(VideoPage)
