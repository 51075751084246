import { Card, IconButton } from '@material-ui/core'
import React, { Component } from 'react'
import { Query, withApollo } from 'react-apollo'
import { View, Text } from 'react-native-web'
import {
  GET_FUTURE_ATTENDANCE,
  GET_MAKEUP,
} from '../../../services/queries/schedule_queries'
import { formatDate, formatTime } from '../../../utils/dateTime'
import LoadingPage from '../Loading/LoadingPage'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import MakeupDialog from '../Lesson/MakeupDialog'
import solveColors from '../../components/colors'
const WEEK_MS = 604800000

class ScheduleForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      startDate: new Date(),
      // startDate will change when user click on prev week or next week
      // startDate decides the date range of lessons we query
      makeupDialogVisible: false,
      currLesson: null,
    }
  }

  selectLesson = sched => {
    //input is a attendee object
    this.setState({
      makeupDialogVisible: true,
      currLesson: sched.lesson,
      makeup: null,
      currentAttendee: sched,
    })
  }

  selectMadeupLesson = (sched, makeupObj) => {
    //input is a attendee object whose lesson is the make up class student will be attending instead of the original one
    this.setState({
      makeupDialogVisible: true,
      currLesson: sched.lesson,
      makeup: makeupObj,
      currentAttendee: sched,
    })
  }

  render() {
    const { startDate, makeupDialogVisible, currLesson, makeup } = this.state
    return (
      <Query
        query={GET_FUTURE_ATTENDANCE}
        variables={{
          filter: {
            studentId: this.props.studentId,
            lesson: {
              startOn: {
                $gtDate: startDate.toISOString(),
                $ltDate: new Date(startDate.getTime() + WEEK_MS).toISOString(),
              },
              classroom: {
                onCatalog: true,
              },
            },
          },
        }}
      >
        {({ data, loading, error, refetch }) => {
          if (loading) return <LoadingPage />

          if (error) return `${error}`
          const schedule = data.attendees
          return (
            <View>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <IconButton
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                  onClick={() => {
                    this.setState(
                      {
                        startDate: new Date(
                          this.state.startDate.getTime() - WEEK_MS
                        ),
                      },
                      refetch
                    )
                  }}
                >
                  <ArrowBackIosIcon />
                  <span>Prev week</span>
                </IconButton>
                <IconButton
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                  onClick={() => {
                    this.setState(
                      {
                        startDate: new Date(
                          this.state.startDate.getTime() + WEEK_MS
                        ),
                      },
                      refetch
                    )
                  }}
                >
                  <span>Next week</span>
                  <KeyboardArrowRightIcon />
                </IconButton>
              </div>
              <div style={{ padding: '8px' }}>
                <div
                  style={{
                    textAlign: 'center',
                    color: solveColors.blue1,
                    paddingBottom: '16px',
                  }}
                >
                  <div style={{ fontWeight: 'bold', fontSize: 26 }}>
                    Upcoming classes
                  </div>
                  <div>
                    If you cannot attend a class, mark it as absent and we will
                    send you course materials
                  </div>
                </div>
                {schedule.length === 0 && <div>Nothing to show!</div>}
                {schedule.map((sched, ind) => {
                  if (sched.isMakeup) {
                    return (
                      <Query
                        key={ind}
                        query={GET_MAKEUP}
                        variables={{
                          filter: {
                            studentId: this.props.studentId,
                            fromLesson: { id: sched.lesson.id },
                          },
                        }}
                      >
                        {({ loading, error, data }) => {
                          if (loading) return <CardRow sched={sched} />
                          if (error) return <CardRow error={error} />

                          if (data.makeups.length === 0) {
                            return (
                              <CardRow error={'Makeup lesson not found.'} />
                            )
                          }

                          if (data.makeups[0].status === 'ABSENT') {
                            //not yet sure how to handle when the make up class is marked absent for some reason?
                            return null
                          }

                          let makeupSched = { lesson: data.makeups[0].toLesson }

                          //forces .lesson because card row uses .lesson
                          return (
                            <CardRow
                              sched={makeupSched}
                              original={sched}
                              makeup
                              handleOnclick={() =>
                                this.selectMadeupLesson(
                                  makeupSched,
                                  data.makeups[0]
                                )
                              }
                            />
                          )
                        }}
                      </Query>
                    )
                  }
                  return (
                    <CardRow
                      key={ind}
                      sched={sched}
                      handleOnclick={() => {
                        if (sched.status === 'ABSENT') {
                          this.selectMadeupLesson(sched, {
                            originalAbsent: true,
                          })
                        } else {
                          this.selectLesson(sched)
                        }
                      }}
                    />
                  )
                })}
              </div>
              <MakeupDialog
                visible={makeupDialogVisible}
                studentId={this.props.studentId}
                lesson={currLesson}
                attendee={this.state.currentAttendee}
                makeup={makeup}
                openComfirmation={() => {
                  refetch()
                }}
                openError={() =>
                  console.log('You need to select a makeup lesson!')
                }
                closeDialog={() =>
                  this.setState({ makeupDialogVisible: false })
                }
              />
            </View>
          )
        }}
      </Query>
    )
  }
}

function CardRow({ sched, makeup, original, error, handleOnclick }) {
  return (
    <Card
      onClick={() => {
        if (!error && new Date() < new Date(sched.lesson.startOn)) {
          handleOnclick()
        }
      }}
      style={{ padding: '12px', margin: '8px' }}
    >
      {error && <Text>{error}</Text>}
      {!error && (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            textDecoration: sched.status === 'ABSENT' ? 'line-through' : 'none',
          }}
        >
          <div style={{ marginRight: '16px', fontWeight: 'bold' }}>{`${
            sched.lesson.classroom.title.split(':')[
              sched.lesson.classroom.title.split(':').length > 1 ? 1 : 0
            ]
          }`}</div>
          <div
            style={{ fontSize: 'small', color: 'grey', marginRight: '16px' }}
          >
            {formatDate(sched.lesson.startOn, false) +
              ' | ' +
              `${formatTime(sched.lesson.startOn)} - ${formatTime(
                sched.lesson.endOn
              ) +
                ' | ' +
                sched.lesson.classroom.centerLocation.name}`}
          </div>
          {makeup && (
            <div
              style={{ fontSize: 'small', color: 'grey', marginRight: '16px' }}
            >{`Make up lesson for: ${original.lesson.classroom.title}`}</div>
          )}
        </div>
      )}
    </Card>
  )
}

export default withApollo(ScheduleForm)
