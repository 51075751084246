import React, { useState } from 'react'
import { ImageBackground, TouchableOpacity, View, Text } from 'react-native-web'
import { Button, IconButton } from '@material-ui/core'
import logo1 from './../../images/logo1.png'
import logo2 from './../../images/logo2.webp'
import CombinedLoginPage from '../CombinedLogin/CombinedLoginPage'
import { withRouter } from 'react-router-dom'
import MenuIcon from '@material-ui/icons/Menu'
import solveColors from '../../components/colors'
import RegisterPage from '../Register/RegisterPage'
import { useCookies } from 'react-cookie'

function HomePageMobile(props) {
  const [cookies, , removeCookie] = useCookies([])

  const loggedIn = cookies['token']
  const buttons = [
    'home',
    `log ${loggedIn ? 'out' : 'in'}`,
    `join`,
    'catalog',
    'dashboard',
  ]
  const [showLogin, setShowLogin] = useState(
    props.location.state ? props.location.state.openLogin : false
  )
  const [showSideBar, setShowSideBar] = useState(false)
  const [showJoin, setShowJoin] = useState(
    props.location.state ? props.location.state.openJoin : false
  )
  const redirectTo = props.redirectTo
  const backgroundImage = {
    uri:
      'https://ardent-shared-media.s3-us-west-2.amazonaws.com/system/homepage_background.jpg',
  }
  const execute = button => {
    setShowSideBar(false)
    switch (button) {
      case 'home':
        props.history.push('/')
        return
      case 'dashboard':
        props.history.push('/dashboard')
        return
      case 'catalog':
        props.history.push('/catalog')
        return
      case 'join':
        setShowJoin(true)
        return
      case 'log out':
      case 'log in':
        if (loggedIn) {
          sessionStorage.clear()
          Object.keys(cookies).forEach(k => removeCookie(k))
          props.history.go(0)
          return
        }
        setShowLogin(true)
        return
      default:
        return
    }
  }

  return (
    <>
      <View
        style={{
          width: '100%',
          height: '100%',
          position: 'fixed',
          left: showSideBar ? 0 : '-100%',
          zIndex: 999,
          transitionDuration: '0.2s',
          transitionProperty: 'background-color',
          transitionTimingFunction: 'ease-in-out',
          flexDirection: 'row',
        }}
      >
        <View
          style={{
            backgroundColor: solveColors.blue4,
            width: '60%',
            height: '100%',
          }}
        >
          {buttons.map(but => {
            if (but === 'dashboard' && !loggedIn) {
              return null
            }
            return (
              <Button
                style={{
                  paddingTop: '25px',
                  paddingBottom: '25px',
                  color: 'white',
                  borderTop: '1px solid white',
                }}
                onClick={() => execute(but)}
              >
                {but}
              </Button>
            )
          })}
        </View>
        <TouchableOpacity
          style={{
            width: '40%',
            height: '100%',
          }}
          onPress={() => setShowSideBar(false)}
        ></TouchableOpacity>
      </View>
      <ImageBackground
        // style={styles.background}
        // imageStyle={styles.imageBackground}
        style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'space-between',
        }}
        imageStyle={{
          filter: 'brightness(75%)',
          resizeMode: 'cover',
          alignSelf: 'center',
          left: '-65%',
        }}
        source={backgroundImage}
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <IconButton onClick={() => setShowSideBar(true)}>
            <MenuIcon htmlColor="white" />
          </IconButton>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              color="primary"
              variant="contained"
              style={{
                textTransform: 'none',
                marginRight: '10px',
              }}
              onClick={() => {
                execute('log in')
              }}
            >
              {`Log${loggedIn ? ' out' : 'in'}`}
            </Button>
            {!loggedIn && (
              <Button
                color="primary"
                variant="contained"
                style={{
                  textTransform: 'none',
                  marginRight: '10px',
                }}
                onClick={() => {
                  execute('join')
                }}
              >
                Join
              </Button>
            )}
          </div>
        </View>
        <View
          style={{
            backgroundColor: 'white',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '4px',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <TouchableOpacity
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              margin: '8px',
            }}
            onPress={() => {
              execute('home')
            }}
          >
            <img
              src={logo1}
              style={{
                height: 'auto',
                width: '50px',
              }}
              alt={'Home Page'}
            />
            <img
              src={logo2}
              alt={'Home Page'}
              style={{
                height: 'auto',
                width: '150px',
              }}
            />
          </TouchableOpacity>
        </View>
        <View
          style={{
            marginLeft: '8px',
            flexGrow: 1,
            justifyContent: 'flex-end',
            paddingBottom: '12vh',
          }}
        >
          <Text style={{ color: 'white', fontSize: 20 }}>
            Welcome to Ardent Academy
          </Text>
          <br />
          <Text style={{ color: 'white', fontStyle: 'italic' }}>
            Challenging brilliant minds since 2006
          </Text>
          <br />
        </View>
      </ImageBackground>
      <CombinedLoginPage
        visible={showLogin}
        close={() => setShowLogin(false)}
        redirectTo={redirectTo}
      />
      <RegisterPage
        visible={showJoin}
        close={() => setShowJoin(false)}
        redirectTo={redirectTo}
      />
    </>
  )
}

export default withRouter(HomePageMobile)
