import React, { Component } from 'react'
import {
  View,
  TouchableOpacity,
  TextInput,
  StyleSheet,
  Text,
} from 'react-native'
import { solveColors } from '../../components/colors'

class InstructorLoginInput extends Component {
  constructor(props) {
    super(props)
    this.pwInput = React.createRef()
  }

  render() {
    return (
      <View>
        <TextInput
          style={styles.textinput}
          placeholder={'Email'}
          autoFocus={true}
          onChangeText={this.props.onEmailChange}
          placeholderTextColor={solveColors.offWhite}
          onSubmitEditing={() => {
            this.pwInput.current.focus()
          }}
        />
        <TextInput
          ref={this.pwInput}
          style={[styles.textinput, { marginBottom: 20 }]}
          placeholder={'Password'}
          secureTextEntry={true}
          onChangeText={this.props.onPWChange}
          placeholderTextColor={solveColors.offWhite}
          onSubmitEditing={this.props.onSubmit}
        />
        <TouchableOpacity
          style={styles.button}
          disabled={this.props.disabled}
          onPress={this.props.onSubmit}
        >
          <Text style={{ color: 'white' }}>Login</Text>
        </TouchableOpacity>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  textinput: {
    padding: 10,
    color: 'white',
    borderBottomWidth: 1,
    borderBottomColor: 'white',
    margin: 5,
    textAlign: 'center',
  },
  button: {
    alignItems: 'center',
    backgroundColor: '#237FBB',
    padding: 10,
    borderRadius: 10,
    marginBottom: 10,
  },
})

export default InstructorLoginInput
