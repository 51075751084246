import React from 'react'
import { StyleSheet, View, Text } from 'react-native-web'
import ReferralTable from './ReferralTable'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard'
import Badge from '@material-ui/core/Badge'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import TablePagination from '@material-ui/core/TablePagination'
import { solveColors } from '../../components/colors'

const ReferralDetail = props => {
  const {
    referrals,
    page,
    rows,
    handleChangePage,
    handleChangeRows,
    windowWidth,
  } = props

  const signups = referrals.filter(referral => referral.status === 'SIGNEDUP')
    .length
  const enrolled = referrals.filter(referral => referral.status === 'ENROLLED')
    .length

  return (
    <View style={styles.rootContainer}>
      <View style={styles.sidebarContainer}>
        <div
          style={{
            width: '100%',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Badge
            color="secondary"
            badgeContent={signups.toString()}
            styles={{ alignSelf: 'center' }}
          >
            <PeopleAltIcon
              style={{
                width: '55px',
                height: '55px',
                color: solveColors.navy,
              }}
            />
          </Badge>
          <Text style={styles.sidebarText}>Signups</Text>
        </div>
        <div
          style={{
            width: '100%',
            height: 'auto',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Badge color="secondary" badgeContent={enrolled.toString()}>
            <CardGiftcardIcon
              style={{
                width: '55px',
                height: '55px',
                color: solveColors.navy,
              }}
            />
          </Badge>
          <Text style={styles.sidebarText}>Enrollments</Text>
        </div>
        <div
          style={{
            width: '100%',
            height: 'auto',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Badge color="secondary" badgeContent={`$${enrolled * 100}`}>
            <MonetizationOnIcon
              style={{
                width: '55px',
                height: '55px',
                color: solveColors.navy,
              }}
            />
          </Badge>
          <Text style={styles.sidebarText}>Credits</Text>
        </div>
      </View>
      <View style={styles.referralTableContainer}>
        <ReferralTable
          referrals={referrals}
          page={page}
          rows={rows}
          handleChangePage={handleChangePage}
          handleChangeRows={handleChangeRows}
          windowWidth={windowWidth}
        />
        <TablePagination
          style={{ marginTop: 'auto' }}
          rowsPerPageOptions={[5]}
          component="div"
          count={referrals ? referrals.length : 0}
          rowsPerPage={rows}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRows}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  rootContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    height: '383px',
  },
  sidebarContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-around',
    borderRightWidth: '1px',
    borderRightColor: solveColors.grey1,
  },
  referralTableContainer: {
    flexDirection: 'column',
    display: 'flex',
    flex: 6,
    height: 'auto',
  },
  badgeContainer: {
    width: '100%',
    height: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sidebarText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: solveColors.navy,
  },
})

export default ReferralDetail
