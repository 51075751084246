import { StyleSheet, View, Text, TouchableOpacity } from 'react-native'
import React, { Component } from 'react'
import Modal from 'modal-react-native-web'
import solveColors from './../../components/colors'
import { DropDownMenu } from './../../components/'
import { Query, ApolloConsumer } from 'react-apollo'
import { GET_MAKEUP_LESSONS, CREATE_MAKEUP } from './../../../services/queries'
import { formatTime } from './../../../utils/dateTime'
import jwt from 'jsonwebtoken'
import { Button } from '@material-ui/core'
import {
  ARCHIVE_MAKEUP,
  UPDATE_ATTENDANCE,
} from '../../../services/queries/attendance_queries'
import { withCookies } from 'react-cookie'

class MakeupDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: props.visible,
      description: '',
      makeupLesson: props.makeup ? props.makeup.toLesson : null,
      error: '',
      setAbsent: props.makeup ? false : true,
      loading: false,
      attendeeId: props.attendeeId,
    }
  }
  componentDidMount() {
    Modal.setAppElement('body')
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.visible !== this.props.visible ||
      prevProps.makeup !== this.props.makeup ||
      prevProps.attendeeId !== this.props.attendeeId
    ) {
      this.setState({
        visible: this.props.visible,
        description: '',
        makeupLesson: this.props.makeup ? this.props.makeup.toLesson : null,
        error: '',
        setAbsent: this.props.makeup ? false : true,
        loading: false,
        attendeeId: this.props.attendeeId,
      })
    }
  }
  handleSchedule = async client => {
    const { lesson } = this.props
    const { description, makeupLesson, setAbsent, attendeeId } = this.state
    if (!makeupLesson && !this.state.setAbsent) {
      //didn't chose a lesson and didnt mark absence
      this.setState({
        error: 'You need to select a makeup lesson.',
      })
      return
    }
    this.setState({ error: '', loading: true })
    try {
      if (setAbsent) {
        await client.mutate({
          mutation: UPDATE_ATTENDANCE,
          variables: {
            id: attendeeId,
            input: { status: 'ABSENT', note: description },
          },
        })
      } else {
        await client.mutate({
          mutation: CREATE_MAKEUP,
          variables: {
            input: {
              studentId:
                this.props.studentId ||
                jwt.decode(this.props.cookies.get('token')).id,
              createdById: 'b001d0de-a579-4535-ab3e-ac7ebe165484',
              fromLessonId: lesson.id,
              toLessonId: makeupLesson ? makeupLesson.id : null,
              status: this.state.setAbsent ? 'ABSENT' : 'SCHEDULED',
              notes: description,
            },
          },
        })
      }
    } catch (e) {
      console.log('error: ', `${e}`)
      this.setState({
        error: 'Unsuccessful, please try again later',
        loading: false,
      })
      return
    }
    this.setState({ loading: false })
    this.props.openComfirmation()
    this.props.closeDialog()
  }

  handleUndoMakeup = async client => {
    const { attendeeId } = this.state
    this.setState({ loading: true })
    try {
      if (this.props.makeup.originalAbsent) {
        //if trying to undo absent
        await client.mutate({
          mutation: UPDATE_ATTENDANCE,
          variables: {
            id: attendeeId,
            input: { status: 'UNKNOWN', note: '' },
          },
        })
      } else {
        //trying to undo makeup class
        await client.mutate({
          mutation: ARCHIVE_MAKEUP,
          variables: { id: this.props.makeup.id },
        })
      }
    } catch (e) {
      console.log('error: ', `${e}`)
      this.setState({
        error: 'something went wrong, please try again later',
        loading: false,
      })
    }
    this.setState({ loading: false })
    this.props.openComfirmation()
    this.props.closeDialog()
  }

  render() {
    const { lesson, makeup } = this.props
    const { description, visible, makeupLesson, error, loading } = this.state
    if (!lesson) {
      return <View />
    }
    const { title, order } = lesson.lessonPlan
    const { startOn, endOn } = lesson
    return (
      <ApolloConsumer>
        {client => (
          <Modal visible={visible} transparent={true}>
            <View style={styles.backdrop}>
              <View style={styles.results}>
                <Text style={styles.title}>Schedule a Makeup</Text>
                <Text style={styles.sectionHeader}>Current: </Text>
                <View style={styles.section}>
                  <Text
                    style={{ marginLeft: 10 }}
                  >{`Classroom: ${sessionStorage.getItem('classroomTitle') ||
                    this.props.lesson.classroom.title}`}</Text>
                  <Text style={{ marginLeft: 10 }}>
                    {`Lesson: ${order}. ${title} ${new Date(
                      lesson.startOn
                    ).toDateString()} ${formatTime(startOn)} - ${formatTime(
                      endOn
                    )}
                  `}
                  </Text>
                </View>
                <View style={styles.section}>
                  <Text
                    style={{
                      alignSelf: 'flex-start',
                      fontSize: 12,
                      fontWeight: 'bold',
                    }}
                  >
                    Makeup:{' '}
                  </Text>
                  <Text style={{ marginLeft: 10 }}>{`Classroom: ${
                    makeupLesson ? makeupLesson.classroom.title : ''
                  }`}</Text>
                  {makeup && (
                    <div>
                      <Text style={{ margin: 10 }}>
                        {makeup.originalAbsent
                          ? `Absent`
                          : `Lesson: ${makeup.toLesson.lessonPlan.title}`}
                      </Text>
                      <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={() => this.handleUndoMakeup(client)}
                        disabled={loading}
                      >
                        undo
                      </Button>
                    </div>
                  )}
                  {!makeup && (
                    //only show selection when this class is not a makeup class
                    <Query
                      query={GET_MAKEUP_LESSONS}
                      variables={{
                        lessonId: lesson.id,
                      }}
                    >
                      {({ data, loading, error }) => {
                        if (loading) return 'Loading...'
                        if (error) return `${error}`
                        const { success, makeupLessons } = data.getMakeupLessons
                        if (!success || error || makeupLessons.length === 0) {
                          return (
                            <Text style={{ marginLeft: 10 }}>
                              There are no makeups avaialable for this lessons
                              :(
                            </Text>
                          )
                        }
                        return (
                          <DropDownMenu
                            name={'Lesson:'}
                            options={[
                              this.props.cookies.get('family') === '1'
                                ? {
                                    key: 'Mark me absent',
                                    value: 'absent',
                                  }
                                : { key: '', value: null },
                              ...makeupLessons.map(l => ({
                                key: `${l.lessonPlan.title}: ${new Date(
                                  l.startOn
                                ).toDateString()} ${formatTime(
                                  l.startOn
                                )} - ${formatTime(l.endOn)}`,
                                value: l.id,
                              })),
                            ]}
                            field={
                              this.state.setAbsent
                                ? 'absent'
                                : makeupLesson
                                ? makeupLesson.id
                                : null
                            }
                            onChange={value => {
                              if (value === 'absent') {
                                //special case for absent
                                this.setState({
                                  setAbsent: true,
                                  makeupLesson: null,
                                })
                                return
                              }
                              this.setState({
                                makeupLesson: makeupLessons.find(
                                  m => m.id === value
                                ),
                                setAbsent: false,
                              })
                            }}
                          />
                        )
                      }}
                    </Query>
                  )}
                </View>
                <View style={styles.section}>
                  <Text style={styles.sectionHeader}>Note:</Text>
                  <textarea
                    style={{
                      minHeight: 200,
                      borderRadius: 5,
                      borderColor: solveColors.grey3,
                      alignSelf: 'center',
                      minWidth: 150,
                      width: '100%',
                      resize: 'none',
                      fontSize: 16,
                      marginBottom: 10,
                    }}
                    placeholder={'Reason For Makeup...'}
                    value={
                      makeup
                        ? makeup.originalAbsent
                          ? this.props.attendee.note
                          : makeup.notes
                        : description
                    }
                    readOnly={makeup}
                    onChange={event => {
                      this.setState({ description: event.target.value })
                    }}
                  />
                </View>
                {error !== '' && (
                  <Text style={{ color: 'red', marginBottom: 10 }}>
                    {error}
                  </Text>
                )}
                <View style={{ flexDirection: 'row' }}>
                  <TouchableOpacity
                    disabled={makeup || loading}
                    style={[
                      styles.button,
                      {
                        backgroundColor: solveColors.blue2,
                        bottom: 0,
                        marginRight: 20,
                      },
                    ]}
                    onPress={() => this.handleSchedule(client)}
                  >
                    <Text style={{ color: 'white' }}>Schedule</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={[
                      styles.button,
                      { backgroundColor: solveColors.blue2, bottom: 0 },
                    ]}
                    onPress={() => {
                      this.props.closeDialog()
                    }}
                  >
                    <Text style={{ color: 'white' }}>Cancel</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
        )}
      </ApolloConsumer>
    )
  }
}

const styles = StyleSheet.create({
  results: {
    maxWidth: '800px',
    width: '100%',
    borderRadius: '10px',
    margin: 'auto',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: 25,
  },
  backdrop: {
    backgroundColor: 'rgba(52, 52, 52, 0.2)',
    height: '100%',
    width: '100%',
  },
  button: {
    width: 100,
    height: 30,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  section: {
    marginVertical: 5,
    width: '100%',
  },
  title: {
    textAlign: 'center',
    marginBottom: 20,
    fontSize: 20,
    color: solveColors.blue2,
  },
  sectionHeader: {
    alignSelf: 'flex-start',
    fontSize: 12,
    fontWeight: 'bold',
  },
})
export default withCookies(MakeupDialog)
