import React, { Component } from 'react'
import { View } from 'react-native-web'
import Header from './Header'
import styles from './styles'
import Router from '../../router'
import { isMobile, doNotShowHeader } from '../../config'
import { VideoModal } from '.'

class Main extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showSide: !isMobile,
      hideTop: false,
      videoModalVisible: false,
    }
  }

  hideBars = () => {}

  displayBars = () => {}

  toggle = () => {
    this.setState({
      showSide: !this.state.showSide,
      hideTop: !this.state.hideTop,
    })
  }

  updateTitles = (title, subtitle = '') => {
    if (title !== this.state.title)
      this.setState({ ...this.state, title: title })
    if (subtitle !== this.state.subtitle)
      this.setState({ ...this.state, subtitle: subtitle })
  }

  render() {
    return (
      <View style={[styles.background, { flexDirection: 'column' }]}>
        {/* <NavigationBar
          hide={!this.state.showSide}
          toggleBars={() => this.setState({ showSide: !this.state.showSide })}
        /> */}
        <Header
          title={this.state.title}
          subtitle={this.state.subtitle}
          hide={this.state.hideTop}
          toggleBars={() => this.setState({ showSide: !this.state.showSide })}
          openVideoModal={() => this.setState({ videoModalVisible: true })}
        />
        <View
          style={{
            width:
              isMobile ||
              doNotShowHeader.includes(window.location.pathname) ||
              !this.state.showSide
                ? '100%'
                : 'calc(100% - 100px)',
            alignSelf: 'flex-end',
            flex: 1,
            display: 'flex',
          }}
        >
          <Router
            displayBars={this.displayBars}
            hideBars={this.hideBars}
            updateTitles={this.updateTitles}
            toggleMenu={this.toggle}
          />
        </View>
        <VideoModal
          visible={this.state.videoModalVisible}
          onClose={() => this.setState({ videoModalVisible: false })}
          videoId={'438274859'}
        />
      </View>
    )
  }
}

export default Main
