import React from 'react'
import { View, Image } from 'react-native'
import logo from './../../images/solve-logo.png'
import { animated, useSpring } from 'react-spring'
import solveColors from '../../components/colors'
import { delay } from 'q'

const LoadingPage = props => {
  const spring = useSpring({
    config: { friction: 60, mass: 20 },
    from: { transform: 'rotate(0deg)' },
    to: async next => {
      for (let i = 1; i < 1000; i++) {
        next({ transform: `rotate(${i * 720}deg)` })
        await delay(2500)
      }
    },
  })
  return (
    <View
      style={{
        margin: 'auto',
        backgroundColor: solveColors.blue2,
        borderRadius: 200,
        padding: 3,
      }}
    >
      <animated.div style={spring}>
        <Image style={{ width: 100, height: 100 }} source={logo} />
      </animated.div>
    </View>
  )
}

export default LoadingPage
