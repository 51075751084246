import { FlatList, Text, View } from 'react-native-web'
import Card from 'react-bootstrap/Card'
import React, { useState } from 'react'
import Button from './Button'
import { withRouter } from 'react-router-dom'
import solveColors from '../../components/colors'
import { AppreciationForm } from '../../components'
import { numToLetterGrade } from './../../../utils/grades'
import { formatDate, getMonth, getYear } from './../../../utils/dateTime'
import {
  StarBorder,
  ClassOutlined,
  SentimentSatisfiedOutlined,
} from '@material-ui/icons/'
import styles from './styles'

//remove after teacher appreciation week
import { useCookies } from 'react-cookie'
import jwt from 'jsonwebtoken'

const isTeacherAppreciation = false

const sortClassrooms = (a, b) =>
  a.classroom.code.charAt(0) < b.classroom.code.charAt(0)
    ? -1
    : a.classroom.code.charAt(0) > b.classroom.code.charAt(0)
    ? 1
    : new Date(b.classroom.endDate).getTime() -
      new Date(a.classroom.endDate).getTime()

export const EnrollmentForm = props => {
  const { history, windowWidth, filteredEnrollments, progress, filter } = props

  //remove after teacher appreciation week
  const [cookies] = useCookies([])
  const token = cookies['token'] && jwt.decode(cookies['token'])
  let studentId
  if (token && token.userType === 'Student') {
    studentId = token.id
  }

  const [yearFilter] = useState(null)
  const [monthFilter] = useState(null)
  let allEnrollments = []
  let enrolledClasses = []
  let trials = {}
  if (sessionStorage.getItem('trials')) {
    JSON.parse(sessionStorage.getItem('trials')).forEach(trial => {
      trials[trial.classroomId] = trial.toLesson
    })
  }
  const years = new Set(
    filteredEnrollments
      .map(e => getYear(e.classroom.endDate))
      .sort()
      .reverse()
  )
  if (filter === 'OPENED') {
    const openEnrollments = filteredEnrollments
      .filter(
        item =>
          (item.status === 'PAID' || !item.status) &&
          item.classroom.status === 'OPENED'
      )
      .sort(sortClassrooms)
    if (openEnrollments.length > 0) {
      // allEnrollments.push('OPENED CLASSES:')
      // allEnrollments.push(openEnrollments)
      enrolledClasses.push(...openEnrollments)
    }

    const publishedEnrollments = filteredEnrollments
      .filter(
        item =>
          (item.status === 'PAID' || !item.status) &&
          item.classroom.status === 'PUBLISHED'
      )
      .sort(sortClassrooms)

    if (publishedEnrollments.length > 0) {
      // allEnrollments.push('PUBLISHED CLASSES:')
      //allEnrollments.push(publishedEnrollments)
      enrolledClasses.push(...publishedEnrollments)
    }
    allEnrollments.push(enrolledClasses)
    const qualifyEnrollments = filteredEnrollments
      .filter(item => item.status === 'QUALIFY')
      .sort(sortClassrooms)
    if (qualifyEnrollments.length > 0) {
      allEnrollments.push('PREVIEW CLASSES:')
      allEnrollments.push(qualifyEnrollments)
    }
    const trialEnrollments = filteredEnrollments
      .filter(item => item.status === 'TRIAL')
      .sort(sortClassrooms)
    if (trialEnrollments.length > 0) {
      allEnrollments.push('TRIAL CLASSES:')
      allEnrollments.push(trialEnrollments)
    }
  } else {
    let sortedDateEnrollments = filteredEnrollments.concat()
    if (monthFilter !== null && monthFilter !== undefined) {
      sortedDateEnrollments = sortedDateEnrollments.filter(
        e => new Date(e.classroom.endDate).getMonth() === monthFilter
      )
    }
    if (yearFilter) {
      sortedDateEnrollments = sortedDateEnrollments.filter(
        e =>
          new Date(e.classroom.endDate).getYear() + 1900 ===
          parseInt(yearFilter)
      )
    }
    for (const year of years) {
      const yearEnrollments = sortedDateEnrollments.filter(
        r => getYear(r.classroom.endDate) === year
      )
      for (let m = 11; m >= 0; m--) {
        let monthEnrollments = yearEnrollments
          .filter(r => new Date(r.classroom.endDate).getMonth() === m)
          .sort()
        if (monthEnrollments.length > 0) {
          const { endDate } = monthEnrollments[0].classroom
          allEnrollments.push(`${getMonth(endDate)} ${year}`)
          allEnrollments.push(monthEnrollments)
        }
      }
    }
  }

  return (
    <React.Fragment>
      {studentId && filter !== 'CLOSED' && isTeacherAppreciation && (
        <AppreciationForm
          openEnrollments={allEnrollments ? allEnrollments[0] : []}
          windowWidth={windowWidth}
          studentId={studentId}
        />
      )}
      {/* {filter === 'CLOSED' && (
        <View
          style={{
            alignSelf: 'flex-start',
            marginLeft: '10vw',
            flexDirection: 'row',
            marginVertical: 10,
          }}
        >
          <View
            style={[
              {
                background: `linear-gradient(to right, #07c1eb 50%, ${solveColors.blue2} 90%)`,
                alignSelf: 'center',
                marginRight: 10,
                marginTop: 25,
              },

              styles.sortButton,
            ]}
          >
            <Text style={{ fontSize: 18, color: 'white' }}>Filter:</Text>
          </View>

          <DropDownMenu
            name={'Year'}
            width={50}
            field={null}
            options={[null, ...Array.from(years)]}
            onChange={year => {
              setYearFitler(year)
            }}
          />
          <DropDownMenu
            name={'Months'}
            width={50}
            field={null}
            options={[null, ...moment.months()]}
            onChange={month => {
              setMonthFilter(
                !month
                  ? null
                  : moment()
                      .month(month)
                      .format('M') - 1
              )
            }}
          />
        </View>
      )} */}
      {/* data={allEnrollments}
        keyExtractor={(item, index) => index}
        renderItem={({ item }) =>  */}
      {allEnrollments.map(item => {
        if (typeof item === 'string') {
          return (
            <View
              style={[
                {
                  background: `linear-gradient(to right, #07c1eb 50%, ${solveColors.blue2} 90%)`,
                  minWidth: (Math.floor(windowWidth / 350) - 1) * 350,
                },
                styles.sortButton,
              ]}
            >
              <Text style={{ fontSize: 20, color: 'white' }}>{item}</Text>
            </View>
          )
        } else {
          return (
            <FlatList
              numColumns={
                Math.floor(windowWidth / 350) >= 1
                  ? Math.floor(windowWidth / 350)
                  : 1
              }
              initialNumToRender={item.length}
              data={item}
              key={Math.floor(windowWidth / 350)}
              keyExtractor={item => item.classroom.id}
              renderItem={({ item }) => {
                const {
                  classroom,
                  overallGrade,
                  status: enrollmentStatus,
                } = item
                const {
                  startDate,
                  endDate,
                  course,
                  status: classroomStatus,
                  title,
                  teachingStyle,
                  id: classroomId,
                  title: classroomTitle,
                  finalAccessDate,
                } = classroom
                const within3Weeks =
                  new Date().getTime() - new Date(endDate).getTime() <=
                  60 * 60 * 24 * 7 * 1000 * 3
                const allowAccess =
                  finalAccessDate &&
                  new Date(finalAccessDate).getTime() >
                    new Date(endDate).getTime()
                    ? new Date().getTime() <=
                      new Date(finalAccessDate).getTime()
                    : within3Weeks
                const classroomStartDate = formatDate(startDate)
                const classroomEndDate = formatDate(endDate)
                const buttonDisabled = false
                const tutored = teachingStyle === 'TUTORED'
                const centerLocation = classroom.centerLocation
                  ? classroom.centerLocation.name
                  : 'ONLINE'
                return (
                  <Card
                    title="CLASSROOM CARD"
                    style={{
                      backgroundColor: 'white',
                      margin: 10,
                      width: 250,
                      boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.25)',
                    }}
                  >
                    <Card.Img style={{ width: 250 }} src={course.imageUrl} />
                    <Card.Body
                      style={{
                        padding: 10,
                        display: 'flex',
                        flexDirection: 'column',
                        paddingBottom: 100,
                      }}
                    >
                      <Card.Title
                        style={{
                          fontWeight: 'bold',
                          fontSize: '16px',
                          paddingBottom: 10,
                        }}
                      >
                        {title.includes(':')
                          ? title.split(':')[1].trim()
                          : title}
                      </Card.Title>
                      {!tutored && (
                        <Text style={{ alignSelf: 'center' }}>
                          {centerLocation}
                        </Text>
                      )}
                      {tutored && (
                        <Text style={{ alignSelf: 'center' }}>
                          One-On-One Tutoring
                        </Text>
                      )}
                      {/*
                    <Text>{`${classroomDays}: ${classroomStartTime} - ${classroomEndTime}`}</Text>
                  */}
                      {!tutored && (
                        <Text>{`${classroomStartDate} - ${classroomEndDate}`}</Text>
                      )}
                      {/* {tutored && (
                        <Text>{`${classroomStartDate} - ${classroomEndDate}`}</Text>
                        )} */}
                      <View
                        style={{
                          position: 'absolute',
                          bottom: 20,
                          width: 230,
                        }}
                      >
                        <Button
                          disabled={buttonDisabled}
                          allowAccess={allowAccess}
                          status={classroomStatus}
                          progress={
                            progress.find(a => a.classroomId === classroomId) ||
                            ''
                          }
                          onPress={() => {
                            sessionStorage.setItem('classroomId', classroomId)
                            sessionStorage.setItem(
                              'classroomTitle',
                              classroomTitle.split(':')[1].trim()
                            )
                            history.push({
                              pathname:
                                filter === 'OPENED' ||
                                allowAccess ||
                                enrollmentStatus === 'QUALIFY' ||
                                enrollmentStatus === 'TRIAL'
                                  ? '/lessons'
                                  : '/transcript',
                              search: `classroomId=${classroomId}`,
                            })
                            if (
                              filter === 'OPENED' ||
                              allowAccess ||
                              enrollmentStatus === 'QUALIFY' ||
                              enrollmentStatus === 'TRIAL'
                            ) {
                              if (enrollmentStatus === 'TRIAL') {
                                sessionStorage.setItem('trial', 1)
                              }
                              if (enrollmentStatus === 'QUALIFY') {
                                sessionStorage.setItem('preview', 1)
                              }
                              if (trials[classroomId]) {
                                sessionStorage.setItem(
                                  'trialToLesson',
                                  trials[classroomId]
                                )
                              }
                              sessionStorage.removeItem('teachingSyle')
                              sessionStorage.setItem(
                                'teachingStyle',
                                teachingStyle
                              )
                            }
                          }}
                        />
                        <View
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            borderBottomWidth: 2,
                            borderColor: solveColors.grey1,
                            marginTop: 20,
                            paddingVertical: 5,
                          }}
                        >
                          <Card.Body
                            title="Grade"
                            style={{
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <StarBorder style={{ height: 15 }} />
                            <Text>{numToLetterGrade(overallGrade)}</Text>
                          </Card.Body>
                          <Card.Body
                            title="Overdue Assignments"
                            style={{
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <ClassOutlined style={{ height: 15 }} />
                            <Text title="Overdue Assignments">2</Text>
                          </Card.Body>
                          <Card.Body
                            title="Points"
                            style={{
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <SentimentSatisfiedOutlined
                              style={{ height: 15 }}
                            />
                            <Text title="Points">350</Text>
                          </Card.Body>
                        </View>
                      </View>
                    </Card.Body>
                  </Card>
                )
              }}
            />
          )
        }
      })}
    </React.Fragment>
  )
}

export default withRouter(EnrollmentForm)
