import React, { Suspense } from 'react'
import { CircularProgress } from '@material-ui/core'

const LazyLoadVerify = React.lazy(() => import('./Verify'))

const Verify = props => (
  <Suspense fallback={<CircularProgress />}>
    <LazyLoadVerify {...props} />
  </Suspense>
)

export default Verify
