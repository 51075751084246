import { StyleSheet, View, Text } from 'react-native'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import solveColors from './../../components/colors'
import InjectedCheckoutForm from './CheckoutForm'
import { ScrollView } from 'react-native-web'
import LoadingPage from '../Loading/LoadingPage'
import { Query } from 'react-apollo'
import { GET_INVOICE } from '../../../services/queries/payment_queries'
import { withCookies } from 'react-cookie'

class PaymentPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      classroomId: props.location.search.split('=')[1],
      hover: false,
      legalCheck: false,
    }
  }
  toggleLegal = ind => {
    this.setState({ legalCheck: !this.state.legalCheck })
  }

  setHover(bool) {
    this.setState({ hover: bool })
  }

  componentDidMount() {
    if (!this.props.cookies.get('familyAccountId')) {
      this.props.history.push('/family')
    }
    if (!this.props.cookies.get('token')) {
      alert('An error has occured. Please try again.')
      this.props.history.goBack()
    }
  }

  render() {
    const { legalCheck } = this.state

    if (!this.props.cookies.get('token')) {
      return <LoadingPage />
    }
    return (
      <Query
        query={GET_INVOICE}
        variables={{
          filter: {
            familyAccountId: this.props.cookies.get('familyAccountId'),
            $or: [{ status: 'PENDING' }, { status: 'OPEN' }],
          },
        }}
        fetchPolicy="no-cache"
      >
        {({ data, refetch, error, loading }) => {
          if (loading) {
            return <LoadingPage />
          }
          if (error) {
            return `${error}`
          }
          if (!data || data.invoices.length !== 1) {
            return 'Could not successfully read shopping cart'
          }
          const invoice = data.invoices[0]
          return (
            <ScrollView
              style={{
                backgroundColor: 'white',
                padding: '6px',
                maxHeight: '800px',
              }}
            >
              <View>
                <Text style={styles.notifyHeader}>
                  Registration and Payment
                </Text>
                <Text style={styles.notifyBody}>
                  Full payment of tuition and fees is required at the time of
                  registration. We accept Discover, MasterCard, Visa, and
                  American Express.
                </Text>

                <div ref={this.legalRef}></div>

                <Text style={styles.notifyHeader}>
                  Enrollment Agreement and Withdrawal Policy
                </Text>
                <View style={{ width: '100%', fontSize: '14px' }}>
                  <Text style={styles.notifyBody}>
                    Withdrawals made 1 week before the first day of class will
                    receive a full refund of the course tuition minus the
                    processing fee of $50. Otherwise, no refund nor credit.
                    Class change is allowed up to 2 weeks before the start of
                    class, depending on availability and upon instructor's
                    approval.
                  </Text>
                  <br />
                  <Text style={styles.notifyBody}>
                    No pro-rated tuition or credit will be given for missed
                    classes.
                  </Text>
                  <br />
                  <Text style={styles.notifyBody}>
                    Class schedules are subject to change or cancellations.
                  </Text>
                  <br />
                  <Text style={styles.notifyBody}>
                    Class change or withdrawal requests must be made by emailing{' '}
                    <a href="mailto:care@ArdentAcademy.com">
                      care@ArdentAcademy.com
                    </a>
                    .
                  </Text>
                  <br />
                  <label style={{ marginLeft: '10px' }}>
                    <input
                      type="checkbox"
                      selected={legalCheck}
                      onChange={() => this.toggleLegal()}
                    />
                    <b>
                      I have read and agree to the Enrollment Agreement and
                      Withdrawal Policy.
                    </b>
                  </label>
                </View>
                <Text style={styles.notifyHeader}>Pay via Credit Card</Text>

                {/* <Text style={styles.notifyBody}>
                  If you are a current or previous student and you forgot your
                  login credentials, please contact our offices at 949-861-2211.
                </Text> */}
              </View>
              <View
                style={[
                  styles.payblock,
                  {
                    boxShadow: this.state.hover
                      ? '0 8px 16px 0 rgba(0,0,0,0.4)'
                      : '0 4px 8px 0 rgba(0,0,0,0.4)',
                  },
                ]}
                onMouseEnter={() => {
                  this.setHover(true)
                }}
                onMouseLeave={() => {
                  this.setHover(false)
                }}
              >
                <InjectedCheckoutForm
                  legal={legalCheck}
                  credits={this.props.credits}
                  invoice={invoice}
                  handleConfirmPayment={this.props.handleConfirmPayment}
                />
              </View>
            </ScrollView>
          )
        }}
      </Query>
    )
  }
}

const styles = StyleSheet.create({
  button: {
    width: 100,
    height: 40,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: solveColors.blue5,
  },

  body: {
    width: '100%',
    marginBottom: '2vh',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  info: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    flexGrow: 1,
    flex: 1,
    marginBottom: '2vh',
  },
  notifyHeader: {
    fontSize: '21px',
    color: solveColors.blue5,
    marginTop: '10px',
  },
  notifyBody: { width: '100%', marginLeft: '10px' },
  payblock: {
    backgroundColor: 'white',
    maxWidth: '500px',
    margin: '10px',
    padding: '10px',
    borderRadius: '10px',
    marginBottom: '20px',
    transitionDuration: '0.3s',
    transitionProperty: 'background-color',
    transitionTimingFunction: 'ease-in-out',
  },
})

export default withRouter(withCookies(PaymentPage))
