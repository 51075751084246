import React, { useState, useEffect } from 'react'
import {
  Text,
  TouchableOpacity,
  Dimensions,
  ScrollView,
} from 'react-native-web'
import { isMobile } from '../../config'
import styles from './styles'
import solveColors from './colors'
import { Badge } from '@material-ui/core'

export const Tabs = props => {
  const tabLocks = props.tabLocks || [false, false, false]
  const buttonTextColor = solveColors.grey4
  const defaultTab = props.defaultTab || 0
  const [selected, setSelected] = useState(props.tabInfo[defaultTab].label)
  const [windowWidth, setWindowWidth] = useState(Dimensions.get('window').width)

  useEffect(() => {
    const getWidth = () => setWindowWidth(Dimensions.get('window').width)

    window.addEventListener('resize', getWidth)

    return () => window.removeEventListener('resize', getWidth)
  }, [props.tabInfo])

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <ScrollView horizontal style={styles.tabs}>
        {props.tabInfo.map((item, index) => (
          <TouchableOpacity
            key={item.label}
            disabled={tabLocks[index]}
            style={[
              styles.tabBtn,
              selected === item.label
                ? {
                    borderBottomColor: solveColors.blue1,
                    borderBottomStyle: 'solid',
                    borderBottomWidth: '2px',
                  }
                : null,
              isMobile && {
                width: windowWidth / props.tabInfo.length,
              },
            ]}
            onPress={() => {
              if (!tabLocks[index]) {
                item.filter()
                setSelected(item.label)
              }
            }}
          >
            {isMobile && item.iconLabel ? (
              <React.Fragment>{item.iconLabel}</React.Fragment>
            ) : (
              <React.Fragment>
                <Text
                  style={[
                    styles.tabBtnText,
                    item.disabled
                      ? { color: solveColors.grey3 }
                      : selected === item.label
                      ? { color: solveColors.blue1 }
                      : { color: buttonTextColor },
                    tabLocks[index] && { color: solveColors.grey3 },
                  ]}
                >
                  {item.label}
                </Text>
                {item.notifications > 0 && (
                  <Badge
                    badgeContent={item.notifications}
                    color="error"
                    style={{ marginLeft: 10 }}
                  />
                )}
              </React.Fragment>
            )}
          </TouchableOpacity>
        ))}
      </ScrollView>
    </div>
  )

  // return (
  //   <React.Fragment>
  //     <View style={styles.tabs}>
  //       <FlatList
  //         numColumns={3}
  //         key={Math.floor(windowWidth / 160)}
  //         keyExtractor={item => item.label}
  //         extraData={selected}
  //         data={props.tabInfo}
  //         renderItem={({ item, index }) => (
  //           <TouchableOpacity
  //             disabled={tabLocks[index]}
  //             style={[
  //               styles.tabBtn,
  //               selected === item.label
  //                 ? {
  //                     borderBottomColor: solveColors.blue1,
  //                     borderBottomStyle: 'solid',
  //                     borderBottomWidth: '2px',
  //                   }
  //                 : null,
  //             ]}
  //             onPress={() => {
  //               if (!tabLocks[index]) {
  //                 item.filter()
  //                 setSelected(item.label)
  //               }
  //             }}
  //           >
  //             <Text
  //               style={[
  //                 styles.tabBtnText,
  //                 item.disabled
  //                   ? { color: solveColors.grey3 }
  //                   : selected === item.label
  //                   ? { color: solveColors.blue1 }
  //                   : { color: buttonTextColor },
  //                 tabLocks[index] && { color: solveColors.grey3 },
  //               ]}
  //             >
  //               {item.label}
  //             </Text>
  //             {item.notifications > 0 && (
  //               <Badge
  //                 badgeContent={item.notifications}
  //                 color="error"
  //                 style={{ marginLeft: 10 }}
  //               />
  //             )}
  //           </TouchableOpacity>
  //         )}
  //       />
  //     </View>
  //   </React.Fragment>
  // )
}

export default Tabs
