import { FlatList, Text, View } from 'react-native-web'
import Card from 'react-bootstrap/Card'
import React from 'react'
import Button from './Button'
import { withRouter } from 'react-router-dom'
import { formatDate } from '../../../utils/dateTime'
import styles from './styles'
import solveColors from '../../components/colors'

export const AdminForm = props => {
  const { history, windowWidth, classrooms, filter } = props
  let allClasses = []
  const openClasses = classrooms
    .filter(item => item.status === 'OPENED')
    .sort((a, b) =>
      a.code.charAt(0) < b.code.charAt(0)
        ? -1
        : a.code.charAt(0) > b.code.charAt(0)
        ? 1
        : new Date(b.endDate).getTime() - new Date(a.endDate).getTime()
    )
  if (openClasses.length > 0) {
    allClasses.push('OPENED CLASSES:')
    allClasses.push(openClasses)
  }

  const publishedClasses = classrooms
    .filter(item => item.status === 'PUBLISHED')
    .sort((a, b) =>
      a.code.charAt(0) < b.code.charAt(0)
        ? -1
        : a.code.charAt(0) > b.code.charAt(0)
        ? 1
        : new Date(b.endDate).getTime() - new Date(a.endDate).getTime()
    )
  if (publishedClasses.length > 0) {
    allClasses.push('PUBLISHED CLASSES:')
    allClasses.push(publishedClasses)
  }

  return (
    <React.Fragment>
      {/*filter === 'CLOSED' && (
        <View
          style={{
            alignSelf: 'flex-start',
            marginLeft: '10vw',
            flexDirection: 'row',
            marginVertical: 10,
          }}
        >
          <View
            style={[
              {
                background: `linear-gradient(to right, #07c1eb 50%, ${solveColors.blue2} 90%)`,
                alignSelf: 'center',
                marginRight: 10,
                marginTop: 25,
              },

              styles.sortButton,
            ]}
          >
            <Text style={{ fontSize: 18, color: 'white' }}>Filter:</Text>
          </View>

          <DropDownMenu
            name={'Year'}
            width={50}
            field={null}
            options={[null, ...Array.from(years)]}
            onChange={year => {
              setYearFitler(year)
            }}
          />
          <DropDownMenu
            name={'Months'}
            width={50}
            field={null}
            options={[null, ...moment.months()]}
            onChange={month => {
              setMonthFilter(
                !month
                  ? null
                  : moment()
                      .month(month)
                      .format('M') - 1
              )
            }}
          />
        </View>
      )*/}

      <FlatList
        data={allClasses}
        keyExtractor={(item, index) => index}
        renderItem={({ item }) => {
          if (typeof item === 'string') {
            return (
              <View
                style={[
                  {
                    background: `linear-gradient(to right, #07c1eb 50%, ${solveColors.blue2} 90%)`,
                    minWidth: (Math.floor(windowWidth / 350) - 1) * 350,
                  },
                  styles.sortButton,
                ]}
              >
                <Text style={{ fontSize: 20, color: 'white' }}>{item}</Text>
              </View>
            )
          } else {
            return (
              <FlatList
                numColumns={
                  Math.floor(windowWidth / 350) >= 1
                    ? Math.floor(windowWidth / 350)
                    : 1
                }
                initialNumToRender={item.length}
                data={item}
                key={Math.floor(windowWidth / 350)}
                keyExtractor={item => item.id}
                renderItem={({ item }) => {
                  const {
                    startDate,
                    endDate,
                    course,
                    status,
                    title,
                    teachingStyle,
                    id: classroomId,
                    title: classroomTitle,
                  } = item
                  const classroomStartDate = formatDate(startDate)
                  const classroomEndDate = formatDate(endDate)

                  return (
                    <Card
                      title="CLASSROOM CARD"
                      style={{
                        backgroundColor: 'white',
                        margin: 10,
                        width: 250,
                        boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.25)',
                      }}
                    >
                      <Card.Img style={{ width: 250 }} src={course.imageUrl} />
                      <Card.Body
                        style={{
                          padding: 10,
                          display: 'flex',
                          flexDirection: 'column',
                          paddingBottom: 100,
                        }}
                      >
                        <Card.Title
                          style={{
                            fontWeight: 'bold',
                            fontSize: '16px',
                            paddingBottom: 10,
                          }}
                        >
                          {title.split(':')[1].trim()}
                        </Card.Title>
                        <Text style={{ alignSelf: 'center' }}>ONLINE</Text>
                        {/*
                <Text>{`${classroomDays}: ${classroomStartTime} - ${classroomEndTime}`}</Text>
              */}
                        <Text>{`${classroomStartDate} - ${classroomEndDate}`}</Text>
                        <View
                          style={{
                            position: 'absolute',
                            bottom: 20,
                            width: 230,
                          }}
                        >
                          <Button
                            status={status}
                            onPress={() => {
                              sessionStorage.setItem('classroomId', classroomId)
                              sessionStorage.setItem(
                                'classroomTitle',
                                classroomTitle.split(':')[1].trim()
                              )
                              history.push({
                                pathname: '/lessons',
                                search: `classroomId=${classroomId}`,
                              })
                              if (filter === 'OPENED') {
                                sessionStorage.removeItem('teachingSyle')
                                sessionStorage.setItem(
                                  'teachingStyle',
                                  teachingStyle
                                )
                              }
                            }}
                          />
                        </View>
                      </Card.Body>
                    </Card>
                  )
                }}
              />
            )
          }
        }}
      />
    </React.Fragment>
  )
}

export default withRouter(AdminForm)
