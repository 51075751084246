import React from 'react'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native-web'
import { withRouter } from 'react-router-dom'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import solveColors from './../../components/colors'
import { useCookies } from 'react-cookie'

const PaymentSuccess = props => {
  const [cookies] = useCookies([])
  if (!cookies['familyAccountId']) {
    props.history.push('/family')
  }
  return (
    <View style={styles.container}>
      <Text style={styles.confirmationText}>
        Congratulation, Your Purchase is Successful!
      </Text>
      {/* <Text style={styles.confirmationText}>{`Transaction Number:`}</Text>
      <Text style={styles.confirmationText}>{`Invoice Number: `}</Text> */}
      <Text style={styles.confirmationText}>{`$${props.orderTotal ||
        '0'} has been paid!`}</Text>
      <View style={styles.rowContainer}>
        <TouchableOpacity
          style={styles.dashboardButton}
          onPress={() => {
            props.history.push('/catalog')
          }}
        >
          <ArrowBackIosIcon style={{ color: 'white' }} />
          <Text style={{ color: 'white', fontWeight: 'bold', fontSize: 16 }}>
            Go to Catalog
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.dashboardButton}
          onPress={() => {
            props.history.push('/catalog')
          }}
        >
          <ArrowBackIosIcon style={{ color: 'white' }} />
          <Text style={{ color: 'white', fontWeight: 'bold', fontSize: 16 }}>
            View Order
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  background: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  container: {
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
    borderRadius: 10,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  rowContainer: {
    marginTop: 20,
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  confirmationText: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  classroom: {
    marginVertical: 20,
    flexDirection: 'row',
    marginRight: 15,
    width: '100%',
  },
  classroomImg: {
    height: '100px',
    width: '150px',
    resizeMode: 'contain',
    marginRight: 20,
  },
  dashboardButton: {
    flexDirection: 'row',
    backgroundColor: solveColors.green2,
    alignItems: 'center',
    height: '40px',
    padding: 10,
    borderRadius: 10,
    marginVertical: 10,
  },
})
export default withRouter(PaymentSuccess)
