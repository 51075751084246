import React from 'react'
import { TouchableOpacity, Text } from 'react-native-web'
import styles from './styles'

const Button = props => {
  return (
    <TouchableOpacity
      style={props.buttonStyling ? props.buttonStyling : null}
      onPress={() => props.onPress(props)}
    >
      <Text
        style={[
          styles.buttonText,
          styles.bold,
          props.textStyling ? props.textStyling : null,
        ]}
      >
        {props.text}
      </Text>
    </TouchableOpacity>
  )
}

export default Button
