import { Text, ScrollView, View, TouchableOpacity } from 'react-native'
import React, { useState } from 'react'
import LessonForm from './LessonForm'
import { withRouter } from 'react-router-dom'
import { Query } from 'react-apollo'
import { GET_LESSONS, GET_MAKEUPS } from '../../../services/queries'
import styles from './styles'
import GradebookPage from './GradebookPage'
import MessagePage from './MessagePage'
import { MessageModal, Tabs } from '../../components/index'
import Modal from 'modal-react-native-web'
import LoadingPage from './../Loading/LoadingPage'
import LessonNavBar from './LessonNavbar'
import { ApolloConsumer } from 'react-apollo'
import TeachersPage from './TeachersPage'
import ClassroomForm from './ClassroomForm'
import AttendancePage from './AttendancePage'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import jwt from 'jsonwebtoken'
import LeaderboardPage from './LeaderboardPage'
import AnnouncementsPage from './AnnoucementsPage'
import TutorSessionPage from './TutorSessionPage'
import { useCookies } from 'react-cookie'

const LessonPage = props => {
  const [cookies] = useCookies([])
  if (
    !sessionStorage.getItem('classroomTitle') ||
    !sessionStorage.getItem('classroomId')
  ) {
    props.history.push({ pathname: '/dashboard' })
  }
  props.changeTitles(sessionStorage.getItem('classroomTitle'))
  const classroomId = sessionStorage.getItem('classroomId')
  const preview = sessionStorage.getItem('preview') === '1'
  const trial = sessionStorage.getItem('trial') === '1'
  const trialToLesson = sessionStorage.getItem('trialToLesson')
  const classroomData = JSON.parse(sessionStorage.getItem('classroomData'))
  const defaultTab = parseInt(sessionStorage.getItem(`tab:${classroomId}`))
  const [currentTab, setCurrentTab] = useState(
    defaultTab === 1
      ? 'announcements'
      : defaultTab === 2
      ? 'messages'
      : defaultTab === 3
      ? 'gradebook'
      : defaultTab === 4
      ? 'teachers'
      : defaultTab === 5
      ? 'leaderboard'
      : defaultTab === 6
      ? 'attendance'
      : 'problems'
  )
  const [submissionLoading, setSubmissionLoading] = useState(false)
  const [modalVisibility, setModalVisibility] = useState(false)
  const [modalMsg, setModalMsg] = useState('')
  const initLessonNum =
    sessionStorage.getItem(`lessonNum:${classroomId}`) !== null
      ? parseInt(sessionStorage.getItem(`lessonNum:${classroomId}`))
      : 0
  const [lessonSelected, setLessonSelected] = useState(
    sessionStorage.getItem(`lessonNum:${classroomId}`) !== null &&
      sessionStorage.getItem(`lessonNum:${classroomId}`) !== undefined
  )
  const [currLessonNum, setCurrLessonNum] = useState(initLessonNum)
  Modal.setAppElement('body')
  const teachingStyle = sessionStorage.getItem('teachingStyle')
  const employee = cookies['instructor'] === '1' || cookies['admin'] === '1'
  const studentId = jwt.decode(cookies['token']).id

  let tabInfo = [
    {
      label: 'LESSONS',
      filter: () => {
        setCurrentTab('problems')
        sessionStorage.setItem(`tab:${classroomId}`, 0)
      },
    },
    {
      label: 'NEWS',
      filter: () => {
        setCurrentTab('announcements')
        sessionStorage.setItem(`tab:${classroomId}`, 1)
      },
    },
    {
      label: 'MESSAGES',
      filter: () => {
        setCurrentTab('messages')
        sessionStorage.setItem(`tab:${classroomId}`, 2)
      },
      disabled: preview,
    },
    {
      label: 'GRADEBOOK',
      filter: () => {
        setCurrentTab('gradebook')
        sessionStorage.setItem(`tab:${classroomId}`, 3)
      },
      disabled: preview,
    },
    {
      label: 'TEACHERS',
      filter: () => {
        setCurrentTab('teachers')
        sessionStorage.setItem(`tab:${classroomId}`, 4)
      },
    },
    {
      label: 'LEADERBOARD',
      filter: () => {
        setCurrentTab('leaderboard')
        sessionStorage.setItem(`tab:${classroomId}`, 5)
      },
    },
    {
      label: 'ATTENDANCE',
      filter: () => {
        setCurrentTab('attendance')
        sessionStorage.setItem(`tab:${classroomId}`, 6)
      },
    },
  ]

  return (
    <Query
      query={GET_MAKEUPS}
      variables={{
        filter: {
          studentId: jwt.decode(cookies['token']).id,
        },
      }}
    >
      {({ data: data0, loading: loading0, error: error0, refetch }) => (
        <Query
          query={GET_LESSONS}
          variables={{
            id: classroomId,
            enrollmentsFilter: {
              student: studentId,
            },
          }}
          skip={classroomData && true}
        >
          {({ data: data2, loading: loading2, error: error2 }) => {
            if (error0) return `${error0}`
            if (error2) return `${error2}`

            if (loading0 || loading2 || submissionLoading)
              return <LoadingPage />
            const { makeups } = data0
            if (classroomData) {
              data2 = classroomData
            }
            sessionStorage.setItem('classroomData', JSON.stringify(data2))
            if (!trialToLesson) {
              tabInfo[2].notifications =
                data2.classroom.enrollments.length === 1
                  ? data2.classroom.enrollments[0].unreadMessages
                  : 0
            }
            let lessons = data2.classroom.lessons
            const { onSolve, headline } = data2.classroom
            const noStartDate =
              teachingStyle === 'MENTORED' || teachingStyle === 'TUTORED'
            const startDate = !noStartDate ? data2.classroom.startDate : ''

            if (lessons.length === 0)
              return 'Sorry this classroom is currently under construction :('
            lessons.sort((a, b) => a.lessonPlan.order - b.lessonPlan.order)

            const lessonLocks = new Array(lessons.length).fill(false)
            for (const lesson of lessons) {
              const ind = lesson.lessonPlan.order - 1
              let makeup = makeups.find(
                m => m.findLesson && m.fromLesson.id === lesson.id
              )
              if (!makeup || !makeup.toLesson) {
                makeup = undefined
              }
              if (
                employee ||
                lesson.lessonPlan.lessonPlanType === 'INTRODUCTION' ||
                (trial && lesson.attendees.length > 0)
              ) {
                continue
              }
              if (preview || trial) {
                lessonLocks[ind] = true
              }
              if (
                teachingStyle === 'CLASSROOM' ||
                teachingStyle === 'BLENDED'
              ) {
                const date = makeup
                  ? new Date(Date.parse(makeup.toLesson.previewOn))
                  : new Date(Date.parse(lesson.previewOn))
                lessonLocks[ind] = date > Date.now()
              }
              if (teachingStyle === 'MENTORED') {
                lessonLocks[ind] = Date.parse(props.startDate) > Date.now()
              }
            }
            //modify lessonLock so that all lessons after toLesson are locked
            if (trialToLesson) {
              const trialToLessonIndex = lessons.findIndex(
                lesson => lesson.id === trialToLesson
              )
              lessonLocks.fill(true, trialToLessonIndex + 1)
            }

            const lesson = lessons.find(
              l => l.lessonPlan.order === currLessonNum
            )
            const makeup = makeups.find(
              m => lesson && m.fromLesson && m.fromLesson.id === lesson.id
            )
            const { order, title, description } = lesson
              ? lesson.lessonPlan
              : {}

            const TAB_LESSON = 0
            const TAB_NEWS = 1
            const TAB_MESSAGES = 2
            const TAB_GRADEBOOK = 3
            const TAB_TEACHERS = 4
            const TAB_LEADERBOARD = 5
            const TAB_ATTENDANCE = 6
            const tabLocks = [0, 0, 0, 0, 0, 0, 0]
            tabLocks[TAB_LESSON] = false
            tabLocks[TAB_NEWS] = false
            tabLocks[TAB_MESSAGES] = !!trialToLesson
            tabLocks[TAB_GRADEBOOK] = !!trialToLesson
            tabLocks[TAB_TEACHERS] = false
            tabLocks[TAB_LEADERBOARD] = !!trialToLesson
            tabLocks[TAB_ATTENDANCE] = !!trialToLesson

            return (
              <React.Fragment>
                <Tabs
                  tabInfo={tabInfo}
                  tabLocks={tabLocks}
                  defaultTab={defaultTab}
                />
                {!onSolve && currentTab === 'problems' && (
                  <Text>
                    This class is not one solve you can check it out at
                    elearning.ardentacademy.com
                  </Text>
                )}
                {onSolve && currentTab === 'announcements' && (
                  <AnnouncementsPage classroomId={classroomId} />
                )}
                {onSolve &&
                  currentTab === 'attendance' &&
                  (teachingStyle === 'TUTORED' ? (
                    <TutorSessionPage
                      id={classroomId}
                      studentId={jwt.decode(cookies['token']).id}
                    ></TutorSessionPage>
                  ) : (
                    <AttendancePage
                      classroomId={classroomId}
                      makeups={makeups}
                      refetch={refetch}
                    />
                  ))}
                {onSolve && currentTab === 'leaderboard' && (
                  <LeaderboardPage
                    lessons={lessons}
                    selectedLesson={
                      lessonSelected ? lesson.lessonPlan.order : null
                    }
                  />
                )}
                {onSolve && currentTab === 'problems' && !lessonSelected && (
                  <ClassroomForm
                    headline={headline}
                    lessons={lessons}
                    lessonLocks={lessonLocks}
                    classroomId={classroomId}
                    setLessonSelected={setLessonSelected}
                    setCurrLessonNum={setCurrLessonNum}
                    preview={preview}
                    trialToLesson={trialToLesson}
                    teachingStyle={teachingStyle}
                  />
                )}
                {onSolve && currentTab === 'problems' && lessonSelected && (
                  <View
                    style={{
                      flexDirection: 'row',
                      height: '100%',
                      flex: 1,
                    }}
                  >
                    <View
                      style={{
                        maxHeight: 'calc(100% - 325px)',
                        minHeight: 250,
                      }}
                    >
                      <TouchableOpacity
                        style={styles.lesson_back}
                        onPress={() => {
                          setLessonSelected(false)
                          sessionStorage.removeItem(`lessonNum:${classroomId}`)
                        }}
                      >
                        <ArrowBackIosIcon style={{ height: 16 }} />
                        <Text style={{ fontSize: 16 }}>{'Back'}</Text>
                      </TouchableOpacity>
                      <LessonNavBar
                        lessons={lessons}
                        lessonLocks={lessonLocks}
                        currLessonNum={currLessonNum}
                        startDate={startDate}
                        setCurrLessonNum={setCurrLessonNum}
                        classroomId={classroomId}
                      />
                    </View>

                    <ScrollView>
                      <View style={styles.list}>
                        <Text style={styles.lesson_title}>
                          {order}. {title}
                        </Text>
                        <View style={{ maxWidth: '50vw' }}>
                          <Text style={{ flexWrap: 'wrap' }}>
                            {description}
                          </Text>
                        </View>
                        <ApolloConsumer>
                          {client => (
                            <LessonForm
                              setSubmissionLoading={setSubmissionLoading}
                              client={client}
                              lessonId={lesson.id}
                              lessonPlanType={lesson.lessonPlan.lessonPlanType}
                              openModal={msg => {
                                setModalMsg(msg)
                                setModalVisibility(true)
                              }}
                              makeup={makeup}
                            />
                          )}
                        </ApolloConsumer>
                      </View>
                    </ScrollView>
                  </View>
                )}
                {currentTab === 'teachers' && <TeachersPage />}
                {currentTab === 'messages' && (
                  <MessagePage
                    classroomId={classroomId}
                    refetch={() => {
                      refetch()
                    }}
                  />
                )}
                {currentTab === 'gradebook' && !trialToLesson && (
                  <GradebookPage lessons={lessons} classroomId={classroomId} />
                )}
                <MessageModal
                  visible={modalVisibility}
                  transparent={true}
                  onClose={() => {
                    setModalVisibility(false)
                  }}
                  message={modalMsg}
                />
              </React.Fragment>
            )
          }}
        </Query>
      )}
    </Query>
  )
}

export default withRouter(LessonPage)
