import { StyleSheet, Text, TouchableOpacity } from 'react-native'
import React, { Component } from 'react'
import { solveColors } from '../../../components/colors'
import problemStates from '../ProblemStates'

class SkipButton extends Component {
  constructor(props) {
    super(props)
    this.state = { probState: props.probState, allowSkip: props.allowSkip }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.probState !== props.probState) {
      return { probState: props.probState }
    }
    if (state.allowSkip !== props.allowSkip) {
      return { allowSkip: props.allowSkip }
    }
    return null
  }

  render() {
    const { incrementProblem, lastProb, handleProblemSubmit } = this.props
    return (
      <TouchableOpacity
        style={[
          getButtonStyle(this.state.probState, this.state.allowSkip),
          getLastProbStyle(lastProb),
          styles.skip_button,
        ]}
        disabled={
          !this.state.allowSkip ||
          this.state.probState !== problemStates.unanswered
        }
        onPress={async () => {
          if (lastProb) handleProblemSubmit(true)
          else incrementProblem()
        }}
      >
        <Text style={{ color: 'white', fontWeight: 'bold' }}>{'SKIP'}</Text>
      </TouchableOpacity>
    )
  }
}

const getButtonStyle = (state, skip) => {
  if (skip && state === problemStates.unanswered) return styles.skip_available
  return styles.skip_not_available
}

const getLastProbStyle = lastProb => {
  if (lastProb) return styles.hide_last_problem
}

const styles = StyleSheet.create({
  skip_button: {
    alignItems: 'center',
    paddingVertical: 10,
    borderRadius: 10,
    margin: 10,
    width: 160,
  },
  skip_available: {
    backgroundColor: solveColors.orange,
  },
  skip_not_available: {
    backgroundColor: solveColors.grey1,
  },
  hide_last_problem: {
    display: 'none',
  },
})

export default SkipButton
