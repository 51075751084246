import React from 'react'
import { withRouter } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import { withApollo } from 'react-apollo'
import { GET_FAMILY_ACC_INFO } from '../../../services/queries'
import { withCookies } from 'react-cookie'

function LandingPage(props) {
  const params = new URLSearchParams(window.location.search)

  const token = params.get('token')
  const familyAcc = params.get('familyAcc')

  if (
    !token ||
    !familyAcc ||
    !jwt.decode(token) ||
    jwt.decode(token).exp < new Date().getTime() / 1000
  ) {
    props.history.replace('/')
  }

  const id = jwt.decode(token).id

  sessionStorage.clear()
  Object.keys(this.props.cookies.getAll()).forEach(k =>
    this.props.cookies.remove(k)
  )
  this.props.cookies.set('token', token)
  this.props.cookies.set('familyAccountId', familyAcc)
  this.props.cookies.set('family', 1)

  props.client
    .query({ query: GET_FAMILY_ACC_INFO, variables: { id } })
    .then(({ data }) => {
      this.props.cookies.set('firstName', data.familyMember.firstName)
      this.props.cookies.set('lastName', data.familyMember.lastName)
      props.history.replace('/dashboard')
    })
    .catch(e => {
      alert('getting family member info failed')
      props.history.replace('/dashboard')
    })

  return <div>Redirecting...</div>
}

export default withApollo(withRouter(withCookies(LandingPage)))
