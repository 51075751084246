import React from 'react'
import { Query, Subscription } from 'react-apollo'
import { View, Text } from 'react-native'
import { solveColors } from './colors'
import {
  COUNT_SOLVED_PROBLEMS,
  SUBSCRIBE_PROBLEM_RESPONSES,
} from '../../services/queries'

const ResponseSubscriptionView = props => {
  return (
    <Query query={COUNT_SOLVED_PROBLEMS}>
      {({ error, loading, data }) => {
        if (error) return <React.Fragment />
        if (loading) return <React.Fragment />
        const initialCount = data.countProblemResponses
        //could use subscribeToMore
        return (
          <Subscription subscription={SUBSCRIBE_PROBLEM_RESPONSES} skip={false}>
            {({ data }) => {
              return (
                <ResponseSubscriptionInnerView
                  count={
                    data === undefined
                      ? initialCount
                      : data.onProblemResponseCountChanged.newCount
                  }
                />
              )
            }}
          </Subscription>
        )
      }}
    </Query>
  )
}

const ResponseSubscriptionInnerView = React.memo(({ count }) => {
  return (
    <View>
      <Text
        style={{
          color: solveColors.offWhite,
          textAlign: 'center',
          fontSize: 100,
        }}
      >
        {count}
      </Text>
      <Text
        style={{
          color: solveColors.offWhite,
          textAlign: 'center',
          marginBottom: 15,
          fontSize: 25,
        }}
      >
        Problems Solved!
      </Text>
    </View>
  )
})

export default ResponseSubscriptionView
