import React from 'react'
import { Text, View, Picker, StyleSheet } from 'react-native'
import solveColors from './../components/colors'

const DropDownMenu = props => {
  const { name, width, field, onChange, setFocus, options } = props
  return (
    <View style={{ margin: 10, ...width }}>
      <Text style={styles.fieldText}>{name}</Text>
      <Picker
        style={[styles.dropdown]}
        selectedValue={field}
        onValueChange={v => {
          onChange(v)
          if (setFocus) setFocus(null)
        }}
      >
        {options.map((k, v) => {
          if (typeof k === 'object') {
            return (
              <Picker.Item
                label={k.key === null ? '' : String(k.key)}
                value={k.value}
                key={k.key}
              />
            )
          }
          return (
            <Picker.Item
              label={k === null ? '' : String(k)}
              value={k}
              key={k}
            />
          )
        })}
      </Picker>
    </View>
  )
}

const styles = StyleSheet.create({
  fieldDescriptor: {
    fontSize: 12,
    marginBottom: '3px',
  },
  fieldText: {
    fontSize: 11,
    color: 'dimgrey',
    marginBottom: 10,
    alignSelf: 'flex-start',
  },
  dropdown: {
    borderWidth: '1px',
    borderColor: solveColors.grey1,
    borderRadius: 5,
    padding: 5,
  },
})

export default DropDownMenu
