import React, { Component } from 'react'
import {
  Text,
  View,
  ScrollView,
  FlatList,
  StyleSheet,
  Dimensions,
} from 'react-native-web'
import { withRouter } from 'react-router-dom'
import { debounce } from 'lodash'
import SyllabusModal from './SyllabusModal'
import colors from './../../components/colors'
import { MessageModal, VideoModal, ClassroomCard } from './../../components/'
import { FILTER_COURSES } from '../../../services/queries'
import { withApollo } from 'react-apollo'
import {
  EARLY_BIRD,
  YEAR,
  QUARTER,
  APCOURSE,
  RESEARCHCOURSE,
} from './../../../config'
import LoadingPage from '../Loading/LoadingPage'
import { Button } from '@material-ui/core'
import { withCookies } from 'react-cookie'

class CatalogForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      selectedId: null,
      classList: {},
      width: Dimensions.get('window').width,
      loggedIn: props.cookies.get('token') !== null,
      syllabusOpen: false,
      syllabusId: null,
      loginPopupVisible: false,
      messageVisible: false,
      videoVisible: false,
      videoId: null,
    }
  }

  componentDidMount() {
    window.addEventListener('resize', debounce(this.handleResize, 100))

    const params = new URLSearchParams(window.location.search)
    let args = {}

    //currently, only one can be selected, so code is written to assume only one can be selected
    try {
      if (params.get('session')) {
        args['session'] = JSON.parse(params.get('session'))
        if (JSON.parse(params.get('session')).quarter === 'OnDemand') {
          this.setState({
            resultTitle: 'Self-Paced Classes',
          })
        } else {
          this.setState({
            resultTitle: JSON.parse(params.get('session')).quarter + ' Classes',
          })
        }
      }
      if (params.get('subject')) {
        args['subject'] = params.get('subject')
        this.setState({ resultTitle: params.get('subject') + ' Classes' })
      }

      if (params.get('grade')) {
        args['grade'] = params.get('grade')
        this.setState({ resultTitle: params.get('grade') + ' Classes' })
      }
      if (params.get('location')) {
        args['location'] = params.get('location')
        this.setState({ resultTitle: params.get('location') + ' Classes' })
      }
    } catch (e) {
      this.props.history.goBack()
    }
    this.fetchData(this.props.client, args)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    this.setState({
      width: Dimensions.get('window').width,
    })
  }

  navigateTo = id => {
    this.props.history.push('/checkout?classroomId=' + id)
  }

  leave = () => {
    this.setState({ selectedId: null })
  }

  openSyllabus = syllabusId => {
    this.setState({ syllabusId, syllabusOpen: true })
  }

  closeSyllabus = () => {
    this.setState({ syllabusOpen: false })
  }
  openVideo = videoId => {
    this.setState({ videoVisible: true, videoId })
  }

  closeVideo = () => {
    this.setState({ videoVisible: false })
  }

  constructVariables = args => {
    const { subject, session, location, grade } = args
    let year
    let quarter
    if (session) {
      year = parseInt(session.year)
      quarter = session.quarter
    } else {
      year = YEAR
      quarter = QUARTER
    }
    let variables
    if (subject === 'Research') {
      variables = {
        classroomFilter: {
          $or: [{ status: 'OPENED' }, { status: 'PUBLISHED' }],
          onCatalog: true,
          course: {},
        },
      }
    } else {
      variables = {
        classroomFilter: {
          $or: [{ status: 'OPENED' }, { status: 'PUBLISHED' }],
          year: year,
          quarter: quarter,
          onCatalog: true,
          course: {},
        },
      }
    }
    if (session) {
      variables.classroomFilter.session = session.session
    }
    if (location) {
      variables.classroomFilter.centerLocation = { name: location }
    }
    if (grade) {
      const [startGrade, endGrade] = grade.split('-')
      // Interval intersection - start1 <= end2 && end1 >= start2
      variables.classroomFilter.course = {
        startGrade: { $lte: parseInt(endGrade) },
        endGrade: { $gte: parseInt(startGrade) },
      }
    }
    if (subject === 'AP') {
      variables.classroomFilter.course = {
        code: { $in: APCOURSE },
      }
    } else if (subject === 'Research') {
      variables.classroomFilter.course = {
        code: { $in: RESEARCHCOURSE },
      }
    } else if (subject === 'Olympiad' || subject === 'Accelerated') {
      variables.classroomFilter.course = {
        subject: { name: subject },
      }
    } else if (subject) {
      variables.classroomFilter.course = {
        subject: { category: { name: subject } },
      }
    }

    // Optimization - remove `course` sub-filter if it's empty.
    // This way, the API won't have to join `course` during the filtering stage.
    if (Object.keys(variables.classroomFilter.course).length === 0) {
      delete variables.classroomFilter.course
    }

    return variables
  }

  fetchData = async (client, args) => {
    this.setState({ loading: true })

    const {
      data: { classrooms },
    } = await client.query({
      query: FILTER_COURSES,
      variables: this.constructVariables(args),
    })

    if (classrooms.length === 0) {
      alert('No matching classrooms found!')
      this.props.history.goBack()
      this.setState({ loading: false })
    } else {
      let classList = { [this.state.resultTitle || 'results']: classrooms }

      for (let key of Object.keys(classList)) {
        classList[key].sort((a, b) => {
          return a.code.localeCompare(b.code)
        })
      }
      this.setState({ loading: false, classList })
    }
  }

  render() {
    if (this.state.loading) {
      return <LoadingPage />
    }
    return (
      <React.Fragment>
        <SyllabusModal
          visible={this.state.syllabusOpen}
          classroomId={this.state.syllabusId}
          onClose={this.closeSyllabus}
        />
        <MessageModal
          visible={this.state.messageVisible}
          message={'You have been successfully logged in!'}
          onClose={() => {
            this.setState({ messageVisible: false })
          }}
        />
        <VideoModal
          visible={this.state.videoVisible}
          onClose={this.closeVideo}
          videoId={this.state.videoId}
        />
        <ScrollView style={{ backgroundColor: 'white' }}>
          <Button
            style={{ alignSelf: 'flex-start', margin: '6px' }}
            color="primary"
            onClick={() => this.props.history.push('/catalog')}
          >
            {'< Catalog'}
          </Button>

          {Object.keys(this.state.classList).map(key => (
            <View key={key}>
              <Text style={styles.header}>{key}</Text>

              {/* {key === 'Summer classes' && (
                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ fontWeight: 'bold' }}>Grades: </Text>
                  <Text>TEST</Text>
                </View>
              )} */}
              <FlatList
                style={{
                  marginLeft: '2vw',
                  marginRight: '2vw',
                  alignSelf: 'center',
                }}
                numColumns={
                  Math.floor(this.state.width / 350) >= 1
                    ? Math.floor(this.state.width / 350)
                    : 1
                }
                data={this.state.classList[key]}
                key={this.state.width / 350}
                keyExtractor={item => item.id}
                renderItem={({ item }) => (
                  <div style={{ alignSelf: 'center' }}>
                    <ClassroomCard
                      onEnroll={() => {
                        if (!this.state.loggedIn) {
                          this.props.history.push('/', {
                            from:
                              window.location.pathname + window.location.search,
                            openLogin: true,
                          })
                        } else {
                          this.navigateTo(item.id)
                        }
                      }}
                      videoId={item.videoId}
                      openVideo={videoId => {
                        this.openVideo(videoId)
                      }}
                      classroomId={item.id}
                      imageUrl={item.course.imageUrl}
                      title={item.title
                        .split(':')
                        .splice(1)
                        .join(' ')}
                      tuitionCents={
                        EARLY_BIRD
                          ? item.earlyBirdtuitionCents
                          : item.tuitionCents
                      }
                      enrollmentCount={item.enrollmentCount}
                      enrollmentMax={item.enrollmentMax}
                      startTime={item.startTime}
                      endTime={item.endTime}
                      startDate={item.startDate}
                      endDate={item.endDate}
                      startGrade={item.course.startGrade}
                      endGrade={item.course.endGrade}
                      locationName={item.centerLocation.name}
                      teachingStyle={item.teachingStyle}
                      meetingsPerWeek={item.meetingsPerWeek}
                      // instructorName={
                      //   item.leadInstructor && item.leadInstructor.length > 0
                      //     ? item.leadInstructor
                      //     : 'Ardent Staff'
                      // }
                      instructorName={
                        item.leadInstructor &&
                        item.leadInstructor !== 'Ardent Instructor'
                          ? item.leadInstructor
                          : item.instructors &&
                            item.instructors.length > 0 &&
                            item.instructors[0].displayName
                          ? item.instructors[0].displayName
                          : 'Ardent Staff'
                      }
                      openSyllabus={() => {
                        this.openSyllabus(item.id)
                      }}
                      openLoginPopup={() => {
                        this.setState({ loginPopupVisible: true })
                      }}
                      hasPreview={false}
                      classroomDays={item.meets}
                    />
                  </div>
                )}
              />
            </View>
          ))}
        </ScrollView>
      </React.Fragment>
    )
  }
}

export default withRouter(withApollo(withCookies(CatalogForm)))

const styles = StyleSheet.create({
  header: {
    color: '#2D97DF',
    padding: '1vh',
    marginLeft: '2vw',
    fontWeight: 'bold',
    fontSize: '16px',
    fontFamily:
      'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace',
  },
  return: {
    backgroundColor: colors.turquoise,
    paddingTop: '1.5vh',
    paddingBottom: '1.5vh',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  returnText: {
    color: 'white',
    fontWeight: 'bold',
    fontFamily:
      'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace',
  },
})
