import React, { Component } from 'react'
import { View, StyleSheet } from 'react-native-web'
import ProblemSubmitButton from './ProblemSubmitButton'
import AssignmentSubmitButton from './AssignmentSubmitButton'
import SkipButton from './SkipButton'
import SolutionButton from './SolutionButton'
import ScratchpadButton from './ScratchpadButton'
import solveColors from '../../../components/colors'
import { DialogModal, Latex } from '../../../components/index'

class EmbedProblemForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      studentAnswer: '',
      dlgModalOpen: false,
      partial: true,
    }
  }
  openDialogModal() {
    this.setState({ dlgModalOpen: true })
  }
  closeDialogModal() {
    this.setState({ dlgModalOpen: false })
  }
  handleProblemSubmit = async (fromSkip = false) => {
    const {
      onProblemSubmit,
      problem,
      review,
      lastProb,
      gotoLessons,
    } = this.props
    const { answer } = problem
    let studentAnswer = ''
    if (review && !lastProb) {
      onProblemSubmit(studentAnswer, answer)
      return
    } else if (review && lastProb) {
      gotoLessons()
      return
    }
    onProblemSubmit(studentAnswer, answer, fromSkip)
  }

  handleAssignmentSubmit = () => {
    const { answered } = this.props
    if (answered) {
      this.setState({ partial: false })
    }
    this.openDialogModal()
  }
  render() {
    const {
      probState,
      complete,
      allowSkip,
      incrementProblem,
      problem,
      feedbackType,
      review,
      lastProb,
      problemStates,
      instructor,
      showSolution,
      toggleShowSolution,
      problemSetLength,
      onAssignmentSubmit,
      isLoading,
    } = this.props

    const { questionText, problemType } = problem
    const questionInstructions = questionText.substring(
      0,
      questionText.indexOf('<iframe')
    )
    const embeddedhtml = questionText.substring(
      questionText.indexOf('<iframe'),
      questionText.indexOf('</iframe>') + 9
    )
    return (
      <View style={styles.repl}>
        <Latex latex={questionInstructions} />
        <div
          style={{ width: '100%', height: '100%' }}
          dangerouslySetInnerHTML={{
            __html: embeddedhtml,
          }}
        />

        <View style={styles.buttonBar}>
          <ScratchpadButton toggleScratchpad={this.props.toggleScratchpad} />
          <ProblemSubmitButton
            isLoading={isLoading}
            probState={probState}
            problemType={problemType}
            handleProblemSubmit={this.handleProblemSubmit}
            complete={complete}
            feedbackType={feedbackType}
            review={review}
            lastProb={lastProb}
          />
          {allowSkip && !instructor && !review && (
            <SkipButton
              probState={probState}
              allowSkip={allowSkip}
              lastProb={lastProb}
              handleProblemSubmit={this.handleProblemSubmit}
              incrementProblem={incrementProblem}
            />
          )}
          {!review && !instructor && (
            <AssignmentSubmitButton
              handleAssignmentSubmit={this.handleAssignmentSubmit}
              locked={problemStates.filter(s => s !== 0).length === 0}
            />
          )}
          {!review && instructor && (
            <SolutionButton
              showSolution={showSolution}
              toggleShowSolution={toggleShowSolution}
            />
          )}
        </View>
        <DialogModal
          visible={this.state.dlgModalOpen}
          transparent={true}
          onYes={async () => {
            if (this.state.studentAnswer) await this.handleProblemSubmit()
            onAssignmentSubmit()
            this.closeDialogModal()
          }}
          onNo={() => {
            this.closeDialogModal()
          }}
          message={
            this.state.partial
              ? `You have answered ${
                  problemStates.filter(p => p !== 0).length
                }/${problemSetLength} problems. Are you sure you want to submit the assignment for grading?`
              : 'Once submitted, you cannot change your answers. Are you sure you want to submit?'
          }
        />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  instructions: {
    padding: 10,
    alignSelf: 'center',
  },
  question: {
    alignItems: 'center',
    userSelect: 'none',
    maxWidth: '40vw',
  },
  image: {
    height: 200,
    width: 700,
    resizeMode: 'contain',
  },
  review: {
    alignItems: 'center',
  },
  textarea: {
    minHeight: 100,
    borderRadius: 5,
    borderColor: solveColors.grey3,
    alignSelf: 'center',
    minWidth: 300,
  },
  buttonBar: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  problem: {
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '60vw',
  },
  repl: {
    alignItems: 'center',
    width: '80vw',
    height: 'calc(90vh - 100px)',
  },
})

export default EmbedProblemForm
