import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'
import { TouchableOpacity } from 'react-native-web'

export default function CatalogCard(props) {
  const [hover, setHover] = useState(false)

  const tutored = props.field2 && props.field2.includes('N/A')
  const club = props.field2 && props.field2.includes('Club')
  const self_paced = props.field2 && props.field2.includes('TBD')
  const field1 =
    tutored || self_paced
      ? 'Personal, Flexible, Effective'
      : club
      ? 'Grades K - 8'
      : props.field1
  const field2 =
    tutored || self_paced
      ? 'Start Anytime'
      : club
      ? 'Contests and Practice Exams'
      : props.field2

  return (
    <TouchableOpacity
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
      onPress={props.onPress}
    >
      <Card
        title=""
        style={{
          backgroundColor: props.selected ? 'gold' : 'white',
          margin: 10,
          width: 250,
          boxShadow: hover
            ? '0 8px 16px 0 rgba(0,0,0,0.4)'
            : '0 4px 8px 0 rgba(0,0,0,0.4)',
          transitionDuration: '0.3s',
          transitionProperty: 'background-color',
          transitionTimingFunction: 'ease-in-out',
        }}
        onMouseEnter={() => {
          setHover(true)
        }}
        onMouseLeave={() => {
          setHover(false)
        }}
      >
        <Card.Img style={{ width: 250 }} src={props.image} />
        <Card.Body
          style={{
            padding: 10,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Card.Title
            style={{
              fontWeight: 'bold',
              fontSize: '16px',
            }}
          >
            {props.title}
          </Card.Title>
          <Card.Body>
            <Card.Text style={dstyles.text}>{field1}</Card.Text>
            <Card.Text style={dstyles.text}>{field2}</Card.Text>
            <Card.Text style={dstyles.text}>{props.field3}</Card.Text>
            <Card.Text style={dstyles.text}>{props.field4}</Card.Text>
          </Card.Body>
        </Card.Body>
      </Card>
    </TouchableOpacity>
  )
}

const dstyles = {
  text: {
    fontSize: '14px',
    margin: 0,
  },
}
