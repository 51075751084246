import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { ScrollView, View, Text } from 'react-native-web'
import { withRouter } from 'react-router-dom'
import { GET_FAMILY_STUDENTS } from '../../../services/queries'
import LoadingPage from '../Loading/LoadingPage'
import EnrollmentDetails from './EnrollmentDetails'

import ClassCard from './ClassCard'
import { withCookies } from 'react-cookie'

class ReportsView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedEnrollmentId: '',
      selectedStudentId: '',
    }
  }

  componentDidMount() {
    if (
      this.props.cookies.get('family') !== '1' ||
      !this.props.cookies.get('familyAccountId')
    ) {
      this.props.history.push('/')
    }
  }

  goToDetails = (enrollId, studentId) => {
    this.setState({
      selectedEnrollmentId: enrollId,
      selectedStudentId: studentId,
    })
  }

  render() {
    const { selectedEnrollmentId, selectedStudentId } = this.state

    if (selectedEnrollmentId && selectedStudentId) {
      return (
        <EnrollmentDetails
          enrollmentId={selectedEnrollmentId}
          studentId={selectedStudentId}
          close={() =>
            this.setState({ selectedEnrollmentId: '', selectedStudentId: '' })
          }
        />
      )
    }

    return (
      <ScrollView style={{ width: '100%', padding: '6px' }}>
        {/* <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
          
        </div> */}
        <Query
          query={GET_FAMILY_STUDENTS}
          variables={{ id: this.props.cookies.get('familyAccountId') }}
        >
          {({ data, loading, refetch, error }) => {
            if (loading) return <LoadingPage />
            if (error) return `${error}`

            let publishedEnrollments = []

            for (let student of data.familyAccount.students) {
              for (let enrollment of student.enrollments) {
                if (enrollment.classroom.status === 'PUBLISHED') {
                  publishedEnrollments.push({ enrollment, student })
                }
              }
            }

            return (
              <View style={{ width: '100%' }}>
                {data.familyAccount.students.map(s => {
                  const openEnrollments = s.enrollments.filter(
                    enr => enr.classroom.status === 'OPENED'
                  )
                  if (openEnrollments.length === 0) {
                    return null
                  }
                  return (
                    <div key={s.id}>
                      <div style={{ margin: '4px' }}>
                        {openEnrollments.map((e, ind) => {
                          return (
                            <ClassCard
                              key={e.id}
                              goToDetails={this.goToDetails}
                              s={s}
                              e={e}
                            />
                          )
                        })}
                      </div>
                      <br />
                    </div>
                  )
                })}
                {publishedEnrollments.length !== 0 && (
                  <div style={{ margin: '4px' }}>
                    <Text>Starting Soon</Text>
                    {publishedEnrollments.map(
                      ({ enrollment, student }, ind) => {
                        return (
                          <ClassCard
                            key={enrollment.id}
                            goToDetails={this.goToDetails}
                            s={student}
                            e={enrollment}
                            published
                          />
                        )
                      }
                    )}
                  </div>
                )}
              </View>
            )
          }}
        </Query>
      </ScrollView>
    )
  }
}

export default withRouter(withCookies(ReportsView))
