import { FlatList } from 'react-native-web'
import { View } from 'react-native'
import React from 'react'
import { ClassroomCard } from './../../components/'
import { withRouter } from 'react-router-dom'
import { EARLY_BIRD } from '../../../config'

const SessionForm = props => {
  const { windowWidth, recommendations, session, openVideo, history } = props
  return (
    <View>
      <FlatList
        data={recommendations}
        numColumns={
          Math.floor(windowWidth / 400) >= 1 ? Math.floor(windowWidth / 400) : 1
        }
        key={Math.floor(windowWidth / 400)}
        listKey={(item, index) => session + index.toString()}
        keyExtractor={item => item.classroomId}
        renderItem={({ item }) => {
          const {
            imageUrl,
            title,
            classroomId,
            tuitionCents,
            earlyBirdtuitionCents,
            startGrade,
            endGrade,
            startTime,
            endTime,
            startDate,
            endDate,
            centerLocation,
            instructorName,
            classroomDays,
            hasPreview,
            videoId,
          } = item
          return (
            <ClassroomCard
              videoId={videoId}
              openVideo={videoId => {
                openVideo(videoId)
              }}
              onEnroll={() =>
                props.history.push('/checkout?classroomId=' + classroomId)
              }
              classroomId={classroomId}
              imageUrl={imageUrl}
              title={title.split('- ')[1]}
              tuitionCents={EARLY_BIRD ? earlyBirdtuitionCents : tuitionCents}
              startGrade={startGrade}
              endGrade={endGrade}
              locationName={centerLocation.name}
              startTime={startTime}
              endTime={endTime}
              startDate={startDate}
              endDate={endDate}
              //locationName={centerLocation.name}
              instructorName={instructorName}
              openSyllabus={() => {
                history.push({
                  pathname: '/syllabus',
                  search: `classroomId=${classroomId}`,
                })
              }}
              openLoginPopup={() => {
                this.setState({ loginPopupVisible: true })
              }}
              hasPreview={hasPreview}
              classroomDays={classroomDays}
            />
          )
        }}
      />
    </View>
  )
}

export default withRouter(SessionForm)
