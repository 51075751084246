import React, { Component } from 'react'
import { View, TouchableOpacity, TextInput, Text } from 'react-native'
import { solveColors } from '../../components/colors'
import styles from './styles'

class LoginInput extends Component {
  constructor(props) {
    super(props)
    this.textInput = React.createRef()
  }

  componentDidUpdate() {
    this.textInput.current.focus()
  }

  render() {
    return (
      <View>
        <TextInput
          ref={this.textInput}
          value={this.props.value}
          secureTextEntry={this.props.secure}
          style={styles.textinput}
          placeholder={this.props.placeholder}
          onChangeText={this.props.onChangeText}
          autoFocus={true}
          placeholderTextColor={solveColors.offWhite}
          onSubmitEditing={this.props.onSubmit}
        />
        <TouchableOpacity
          style={styles.button}
          disabled={this.props.disabled}
          onPress={this.props.onSubmit}
        >
          <Text style={{ color: 'white' }}>{this.props.buttonText}</Text>
        </TouchableOpacity>
      </View>
    )
  }
}

export default LoginInput
