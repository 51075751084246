import { FlatList, View, StyleSheet, Text } from 'react-native'
import React, { useState } from 'react'
import { TouchableOpacity } from 'react-native-web'
import solveColors from './../../components/colors'
import MessageModal from './../../components/MessageModal'

const LessonNavBar = props => {
  const [dialogVisible, setDialogVisible] = useState(false)

  const teachingStyle = sessionStorage.getItem('teachingStyle')

  return (
    <View>
      <View style={styles.lesson_navbar}>
        <Text style={styles.label}>Lessons: </Text>
        <FlatList
          data={props.lessons}
          extraData={props}
          keyExtractor={item => item.id}
          renderItem={({ item }) => {
            const { order, title } = item.lessonPlan
            const locked = props.lessonLocks[order - 1]
            return (
              <TouchableOpacity
                disabled={locked && teachingStyle !== 'SELF_PACED'}
                onPress={() => {
                  if (locked && teachingStyle === 'SELF_PACED') {
                    setDialogVisible(true)
                  } else {
                    props.setCurrLessonNum(order)
                    sessionStorage.setItem(
                      `lessonNum:${props.classroomId}`,
                      order
                    )
                  }
                }}
                style={[
                  styles.navbar_item,
                  props.currLessonNum === order
                    ? { backgroundColor: solveColors.orange }
                    : { backgroundColor: 'white' },
                ]}
              >
                <Text
                  style={
                    props.currLessonNum === order
                      ? { color: 'white' }
                      : locked
                      ? { color: solveColors.grey3 }
                      : { color: 'black' }
                  }
                >
                  {order}. {title}
                </Text>
              </TouchableOpacity>
            )
          }}
        />
      </View>
      <MessageModal
        visible={dialogVisible}
        message={
          'To unlock this lesson you must complete the previous lesson. All homeworks must have a score of 75% or higher. All quizzes must have a score of 60% or higher.'
        }
        onClose={() => setDialogVisible(false)}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  lesson_navbar: {
    borderColor: '#EDEDED',
    borderWidth: 1,
    borderRadius: 5,
    maxWidth: 200,
    marginLeft: 10,
    marginRight: 20,
    marginTop: '10px',
    padding: 20,
    backgroundColor: 'white',
    shadowColor: '#000',
    shadowOffset: { width: 5, height: 5 },
    shadowOpacity: 0.25,
    shadowRadius: 2,
    maxHeight: 'calc(100vh - 250px)',
  },
  navbar_item: {
    padding: 7,
    marginVertical: 3,
    borderRadius: 10,
  },
  label: {
    fontWeight: 'bold',
    fontSize: 18,
  },
})

export default LessonNavBar
