import React, { Component } from 'react'
import { View, Image, ImageBackground, Text, StyleSheet } from 'react-native'
import BannerMessage from '../../components/BannerMessage'
import logo from './../../images/solve-logo.png'
import login_background from './../../images/login_background.svg'
import { access } from '../../components/styles'
import { ApolloConsumer } from 'react-apollo'
import { version } from '../../../../package.json'
import solveColors from '../../components/colors'
import _ from 'lodash'
import FamilyLoginInput from './FamilyLoginInput'
import {
  FAMILY_LOGIN,
  FAMILY_VERIFY,
} from '../../../services/queries/login_queries'
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import LoginPage from '../Login/LoginPage'
import { TouchableOpacity } from 'react-native-web'
import { withCookies } from 'react-cookie'

class FamilyLoginPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      phone: '',
      code: '',
      invalidCredentials: false,
      message: '',
      success: null,
      loginMode: 'parent',
    }
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.from) {
      this.setState({ redirectTo: this.props.location.state.from })
    }
  }

  handlePhoneChange = phone => {
    this.setState({ phone })
  }

  handleCodeChange = code => {
    this.setState({ code })
  }

  throttledFamilyVerify = _.throttle(async client => {
    if (!this.state.code || !this.state.phone) {
      return
    }
    const { data } = await client.mutate({
      mutation: FAMILY_VERIFY,
      variables: {
        phone: this.state.phone,
        code: this.state.code,
      },
    })

    if (data && data.familyVerify.success) {
      const { firstName, lastName, imageUrl } = data.familyVerify.familyMember

      this.props.cookies.set('token', data.familyVerify.token)
      this.props.cookies.set('firstName', firstName)
      this.props.cookies.set('lastName', lastName)
      this.props.cookies.set('imageUrl', imageUrl)
      this.props.cookies.set('family', 1)
      this.props.cookies.set(
        'familyAccountId',
        data.familyVerify.familyAccountId
      )
      this.props.history.push({
        pathname: this.state.redirectTo || '/dashboard',
      })
    } else {
      this.setState({ success: false, message: data.familyVerify.message })
    }
  }, 3000)

  throttledFamilyLogin = _.throttle(async client => {
    if (!this.state.phone) {
      return
    }
    sessionStorage.clear()
    Object.keys(this.props.cookies.getAll()).forEach(k =>
      this.props.cookies.remove(k)
    )
    const { data } = await client.mutate({
      mutation: FAMILY_LOGIN,
      variables: {
        phone: this.state.phone,
        callInstead: false,
      },
    })

    if (data && data.familyLogin.verificationCodeSent) {
      this.setState({ success: true, message: data.familyLogin.message })
    } else {
      this.setState({ success: false, message: data.familyLogin.message })
    }
  }, 3000)

  render() {
    if (this.state.loginMode === 'student') {
      return <LoginPage handleBack={this.props.handleBack} />
    }
    return (
      <ApolloConsumer>
        {client => (
          <ImageBackground source={login_background} style={access.background}>
            {this.state.success !== null && (
              <BannerMessage
                success={this.state.success}
                msg={this.state.message}
              />
            )}

            <View style={access.header}>
              <Image
                style={access.logo}
                source={
                  this.state.validUN ? this.props.cookies.get('imageUrl') : logo
                }
              />
              <FormControl color="primary" variant="contained">
                <RadioGroup
                  row
                  aria-label="login"
                  name="login"
                  value={this.state.loginMode}
                  onChange={e => this.setState({ loginMode: e.target.value })}
                >
                  <FormControlLabel
                    value="parent"
                    control={<Radio />}
                    label="Parent"
                    style={{ color: 'white' }}
                  />
                  <FormControlLabel
                    value="student"
                    control={<Radio />}
                    label="Student"
                    style={{ color: 'white' }}
                  />
                </RadioGroup>
              </FormControl>

              <FamilyLoginInput
                onPhoneChange={this.handlePhoneChange}
                onCodeChange={this.handleCodeChange}
                onLogin={() => this.throttledFamilyLogin(client)}
                onVerify={() => {
                  this.throttledFamilyVerify(client)
                }}
              />
              <TouchableOpacity
                style={styles.button}
                onPress={() => {
                  if (this.state.validUN) {
                    this.setState({ validUN: false })
                  } else {
                    this.props.handleBack
                      ? this.props.handleBack()
                      : this.props.history.push('/')
                  }
                }}
              >
                <Text style={{ color: 'white' }}>Back</Text>
              </TouchableOpacity>
              <Text style={styles.basicText}>Family Login</Text>
              <Text style={styles.basicText}>Version {version}</Text>
            </View>
          </ImageBackground>
        )}
      </ApolloConsumer>
    )
  }
}

const styles = StyleSheet.create({
  basicText: {
    color: solveColors.offWhite,
    textAlign: 'center',
  },
  problemSolvedText: {
    color: solveColors.offWhite,
    textAlign: 'center',
    marginBottom: 15,
    fontSize: 25,
  },
  solvedCount: {
    color: solveColors.offWhite,
    textAlign: 'center',
    fontSize: 100,
  },
  button: {
    alignItems: 'center',
    backgroundColor: '#237FBB',
    padding: 10,
    borderRadius: 10,
    marginBottom: 10,
    width: 175,
  },
})

export default withRouter(withCookies(FamilyLoginPage))
