import React, { Component } from 'react'
import { View, Text } from 'react-native-web'
import { TextField, Button } from '@material-ui/core'
import _ from 'lodash'
import { withApollo } from 'react-apollo'
import { withCookies } from 'react-cookie'
import { START_SESSION, USER_LOGIN } from '../../../services/queries'

const COOKIE_OPTIONS = {
  maxAge: 604800, // Number of seconds in a week
  sameSite: 'strict',
}

class StudentEmployeeLoginForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      username: '',
      password: '',
      loading: false,
      errorMsg: '',
    }
  }

  throttledLogin = _.throttle(async () => {
    this.setState({ loading: true })

    const { client, cookies, onSuccess } = this.props
    const { username, password } = this.state

    const { data } = await client.mutate({
      mutation: USER_LOGIN,
      variables: {
        username,
        password,
        studentOpportunityFilter: {
          stage: 'TRIAL',
          status: 'OPEN',
        },
      },
    })

    if (data && data.userLogin.success) {
      const { token, user, userType } = data.userLogin
      // If the user is an employee but is neither an admin nor an instructor, forbid login
      if (userType === 'Employee' && !user.isAdminOfSOLVE && !user.isTeacher) {
        this.setState({
          errorMsg:
            'Your account is neither an instructor nor an admin of SOLVE.',
          loading: false,
        })
        return
      }

      sessionStorage.clear()
      Object.keys(cookies.getAll()).forEach(key => cookies.remove(key))

      this.saveLogin(token, user)
      let gradeUpdated = false

      if (userType === 'Employee') {
        this.saveEmployeeType(user)
      } else if (userType === 'Student') {
        await this.startStudentSession(user.id)
        this.saveTrials(user)
        this.checkBirthday(user)
        gradeUpdated = user.gradeUpdated
      }

      this.setState({ errorMsg: '', loading: false })
      onSuccess(userType, gradeUpdated)
    } else {
      this.setState({ errorMsg: data.userLogin.message, loading: false })
    }
  }, 1000)

  saveEmployeeType = employee => {
    const { cookies } = this.props
    if (employee.isTeacher) {
      cookies.set('instructor', 1, COOKIE_OPTIONS)
    }
    if (employee.isAdminOfSOLVE) {
      cookies.set('admin', 1, COOKIE_OPTIONS)
    }
  }

  startStudentSession = async studentId => {
    const { client } = this.props
    const { data: sessionData } = await client.mutate({
      mutation: START_SESSION,
      variables: {
        studentId,
      },
    })
    sessionStorage.setItem('sessionId', sessionData.startStudentSession)
  }

  saveTrials = student => {
    const trials = student.opportunities.flatMap(opportunity =>
      opportunity.trials.map(trial => ({
        toLesson: trial.toLesson.id,
        classroomId: trial.toLesson.classroom.id,
      }))
    )
    sessionStorage.setItem('trials', JSON.stringify(trials))
  }

  checkBirthday = student => {
    let today = new Date()
    let bday = new Date(student.birthdate)
    if (
      today.getMonth() === bday.getMonth() &&
      today.getDate() === bday.getDate()
    ) {
      sessionStorage.setItem('birthdate', true)
    }
  }

  saveLogin = (token, user) => {
    const { cookies } = this.props

    cookies.set('token', token, COOKIE_OPTIONS)
    cookies.set('firstName', user.firstName, COOKIE_OPTIONS)
    cookies.set('lastName', user.lastName, COOKIE_OPTIONS)
    cookies.set('imageUrl', user.imageUrl, COOKIE_OPTIONS)
  }

  render() {
    const { username, password, loading, errorMsg } = this.state
    return (
      <View>
        <Text style={{ color: 'red' }}>{errorMsg}</Text>
        <br />
        {/* Prevent navigation to avoid breaking login flow */}
        <form onSubmit={event => event.preventDefault()}>
          <View>
            <TextField
              label="Username/Email"
              value={username}
              onChange={e => this.setState({ username: e.target.value })}
              autoComplete="username"
              disabled={loading}
            />
            <TextField
              label="Password"
              type="password"
              value={password}
              onChange={e => this.setState({ password: e.target.value })}
              autoComplete="current-password"
              disabled={loading}
            />
            <br />
            <Button
              type="submit"
              color="primary"
              variant="contained"
              onClick={this.throttledLogin}
              disabled={loading}
            >
              Log in
            </Button>
          </View>
        </form>
      </View>
    )
  }
}

export default withApollo(withCookies(StudentEmployeeLoginForm))
