import { View, StyleSheet, Text, TouchableOpacity } from 'react-native'
import React, { Component } from 'react'
import ProblemSubmitButton from './ProblemSubmitButton'
import AssignmentSubmitButton from './AssignmentSubmitButton'
import SkipButton from './SkipButton'
import { DialogModal, Latex } from '../../../components/'
import ScratchpadButton from './ScratchpadButton'
import SolutionButton from './SolutionButton'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const encodeChoices = choices => {
  let value = ''
  for (let i = 0; i < choices.length; i++) {
    value += choices[i].id - 1
  }
  return value
}
const randomize = choices => {
  let randomize = choices.length
  let temp
  let index

  while (randomize > 0) {
    index = Math.floor(Math.random() * randomize)
    randomize--
    temp = choices[randomize]
    choices[randomize] = choices[index]
    choices[index] = temp
  }
  return choices
}
const decodeChoices = (value, choices) => {
  let ans_choices = choices.map(o => ({
    id: `${o.order}`,
    content: `${o.text}`,
  }))

  let lookup = choices.map(o => ({ id: `${o.order}`, content: `${o.text}` }))
  if (!value || value.length > choices.length) return randomize(ans_choices)

  for (let i = 0; i < value.length; i++) {
    const order = parseInt(value.charAt(i)) + 1
    if (lookup.findIndex(c => parseInt(c.id) === order) === -1)
      return randomize(ans_choices)
    ans_choices[i] = lookup[lookup.findIndex(c => parseInt(c.id) === order)]
  }
  return ans_choices
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  padding: 18,
  margin: `0 0 18px 0`,
  alignItems: 'center',
  textAlign: 'center',
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: 10,
  borderColor: '#EDEDED',
  background: isDragging ? 'lightblue' : 'white',
  ...draggableStyle,
})

const getListStyle = {
  background: 'white',
  padding: 18,
  width: '100%',
}

class RankingProblemForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      order: decodeChoices(props.currentAnswer, props.problem.choices),
      dlgModalOpen: false,
      partial: true,
      randomize: props.problem.randomizeChoices,
    }
    this.onDragEnd = this.onDragEnd.bind(this)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.problem.choices !== prevState.order) {
      return {
        order: decodeChoices(
          nextProps.currentAnswer,
          nextProps.problem.choices
        ),
      }
    }
    return null
  }
  onDragEnd(result) {
    if (!result.destination) {
      return
    }

    const order = reorder(
      this.state.order,
      result.source.index,
      result.destination.index
    )

    this.setState({
      order,
    })
    this.props.updateProblemAnswer(encodeChoices(order))
  }
  handleProblemSubmit = async (fromSkip = false) => {
    const {
      problem,
      onProblemSubmit,
      review,
      lastProb,
      gotoLessons,
    } = this.props
    const answer = encodeChoices(
      problem.choices.map(o => ({
        id: `${o.order}`,
        content: `${o.text}`,
      }))
    )

    const studentAnswer = encodeChoices(this.state.order)
    if (review && !lastProb) {
      onProblemSubmit(studentAnswer, answer)
      return
    }
    if (review && lastProb) {
      gotoLessons()
      return
    }
    onProblemSubmit(studentAnswer, answer, fromSkip)
  }

  handleAssignmentSubmit = () => {
    const { answered } = this.props
    if (answered) {
      this.setState({ partial: false })
    }
    this.openDialogModal()
  }

  openDialogModal = () => {
    this.setState({ dlgModalOpen: true })
  }

  closeDialogModal = () => {
    this.setState({ dlgModalOpen: false })
  }

  render() {
    const {
      problem,
      probState,
      complete,
      allowSkip,
      incrementProblem,
      feedbackType,
      review,
      lastProb,
      onAssignmentSubmit,
      scratchpadUrl,
      currentProbNum,
      problemStates,
      instructor,
      showSolution,
      toggleShowSolution,
      problemSetLength,
      isLoading,
    } = this.props

    const { questionText, solutionText } = problem
    const typesetMath = true
    return (
      <View style={styles.problem}>
        <View style={styles.question}>
          <View style={styles.questionText}>
            {typesetMath && <Latex latex={questionText} />}
            {!typesetMath && <Text>{questionText}</Text>}
          </View>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable
              droppableId="droppable"
              renderClone={(provided, snapshot, rubric) => (
                <div
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                  style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style
                  )}
                >
                  <Latex
                    latex={this.state.order[rubric.source.index].content}
                  />
                </div>
              )}
            >
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle}
                >
                  {this.state.order.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <Latex latex={item.content} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <DialogModal
            visible={this.state.dlgModalOpen}
            transparent={true}
            onYes={async () => {
              if (this.state.studentAnswer) await this.handleProblemSubmit()
              onAssignmentSubmit()
              this.closeDialogModal()
            }}
            onNo={() => {
              this.closeDialogModal()
            }}
            message={
              this.state.partial
                ? `You have answered ${
                    problemStates.filter(p => p !== 0).length
                  }/${problemSetLength} problems. Are you sure you want to submit the assignment for grading?`
                : 'Once submitted, you cannot change your answers. Are you sure you want to submit?'
            }
          />
        </View>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          {scratchpadUrl && (
            <TouchableOpacity
              style={{ marginRight: '0.5vh' }}
              onPress={() => this.props.deleteScratchpadImg(currentProbNum)}
            >
              <Text>{'X'}</Text>
            </TouchableOpacity>
          )}
          <Text>{scratchpadUrl}</Text>
        </View>
        <View style={styles.buttonBar}>
          <ScratchpadButton toggleScratchpad={this.props.toggleScratchpad} />
          <ProblemSubmitButton
            isLoading={isLoading}
            probState={probState}
            feedbackType={feedbackType}
            complete={complete}
            handleProblemSubmit={this.handleProblemSubmit}
            review={review}
            lastProb={lastProb}
          />
          {allowSkip && !instructor && !review && (
            <SkipButton
              probState={probState}
              allowSkip={allowSkip}
              lastProb={lastProb}
              handleProblemSubmit={this.handleProblemSubmit}
              incrementProblem={incrementProblem}
            />
          )}
          {!review && !instructor && (
            <AssignmentSubmitButton
              handleAssignmentSubmit={this.handleAssignmentSubmit}
              locked={problemStates.filter(s => s !== 0).length === 0}
            />
          )}
          {!review && instructor && (
            <SolutionButton
              showSolution={showSolution}
              toggleShowSolution={toggleShowSolution}
            />
          )}
        </View>

        {(review || showSolution) && (
          <View style={styles.answerText}>
            <Text>Correct Answer:</Text>
            <Text>
              {problem.choices
                .sort((a, b) => a.order - b.order)
                .map(o => (
                  <Latex latex={o.text}></Latex>
                ))}
            </Text>
            {solutionText !== null &&
              solutionText !== undefined &&
              solutionText !== '' && (
                <View style={{ alignItems: 'center' }}>
                  <Text>{'\n'}Solution:</Text>
                  {typesetMath && <Latex latex={solutionText} />}
                  {!typesetMath && <Text>{solutionText}</Text>}
                </View>
              )}
          </View>
        )}
      </View>
    )
  }
}

const styles = StyleSheet.create({
  answerText: {
    alignItems: 'center',
  },
  question: {
    alignItems: 'center',
    userSelect: 'none',
    maxWidth: '40vw',
  },
  questionText: {
    fontSize:
      sessionStorage.getItem('size') === 'large'
        ? 24
        : sessionStorage.getItem('size') === 'med'
        ? 20
        : 14,
  },
  buttonBar: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  problem: {
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '60vw',
  },
})

export default RankingProblemForm
