import React, { useMemo } from 'react'
import { TouchableOpacity, Text } from 'react-native-web'
import { useTable } from 'react-table'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import solveColors from '../../components/colors'

const CartTable = props => {
  const { loading, setLoading, archiveItem, refetch } = props

  const columns = useMemo(
    () => [
      {
        id: 'x',
        Header: 'X',
        Cell: row => (
          <TouchableOpacity
            disabled={loading}
            onPress={() => {
              setLoading(false)
              archiveItem(row.row.original.id).then(() => {
                setTimeout(() => {
                  refetch()
                  setLoading(true)
                }, 500)
              })
            }}
          >
            <Text>X</Text>
          </TouchableOpacity>
        ),
      },
      {
        Header: '#',
        Cell: props => props.row.index + 1,
        width: 30,
      },
      {
        id: 'student',
        Header: 'Student',
        accessor: row =>
          row.student
            ? row.student.firstName + ' ' + row.student.lastName
            : 'Account',
      },
      {
        id: 'item',
        Header: 'Item',
        accessor: row => row.description,
      },
      {
        Header: 'Unit Price',
        accessor: 'unitPrice',
        Cell: row =>
          row.row.original.offerType === 'CLASSROOM' &&
          row.row.original.classroom
            ? `$${(row.row.original.classroom.tuitionCents / 100).toFixed(2)}`
            : `$${row.row.original.unitPrice.toFixed(2)}`,
        width: 100,
        style: {
          textAlign: 'right',
        },
      },
      {
        Header: 'Early Bird',
        accessor: 'earlyBirdDiscount',
        Cell: row =>
          row.row.original.offerType === 'CLASSROOM' &&
          row.row.original.classroom
            ? row.row.original.earlyBird
              ? `$${(
                  row.row.original.classroom.earlyBirdtuitionCents / 100
                ).toFixed(2)}`
              : `$${(row.row.original.classroom.TuitionCents / 100).toFixed(2)}`
            : `N/A`,
        width: 100,
        style: {
          textAlign: 'center',
        },
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
        width: 100,
        style: {
          textAlign: 'center',
        },
      },
      {
        id: 'price',
        Header: 'Price',
        accessor: 'price',
        Cell: props => <span>{`$${props.value.toFixed(2)}`}</span>,
        width: 100,
        style: {
          textAlign: 'right',
        },
      },
    ],
    [loading, setLoading, archiveItem, refetch]
  )

  const hideColumns = [] // ['#', 'unitPrice', 'earlyBird', 'quantity']

  if (props.isPaid) {
    hideColumns.push('x')
  }

  const { getTableProps, headerGroups, prepareRow, rows } = useTable({
    columns,
    data: props.items,
    initialState: { hiddenColumns: hideColumns },
  })

  return (
    <Table {...getTableProps()}>
      <TableHead>
        {headerGroups.map(headerGroup => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <TableCell
                {...column.getHeaderProps()}
                style={{
                  borderBottom: `solid 3px ${solveColors.blue1}`,
                  color: solveColors.blue1,
                  fontWeight: 'bold',
                }}
              >
                {column.render('Header')}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <TableCell {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

export default CartTable
