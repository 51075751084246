import React, { useState } from 'react'
import {
  Text,
  View,
  FlatList,
  TouchableOpacity,
  Image,
  ScrollView,
} from 'react-native-web'
import { Query } from 'react-apollo'
import LoadingPage from '../Loading/LoadingPage'
import solveColors from '../../components/colors'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import lateIcon from './../../images/arriveLate.png'
import earlyIcon from './../../images/leaveEarly.png'
import { GET_TUTOR_SESSIONS } from '../../../services/queries/attendance_queries'

const TutorSessionPage = props => {
  return (
    <Query
      query={GET_TUTOR_SESSIONS}
      context={{ headers: { 'cache-control': 'no-cache' } }}
      variables={{
        classroomFilter: {
          classroom: { id: props.id },
          student: { id: props.studentId },
        },
      }}
    >
      {({ data, loading, error }) => {
        if (loading) {
          return <LoadingPage />
        }
        if (error) {
          return `${error}`
        }
        let sessions = data.tutorSessions.sort(function(a, b) {
          return new Date(a.startDateTime) - new Date(b.startDateTime) //sorting by date
        })
        return (
          <ScrollView showsHorizontalScrollIndicator={false}>
            <HeaderRow date={'DATE'} status={'STATUS'} location={'LOCATION'} />
            <FlatList
              data={sessions}
              renderItem={({ item }) => {
                const { status, order, startDateTime: date } = item
                const location = item.classroom.centerLocation.name
                return (
                  <TutorSessionRow
                    order={order}
                    date={date}
                    status={status}
                    location={location}
                  />
                )
              }}
            />
          </ScrollView>
        )
      }}
    </Query>
  )
}

const TutorSessionRow = props => {
  const { order, status, date, last, location } = props
  const [hover, setHover] = useState(null)
  return (
    <TouchableOpacity
      disabled={true}
      style={{
        flexDirection: 'row',
        marginHorizontal: 10,
        padding: 10,
        marginBottom: 2,
        backgroundColor: hover === order ? '#e6fdff' : 'white',
        justifyContent: 'space-between',
        borderBottomRightRadius: last ? 5 : 0,
        borderBottomLeftRadius: last ? 5 : 0,
      }}
      onMouseEnter={() => {
        setHover(order)
      }}
      onMouseLeave={() => {
        setHover(null)
      }}
    >
      <View style={{ width: 150, flexDirection: 'row', alignItems: 'center' }}>
        {status === 'UNKNOWN' && (
          <RadioButtonUncheckedIcon style={{ color: solveColors.green }} />
        )}
        {status === 'ABSENT' && (
          <HighlightOffIcon style={{ color: solveColors.red1 }} />
        )}
        {status === 'ONTIME' && (
          <CheckCircleOutlineIcon style={{ color: solveColors.green }} />
        )}
        {status === 'LATE' && (
          <Image
            style={{ width: 25, height: 25, resizeMode: 'stretch' }}
            source={lateIcon}
          />
        )}
        {status === 'EARLY' && (
          <Image
            style={{
              width: 25,
              height: 25,
              resizeMode: 'stretch',
            }}
            source={earlyIcon}
          />
        )}
        <Text style={{ marginLeft: 10 }}>{status}</Text>
      </View>

      <Text style={{ width: 150 }}>
        {date === 'DATE' ? 'DATE' : new Date(date).toDateString()}
      </Text>
      <Text style={{ width: 100 }}>{location}</Text>
    </TouchableOpacity>
  )
}
const HeaderRow = props => {
  const { status, date, location } = props
  return (
    <View
      style={{
        flexDirection: 'row',
        marginHorizontal: 10,
        padding: 10,
        marginTop: 10,
        marginBottom: 2,
        backgroundColor: solveColors.blue1,
        justifyContent: 'space-between',
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
      }}
    >
      <Text style={{ color: 'white', width: 150 }}>{status}</Text>
      <Text style={{ color: 'white', width: 150 }}>
        {date === 'DATE' ? 'DATE' : new Date(date).toDateString()}
      </Text>
      <Text style={{ color: 'white', width: 100 }}>{location}</Text>
    </View>
  )
}

export default TutorSessionPage
