import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { Text } from 'react-native-web'
import CartTable from './CartTable'
import CartCreditsTable from './CartCreditsTable'
import solveColors from '../../components/colors'
import paidIcon from './../../images/paidIcon.png'
import reminderIcon from './../../images/reminderIcon.png'

class CartForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sortedItems: [],
      loading: false,
      status: this.props.invoice.status,
    }
  }

  componentDidMount() {
    let sortedItems = this.props.invoice.items
    let ordering = { CLASSROOM: 0, OFFER: 1, DISCOUNT: 2 }
    sortedItems.sort((a, b) => {
      if (a.itemType === b.itemType) {
        return a.student.id.localeCompare(b.student.id)
      }
      return (
        ordering[a.itemType] - ordering[b.itemType] ||
        a.itemType.localeCompare(b.itemType)
      )
    })
    this.setState({ sortedItems })
  }

  setLoading = loading => this.setState({ loading })

  render() {
    const { loading, sortedItems } = this.state
    if (!sortedItems) {
      return <div>Loading...</div>
    }

    const { credits, invoice } = this.props
    const { price, balance } = invoice
    const { status } = this.state
    const isPaid = status === 'PAID' || (status === 'OPEN' && balance === 0)

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '5px',
          overflowY: 'auto',
          flex: 1,
        }}
      >
        <Text style={{ fontSize: '21px', color: solveColors.blue5 }}>
          Review Your Order
        </Text>
        <div style={{ display: 'flex' }}></div>
        <CartTable
          items={sortedItems}
          archiveItem={this.props.archiveItem}
          refetch={this.props.refetch}
          loading={loading}
          setLoading={this.setLoading}
          isPaid={isPaid}
        />
        <br />
        {credits && credits.length > 0 && (
          <CartCreditsTable
            credits={credits}
            loading={loading}
            isPaid={isPaid}
            removeSelectedCredit={this.props.removeSelectedCredit}
          />
        )}
        <Text
          style={{ color: solveColors.blue3 }}
        >{`Order Total: $${price.toFixed(2)}`}</Text>
        <Text style={{ color: solveColors.blue3 }}>{`Paid Amount: $${(
          price - balance
        ).toFixed(2)}`}</Text>
        <Text
          style={{ color: solveColors.blue3 }}
        >{`Account Balance: $${balance.toFixed(2)}`}</Text>
        <br />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {!isPaid && (
            <Button
              disabled={loading}
              color="primary"
              variant="contained"
              onClick={() => this.props.openModal()}
              style={{ marginRight: '2em' }}
            >
              Go to payment
            </Button>
          )}
          {isPaid && (
            <img src={paidIcon} alt={'Paid'} width={100} height={100} />
          )}
          {!isPaid && (
            <img src={reminderIcon} alt={'Unpaid'} width={150} height={100} />
          )}
          <Button
            disabled={loading}
            color="secondary"
            variant="contained"
            onClick={() =>
              this.props.onBack
                ? this.props.onBack()
                : this.props.history.goBack()
            }
            style={{ marginRight: '2em' }}
          >
            Back
          </Button>
        </div>
      </div>
    )
  }
}

export default withRouter(CartForm)
