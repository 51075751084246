import { StyleSheet } from 'react-native-web'
import solveColors from './../../components/colors'

export const styles = StyleSheet.create({
  button: {
    marginTop: 20,
    justifyContent: 'center',
    backgroundColor: 'green',
    borderRadius: 50,
    height: 30,
    width: 150,
    alignSelf: 'center',
    alignItems: 'center',
    boxShadow: '5px 5px 3px rgba(0, 0, 0, 0.25)',
  },

  buttonText: {
    color: 'white',
    fontSize: 'vmin',
    fontWeight: 'bold',
    textAlign: 'center',
    textAlignVertical: 'center',
  },
  cardBody: {
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingBottom: 60,
  },
  cardContainer: {
    backgroundColor: 'white',
    margin: 10,
    width: 250,
    shadowColor: '#000',
    shadowOffset: { width: 5, height: 5 },
    shadowOpacity: 0.25,
    shadowRadius: 2,
  },
  lockedButton: {
    backgroundColor: '#575757',
  },
  moreRecsButton: {
    boxShadow: '5px 5px 3px rgba(0, 0, 0, 0.25)',
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 50,
    width: 400,
    borderRadius: 10,
    height: 50,
  },
  moreRecsText: {
    fontSize: 20,
    color: 'white',
    fontWeight: 'bold',
  },
  editButtons: {
    flexDirection: 'row',
    width: '100%',
  },
  editProfileView: {
    backgroundColor: 'white',
    minWidth: 'calc(450px + 5%)',
    padding: '20px',
  },
  resetButton: {
    marginLeft: '20px',
  },

  fieldDescriptor: {
    fontSize: '12px',
    marginBottom: '3px',
  },

  solidButton: {
    borderRadius: 10,
    paddingVertical: '7px',
    paddingHorizontal: '30px',
    width: 'calc(5% + 170px)',
    boxShadow: '5px 5px 3px rgba(0, 0, 0, 0.25)',
    alignItems: 'center',
    backgroundColor: solveColors.blue4,
  },
  clearButton: {
    borderRadius: 10,
    paddingVertical: '7px',
    paddingHorizontal: '30px',
    width: 'calc(5% + 170px)',
    alignItems: 'center',
    borderWidth: 3,
    borderColor: solveColors.blue4,
  },
  container: { alignItems: 'center', justifyContent: 'center' },
  subjectButton: {
    width: 230,
    height: 80,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    margin: 5,
  },
  subjectIcon: { width: 40, height: 40, resizeMode: 'cover' },
  errorText: { color: 'red', fontSize: 16 },
  sortButton: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 5,
    marginRight: 20,
    boxShadow: '5px 5px 3px rgba(0, 0, 0, 0.25)',
  },
  sortBar: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-start',
  },
  studentInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: '5em',
    paddingRight: '5em',
    padding: '1em',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'lightGrey',
    backgroundColor: 'white',
  },
})

export default styles
