import { Dimensions } from 'react-native-web'
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import EnrollmentForm from './EnrollmentForm'
import RecommendationForm from '../Recommendations/RecommendationForm'
import jwt from 'jsonwebtoken'
import { VideoModal } from '../../components'
import { useCookies } from 'react-cookie'

const CURRENT_Q = 'Spring'
const NEXT_Q = 'Summer'

export const DashboardForm = props => {
  const { filter, enrollments, progress, trialEnrollments } = props
  const [windowWidth, setWindowWidth] = useState(Dimensions.get('window').width)
  const [video, setVideo] = useState({
    currVideoId: null,
    videoVisible: false,
  })
  const [cookies] = useCookies()

  let studentId = null
  if (
    !cookies['token'] ||
    jwt.decode(cookies['token']).userType === 'Student'
  ) {
    studentId = jwt.decode(cookies['token']).id
  }

  const handleOpenVideo = videoId => {
    setVideo({
      currVideoId: videoId,
      videoVisible: true,
    })
  }

  const handleCloseVideo = () => {
    setVideo({
      currVideoId: null,
      videoVisible: false,
    })
  }

  useEffect(() => {
    const getWidth = () => setWindowWidth(Dimensions.get('window').width)
    window.addEventListener('resize', getWidth)
    //recommendations.length = 0
    return () => {
      window.removeEventListener('resize', getWidth)
    }
  }, [])

  let filteredEnrollments = enrollments.filter(item => {
    return filter === 'CLOSED'
      ? item.classroom.status === filter
      : item.classroom.status === 'OPENED' ||
          item.classroom.status === 'PUBLISHED'
  })
  /**
   * Trial enrollment doesn't actually have an enrollments record
   * We just append it onto filteredEnrollments
   */
  filteredEnrollments = [...filteredEnrollments, ...trialEnrollments]
  if (filter === 'CLOSED' || filter === 'OPENED') {
    return (
      <EnrollmentForm
        filter={filter}
        windowWidth={windowWidth}
        filteredEnrollments={filteredEnrollments}
        progress={progress}
      />
    )
  } else if (studentId) {
    return (
      <React.Fragment>
        <RecommendationForm
          studentId={studentId}
          windowWidth={windowWidth}
          progress={progress}
          currentQuarter={CURRENT_Q}
          nextQuarter={NEXT_Q}
          openVid={handleOpenVideo}
        />
        <VideoModal
          visible={video.videoVisible}
          onClose={handleCloseVideo}
          videoId={video.currVideoId}
        />
      </React.Fragment>
    )
  }
}

export default withRouter(DashboardForm)
