import { StyleSheet, View, Text, TouchableOpacity } from 'react-native'
import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { GET_CLASSROOM_INFO } from './../../../services/queries'
import Modal from 'modal-react-native-web'
import solveColors from './../../components/colors'
import Syllabus from './Syllabus'
import LoadingPage from './../Loading/LoadingPage'

class SyllabusModal extends Component {
  state = {
    visible: this.props.visible,
  }

  componentDidMount() {
    Modal.setAppElement('body')
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.visible !== nextProps.visible) {
      return { visible: nextProps.visible }
    }
    return null
  }
  render() {
    return (
      this.state.visible && (
        <Modal visible={this.state.visible} transparent={true}>
          <View style={styles.backdrop}>
            <View style={styles.results}>
              <Query
                query={GET_CLASSROOM_INFO}
                variables={{ id: this.props.classroomId }}
              >
                {({ data, loading, error }) => {
                  if (error) return `${error}`
                  if (loading) return <LoadingPage />
                  const title = data.classroom.title.split(':')[1].trim()

                  return <Syllabus title={title} classroom={data.classroom} />
                }}
              </Query>

              <TouchableOpacity
                style={[
                  styles.button,
                  { backgroundColor: solveColors.blue5, bottom: 0 },
                ]}
                onPress={() => {
                  this.props.onClose()
                }}
              >
                <Text style={{ color: 'white' }}>Close</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      )
    )
  }
}

const styles = StyleSheet.create({
  results: {
    borderRadius: '10px',
    height: '90vh',
    margin: 'auto',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: 25,
  },
  backdrop: {
    backgroundColor: 'rgba(52, 52, 52, 0.2)',
    height: '100%',
    width: '100%',
  },
  button: {
    height: 40,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    width: '20%',
  },
})
export default SyllabusModal
