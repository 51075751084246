import React from 'react'
import { TouchableOpacity, Text, StyleSheet } from 'react-native-web'
import solveColors from '../../../components/colors'

const ScratchpadButton = props => {
  return (
    <TouchableOpacity
      style={[styles.skip_button]}
      onPress={props.toggleScratchpad}
    >
      <Text style={styles.text}>OPEN SCRATCHPAD</Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  skip_button: {
    alignItems: 'center',
    paddingVertical: 10,
    borderRadius: 10,
    margin: 10,
    width: 160,
    backgroundColor: solveColors.green2,
  },
  text: {
    color: 'white',
    fontWeight: 'bold',
  },
})

export default ScratchpadButton
