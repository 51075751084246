import React from 'react'
import { TouchableOpacity, Text, StyleSheet } from 'react-native-web'
import solveColors from './../../components/colors'

const PromoBanner = props => {
  //const [promos, setPromos] = useState(props.promos)
  const { promos } = props
  // const [currInd, setCurrInd] = useState(0)
  const currInd = 0
  return (
    <TouchableOpacity
      style={styles.promoBanner}
      disabled={!promos[currInd].link}
      onPress={() => {
        window.open(promos[currInd].link)
      }}
    >
      <Text style={styles.bannerText}>{promos[currInd].message}</Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  promoBanner: {
    height: 50,
    backgroundColor: solveColors.blue2,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bannerText: {
    color: 'white',
    fontSize: 20,
    fontStyle: 'italic',
    fontFamily: 'Times New Roman',
  },
})

export default PromoBanner
