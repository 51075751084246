import gql from 'graphql-tag'

export const GET_FUTURE_ATTENDANCE = gql`
  query getFutureAttendance($filter: Filters) {
    attendees(filter: $filter, sort: { lesson: { startOn: 1 } }) {
      id
      isMakeup
      status
      note
      lesson {
        id
        startOn
        endOn
        classroom {
          id
          title
          centerLocation {
            id
            name
          }
        }
        lessonPlan {
          id
          title
          order
        }
      }
    }
  }
`

export const GET_MAKEUP = gql`
  query getMakeup($filter: Filters!) {
    makeups(filter: $filter) {
      id
      status
      notes
      toLesson {
        id
        startOn
        endOn
        classroom {
          id
          title
          centerLocation {
            id
            name
          }
        }
        lessonPlan {
          id
          title
          order
        }
      }
    }
  }
`
