import gql from 'graphql-tag'

export const GET_ATTENDANCE = gql`
  query getAttendance($classroomId: ID!, $attendeeFilter: Filters) {
    classroom(id: $classroomId) {
      id
      centerLocation {
        id
        name
      }
      teachingStyle
      lessons(sort: { lessonPlan: { order: 1 } }) {
        startOn
        endOn
        id
        lessonPlan {
          id
          title
          order
        }
        attendees(filter: $attendeeFilter) {
          status
          isMakeup
          id
        }
      }
    }
  }
`
export const GET_TUTOR_SESSIONS = gql`
  query getTutorSessions($classroomFilter: Filters) {
    tutorSessions(filter: $classroomFilter) {
      id
      classroom {
        id
        centerLocation {
          id
          name
        }
      }
      status
      order
      startDateTime
    }
  }
`
export const GET_MAKEUP_LESSONS = gql`
  query GetMakeupLessons($lessonId: ID!) {
    getMakeupLessons(lessonId: $lessonId) {
      success
      message
      makeupLessons {
        id
        startOn
        endOn
        lessonPlan {
          id
          title
          order
        }
        classroom {
          id
          title
        }
      }
    }
  }
`
export const CREATE_MAKEUP = gql`
  mutation createMakeup($input: CreateMakeupInput!) {
    createMakeup(input: $input) {
      id
      fromLesson {
        id
        classroom {
          id
        }
      }
      toLesson {
        id
        startOn
        previewOn
        classroom {
          id
          title
          centerLocation {
            id
            name
          }
        }
      }
    }
  }
`

export const ARCHIVE_MAKEUP = gql`
  mutation archiveMakeup($id: ID!) {
    archiveMakeup(id: $id) {
      id
    }
  }
`

export const UPDATE_ATTENDANCE = gql`
  mutation updateAttendance($id: ID!, $input: UpdateAttendeeInput!) {
    updateAttendee(id: $id, input: $input) {
      id
    }
  }
`
