import {
  TextInput,
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  Image,
} from 'react-native-web'
import React, { Component } from 'react'
import ProblemSubmitButton from './ProblemSubmitButton'
import AssignmentSubmitButton from './AssignmentSubmitButton'
import SkipButton from './SkipButton'
import { DialogModal, Latex, VideoPlayerForm } from '../../../components/index'
import ScratchpadButton from './ScratchpadButton'
import { isIPad13 } from 'react-device-detect'
import SolutionButton from './SolutionButton'
import { solveColors } from '../../../components/colors'
import KeyboardEventHandler from 'react-keyboard-event-handler'

class KeyboardProblemForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      studentAnswer: props.currentAnswer,
      dlgModalOpen: false,
      partial: true,
    }
    this.textInput = React.createRef()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.currentAnswer !== prevState.studentAnswer) {
      return { studentAnswer: nextProps.currentAnswer }
    }
    return null
  }

  componentDidMount() {
    this.textInput.current.focus()
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentAnswer !== prevProps.currentAnswer) {
      this.textInput.current.focus()
    }
  }
  openDialogModal() {
    this.setState({ dlgModalOpen: true })
  }
  closeDialogModal() {
    this.setState({ dlgModalOpen: false })
  }
  onEnter = async e => {
    if (this.props.scratchpadOpen) return
    const key = e.which || e.keyCode
    if (key === 13) {
      if (this.props.modalIsOpen) this.props.onClose()
      else if (!this.props.isLoading) this.handleProblemSubmit()
    }
  }
  handleProblemSubmit = async (fromSkip = false) => {
    const {
      onProblemSubmit,
      problem,
      review,
      lastProb,
      gotoLessons,
    } = this.props
    const { problemType, answer } = problem
    const answers = answer ? answer.split('&') : []
    let { studentAnswer } = this.state
    if (review && !lastProb) {
      onProblemSubmit(studentAnswer, answer)
      return
    }
    if (review && lastProb) {
      gotoLessons()
      return
    }
    if (problemType === 'SHORT_NUMBER_RESPONSE' && isNaN(studentAnswer)) {
      studentAnswer = studentAnswer
        ? studentAnswer.replace(/[^(\d|.)+]|\(|\)/g, '')
        : ''
    }

    onProblemSubmit(studentAnswer, answers, fromSkip)
  }
  handleAssignmentSubmit = () => {
    const { answered } = this.props
    if (answered) {
      this.setState({ partial: false })
    }
    this.openDialogModal()
  }
  render() {
    const {
      probState,
      complete,
      allowSkip,
      incrementProblem,
      problem,
      feedbackType,
      review,
      lastProb,
      updateProblemAnswer,
      scratchpadUrl,
      currentProbNum,
      problemStates,
      instructor,
      showSolution,
      toggleShowSolution,
      problemSetLength,
      solutionVideo,
      solutionImageUrl,
      isLoading,
    } = this.props

    const { questionText, answer, problemType, solutionText } = problem
    const typesetMath = true
    const hasSolution = answer !== undefined && answer !== null
    const teachingStyle = sessionStorage.getItem('teachingStyle')

    return (
      <View style={styles.problem}>
        <KeyboardEventHandler
          handleKeys={['enter']}
          handleFocusableElements={true}
          onKeyEvent={(key, e) => {
            if (this.props.modalIsOpen) this.props.onClose()
            else this.handleProblemSubmit()
          }}
        />
        <View style={styles.question}>
          <View style={styles.questionText}>
            {typesetMath && <Latex latex={questionText} />}
            {!typesetMath && <Text>{questionText}</Text>}
          </View>
          <View style={styles.row}>
            <Text style={styles.answerLabel}>Answer: </Text>
            <TextInput
              ref={this.textInput}
              editable={!complete && !review}
              style={styles.textinput}
              value={this.state.studentAnswer}
              keyboardType={
                problemType === 'SHORT_NUMBER_RESPONSE' && !isIPad13
                  ? 'numeric'
                  : 'default'
              }
              onChangeText={studentAnswer => {
                this.setState({ studentAnswer })
                updateProblemAnswer(studentAnswer)
              }}
            />
          </View>

          <DialogModal
            visible={this.state.dlgModalOpen}
            transparent={true}
            onYes={async () => {
              if (this.state.studentAnswer) {
                this.handleProblemSubmit()
              }
              this.props.onAssignmentSubmit()
              this.closeDialogModal()
            }}
            onNo={() => {
              this.closeDialogModal()
            }}
            message={
              this.state.partial
                ? `You have answered ${
                    problemStates.filter(p => p !== 0).length
                  }/${problemSetLength} problems. Are you sure you want to submit the assignment for grading?`
                : 'Once submitted, you cannot change your answers. Are you sure you want to submit?'
            }
          />
        </View>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          {scratchpadUrl && (
            <TouchableOpacity
              style={{ marginRight: '0.5vh' }}
              onPress={() => this.props.deleteScratchpadImg(currentProbNum)}
            >
              <Text>{'X'}</Text>
            </TouchableOpacity>
          )}
          <Text>{scratchpadUrl}</Text>
        </View>

        <View style={styles.buttonBar}>
          {!review && !instructor && teachingStyle === 'SELF_PACED' && (
            <TouchableOpacity
              style={{
                alignItems: 'center',
                paddingVertical: 10,
                borderRadius: 10,
                margin: 10,
                width: 160,
                backgroundColor: solveColors.green,
              }}
              onPress={toggleShowSolution}
            >
              <Text style={{ color: 'white', fontWeight: 'bold' }}>LEARN</Text>
            </TouchableOpacity>
          )}
          <ScratchpadButton toggleScratchpad={this.props.toggleScratchpad} />
          <ProblemSubmitButton
            isLoading={isLoading}
            probState={probState}
            handleProblemSubmit={this.handleProblemSubmit}
            feedbackType={feedbackType}
            review={review}
            lastProb={lastProb}
          />
          {allowSkip && !instructor && !review && (
            <SkipButton
              probState={probState}
              allowSkip={allowSkip}
              lastProb={lastProb}
              handleProblemSubmit={this.handleProblemSubmit}
              incrementProblem={incrementProblem}
            />
          )}
          {!review && !instructor && (
            <AssignmentSubmitButton
              handleAssignmentSubmit={this.handleAssignmentSubmit}
              locked={problemStates.filter(s => s !== 0).length === 0}
            />
          )}
          {!review && instructor && (
            <SolutionButton
              showSolution={showSolution}
              toggleShowSolution={toggleShowSolution}
            />
          )}
        </View>
        {(review || showSolution) && (
          <View style={styles.answerText}>
            <Text>Correct Answer:</Text>
            {typesetMath && hasSolution && <Latex latex={answer} />}
            {!typesetMath && hasSolution && <Text>{answer}</Text>}
            {!hasSolution && (
              <Text>Solution is not available for this question.</Text>
            )}
            {!review && !instructor && showSolution && solutionImageUrl && (
              <Image
                style={{ height: 200, width: 200, resizeMode: 'contain' }}
                source={solutionImageUrl}
              />
            )}
            {!review &&
              !instructor &&
              showSolution &&
              solutionVideo !== null &&
              solutionVideo !== undefined &&
              solutionVideo !== '' && (
                <VideoPlayerForm videoId={solutionVideo} />
              )}
            {solutionText !== null &&
              solutionText !== undefined &&
              solutionText !== '' && (
                <View style={{ alignItems: 'center' }}>
                  <Text>{'\n'}Solution:</Text>
                  {typesetMath && <Latex latex={solutionText} />}
                  {!typesetMath && <Text>{solutionText}</Text>}
                </View>
              )}
          </View>
        )}
      </View>
    )
  }
}

const styles = StyleSheet.create({
  answerLabel: {
    padding: 10,
  },
  answerText: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  row: {
    flexDirection: 'row',
    padding: 20,
    alignSelf: 'center',
  },
  question: {
    alignItems: 'center',
    userSelect: 'none',
    maxWidth: '40vw',
  },
  textinput: {
    borderColor: '#EDEDED',
    borderWidth: 1,
    width:
      sessionStorage.getItem('size') === 'large'
        ? 'calc(200px + 15vw)'
        : sessionStorage.getItem('size') === 'med'
        ? 'calc(140px + 15vw)'
        : 'calc(100px + 15vw)',
    borderRadius: 10,
    padding: 10,
  },
  buttonBar: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  problem: {
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '60vw',
  },
  questionText: {
    fontSize:
      sessionStorage.getItem('size') === 'large'
        ? 24
        : sessionStorage.getItem('size') === 'med'
        ? 20
        : 14,
  },
})

export default KeyboardProblemForm
