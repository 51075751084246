import { Query } from 'react-apollo'
import React from 'react'
import { Text, View } from 'react-native-web'
import { GET_COINS } from './../../services/queries'
import jwt from 'jsonwebtoken'
import { useCookies } from 'react-cookie'

export const CoinsView = props => {
  const [cookies] = useCookies([])
  if (process.env.NODE_ENV === 'production') {
    return <Text />
  }
  return (
    <View
      style={{
        ...props.style,
        padding: 10,
        marginVertical: 5,
        borderRadius: 10,
        backgroundColor: 'rgba(0,0,0,0.4)',
      }}
    >
      {cookies['token'] && (
        <Query
          query={GET_COINS}
          variables={{
            studentId: jwt.decode(cookies['token']).id,
          }}
        >
          {({ data, loading, error }) => {
            return (
              <Text
                style={{
                  color: 'white',
                  textAlign: 'center',
                }}
              >
                {loading ? 'COINS: ' : data && `COINS: ${data.student.coins}`}
              </Text>
            )
          }}
        </Query>
      )}
    </View>
  )
}

export default CoinsView
