const gradeLevel = (startGrade, endGrade) => {
  let gradeLevel = startGrade === 0 ? 'K' : `${startGrade}`
  if (startGrade < endGrade) {
    gradeLevel += ` - ${endGrade}`
  }
  return gradeLevel
}

const instructorNames = instructors => {
  let instructorText = ''
  for (let i = 0; i < instructors.length; i++) {
    instructorText += instructors[i].displayName
    if (i < instructors.length - 1) {
      instructorText = instructorText.concat(', ')
    }
  }
  return instructorText
}
export { gradeLevel, instructorNames }
