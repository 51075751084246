import React, { useState } from 'react'
import { View, TouchableOpacity } from 'react-native-web'
import { withRouter } from 'react-router-dom'
import styles from './styles'
import HeaderText from './HeaderText'
import CoinsView from './CoinsView'
import NavigationBar from './NavigationBar'
import ShoppingCartButton from './ShoppingCartButton'
import MenuIcon from '@material-ui/icons/Menu'
import CustomAvatar from './CustomAvatar'
import { isMobile, doNotShowHeader } from './../../config'
import { Button } from '@material-ui/core'
import { useCookies } from 'react-cookie'

export const Header = props => {
  const { history } = props
  const [cookies] = useCookies([])

  const url = cookies['imageUrl']

  const [showSide, setShowSide] = useState(false)

  const loggedIn = cookies['token']

  if (props.hide || doNotShowHeader.includes(window.location.pathname)) {
    return null
  }

  const firstName = cookies['firstName']
  const lastName = cookies['lastName']
  const name = `${firstName} ${lastName}`
  const firstInitial = firstName && firstName.length > 1 ? firstName[0] : ''
  const lastInitial = lastName && lastName.length > 1 ? lastName[0] : ''
  const initials = `${firstInitial}${lastInitial}`

  return (
    <>
      <NavigationBar
        hide={!showSide}
        toggleBars={() => setShowSide(!showSide)}
        openVideoModal={props.openVideoModal}
      />
      <View
        style={{
          alignSelf: 'flex-end',
          width: isMobile ? '100%' : 'calc(100% - 100px)',
          backgroundImage:
            'linear-gradient(109.34deg, #06CCAF -13.32%, #1CAECA 10.84%, #25A2D5 43.67%, #2D97DF 69.19%)',
        }}
      >
        <View style={styles.header}>
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {isMobile && (
                <TouchableOpacity
                  onPress={() => setShowSide(true)}
                  style={{ paddingLeft: '5px', marginRight: '16px' }}
                >
                  <MenuIcon htmlColor="white" />
                </TouchableOpacity>
              )}

              <HeaderText
                title={
                  props.title ||
                  window.location.pathname[1].toUpperCase() +
                    window.location.pathname.slice(2)
                }
              />
            </View>

            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
                flexWrap: 'wrap-reverse',
              }}
            >
              {loggedIn &&
                cookies['instructor'] !== '1' &&
                cookies['admin'] !== '1' &&
                cookies['family'] !== '1' && <CoinsView />}
              {cookies['familyAccountId'] && <ShoppingCartButton />}
              <TouchableOpacity
                style={{
                  alignItems: 'center',
                  padding: 10,
                }}
                onPress={() => {
                  history.push('/profile')
                }}
              >
                {/* {loggedIn && !isMobile && !(cookies['family'] === '1') && (
                  // <Image
                  //   style={[styles.icon, { borderRadius: 75 }]}
                  //   source={url === 'null' ? dummy_profile : url}
                  // />
                  // <Avatar
                  //   src={url === 'null' ? dummy_profile : url}
                  //   style={{ marginLeft: 10 }}
                  // >
                    {cookies['family'] === '1'
                      ? `${cookies['lastName']} ${isMobile ? '' : 'family'}`
                      : cookies['firstName']}
                  </Avatar>
                )} */}
                <CustomAvatar source={url} initial={initials} content={name} />
              </TouchableOpacity>
              {!loggedIn && (
                <Button
                  style={{ color: 'white' }}
                  onClick={() =>
                    props.history.push('/', {
                      from: window.location.pathname + window.location.search,
                    })
                  }
                >
                  Login
                </Button>
              )}
            </View>
          </View>
        </View>
      </View>
    </>
  )
}

export default withRouter(Header)
