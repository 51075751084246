import React, { useState, useEffect } from 'react'
import { Text, View, Image } from 'react-native-web'
import { withApollo } from 'react-apollo'
import { CREATE_MESSAGE_ISSUE } from '../../services/queries'
import teacher_appreciation from '../images/teacher_appreciation.jpeg'
import solveColors from './colors'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'

const AppreciationForm = props => {
  const { openEnrollments, studentId, client } = props
  const [classroom, setClassroom] = useState('')
  const [instructor, setInstructor] = useState('')
  const [message, setMessage] = useState('')

  const handleClassrooomChange = e => setClassroom(e.target.value)
  const handleInstructorChange = e => setInstructor(e.target.value)
  const handleMessageChange = e => setMessage(e.target.value)
  const handleSendAppreciation = async () => {
    await client.mutate({
      mutation: CREATE_MESSAGE_ISSUE,
      variables: {
        classroomId: classroom.id,
        responsibleEmployeeId: instructor.employee.id,
        studentId: studentId,
        description: message || '',
        dateAssigned: new Date(),
      },
    })
    alert('Your message has been successfully sent. Thank You!')
    window.location.reload()
  }

  useEffect(() => {
    setInstructor('')
  }, [classroom])

  return (
    <Paper
      elevation={4}
      style={{
        width: '60%',
        display: 'flex',
        margin: 20,
        padding: 20,
        flexDirection: 'column',
      }}
    >
      <Text
        style={{
          // marginLeft: 'auto',
          // marginRight: 20,
          alignSelf: 'center',
          color: solveColors.blue3,
          fontSize: 24,
          fontWeight: 'bold',
        }}
      >
        Teacher Appreciation Week (May 3 - 9, 2021)
      </Text>
      <Divider
        orientation="horizontal"
        variant="fullWidth"
        style={{ marginTop: 20, marginBottom: 10 }}
      />
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <View style={{ width: '50%' }}>
          <Image
            source={teacher_appreciation}
            style={{
              flex: 1,
              width: null,
              height: null,
              resizeMode: 'contain',
            }}
          />
        </View>
        <View style={{ width: '50%', padding: 20 }}>
          <FormControl style={{ marginBottom: 10 }}>
            <InputLabel htmlFor="appreciation-classroom-select">
              Select a Class
            </InputLabel>
            <Select
              id="appreciation-classroom-select"
              value={classroom}
              onChange={handleClassrooomChange}
              renderValue={classroom => classroom.title}
            >
              {openEnrollments.map((item, index) => {
                return (
                  <MenuItem value={item.classroom} key={index}>
                    {item.classroom.title}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <FormControl style={{ marginBottom: 20 }}>
            <InputLabel htmlFor="appreciation-instructor-select">
              Select an Instructor
            </InputLabel>
            <Select
              id="appreciation-instructor-select"
              value={instructor}
              disabled={!classroom}
              onChange={handleInstructorChange}
              renderValue={instructor => instructor.displayName}
            >
              {classroom &&
                classroom.instructors.map(instructor => {
                  return (
                    <MenuItem value={instructor}>
                      {instructor.displayName}
                    </MenuItem>
                  )
                })}
            </Select>
          </FormControl>
          <TextField
            label="Message"
            multiline
            rows={6}
            variant="outlined"
            value={message}
            onChange={handleMessageChange}
          />
        </View>
      </View>
      <Button
        onClick={handleSendAppreciation}
        disabled={!classroom || !instructor || !studentId}
        variant="contained"
        color="primary"
        style={{
          width: 100,
          height: 40,
          marginRight: 20,
          marginLeft: 'auto',
        }}
      >
        Send
      </Button>
    </Paper>
  )
}

export default withApollo(AppreciationForm)
