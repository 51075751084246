import React from 'react'
import { Text, View } from 'react-native-web'
import styles from './styles'

const HeaderText = props => {
  return (
    <View style={{ justifyContent: 'center' }}>
      <Text
        style={[
          styles.headerText,
          {
            fontSize: '1rem',
            flexWrap: 'wrap',
          },
        ]}
      >
        {props.title}
      </Text>
      <Text style={[styles.headerText, { fontSize: '25px', flexWrap: 'wrap' }]}>
        {props.subtitle}
      </Text>
    </View>
  )
}

export default HeaderText
