import gql from 'graphql-tag'

export const GET_CLASSROOMS = gql`
  query GetClassrooms($id: ID!) {
    student(id: $id) {
      enrollments {
        classroom {
          id
          title
          code
          status
          teachingStyle
          startDate
          endDate
          startTime
          endTime
          meets
          # delete instructors after teacher appreciation day
          # instructors {
          #  id
          #  displayName
          #  employee {
          #    id
          #  }
          #}
          course {
            id
            imageUrl
            description
          }
          centerLocation {
            name
          }
          finalAccessDate
        }
        overallGrade
      }
    }
  }
`

export const GET_TRIAL_CLASSROOM = gql`
  query GetClassroom($id: ID!) {
    classroom(id: $id) {
      id
      title
      code
      status
      teachingStyle
      startDate
      endDate
      startTime
      endTime
      meets
      course {
        id
        imageUrl
        description
      }
      finalAccessDate
    }
  }
`

export const GET_STUDENT_GRADE = gql`
  query GetStudentGrade($studentId: ID!) {
    student(id: $studentId) {
      grade
    }
  }
`

export const GET_RECOMMENDATIONS = gql`
  query getRecommendations(
    $studentId: ID!
    $currentQuarter: String
    $nextQuarter: String
  ) {
    getNextClassrooms(
      studentId: $studentId
      currentQuarter: $currentQuarter
      nextQuarter: $nextQuarter
    ) {
      success
      message
      nextClassrooms {
        lessons {
          id
        }
        course {
          id
          imageUrl
          description
          startGrade
          endGrade
        }
        videoId
        code
        id
        quarter
        session
        title
        status
        startDate
        endDate
        startTime
        endTime
        meets
        tuitionCents
        earlyBirdtuitionCents
        centerLocation {
          id
          name
        }
        leadInstructor
        instructors {
          displayName
        }
      }
    }
  }
`

export const GET_MORE_RECOMMENDATIONS = gql`
  query GetMoreRecommendations(
    $classroomFilter: Filters!
    $courseFilter: Filters
    $lessonsFilter: Filters
  ) {
    courses(filter: $courseFilter) {
      id
      imageUrl
      description
      endGrade
      startGrade
      code
      classrooms(filter: $classroomFilter) {
        lessons(filter: $lessonsFilter) {
          id
        }
        id
        videoId
        quarter
        title
        status
        startDate
        endDate
        startTime
        endTime
        meets
        tuitionCents
        earlyBirdtuitionCents
        instructors {
          employee {
            firstName
            lastName
          }
        }
        centerLocation {
          id
          name
        }
      }
    }
  }
`

export const GET_COINS = gql`
  query GetCoins($studentId: ID!) {
    student(id: $studentId) {
      coins
    }
  }
`

export const GET_STUDENT_PROGRESS = gql`
  query progress($studentId: ID!) {
    getStudentProgress(studentId: $studentId) {
      completion
      classroomId
    }
  }
`
export const GET_INSTRUCTOR_CLASSROOMS = gql`
  query GetInstructorClassrooms($id: ID!) {
    employee(id: $id) {
      instructors {
        classroom {
          id
          title
          code
          status
          teachingStyle
          startDate
          endDate
          startTime
          endTime
          meets
          course {
            id
            imageUrl
            description
          }
        }
      }
    }
  }
`

export const GET_DASHBOARD_ACCOUNT_INFO = gql`
  query AccountInfo($id: ID!) {
    student(id: $id) {
      firstName
      lastName
      school
      grade
      gender
      birthdate
    }
  }
`
export const UPDATE_DASHBOARD_ACCOUNT_INFO = gql`
  mutation($input: UpdateStudentInput!, $id: ID!) {
    updateStudent(id: $id, input: $input) {
      firstName
      lastName
      school
      grade
      gender
      birthdate
    }
  }
`
export const GET_ADMIN_CLASSROOMS = gql`
  query GetAdminClasrooms($filter: Filters!) {
    classrooms(filter: $filter) {
      id
      title
      code
      status
      teachingStyle
      startDate
      endDate
      startTime
      endTime
      meets
      course {
        id
        imageUrl
        description
      }
    }
  }
`

export const GET_INVOICES = gql`
  query getInvoices($filter: Filters!, $sort: Sorts) {
    invoices(filter: $filter, sort: $sort) {
      id
      invoiceNumber
      status
      price
      balance
      createdOn
      issueDate
      shortSummary
      payments {
        id
        method
        payee
        dateReceived
      }
      items {
        id
      }
      familyAccount {
        id
        primaryFamilyMember {
          id
          firstName
          lastName
        }
      }
      responsibleEmployee {
        id
        firstName
        lastName
      }
    }
    credits(filter: $filter) {
      id
      status
      creditType
      expirationDate
      balance
    }
  }
`

export const GET_INVOICE = gql`
  query getInvoice($id: ID!) {
    invoice(id: $id) {
      id
      invoiceNumber
      issueDate
      status
      familyAccount {
        id
        balance
        primaryFamilyMember {
          id
          firstName
          lastName
          email
          phoneNumber
        }
        customerProfile
        customerId
      }
      items {
        id
        student {
          id
          firstName
          lastName
        }
        earlyBird
        classroom {
          id
          title
          code
          tuitionCents
          earlyBirdtuitionCents
          course {
            id
            code
          }
        }
        discount {
          id
          label
        }
        offer {
          id
        }
        description
        unitPrice
        quantity
        price
        itemType
        enrolled
        withdrawal
        withdrawalAmount
      }
      payments {
        id
        identification
        dateReceived
        amount
        method
        payee
        note
      }
      balance
      price
      summary
    }
  }
`
