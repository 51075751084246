import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'
import solveColors from './colors'
import Tooltip from '@material-ui/core/Tooltip'

const DEFAULT_STYLES = {
  color: solveColors.offWhite,
  backgroundColor: solveColors.blue1,
}

const useStyles = makeStyles(() => ({
  avatarButton: props => ({
    ...DEFAULT_STYLES,
    ...props,
  }),
}))

const CustomAvatar = React.memo(({ source, initial, content }) => {
  const classes = useStyles()
  return (
    <Tooltip title={content}>
      <Avatar src={source} className={classes.avatarButton}>
        {initial}
      </Avatar>
    </Tooltip>
  )
})

export default CustomAvatar
