import React, { useState, useEffect } from 'react'
import { withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import {
  GET_POST_CONTENT,
  GET_POST_CLASSROOMS,
  GET_POST_FAVORITE,
  CREATE_POST_FAVORITE,
  UPDATE_POST_FAVORITE,
} from '../../../services/queries/post_queries'
import PromoForm from './PromoForm'
import { ScrollView, View } from 'react-native-web'
import { useCookies } from 'react-cookie'
import Typography from '@material-ui/core/Typography'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import FavoriteIcon from '@material-ui/icons/Favorite'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import { IconButton, Button, makeStyles } from '@material-ui/core'
import jwt from 'jsonwebtoken'

const useStyles = makeStyles({
  AspectRatioContainer: {
    position: 'relative',
    paddingBottom: '56.25%',
    height: 0,
    overflow: 'hidden',
    width: '100%',
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
})

function PostPage(props) {
  const [cookies] = useCookies([])
  const id = new URLSearchParams(window.location.search).get('id')
  const { client, history } = props
  const [post, setPost] = useState({})
  const [postFavorite, setPostFavorte] = useState(null)
  const [promoClassrooms, setPromoClassrooms] = useState(null)
  const [liked, setLiked] = useState(false)
  const [likedCount, setLikedCount] = useState(0)
  const classes = useStyles()

  // if (!cookies['token']) {
  //   history.goBack()
  // }

  const handleFavoriteRecord = async (
    wasDim,
    existingRecord,
    creationInput
  ) => {
    //create record if null else update
    if (cookies['token']) {
      if (existingRecord) {
        try {
          await client.mutate({
            mutation: UPDATE_POST_FAVORITE,
            variables: {
              id: existingRecord.id,
              input: {
                favorited: wasDim, //if it was dim, then invoking this function means user lit it up..
              },
            },
          })
        } catch (e) {
          console.log(e)
          return
        }
      } else {
        try {
          const { data } = await client.mutate({
            mutation: CREATE_POST_FAVORITE,
            variables: {
              input: creationInput,
            },
          })
          setPostFavorte(data.createPostFavorite)
        } catch (e) {
          console.log(e)
          return
        }
      }
    }
  }

  useEffect(() => {
    const inner = async () => {
      const { data } = await client.query({
        query: GET_POST_CONTENT,
        variables: {
          id: id,
        },
      })
      const { post } = data
      if (!post) {
        history.goBack()
      }
      setLikedCount(post.favoriteCount)
      const courseCodeFilter = post.classroomCodes
        ? post.classroomCodes.map(code => ({
            code: code,
          }))
        : []
      setPost(post)
      if (cookies['token']) {
        const { data: favoritesData } = await client.query({
          query: GET_POST_FAVORITE,
          variables: {
            filter: {
              [jwt.decode(cookies['token']).userType === 'Student'
                ? 'studentId'
                : 'familyMemberId']: jwt.decode(cookies['token']).id,
              postId: post.id,
            },
          },
        })
        const { postFavorites } = favoritesData
        if (postFavorites.length) {
          setPostFavorte(postFavorites[0])
          setLiked(postFavorites[0].favorited)
        }
        const { data: classroomData } = await client.query({
          query: GET_POST_CLASSROOMS,
          variables: {
            filter: {
              status: ['PUBLISHED', 'OPENED'],
              $or: courseCodeFilter,
            },
          },
          skip: !courseCodeFilter.length,
        })
        setPromoClassrooms(classroomData)
      }
    }

    inner()
  }, [client, history, id, cookies])

  if (!id) {
    history.goBack()
  }

  return (
    <View style={{ flex: 1, backgroundColor: 'white' }}>
      {cookies['token'] && (
        <Button onClick={history.goBack} style={{ alignSelf: 'flex-start' }}>
          Back
        </Button>
      )}
      {post && (
        <ScrollView
          style={{ width: '100%' }}
          contentContainerStyle={{ alignItems: 'center' }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              margin: '32px',
              maxWidth: '850px',
            }}
          >
            <div>
              <h2 style={{ wordBreak: 'break-word' }}>{post.title}</h2>
            </div>
            <div>
              <h5 style={{ color: 'grey', wordBreak: 'break-word' }}>
                {post.description}
              </h5>
            </div>
            {post.video ? (
              <div className={classes.AspectRatioContainer}>
                <iframe
                  className={classes.iframe}
                  title={post.video}
                  src={`https://player.vimeo.com/video/${post.video}?title=0&portrait=0&byline=0`}
                  width="460"
                  height="320"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen="true"
                  tabIndex="-1"
                />
              </div>
            ) : (
              <img
                src={post.imageUrl}
                style={{ width: '100%', maxWidth: '850px' }}
                alt={'News Header'}
              />
            )}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                size="medium"
                color="primary"
                disabled={!cookies['token']}
                onClick={e => {
                  e.stopPropagation()
                  setLikedCount(liked ? likedCount - 1 : likedCount + 1)
                  handleFavoriteRecord(!liked, postFavorite, {
                    favorited: !liked,
                    postId: post.id,
                    [jwt.decode(cookies['token']).userType === 'Student'
                      ? 'studentId'
                      : 'familyMemberId']: jwt.decode(cookies['token']).id,
                  })
                  setLiked(!liked)
                }}
                style={{ alignSelf: 'flex-start', flex: 1 }}
              >
                {liked ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                <Typography>{likedCount}</Typography>
              </IconButton>
              <VisibilityOutlinedIcon color="primary" />
              <Typography color="primary">{post.viewCount}</Typography>
            </div>
            <td dangerouslySetInnerHTML={{ __html: post.content }} />
          </div>
          {cookies['token'] &&
            promoClassrooms &&
            promoClassrooms.classrooms.length && (
              <PromoForm promoClassrooms={promoClassrooms} />
            )}
        </ScrollView>
      )}
    </View>
  )
}

export default withApollo(withRouter(PostPage))
