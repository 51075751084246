import React, { useState } from 'react'
import {
  View,
  Text,
  ImageBackground,
  StyleSheet,
  TouchableOpacity,
  Image,
  ScrollView,
} from 'react-native-web'
import { withRouter } from 'react-router-dom'
import solveColors from './../../components/colors'
import PlayCircleFilledOutlinedIcon from '@material-ui/icons/PlayCircleFilledOutlined'
import { VideoModal } from './../../components/'
import HomePageMobile from './HomePageMobile'
import PromoBanner from './PromoBanner'
import CombinedLoginPage from '../CombinedLogin/CombinedLoginPage'
import { isMobile } from './../../../config'
import RegisterPage from '../Register/RegisterPage'
import { useCookies } from 'react-cookie'
import logo2 from '../../images/ardent-logo2.png'
import logoText from '../../images/ardent-logo-text.webp'

const HomePage = props => {
  const [videoModalVisible, setVideoModalVisible] = useState(false)
  const [videoId, setVideoId] = useState(null)
  const [openLogin, setOpenLogin] = useState(
    props.location.state ? props.location.state.openLogin : false
  )
  const [cookies, , removeCookie] = useCookies([])

  const [showJoin, setShowJoin] = useState(
    props.location.state ? props.location.state.openJoin : false
  )
  const redirectTo = props.location.state ? props.location.state.from : null

  const toggleVideoModal = () => {
    setVideoModalVisible(!videoModalVisible)
  }

  const [loggedIn, setLoggedIn] = useState(cookies['token'])

  const backgroundImage = {
    uri:
      'https://ardent-shared-media.s3-us-west-2.amazonaws.com/system/homepage_background.jpg',
  }
  if (isMobile) {
    return <HomePageMobile openLogin={openLogin} redirectTo={redirectTo} />
  }
  return (
    <View style={styles.container}>
      <PromoBanner
        promos={[
          {
            message:
              // '2021 Ardent Scholar Summer Research Applications are now open! Click HERE to Apply NOW!',
              // `Summer 2022 classes start on June 6th. Click here to enroll today!`,
              '$50 Off Summer Session Flash Sale Promotion Ends on Auguest 21, 2023. REGISTER NOW!',
            link: 'https://solve.ardentlabs.io/catalog',
            // 'https://ardentacademy.com/index.php/news-and-events/461-research-2',
          },
        ]}
      />
      <View style={styles.header}>
        <View
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <TouchableOpacity
            style={styles.headerButton}
            onPress={() => {
              props.history.push('/catalog')
            }}
          >
            <Text style={styles.headerButtonText}>Catalog</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.headerButton}
            onPress={() => {
              if (loggedIn) {
                setLoggedIn(false)
                sessionStorage.clear()
                Object.keys(cookies).forEach(k => removeCookie(k))
              } else {
                setOpenLogin(true)
              }
            }}
          >
            <Text style={styles.headerButtonText}>
              {loggedIn ? 'Logout' : 'Login'}
            </Text>
          </TouchableOpacity>
          {!loggedIn && (
            <TouchableOpacity
              style={styles.headerButton}
              onPress={() => {
                setShowJoin(true)
              }}
            >
              <Text style={styles.headerButtonText}>Join</Text>
            </TouchableOpacity>
          )}
        </View>
      </View>

      <ImageBackground
        style={styles.background}
        imageStyle={styles.imageBackground}
        source={backgroundImage}
      >
        <Image
          source={logo2}
          style={{
            width: 100,
            height: 120,
            resizeMode: 'cover',
            position: 'absolute',
            top: -70,
            left: '5vw',
          }}
        />
        <Image
          source={logoText}
          style={{
            width: 210,
            height: 40,
            resizeMode: 'stretch',
            position: 'absolute',
            top: -55,
            left: 'calc(5vw + 110px)',
          }}
        />
        <ScrollView>
          <View style={styles.textBlock}>
            <Text style={styles.headline}>Welcome to Ardent Academy</Text>
            <View style={styles.subTextBlock}>
              <Text
                style={[
                  styles.subText,
                  { marginBottom: 20, fontSize: 18, fontStyle: 'italic' },
                ]}
              >
                Challenging brilliant minds since 2006
              </Text>
              {/* <Text style={styles.subText}>
                Schedule a <Text style={styles.highlight}>trial</Text> in a
                course that meets your child’s educational needs, or a{' '}
                <Text style={styles.highlight}>free consultation</Text> with an
                Ardent educational consultant: call (949) 861-2211.
              </Text> */}
            </View>
            <View style={styles.buttonsBlock}>
              <TouchableOpacity
                style={styles.videoButton}
                onPress={() => {
                  toggleVideoModal()
                  setVideoId('414873363')
                }}
              >
                <PlayCircleFilledOutlinedIcon
                  style={{
                    height: 75,
                    width: 75,
                    color: solveColors.blue3,
                    borderWidth: 10,
                    borderColor: 'black',
                  }}
                />
              </TouchableOpacity>
            </View>
          </View>
        </ScrollView>
      </ImageBackground>
      <VideoModal
        visible={videoModalVisible}
        onClose={toggleVideoModal}
        videoId={videoId}
      />
      <CombinedLoginPage
        redirectTo={redirectTo}
        visible={openLogin}
        close={() => setOpenLogin(false)}
      />
      <RegisterPage
        visible={showJoin}
        close={() => setShowJoin(false)}
        redirectTo={redirectTo}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  background: {
    flex: 1,
  },
  imageBackground: {
    filter: 'brightness(75%)',
    resizeMode: 'cover',
    alignSelf: 'flex-start',
  },
  container: {
    flex: 1,
    display: 'flex',
  },
  headline: {
    fontSize: 50,
    color: 'white',
  },
  subText: {
    fontSize: 16,
    color: 'white',
  },
  textBlock: {
    marginTop: '130px',
    marginLeft: '5vw',
    maxWidth: 'calc(250px + 30vw)',
  },
  subTextBlock: {
    marginVertical: 20,
    maxWidth: 'calc(200px + 30vw)',
  },
  highlight: {
    color: solveColors.blue1,
    fontWeight: 'bold',
  },
  buttonsBlock: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  videoButton: {
    backgroundColor: 'white',
    borderRadius: 50,
    marginRight: 30,
  },
  Button: {
    backgroundColor: solveColors.blue3,
    borderRadius: 5,
    paddingHorizontal: 25,
    paddingVertical: 8,
    marginRight: 30,
  },
  ButtonText: {
    color: 'white',
    fontSize: 16,
  },
  header: {
    height: 75,
    backgroundColor: 'white',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerButton: {
    backgroundColor: 'white',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: solveColors.blue2,
    paddingHorizontal: 25,
    paddingVertical: 10,
    marginRight: 30,
  },
  headerButtonText: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  promoBanner: {
    height: 40,
    backgroundColor: solveColors.blue2,
    flexDirection: 'row',
    alignItems: 'center',
  },
})

export default withRouter(HomePage)
