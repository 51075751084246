import React, { useState } from 'react'
import { ScrollView } from 'react-native'
import { Tabs } from '../../components/'
import jwt from 'jsonwebtoken'
import { Query } from 'react-apollo'
import { GET_INSTRUCTOR_ACC_INFO } from '../../../services/queries'
import LoadingPage from '../Loading/LoadingPage'
import dummy_profile from '../../images/dummy_profile.png'
import InstructorProfileForm from './InstructorProfileForm'
import EditInstructorProfileForm from './EditInstructorProfileForm'
import { useCookies } from 'react-cookie'

const tabInfo = [
  { label: 'ACCOUNT', filter: () => handlePress('Account Info') },
  // { label: "BANK", filter: () => handlePress("Bank") },
  // { label: "LEADERBOARD", filter: () => handlePress("Leaderboard") }
]

const handlePress = filter => {
  console.log(filter)
}

const InstructorProfilePage = props => {
  const [editable, setEditable] = useState(false)
  const [cookies] = useCookies([])
  const id = jwt.decode(cookies['token']).id
  const firstName = cookies['firstName']
  const lastName = cookies['lastName']
  props.changeTitles(`${firstName} ${lastName}`, 'Profile')

  return (
    <Query
      query={GET_INSTRUCTOR_ACC_INFO}
      variables={{ id: id }}
      context={{ headers: { 'cache-control': 'no-cache' } }}
    >
      {({ data, loading, refetch }) => {
        if (loading) return <LoadingPage />
        const {
          firstName,
          lastName,
          imageUrl,
          birthdate,
          position,
          gender,
        } = data.employee
        const company = !data.employee.company
          ? 'ArdentAcademy'
          : data.employee.company
        const url = imageUrl ? imageUrl : dummy_profile
        return (
          <ScrollView>
            <Tabs tabInfo={tabInfo} handleFunction={handlePress} />
            {!editable && (
              <InstructorProfileForm
                instructorId={id}
                setEditable={setEditable}
                company={company}
                position={position}
                firstName={firstName}
                lastName={lastName}
                imageUrl={url}
              />
            )}
            {editable && (
              <EditInstructorProfileForm
                instructorId={id}
                setEditable={setEditable}
                company={company}
                refetch={refetch}
                gender={gender}
                firstName={firstName}
                lastName={lastName}
                imageUrl={url}
                birthdate={birthdate}
                workEmail={data.employee.workEmail}
                personalEmail={data.employee.personalEmail}
                mobileNumber={data.employee.mobileNumber}
              />
            )}
          </ScrollView>
        )
      }}
    </Query>
  )
}

export default InstructorProfilePage
