import gql from 'graphql-tag'

export const GET_ENROLLMENT_DETAILS = gql`
  query getEnrollmentDetails($id: ID!, $filter: Filters!) {
    enrollment(id: $id) {
      id
      classroom {
        id
        code
        title
        lessons {
          id
          lessonPlan {
            id
            order
            title
          }
          attendees(filter: $filter) {
            id
            percentCompleted
            overallGrade
          }
        }
      }
      overallGrade
      homeworkGrade
      quizGrade
      contestGrade
    }
  }
`
