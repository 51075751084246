const formatDate = (dateString, includeYear = true) => {
  const months = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ]
  const date = new Date(dateString)
  const month = date.getMonth()
  const day = date.getDate()
  if (includeYear) {
    const year = date.getFullYear()
    return `${months[month]} ${day}, ${year}`
  } else {
    return `${months[month]} ${day}`
  }
}

const formatTime = dateString => {
  const date = new Date(dateString)
  const hour = date.getHours()
  const min = date.getMinutes()
  return `${hour > 12 ? hour - 12 : hour}:${min < 10 ? '0' : ''}${min}${
    hour > 11 && hour < 23 ? ' pm' : ' am'
  }`
}

const convertToYMD = utcDate => {
  const localDate = new Date(utcDate)
  if (!localDate) return null

  let month = '' + (localDate.getUTCMonth() + 1)
  let day = '' + localDate.getUTCDate()
  let year = '' + localDate.getUTCFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day
  while (year.length < 4) year = '0' + year

  return [month, day, year].join('/')
}

const getMonth = dateString => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const date = new Date(dateString)
  const month = date.getMonth()
  return months[month]
}

const getDay = dateString => {
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]
  const date = new Date(dateString)
  const day = date.getDay()
  return days[day]
}

const getYear = dateString => {
  return new Date(dateString).getYear() + 1900
}

const secondsToMMSS = duration => {
  // Hours, minutes and seconds
  var hrs = ~~(duration / 3600)
  var mins = ~~((duration % 3600) / 60)
  var secs = ~~duration % 60

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = ''

  if (hrs > 0) {
    ret += '' + hrs + ':' + (mins < 10 ? '0' : '')
  }

  ret += '' + mins + ':' + (secs < 10 ? '0' : '')
  ret += '' + secs
  return ret
}

export {
  formatTime,
  formatDate,
  convertToYMD,
  getMonth,
  getYear,
  getDay,
  secondsToMMSS,
}
