import React from 'react'
import { withRouter } from 'react-router-dom'
import { Text } from 'react-native-web'
import solveColors from '../../components/colors'

//mui
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'

const SelectClassForm = React.memo(props => {
  const { classes, history } = props

  return (
    <Paper className={classes.smallFormContainer} elevation={4}>
      <Container className={classes.headerContainer}>
        <Text
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
            color: solveColors.blue3,
          }}
        >
          Select Your classes
        </Text>
        <Button
          className={classes.longButton}
          style={{ marginRight: '20px', marginLeft: 'auto' }}
          variant={'contained'}
          color={'primary'}
          onClick={() => history.push({ pathname: '/cart' })}
        >
          To Checkout
        </Button>
      </Container>
    </Paper>
  )
})

export default withRouter(SelectClassForm)
