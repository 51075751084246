import React from 'react'
import { Text, Image, View } from 'react-native'
import { withRouter } from 'react-router-dom'
import ProfileTable from './ProfileTable'
import Button from './Button'
import styles from './styles'
import { solveColors } from '../../components/colors'
import { CoinsView } from './../../components/'
import { version } from './../../../../package.json'
import { useCookies } from 'react-cookie'

const ProfileForm = props => {
  const { history } = props
  const [cookies, , removeCookie] = useCookies([])
  const handleSignOut = props => {
    if (props.text === 'SIGN OUT') {
      sessionStorage.clear()
      Object.keys(cookies).forEach(k => removeCookie(k))
      history.push({ pathname: '/' })
    }
  }

  const handleForgottenPassword = props => {
    history.push({ pathname: '/changepassword' })
  }

  const handlePress = () => {
    props.setEditable(true)
    sessionStorage.setItem('showStudentEdit', true)
  }
  const profileInfo = [
    {
      left: 'School',
      right: props.school,
    },
    {
      left: 'Grade',
      right: props.grade,
    },
    {
      left: 'Username',
      right: props.username,
    },
  ]
  return (
    <View style={styles.view}>
      <Image style={styles.icon} source={props.imageUrl} />
      <Text style={[styles.name, styles.bold]}>
        {props.firstName} {props.lastName}
      </Text>
      <CoinsView />
      <Button
        buttonStyling={[styles.defaultButton, styles.green]}
        text="EDIT PROFILE"
        onPress={handlePress}
      />
      <Button
        buttonStyling={[styles.defaultButton, styles.white]}
        textStyling={{ color: solveColors.grey3 }}
        text="SIGN OUT"
        onPress={handleSignOut}
      />
      <Text>Ardent SOLVE Version {version}</Text>
      <ProfileTable
        info={profileInfo}
        studentId={props.studentId}
        onRemindPW={handleForgottenPassword}
      />
    </View>
  )
}

export default withRouter(ProfileForm)
