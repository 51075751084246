import { StyleSheet, View, Text, TouchableOpacity, Image } from 'react-native'
import React, { Component } from 'react'
import Modal from 'modal-react-native-web'
import solveColors from './../../components/colors'
import { convertToYMD } from './../../../utils/dateTime'
import { Latex } from './../../components'
import drliB from './../../images/drliB.png'
import { UPDATE_ISSUE } from '../../../services/queries'
import TextField from '@material-ui/core/TextField'
import { withApollo } from 'react-apollo'
import { translateIssueType } from './MessageForm'

class MessageModal extends Component {
  state = {
    visible: this.props.visible,
    emoji: Math.random() * 3,
    message: '',
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.visible !== prevState.visible) {
      return {
        visible: nextProps.visible,
        emoji: Math.random() * 3,
        message: '',
      }
    }
    return null
  }
  handleChangeMessage = e => this.setState({ message: e.target.value })

  handleSendMessage = async () => {
    const { client, data } = this.props
    if (data.issueType === 'MESSAGE') {
      const success = await client.mutate({
        mutation: UPDATE_ISSUE,
        variables: {
          issueId: data.id,
          issueInput: {
            resolution: this.state.message,
            issueStatus: 'RESOLVED',
            isRead: false,
          },
        },
      })
      if (success) {
        this.props.onYes()
      }
    }
  }

  render() {
    const {
      issueType,
      dateAssigned,
      issueStatus,
      problem,
      description,
      response,
      classroom,
      lapCode,
      student,
    } = this.props.data
    const attendance = issueType === 'ATTENDANCE'
    const isMessage = issueType === 'MESSAGE'
    const { visible } = this.state
    return (
      <Modal visible={visible} transparent={true} animationType="fade">
        <View style={styles.backdrop}>
          <View style={styles.results}>
            <View style={styles.title}>
              <Text>{'Type: ' + translateIssueType(issueType)}</Text>
              <Text>{'Date: ' + convertToYMD(dateAssigned)}</Text>
              <Text>{'Status: ' + issueStatus.toLowerCase()}</Text>
            </View>

            <View style={styles.body}>
              <Text>{`Class: ${classroom.code}`}</Text>
              {!isMessage && <Text>{`Lesson: ${lapCode.slice(0, 2)}`}</Text>}
              {!attendance && !isMessage && (
                <Text>{`Assignment: ${lapCode.slice(3, 5)}`}</Text>
              )}
              {!attendance && !isMessage && (
                <Text>{`Problem: ${lapCode.slice(6, 8)}`}</Text>
              )}
            </View>
            {!attendance && !isMessage && problem.questionImageUrl && (
              <img
                style={{ width: '70%', height: 'auto' }}
                src={problem.questionImageUrl}
                alt={'Question'}
              />
            )}
            {!attendance && !isMessage && problem.questionImageUrl && (
              <Latex latex={problem.questionText} />
            )}
            {issueType === 'MESSAGE' && (
              <Text style={styles.info}>
                {`From: ${student.firstName} ${student.lastName}`}
              </Text>
            )}
            <Text style={styles.info}>
              {'Message: ' + (description ? description : 'no message')}
            </Text>
            {issueStatus === 'INITIATED' && issueType === 'MESSAGE' && (
              <TextField
                type="text"
                label="Reply"
                name="Reply"
                fullWidth
                multiline
                rows={5}
                maxRows={5}
                variant={'outlined'}
                style={{
                  marginTop: '10px',
                  marginBottom: '20px',
                }}
                value={this.state.message || ''}
                onChange={this.handleChangeMessage}
              />
            )}
            {!response === false && (
              <View
                style={{ flexDirection: 'row', justifyContent: 'flex-start' }}
              >
                <Image
                  source={drliB}
                  style={{ height: 125, width: 125, marginRight: 20 }}
                />
                <Latex latex={response} />
              </View>
            )}
            {/* {!response && <Text style={styles.info}>Not resolved yet</Text>} */}
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft: 'auto',
              }}
            >
              <TouchableOpacity
                style={styles.button}
                onPress={() => {
                  this.props.onYes()
                }}
              >
                <Text style={{ color: 'white' }}>
                  {this.props.yesText || 'Yes'}
                </Text>
              </TouchableOpacity>
              {issueStatus === 'INITIATED' && issueType === 'MESSAGE' && (
                <TouchableOpacity
                  style={styles.replyButton}
                  onPress={this.handleSendMessage}
                >
                  <Text style={{ color: 'white' }}>Send</Text>
                </TouchableOpacity>
              )}
            </View>
          </View>
        </View>
      </Modal>
    )
  }
}

const styles = StyleSheet.create({
  results: {
    borderRadius: '10px',
    width: '50%',
    minHeight: '50%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: 25,
    justifyContent: 'flex-start',
  },
  backdrop: {
    backgroundColor: 'rgba(52, 52, 52, 0.2)',
    height: '100%',
    width: '100%',
  },
  button: {
    width: 100,
    height: 40,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: solveColors.blue5,
  },
  replyButton: {
    width: 100,
    height: 40,
    borderRadius: 10,
    marginLeft: '10px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: solveColors.blue5,
  },
  title: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '2vh',
    borderBottomColor: 'black',
    borderBottomWidth: 3,
  },
  body: {
    width: '100%',
    marginBottom: '2vh',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  info: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    flexGrow: 1,
    flex: 1,
    marginBottom: '2vh',
  },
})
export default withApollo(MessageModal)
