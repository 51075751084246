import React, { useState, useCallback } from 'react'
import { useTable } from 'react-table'
import { ScrollView, Text, View } from 'react-native'
import { convertToYMD } from './../../../utils/dateTime'
import DialogModal from './MessageModal'
import styles from './styles'
import { TouchableOpacity } from 'react-native-web'
import MarkunreadIcon from '@material-ui/icons/Markunread'
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline'
import { useCookies } from 'react-cookie'
import jwt from 'jsonwebtoken'

export default function MessageForm(props) {
  const [cookies] = useCookies([])
  const [modalVisible, setModalVisible] = useState(false)
  const [selected, setSelected] = useState(0)
  const [hover, setHover] = useState(-1)

  const userToken = React.useMemo(() => {
    if (cookies['token']) {
      return jwt.decode(cookies['token'])
    } else {
      return null
    }
  }, [cookies])

  const resolveIssueAuthor = useCallback(
    issue => {
      if (userToken) {
        if (userToken.userType === 'Student') {
          if (issue.status === 'resolved') {
            let employee = issue.responsibleEmployee
            if (employee) {
              return `${employee.firstName} ${employee.lastName}`
            } else {
              return 'Ardent Solve'
            }
          } else {
            return 'Me'
          }
        } else if (userToken.userType === 'Employee') {
          let student = issue.student
          if (student) {
            return `${student.firstName} ${student.lastName}`
          }
        }
      }
    },
    [userToken]
  )

  const columns = React.useMemo(
    () => [
      {
        Header: 'Read',
        accessor: 'isRead',
        width: '3%',
      },
      {
        Header: 'From',
        accessor: issue => resolveIssueAuthor(issue),
        width: '12%',
      },
      {
        Header: 'Date',
        accessor: 'date',
        width: '3%',
      },
      {
        Header: 'Type',
        accessor: 'issueType',
        width: '7%',
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: '5%',
      },
      {
        Header: 'LL-AA-PP',
        accessor: 'lesson_assignment_problem',
        width: '10%',
      },

      {
        Header: 'Description',
        accessor: 'description',
        width: '60%',
      },
    ],
    [resolveIssueAuthor]
  )

  const data = props.data.map(item => {
    return {
      id: item.id,
      date: convertToYMD(item.dateAssigned),
      status: item.issueStatus.toLowerCase(),
      lesson_assignment_problem: item.lapCode,
      description: item.description,
      response: item.response,
      issueType: translateIssueType(item.issueType),
      isRead: item.isRead,
      student: item.student,
      responsibleEmployee: item.responsibleEmployee,
    }
  })

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data })

  return (
    <React.Fragment>
      <View style={{ flexDirection: 'row' }}>
        <View style={styles.message_button}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <MarkunreadMailboxIcon />
            <Text
              style={{ fontWeight: 'bold', fontSize: 16 }}
            >{`Unread Messages: ${
              props.data.filter(i => !i.isRead).length
            }`}</Text>
          </div>
        </View>

        <TouchableOpacity
          style={styles.message_button}
          onPress={async () => {
            const unreadIssues = props.data.filter(i => !i.isRead)
            for (const i of unreadIssues) {
              if (cookies['instructor'] !== '1') {
                await props.markIssueRead(i.id)
              }
            }
            props.refetch()
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <MarkunreadIcon />
            <Text style={{ fontWeight: 'bold', fontSize: 16 }}>
              Mark All As Read
            </Text>
          </div>
        </TouchableOpacity>
      </View>
      <div style={{ position: 'absolute' }}>
        {props.data && props.data.length && (
          <DialogModal
            visible={modalVisible}
            body={<Text>This is some body text</Text>}
            data={props.data[selected]}
            onYes={async () => {
              const selectedIssue = props.data[selected]
              if (
                selectedIssue.issueStatus === 'RESOLVED' &&
                selectedIssue.isRead === false &&
                cookies['instructor'] !== '1'
              ) {
                await props.markIssueRead(props.data[selected].id)
                props.refetch()
              }
              setModalVisible(false)
            }}
            yesText={'OK'}
          />
        )}
      </div>

      <ScrollView>
        <table
          {...getTableProps()}
          style={{ borderCollapse: 'collapse' }}
          stickyheader="true"
        >
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()} width={column.width}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)
              return (
                <tr
                  {...row.getRowProps({
                    onClick: () => {
                      setModalVisible(true)
                      setSelected(row.index)
                    },
                  })}
                  style={{
                    cursor: 'pointer',
                    backgroundColor:
                      hover === row.index
                        ? 'rgb(200,200,200)'
                        : 'rgb(225,225,225)',
                  }}
                  onMouseEnter={() => setHover(row.index)}
                >
                  {row.cells.map(cell => {
                    let center = true
                    if (cell.column.Header === 'Description') {
                      center = false
                    }
                    if (cell.column.Header === 'Status') {
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={center ? textCenter : textLeft}
                        >
                          {cell.value === 'initiated' && <HelpOutlineIcon />}
                          {cell.value === 'resolved' && (
                            <CheckCircleOutlineIcon />
                          )}
                          {cell.value === 'others' && (
                            <PauseCircleOutlineIcon />
                          )}
                        </td>
                      )
                    }

                    if (cell.column.Header === 'Read') {
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={center ? textCenter : textLeft}
                        >
                          {cell.value && <MarkunreadIcon />}

                          {!cell.value && <MarkunreadMailboxIcon />}
                        </td>
                      )
                    }
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={center ? textCenter : textLeft}
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </ScrollView>
    </React.Fragment>
  )
}

export const translateIssueType = issueType => {
  switch (issueType) {
    case 'NEED_HELP':
      return 'Help Needed'

    case 'REPORT_PROBLEM':
      return 'Report Problem'

    case 'ATTENDANCE':
      return 'Attendance'
    case 'MESSAGE':
      return 'Message'
    default:
      return 'Error: Invalid Issue Type'
  }
}

const textCenter = { textAlign: 'center', padding: '0.5rem' }
const textLeft = { textAlign: 'left', padding: '0.5rem' }
