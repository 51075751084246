import React, { useRef } from 'react'
import { ScrollView, Text } from 'react-native'
import logo from '../images/solve-logo.png'
import solveColors from './colors'
import { View } from 'react-native-web'
import { Card } from '@material-ui/core'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
const StudentInfoTab = props => {
  const imageRef = useRef(null)
  return (
    <div
      style={{
        zIndex: 888,
        transitionDuration: '0.5s',
        transitionProperty: 'background-color',
        transitionTimingFunction: 'ease-in-out',

        height: !props.shrunk ? 150 : 50,
      }}
    >
      <View style={{ backgroundColor: 'white', height: '100%' }}>
        <ScrollView horizontal={true}>
          {props.students.map(student => {
            return (
              <Card
                key={student.id}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: '0.7em',
                  paddingLeft: props.shrunk ? '0.7em' : '3em',
                  paddingRight: props.shrunk ? '0.7em' : '3em',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: 'lightGrey',
                  backgroundColor:
                    props.selected === student.id ? 'lightgrey' : 'white',
                  cursor: 'pointer',
                  marginRight: '0.5em',
                  transitionDuration: '0.3s',
                  transitionProperty: 'background-color',
                  transitionTimingFunction: 'ease-in-out',
                }}
                onClick={() => {
                  props.setStudent(student.id)
                }}
              >
                <img
                  src={student.imageUrl || logo}
                  alt={'Student Profile'}
                  ref={imageRef}
                  width={props.shrunk ? '30px' : '100px'}
                  height={props.shrunk ? '30px' : '100px'}
                  style={{
                    borderRadius: '50%',
                    backgroundColor: solveColors.blue1,
                    margin: '0.5em',
                    transitionDuration: '0.3s',
                    transitionProperty: 'background-color',
                    transitionTimingFunction: 'ease-in-out',
                  }}
                  onError={() => {
                    imageRef.current.src = `https://ui-avatars.com/api/?background=${Math.floor(
                      Math.random() * 16777215
                    ).toString(16)}&name=${student.firstName}+${
                      student.lastName
                    }`
                  }}
                />
                {!props.shrunk && (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text>{student.firstName}</Text>
                    {student.grade && <Text>{`grade ${student.grade}`}</Text>}
                    <Text>{student.school}</Text>
                  </div>
                )}
              </Card>
            )
          })}
          <Card
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: '0.7em',
              paddingLeft: props.shrunk ? '0.7em' : '5em',
              paddingRight: props.shrunk ? '0.7em' : '5em',
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: 'lightGrey',
              cursor: 'pointer',
              marginRight: '0.5em',
              transitionDuration: '0.3s',
              transitionProperty: 'background-color',
              transitionTimingFunction: 'ease-in-out',
            }}
            onClick={props.openAddStudent}
          >
            <PersonAddIcon
              height={props.shrunk ? '30px' : '100px'}
              style={{
                borderRadius: '50px',
                margin: '0.5em',
                transitionDuration: '0.3s',
                transitionProperty: 'background-color',
                transitionTimingFunction: 'ease-in-out',
              }}
            />
          </Card>
        </ScrollView>
      </View>
    </div>
  )
}

export default StudentInfoTab
