import { StyleSheet, View, Text, TouchableOpacity } from 'react-native'
import React, { Component } from 'react'
import Modal from 'modal-react-native-web'
import solveColors from './colors'
import {
  CREATE_ISSUE,
  COUNT_MESSAGES,
  GET_ISSUE,
  UPDATE_ISSUE,
  CREATE_ATTENDANCE_ISSUE,
} from './../../services/queries'
import jwt from 'jsonwebtoken'
import { ApolloConsumer } from 'react-apollo'
import { withCookies } from 'react-cookie'

class ReportProblemModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: props.visible,
      issueType: props.attendance ? 'ATTENDANCE' : 'NEED_HELP',
      description: '',
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.visible !== nextProps.visible) {
      return {
        visible: nextProps.visible,
      }
    }
    return null
  }
  countIssues = async client => {
    const { data } = await client.query({
      query: COUNT_MESSAGES,
      context: { headers: { 'cache-control': 'no-cache' } },
      fetchPolicy: 'no-cache',
      variables: {
        filter: this.props.attendance
          ? {
              studentId: jwt.decode(this.props.cookies.get('token')).id,
              lessonId: this.props.lessonId,
              issueType: this.state.issueType,
            }
          : {
              studentId: jwt.decode(this.props.cookies.get('token')).id,
              problemId: this.props.problemId,
              assignmentId: this.props.assignmentId,
              issueType: this.state.issueType,
            },
      },
    })
    return data.countIssues
  }
  updateIssue = async client => {
    const { data } = await client.mutate({
      mutation: GET_ISSUE,
      variables: {
        filter: {
          assignmentId: this.props.assignmentId,
          problemId: this.props.problemId,
          studentId: jwt.decode(this.props.cookies.get('token')).id,
        },
      },
    })
    const issueId = data.issues[0].id
    await client.mutate({
      mutation: UPDATE_ISSUE,
      variables: {
        issueId,
        issueInput: {
          description: this.state.description,
          dateAssigned: new Date().toISOString(),
          issueStatus: 'INITIATED',
          isRead: false,
          response: null,
        },
      },
    })
  }
  createIssue = async client => {
    await client.mutate({
      mutation: CREATE_ISSUE,
      variables: {
        assignmentId: this.props.assignmentId,
        problemId: this.props.problemId,
        studentId: jwt.decode(this.props.cookies.get('token')).id,
        description: this.state.description,
        issueType: this.state.issueType,
        dateAssigned: new Date().toISOString(),
      },
    })
  }
  createAttendanceIssue = async client => {
    await client.mutate({
      mutation: CREATE_ATTENDANCE_ISSUE,
      variables: {
        attendeeId: this.props.attendeeId,
        studentId: jwt.decode(this.props.cookies.get('token')).id,
        description: this.state.description,
        issueType: this.state.issueType,
        dateAssigned: new Date().toISOString(),
      },
    })
  }
  restoreDefault = () => {
    this.setState({
      issueType: this.props.attendance ? 'ATTENDANCE' : 'NEED_HELP',
      description: '',
    })
  }
  render() {
    const { openComfirmation, openWarning, closeModal, attendance } = this.props
    const { visible } = this.state
    return (
      <ApolloConsumer>
        {client => (
          <Modal visible={visible} transparent={true} animationType="fade">
            <View style={styles.backdrop}>
              <View style={styles.results}>
                <React.Fragment>
                  <Text
                    style={{
                      color: solveColors.blue5,
                      fontSize: 18,
                      marginBottom: 10,
                    }}
                  >
                    {`Report ${attendance ? 'an Attendance' : 'a '} Problem`}
                  </Text>
                  <textarea
                    style={{
                      minHeight: 200,
                      borderRadius: 5,
                      borderColor: solveColors.grey3,
                      alignSelf: 'center',
                      minWidth: 150,
                      width: '100%',
                      resize: 'none',
                      fontSize: 16,
                      marginBottom: 10,
                    }}
                    placeholder={'Describe your problem here...'}
                    value={this.state.description}
                    onChange={event => {
                      this.setState({ description: event.target.value })
                    }}
                  />
                  <View style={{ flexDirection: 'row' }}>
                    <TouchableOpacity
                      style={[
                        styles.button,
                        {
                          bottom: 0,
                          marginRight: 20,
                        },
                      ]}
                      onPress={async () => {
                        const messages = await this.countIssues(client)
                        if (messages === 0) {
                          if (attendance) {
                            await this.createAttendanceIssue(client)
                          } else {
                            await this.createIssue(client)
                          }
                          this.restoreDefault()
                          openComfirmation()
                          closeModal()
                        } else if (this.state.issueType === 'NEED_HELP') {
                          await this.updateIssue(client)
                          this.restoreDefault()
                          openComfirmation()
                          closeModal()
                        } else {
                          openWarning()
                        }
                      }}
                    >
                      <Text style={{ color: 'white' }}>Submit</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={[
                        styles.button,
                        {
                          bottom: 0,
                          marginRight: 20,
                        },
                      ]}
                      onPress={() => {
                        this.restoreDefault()
                        closeModal()
                      }}
                    >
                      <Text style={{ color: 'white' }}>Close</Text>
                    </TouchableOpacity>
                  </View>
                </React.Fragment>
              </View>
            </View>
          </Modal>
        )}
      </ApolloConsumer>
    )
  }
}

const styles = StyleSheet.create({
  results: {
    borderRadius: '10px',
    width: '30%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: 25,
    justifyContent: 'flex-start',
  },
  backdrop: {
    backgroundColor: 'rgba(52, 52, 52, 0.2)',
    height: '100%',
    width: '100%',
  },
  button: {
    width: 100,
    height: 40,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: solveColors.blue5,
  },

  title: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '2vh',
    borderBottomColor: 'black',
    borderBottomWidth: 3,
  },
  body: {
    width: '100%',
    marginBottom: '2vh',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  info: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    flexGrow: 1,
    flex: 1,
    marginBottom: '2vh',
  },
})
export default withCookies(ReportProblemModal)
