import React from 'react'
import { Query, ApolloConsumer } from 'react-apollo'
import LoadingPage from './../Loading/LoadingPage'
import { GET_ISSUES, UPDATE_ISSUE } from './../../../services/queries'
import MessageForm from './MessageForm'
import jwt from 'jsonwebtoken'
import { useCookies } from 'react-cookie'

const MessagePage = props => {
  const [cookies] = useCookies([])
  const filter =
    cookies['instructor'] === '1'
      ? {
          responsibleEmployee: {
            id: jwt.decode(cookies['token']).id,
          },
          classroomId: props.classroomId,
        }
      : {
          student: { id: jwt.decode(cookies['token']).id },
          classroomId: props.classroomId,
        }

  const markIssueRead = async (client, issueId) => {
    await client.mutate({
      mutation: UPDATE_ISSUE,
      variables: { issueId, issueInput: { isRead: true } },
    })
  }
  return (
    <ApolloConsumer>
      {client => (
        <Query
          query={GET_ISSUES}
          context={{ headers: { 'cache-control': 'no-cache' } }}
          variables={{
            filter,
          }}
        >
          {({ data, loading, error }) => {
            if (loading) {
              return <LoadingPage />
            }
            if (error) {
              return `${error}`
            }
            if (!data || !data.issues) return 'No messages available'
            console.log(data)
            return (
              <MessageForm
                data={data.issues}
                markIssueRead={async id => {
                  await markIssueRead(client, id)
                }}
                refetch={props.refetch}
              />
            )
          }}
        </Query>
      )}
    </ApolloConsumer>
  )
}

export default MessagePage
