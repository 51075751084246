import React, { useState, useEffect } from 'react'
import {
  Dimensions,
  Image,
  StyleSheet,
  ScrollView,
  View,
} from 'react-native-web'
import {
  GET_REFERRALS,
  CREATE_REFERRAL,
  VERIFY_FAMILY_ACCOUNT,
} from '../../../services/queries'
import ReferralForm from './ReferralForm'
import ReferralDetail from './ReferralDetail'

import { withRouter } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { withApollo } from 'react-apollo'
import { useCookies } from 'react-cookie'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'

const referralpromotion =
  'https://ardent-shared-media.s3-us-west-2.amazonaws.com/system/referral_promo.png'
const ReferralPage = props => {
  const [cookies] = useCookies([])
  const [referral, setReferral] = useState({
    refereeFirstName: '',
    refereeLastName: '',
    email: '',
    phone: '',
  })
  const [windowWidth, setWindowWidth] = useState(Dimensions.get('window').width)
  const [tab, setTab] = useState(0)
  const [referrals, setReferrals] = useState([])
  const [page, setPage] = useState(0)
  const [rows, setRows] = useState(5)
  const [error, setError] = useState(false)

  const imageRatio = windowWidth / 1600
  const imagePercent = isMobile ? 0.8 : 0.4
  const panelWidth = windowWidth * imagePercent
  const panelHeight = isMobile
    ? imageRatio * 900 * imagePercent * 0.9
    : imageRatio * 900 * imagePercent
  const displayDirection = isMobile || windowWidth < 1920
  const tabs = ['Make a Referral', 'My Referrals']

  const handleChangeTab = (_e, newIndex) => {
    setTab(newIndex)
  }
  const handleChangePage = (_e, newPage) => {
    setPage(newPage)
  }

  const handleChangeRows = e => {
    setRows(e.target.value)
    setPage(0)
  }

  useEffect(() => {
    const injectQuery = async () => {
      const { data } = await props.client.query({
        query: GET_REFERRALS,
        variables: {
          filter: {
            referrer: {
              id: cookies['familyAccountId'],
            },
          },
        },
      })
      setReferrals(data.referrals)
    }
    injectQuery()
    const getWidth = () => setWindowWidth(Dimensions.get('window').width)
    window.addEventListener('resize', getWidth)
    return () => window.removeEventListener('resize', getWidth)
  }, [props, cookies])

  const validateEmail = () => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(referral.email).toLowerCase())
  }

  const validateReferral = async () => {
    if (validateEmail()) {
      const { data: emailData } = await props.client.query({
        query: VERIFY_FAMILY_ACCOUNT,
        variables: {
          filter: {
            email: referral.email,
          },
        },
      })
      if (emailData.countFamilyMembers) {
        alert(
          referral.email + ' has already been registered with Ardent SOLVE.'
        )
        setError(true)
        return false
      } else {
        if (referral.phone) {
          const { data: phoneData } = await props.client.query({
            query: VERIFY_FAMILY_ACCOUNT,
            variables: {
              filter: {
                phone: referral.phone,
              },
            },
          })
          if (phoneData.countFamilyMembers) {
            setError(true)
            alert(
              referral.phone + ' has already been registered with Ardent SOLVE.'
            )
            return false
          }
        }
      }
    } else {
      alert('Please enter a valid email address.')
      return false
    }
    alert('Thank you for your referral. Check your email for confirmation.')
    return true
  }

  const handleSendReferral = async () => {
    const valid = await validateReferral()

    if (
      valid &&
      referral.email &&
      referral.refereeFirstName &&
      referral.refereeLastName
    ) {
      const { data } = await props.client.mutate({
        mutation: CREATE_REFERRAL,
        variables: {
          input: {
            email: referral.email,
            refereeFirstName: referral.refereeFirstName,
            refereeLastName: referral.refereeLastName,
            phone: referral.phoneNumber ? parseInt(referral.phoneNumber) : null,
            status: 'INITIATED',
            referrerId: cookies['familyAccountId'],
          },
        },
      })
      const newReferral = data.createReferral
      referrals.push({ ...newReferral })
      setReferrals([...referrals])
    }
  }

  const renderTabPanels = () => {
    switch (tab) {
      case 0:
        return (
          <ReferralForm
            referral={referral}
            setReferral={setReferral}
            error={error}
            handleSendReferral={handleSendReferral}
            windowWidth={windowWidth}
          />
        )
      case 1:
        return (
          <ReferralDetail
            referrals={referrals}
            page={page}
            rows={rows}
            handleChangePage={handleChangePage}
            handleChangeRows={handleChangeRows}
            windowWidth={windowWidth}
            panelHeight={panelHeight}
          />
        )
      default:
        return <></>
    }
  }

  return (
    <ScrollView style={styles.rootContainer}>
      <Paper
        style={{
          flexDirection: displayDirection ? 'column' : 'row',
          display: 'flex',
          flex: 1,
          width: displayDirection ? panelWidth : panelWidth * 2,
          alignSelf: 'center',
        }}
        elevation={3}
      >
        <Image
          style={{
            alignSelf: 'center',
            width: panelWidth,
            height: imageRatio * 900 * imagePercent,
          }}
          source={referralpromotion}
          alt={'referralpromotion'}
        />
        <Divider orientation={displayDirection ? 'horizontal' : 'vertical'} />
        <View
          style={{
            alignSelf: 'center',
            width: panelWidth,
            height: displayDirection ? 'auto' : panelHeight,
            minHeight: panelHeight,
          }}
        >
          <AppBar
            elevation={0}
            position="relative"
            color="transparent"
            style={{ width: panelWidth }}
          >
            <Tabs
              value={tab}
              onChange={handleChangeTab}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
              aria-label="referral-tab"
            >
              {tabs.map((currentTab, index) => (
                <Tab label={currentTab} key={index} />
              ))}
            </Tabs>
          </AppBar>
          <Divider orientation={'horizontal'} />
          <Box
            style={{
              display: 'flex',
              flex: 1,
            }}
          >
            {renderTabPanels()}
          </Box>
        </View>
      </Paper>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  rootContainer: {
    paddingLeft: '30px',
    paddingRight: '30px',
    paddingTop: '30px',
    paddingBottom: '30px',
    display: 'flex',
    flex: 1,
  },
  referralContainer: {
    flexDirection: isMobile ? 'column' : 'row',
    display: 'flex',
    flex: 1,
  },
  innerContainer: {
    width: '60%',
    height: '80%',
  },
  detailsContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignSelf: 'center',
    alignItems: 'center',
  },
  referralStatusContainer: {
    backgroundColor: 'red',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignSelf: 'center',
    alignItems: 'center',
  },
  cardContainer: {
    width: 180,
    height: 80,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.25)',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
})

export default withApollo(withRouter(ReferralPage))
