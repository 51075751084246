import React, { useState, useReducer } from 'react'
import { withApollo } from 'react-apollo'
import { withCookies } from 'react-cookie'
import { Dimensions, Image, Text } from 'react-native-web'
import { isMobile } from 'react-device-detect'
import solveColors from '../../components/colors'
import { VideoModal } from '../../components'
import { getUsername } from '../../../utils/username'
import {
  UPDATE_FAMILY_MEMBER,
  VERIFY_FAMILY_ACCOUNT,
  VERIFY_STUDENT_USERNAME,
} from '../../../services/queries'
import { CREATE_STUDENT } from '../../../services/queries/add_student_queries'

import RecommendationForm from '../Recommendations/RecommendationForm'
import ConfirmParentForm from './ConfirmParentForm'
import AddStudentForm from './AddStudentForm'
import SelectClassForm from './SelectClassForm'

//mui
import { makeStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import AdjustIcon from '@material-ui/icons/Adjust'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

const refereepromotion =
  'https://ardent-shared-media.s3-us-west-2.amazonaws.com/system/referree_promo.png'

const useStyles = makeStyles({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '520px',
    width: isMobile ? '520px' : '900px',
    marginTop: '30px',
  },
  //form styles
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: isMobile ? '450px' : '410px',
  },
  smallFormContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '56px',
  },
  mediumFormContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: isMobile ? '380px' : '340px',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '64px',
    width: '100%',
    alignItems: 'center',
  },
  //add student form styles
  fieldContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px',
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    marginTop: '20px',
  },
  //Progress bar styles
  progressContainer: {
    display: 'flex',
    marginBottom: '10px',
    alignItems: 'center',
    justifyContent: 'center',
    height: '96px',
    flexDirection: 'column',
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    height: '48px',
    width: '90%',
  },
  progressTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '24px',
    width: '97%',
  },
  progressText: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  activeIcon: {
    color: solveColors.blue3,
    fontSize: '32px',
  },
  inactiveIcon: {
    color: 'rgb(167, 202, 237)',
    fontSize: '32px',
  },
  progressBar: {
    width: '310px',
    height: '3px',
    marginRight: '5px',
    marginLeft: '5px',
  },
  progressBarColorPrimary: {
    backgroundColor: solveColors.grey3,
  },
  progressColorPrimary: {
    backgroundColor: solveColors.grey3,
  },
  //select classroom form styles

  //buttons
  actionContainer: {
    width: '100%',
    height: '80px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  button: {
    width: '100px',
  },
  longButton: {
    width: '200px',
    minWidth: '100px',
  },
})

const CURRENT_Q = 'Spring'
const NEXT_Q = 'Summer'

const initialStudentState = {
  firstName: '',
  lastName: '',
  username: '',
  password: '',
  gender: '',
  birthDate: new Date(),
  grade: '',
  school: '',
}

const initialParentState = {
  firstName: '',
  lastName: '',
  relation: '',
  email: '',
  phoneNumber: '',
}

//initial linear progress value is 0 for both
const INITIAL_PROGRESS = [
  { value: 0, active: true },
  { value: 0, active: false },
  { value: 0, active: false },
]

const studentReducer = (prev, { key, value }) => {
  if (key === 'birthDate') {
    const date = value
    const year = parseInt(date.split('-')[0])
    const month = parseInt(date.split('-')[1]) - 1
    const day = parseInt(date.split('-')[2])
    const newBirthDate = new Date(year, month, day)
    return {
      ...prev,
      [key]: newBirthDate,
    }
  } else {
    return {
      ...prev,
      [key]: value,
    }
  }
}

const parentReducer = (prev, { key, value }) => {
  return {
    ...prev,
    [key]: value,
  }
}

const NewFamilyPage = props => {
  const classes = useStyles()
  const [progress, setProgress] = useState(INITIAL_PROGRESS)
  const [error, setError] = useState('')
  const [studentId, setStudentId] = useState(null)
  const [studentState, studentDispatch] = useReducer(
    studentReducer,
    initialStudentState
  )
  const [parentState, parentDispatch] = useReducer(
    parentReducer,
    props.familyMember || initialParentState
  )
  const [windowWidth] = useState(Dimensions.get('window').width)
  const imageRatio = windowWidth / 1600
  const imagePercent = isMobile ? 0.8 : 0.4
  const panelWidth = windowWidth * imagePercent
  //for video modal
  const [currVideoId, setCurrVideoId] = useState(null)
  const [videoVisible, setVideoVisible] = useState(false)

  const {
    firstName,
    lastName,
    username,
    gender,
    birthDate,
    grade,
    school,
  } = studentState

  const {
    firstName: parentFirstName,
    lastName: parentLastName,
    relation,
    email,
    phoneNumber,
  } = parentState

  const handleStudentFormChange = e => {
    studentDispatch({ key: e.target.name, value: e.target.value })
  }

  const handleParentFormChange = e => {
    parentDispatch({ key: e.target.name, value: e.target.value })
  }

  const incrementProgress = () => {
    const currentIndex = progress.findIndex(p => p.value === 0)
    if (currentIndex !== -1) {
      progress[currentIndex].value = 100
      if (currentIndex <= 1) {
        progress[currentIndex + 1].active = true
      }
      setProgress([...progress])
    }
  }

  const validParentPhoneNumber = async () => {
    if (props.familyMember) {
      if (props.familyMember.phoneNumber === phoneNumber) {
        return true
      }
    }
    const { client } = props
    let response
    try {
      response = await client.query({
        query: VERIFY_FAMILY_ACCOUNT,
        variables: {
          filter: {
            phoneNumber: phoneNumber,
          },
        },
      })
      return !response.data.countFamilyMembers
    } catch (e) {
      console.log(e)
      return false
    }
  }

  const validStudentUsername = async () => {
    const { client } = props
    let response
    try {
      response = await client.query({
        query: VERIFY_STUDENT_USERNAME,
        variables: {
          filter: {
            username: username,
          },
        },
      })
      return !response.data.countStudents
    } catch (e) {
      console.log(e)
      return false
    }
  }

  const handleAddStudent = async () => {
    let response
    if (!firstName || !lastName || !grade || !school || !gender || !birthDate) {
      setError('Some fields are missing*')
    } else {
      const password = 'student'
      let username = getUsername(firstName, lastName)
      let validUsername = await validStudentUsername()
      while (!validUsername) {
        username = getUsername(firstName, lastName)
        validUsername = await validStudentUsername()
      }
      try {
        const familyAccountId = props.cookies.get('familyAccountId')
        const { client } = props
        response = await client.mutate({
          mutation: CREATE_STUDENT,
          variables: {
            input: {
              firstName: firstName,
              lastName: lastName,
              username: username,
              password: password,
              grade: parseInt(grade),
              school: school,
              gender: gender,
              birthdate: new Date(birthDate).toISOString(),
              familyAccountId: familyAccountId,
            },
          },
        })
        setStudentId(response.data.createStudent.id)
        setError('')
        incrementProgress()
        return response
      } catch (e) {
        console.log(e)
        setError('An error occured')
      }
    }
  }

  const handleConfirmParent = async () => {
    const validPhone = await validParentPhoneNumber()
    if (!validPhone) {
      setError('This phone number is already in use*')
      return
    }
    if (
      !parentFirstName ||
      !parentLastName ||
      !email ||
      !phoneNumber ||
      !relation
    ) {
      setError('Some fields are missing*')
    } else {
      const familyMemberId = props.familyMember.id
      const { client } = props
      try {
        await client.mutate({
          mutation: UPDATE_FAMILY_MEMBER,
          variables: {
            id: familyMemberId,
            input: {
              firstName: parentFirstName,
              lastName: parentLastName,
              email: email,
              phoneNumber: phoneNumber,
              relation: relation,
            },
          },
        })
        setError('')
        incrementProgress()
      } catch (e) {
        console.log(e)
        setError('An error occured*')
      }
    }
  }

  const renderIcon = index => {
    if (index <= 2) {
      if (progress[index].value === 100) {
        return <CheckCircleOutlineIcon className={classes.activeIcon} />
      } else {
        if (progress[index].active) {
          return <AdjustIcon className={classes.activeIcon} />
        } else {
          return <FiberManualRecordIcon className={classes.inactiveIcon} />
        }
      }
    } else {
      //case for fourth icon
      if (progress[2].value === 100) {
        return <AdjustIcon className={classes.activeIcon} />
      } else {
        return <FiberManualRecordIcon className={classes.inactiveIcon} />
      }
    }
  }

  const renderForm = () => {
    const currentIndex = progress.findIndex(p => p.value === 0)
    switch (currentIndex) {
      case 0:
        return (
          <ConfirmParentForm
            error={error}
            parentState={parentState}
            classes={classes}
            handleConfirmParent={handleConfirmParent}
            incrementProgress={incrementProgress}
            handleFormChange={handleParentFormChange}
          />
        )
      case 1:
        return (
          <AddStudentForm
            error={error}
            studentState={studentState}
            classes={classes}
            handleAddStudent={handleAddStudent}
            incrementProgress={incrementProgress}
            handleFormChange={handleStudentFormChange}
          />
        )
      case 2:
        return <SelectClassForm classes={classes} />
      default:
        return <></>
    }
  }

  return (
    <>
      <Image
        style={{
          alignSelf: 'center',
          width: panelWidth,
          height: imageRatio * 900 * imagePercent,
        }}
        source={refereepromotion}
        alt={'refereepromotion'}
      />
      <Container
        className={classes.contentContainer}
        style={{ height: progress[1].value ? '220px' : '480px' }}
      >
        <Paper className={classes.progressContainer} elevation={4}>
          <Container className={classes.progressTextContainer}>
            <Text>Confirm Information</Text>
            <Text>Add a Student</Text>
            <Text>Select your Classes</Text>
          </Container>
          <Container className={classes.iconContainer}>
            {renderIcon(0)}
            <LinearProgress
              className={classes.progressBar}
              value={progress[0].value}
              variant={'determinate'}
            />
            {renderIcon(1)}
            <LinearProgress
              className={classes.progressBar}
              value={progress[1].value}
              variant={'determinate'}
            />
            {renderIcon(2)}
          </Container>
        </Paper>
        {renderForm()}
      </Container>
      {progress[2].active && studentId && (
        <>
          <RecommendationForm
            studentId={studentId}
            windowWidth={Dimensions.get('window').width}
            currentQuarter={CURRENT_Q}
            nextQuarter={NEXT_Q}
            openVid={v => {
              setCurrVideoId(v)
              setVideoVisible(true)
            }}
          />
          <VideoModal
            visible={videoVisible}
            onClose={() => setVideoVisible(false)}
            videoId={currVideoId}
          />
        </>
      )}
    </>
  )
}

export default withApollo(withCookies(NewFamilyPage))
