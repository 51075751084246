import { View, StyleSheet, Text, TouchableOpacity } from 'react-native'
import { solveColors } from '../../../components/colors'
import problemStates from '../ProblemStates'
import React from 'react'
import { Latex } from '../../../components'

const MatchChoice = props => {
  const getBubbleStyle = () => {
    if (
      props.probState === problemStates.unanswered ||
      !props.selected ||
      props.feedbackType !== 'INSTANT'
    ) {
      return props.selected
        ? styles.bubble_selected
        : styles.bubble_not_selected
    }
    return props.probState === problemStates.correct
      ? styles.bubble_correct
      : styles.bubble_incorrect
  }
  return (
    <TouchableOpacity
      style={[
        styles.choice,
        props.selected ? styles.choice_selected : styles.choice_not_selected,
      ]}
      onPress={() => {
        props.setSelected()
      }}
    >
      <View>
        <Text style={[styles.bubble, getBubbleStyle()]}>
          {String.fromCharCode((props.index % 26) + 65)}
        </Text>
      </View>
      <View style={styles.choice_text}>
        {props.typesetMath && <Latex latex={props.text} />}
        {!props.typesetMath && <Text>{props.text}</Text>}
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  choice_text: {
    flex: 1,
    justifyContent: 'center',
    fontSize:
      sessionStorage.getItem('size') === 'large'
        ? 24
        : sessionStorage.getItem('size') === 'med'
        ? 20
        : 14,
  },
  choice: {
    alignSelf: 'center',
    borderStyle: 'solid',
    borderColor: solveColors.grey1,
    borderWidth: '1px',
    borderRadius: 15,
    flexDirection: 'row',
    paddingHorizontal: '10px',
    paddingVertical: '15px',
    marginVertical: '2%',
    width: 'calc(50px + 20vw)',
  },
  choice_not_selected: {
    backgroundColor: 'white',
  },
  choice_selected: {
    backgroundColor: solveColors.grey1,
  },
  bubble: {
    borderRadius: 100,
    paddingVertical: 5,
    paddingHorizontal: 9,
    marginVertical: 5,
    marginLeft: 10,
    marginRight: 25,
  },
  bubble_not_selected: {
    backgroundColor: solveColors.grey1,
    color: solveColors.grey4,
  },
  bubble_selected: {
    backgroundColor: solveColors.orange,
    color: 'white',
  },
  bubble_correct: {
    backgroundColor: solveColors.green,
    color: 'white',
  },
  bubble_incorrect: {
    backgroundColor: solveColors.red2,
    color: 'white',
  },
})

export default MatchChoice
