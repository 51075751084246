import React, { useState } from 'react'
import {
  Image,
  ImageBackground,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native-web'
import BannerMessage from '../../components/BannerMessage'
import login_background from './../../images/login_background.svg'
import logo from '../../images/solve-logo.png'
import solveColors from '../../components/colors'
import { access } from '../../components/styles'
import { ApolloConsumer } from 'react-apollo'
import {
  UPDATE_ACCOUNT_INFO,
  UPDATE_INSTRUCTOR_INFO,
} from './../../../services/queries'
import { withRouter } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import { useCookies } from 'react-cookie'

const styles = StyleSheet.create({
  disabled: {
    backgroundColor: solveColors.grey3,
  },
})

const ChangePasswordPage = props => {
  const [pw, setPw] = useState(null)
  const [confirmPw, setConfirmPw] = useState(null)
  const [mismatch, setMismatch] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [cookies] = useCookies([])
  const { history } = props
  const searchParams = props.location.search
    ? props.location.search.split('&')
    : []
  const studentId = props.location.search
    ? searchParams[0].split('=')[1]
    : jwt.decode(cookies['token']).id
  const token = props.location.search
    ? searchParams[1].split('=')[1]
    : cookies['token']
  if (props.location.search) {
    sessionStorage.setItem('tempToken', token)
  }
  const handleSubmit = async (client, pw) => {
    setError(false)
    setMismatch(false)

    if (pw || confirmPw) {
      if (pw !== confirmPw) setMismatch(true)
      else {
        try {
          if (
            cookies['instructor'] === undefined ||
            cookies['instructor'] === 0
          ) {
            await client.mutate({
              mutation: UPDATE_ACCOUNT_INFO,
              variables: {
                id: studentId,
                input: {
                  password: pw,
                },
              },
            })
          } else {
            await client.mutate({
              mutation: UPDATE_INSTRUCTOR_INFO,
              variables: {
                id: studentId,
                input: {
                  password: pw,
                },
              },
            })
          }
        } catch (err) {
          setError(true)
          console.log(err)
          return
        }
        setSuccess(true)

        if (!props.location.search) {
          history.push('/')
        }
      }
    }
  }
  return (
    <ApolloConsumer>
      {client => {
        return (
          <ImageBackground source={login_background} style={access.background}>
            {mismatch && (
              <BannerMessage msg="Passwords do not match. Please try again." />
            )}
            {error && (
              <BannerMessage msg="An error has occured. Please contact Ardent staff." />
            )}
            {success && (
              <BannerMessage
                success
                msg="Your password has been successfully changed."
              />
            )}
            <View style={access.header}>
              {/* Query based off of id to get image */}
              {cookies['imageUrl'] ? (
                <Image style={access.logo} source={cookies['imageUrl']} />
              ) : (
                <Image style={access.logo} source={logo} />
              )}
              <TextInput
                secureTextEntry={true}
                autoFocus={true}
                style={access.inputField}
                placeholder={'New Password'}
                placeholderTextColor={solveColors.offWhite}
                onChangeText={text => {
                  setPw(text)
                }}
              />
              <TextInput
                secureTextEntry={true}
                style={[access.inputField]}
                placeholder={'Confirm New Password'}
                placeholderTextColor={solveColors.offWhite}
                onChangeText={text => {
                  setConfirmPw(text)
                }}
                onSubmitEditing={async () => {
                  handleSubmit(client, pw)
                }}
              />
              <TouchableOpacity
                style={[
                  access.button,
                  !pw && !confirmPw ? styles.disabled : null,
                ]}
                disabled={(!pw && !confirmPw) || success}
                onPress={async () => {
                  handleSubmit(client, pw)
                }}
              >
                <Text style={{ color: 'white' }}>CHANGE PASSWORD</Text>
              </TouchableOpacity>
            </View>
          </ImageBackground>
        )
      }}
    </ApolloConsumer>
  )
}

export default withRouter(ChangePasswordPage)
