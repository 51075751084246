import React, { Component } from 'react'
import { Text } from 'react-native-web'
import './VideoModal.css'

class AnimatedModal extends Component {
  render() {
    if (!this.props.visible) {
      return null
    }

    return (
      <div className="modal-video" onClick={this.props.onClose}>
        <div className="modal-video-body">
          <div className="modal-video-inner">
            <div className="modal-video-movie-wrap">
              <Text
                style={{
                  position: 'absolute',
                  left: 50,
                  top: 15,
                  zIndex: 999,
                  fontSize: 'large',
                }}
              >
                {this.props.title}
              </Text>

              <div
                className="modal-video-movie-wrap-iframe"
                style={{
                  backgroundColor: 'white',
                  padding: '32px',
                  paddingTop: '40px',
                  cursor: 'default',
                }}
                onClick={e => {
                  e.stopPropagation()
                }}
              >
                {this.props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AnimatedModal
