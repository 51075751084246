import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import FavoriteIcon from '@material-ui/icons/Favorite'
import { IconButton } from '@material-ui/core'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import jwt from 'jsonwebtoken'
import { withApollo } from 'react-apollo'
import {
  CREATE_POST_FAVORITE,
  CREATE_POST_VIEW,
  GET_POST_FAVORITE,
  UPDATE_POST_FAVORITE,
} from '../../../services/queries/post_queries'
import { withRouter } from 'react-router-dom'
import { useCookies } from 'react-cookie'

const useStyles = makeStyles({
  root: {
    // maxWidth: 900,
    flex: 1,
    flexBasis: '300px',
    maxWidth: '350px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '8px',
  },
  media: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  aspectRatioContainer: {
    position: 'relative',
    paddingBottom: '56.25%',
    height: 0,
    overflow: 'hidden',
    width: '100%',
  },
})

function LargePostCard(props) {
  const [cookies] = useCookies([])
  const classes = useStyles()
  const [liked, setLiked] = useState(false)
  const [likedCount, setLikedCount] = useState(props.post.favoriteCount || 0)
  const { post } = props

  const [existingFavoriteRecord, setExistingFavoriteRecord] = useState(null) //user favorited/unfav this card before. so it has a record in db

  useEffect(() => {
    const inner = async () => {
      if (cookies['token']) {
        props.client
          .query({
            query: GET_POST_FAVORITE,
            variables: {
              filter: {
                [jwt.decode(cookies['token']).userType === 'Student'
                  ? 'studentId'
                  : 'familyMemberId']: jwt.decode(cookies['token']).id,
                postId: props.post.id,
              },
            },
          })
          .then(({ data }) => {
            if (data.postFavorites.length === 0) {
              setLiked(false)
            } else {
              setLiked(data.postFavorites[0].favorited)
              setExistingFavoriteRecord(data.postFavorites[0]) //assuming (user, post) combination is unique
            }
          })
          .catch(e => console.log(e))
      }
    }
    inner()
  }, [props, cookies])

  const handleOnClick = async () => {
    if (cookies['token']) {
      try {
        await props.client.mutate({
          mutation: CREATE_POST_VIEW,
          variables: {
            input: {
              postId: props.post.id,
              [jwt.decode(cookies['token']).userType === 'Student'
                ? 'studentId'
                : 'familyMemberId']: jwt.decode(cookies['token']).id,
            },
          },
        })
      } catch (e) {
        console.log(e)
        return
      }
    }
    props.history.push('/post?id=' + props.post.id)
  }

  const handleHeart = async (wasDim, existingRecord, creationInput) => {
    //existingRecord is an postFavorite object, creationInput can be null if existingRecord is given
    //if existingRecord is null, then creationInput need to be given

    if (existingRecord) {
      try {
        await props.client.mutate({
          mutation: UPDATE_POST_FAVORITE,
          variables: {
            id: existingRecord.id,
            input: {
              favorited: wasDim, //if it was dim, then invoking this function means user lit it up..
            },
          },
        })
      } catch (e) {
        console.log(e)
        return
      }
    } else {
      try {
        const { data } = await props.client.mutate({
          mutation: CREATE_POST_FAVORITE,
          variables: {
            input: creationInput,
          },
        })
        setExistingFavoriteRecord(data.createPostFavorite)
      } catch (e) {
        console.log(e)
        return
      }
    }
  }

  return (
    <Card className={classes.root}>
      <CardActionArea onClick={handleOnClick}>
        <div className={classes.aspectRatioContainer}>
          <CardMedia
            className={classes.media}
            image={post.imageUrl}
            title={post.title}
          />
        </div>

        <CardContent>
          <Typography gutterBottom variant="h6" component="h2">
            {!post.published && (
              <span
                style={{
                  color: 'red',
                  marginRight: '8px',
                  fontSize: 12,
                }}
              >
                UNPUBLISHED
              </span>
            )}
            {post.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {post.description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <IconButton
          size="medium"
          color="primary"
          disabled={!cookies['token']}
          onClick={e => {
            e.stopPropagation()
            setLikedCount(liked ? likedCount - 1 : likedCount + 1)
            handleHeart(!liked, existingFavoriteRecord, {
              favorited: !liked,
              postId: props.post.id,
              [jwt.decode(cookies['token']).userType === 'Student'
                ? 'studentId'
                : 'familyMemberId']: jwt.decode(cookies['token']).id,
            })
            setLiked(!liked)
          }}
        >
          {liked ? <FavoriteIcon /> : <FavoriteBorderIcon />}
          <Typography>{likedCount}</Typography>
        </IconButton>
        <div style={{ display: 'flex' }}>
          <VisibilityOutlinedIcon color="primary" />
          <Typography color="primary">{post.viewCount}</Typography>
        </div>
      </CardActions>
    </Card>
  )
}

export default withApollo(withRouter(LargePostCard))
