import { StyleSheet } from 'react-native-web'
import solveColors from './colors'

export const styles = StyleSheet.create({
  background: {
    backgroundColor: solveColors.grey1,
    paddingBottom: 'vmax',
    display: 'flex',
    width: '100%',
    height: '100%',
  },

  displayfullscreen: {
    display: 'flex',
    width: '100%',
  },

  display: {
    display: 'flex',

    width: 'calc(100% - 100px)',
  },

  split: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    justifyContent: 'space-between',
  },

  //Header

  header: {
    height: '100%',
    padding: '10px',
    paddingRight: '20px',
  },

  headerText: {
    color: 'white',
    textAlign: 'left',
  },

  //Navigation Bar
  navigationbar: {
    backgroundColor: solveColors.grey3,
    width: '100px',
    height: '100%',

    position: 'fixed',
    top: 0,
  },
  mobileNavigationbar: {
    backgroundColor: solveColors.blue3,
    width: '200px',
    height: '100%',
    transitionDuration: '0.2s',
    transitionProperty: 'background-color',
    transitionTimingFunction: 'ease-in-out',
    position: 'fixed',
    top: 0,
    zIndex: 99,
  },

  navbutton: {
    alignItems: 'center',
    width: '100px',
    height: '0px',
    paddingBottom: '80px',
    paddingVertical: '20px',
  },

  icon: {
    width: 60,
    height: 60,
    marginTop: 15,
  },

  navIcon: {
    marginBottom: 10,
  },

  logo: {
    width: 60,
    height: 100,
    alignItems: 'center',
    marginBottom: '20px',
  },

  text: {
    color: 'white',
  },

  //Tab
  tabs: {
    flexDirection: 'row',
    width: '100%',
    backgroundColor: 'white',
  },
  tabBtn: {
    backgroundColor: 'white',
    paddingVertical: '1vh',
    alignItems: 'center',
    width: 150,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  tabBtnText: {
    fontSize: 'vmin',
    textAlign: 'center',
    paddingHorizontal: 10,
  },
})

//Login and Recovery Pages (Access pages)
export const access = StyleSheet.create({
  background: {
    flex: 1,
    width: '100vw',
    height: '100vh',
  },
  header: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: 150,
    height: 150,
    borderRadius: 75,
    marginBottom: 20,
  },

  inputField: {
    padding: 10,
    color: 'white',
    borderBottomWidth: 1,
    borderBottomColor: 'white',
    width: '180px',
    marginVertical: '5px',
  },

  button: {
    alignItems: 'center',
    backgroundColor: solveColors.green,
    marginTop: 20,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
  },
})

export default styles
