import React, { useState, useEffect } from 'react'
import {
  View,
  Text,
  FlatList,
  TouchableOpacity,
  ScrollView,
  Image,
} from 'react-native-web'
import solveColors from './../components/colors'
import dummy_profile from '../images/dummy_profile.png'
import { Latex } from './../components'
import { useCookies } from 'react-cookie'

const ChatBox = props => {
  const [scrollView] = useState(React.createRef())
  const { messages, options } = props
  let new_options = []
  for (let i = 0; i < options.length; i++) {
    new_options.push({ ...options[i], key: i.toString() })
  }
  const [cookies] = useCookies([])
  const chatdrli = {
    uri:
      'https://ardent-shared-media.s3-us-west-2.amazonaws.com/system/chatdrli.png',
  }
  useEffect(() => {
    scrollView.current.scrollToEnd()
  })
  return (
    <View>
      <ScrollView
        ref={scrollView}
        style={{
          paddingTop: 20,
          paddingLeft: 10,
          paddingRight: 10,
          height: '50vh',
          background: 'white',
          borderRadius: '0px 0px 20px 20px',
          width: '100%',
        }}
        showsHorizontalScrollIndicator={false}
        contentContainerstyle={{}}
      >
        {messages.map(message => {
          const { text, user, latex } = message
          if (text) {
            return (
              <View
                key={messages.indexOf(message).toString()}
                style={{
                  marginBottom: 1,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <View
                  style={{
                    marginBottom: 1,
                    alignItems: 'flex-end',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Image
                    style={
                      user === 'Dr.Li'
                        ? {
                            borderRadius: 75,
                            width: 40,
                            height: 40,
                            marginRight: 5,
                          }
                        : { background: 'white' }
                    }
                    source={chatdrli}
                  />
                  {!latex && (
                    <Text
                      style={[
                        { padding: 10, borderRadius: 10 },
                        user === 'Dr.Li'
                          ? {
                              background: solveColors.grey1,
                              alignSelf: 'flex-start',
                              maxWidth: '70%',
                            }
                          : {
                              background: solveColors.blue1,
                              maxWidth: '70%',
                              marginLeft: 'auto',
                              marginRight: 5,
                            },
                      ]}
                    >
                      {text}
                    </Text>
                  )}
                  {latex && (
                    <View
                      style={[
                        { padding: 10, borderRadius: 10 },
                        user === 'Dr.Li'
                          ? {
                              background: solveColors.grey1,
                              alignSelf: 'flex-start',
                              maxWidth: '70%',
                            }
                          : {
                              background: solveColors.blue1,
                              maxWidth: '70%',
                              marginLeft: 'auto',
                              marginRight: 5,
                            },
                      ]}
                    >
                      <Latex latex={text} />
                    </View>
                  )}
                  <Image
                    style={
                      user !== 'Dr.Li'
                        ? {
                            borderRadius: 75,
                            width: 40,
                            height: 40,
                          }
                        : { background: 'white' }
                    }
                    source={
                      cookies['imageUrl'] === 'null'
                        ? dummy_profile
                        : cookies['imageUrl']
                    }
                  />
                </View>

                <Text
                  style={[
                    { background: 'white', fontSize: 10, marginTop: 0 },
                    user === 'Dr.Li'
                      ? {
                          maxWidth: '70%',
                          marginLeft: '17%',
                          marginRight: 'auto',
                        }
                      : {
                          maxWidth: '70%',
                          marginLeft: 'auto',
                          marginRight: '17%',
                        },
                  ]}
                >
                  {new Date().toLocaleTimeString('en-GB', {
                    hour: '2-digit',
                    minute: '2-digit',
                  })}
                </Text>
              </View>
            )
          } else {
            return <React.Fragment />
          }
        })}
      </ScrollView>
      <View
        style={{
          alignItems: 'flex-end',
          // background: 'white',
          // position: 'fixed',
          // bottom: 0,
          // right: 20,
        }}
      >
        <FlatList
          style={{
            position: 'fixed',
            bottom: 0,
            right: 20,
          }}
          data={new_options}
          horizontal={true}
          renderItem={({ item }) => {
            const { onClick, text } = item

            return (
              <TouchableOpacity
                onPress={onClick}
                style={{
                  alignSelf: 'flex-end',
                  padding: 10,
                  borderRadius: 15,
                  borderWidth: 2,
                  borderColor: solveColors.blue1,
                  marginBottom: 5,
                  marginRight: 5,
                }}
              >
                <Text
                  style={{
                    color: solveColors.blue1,
                  }}
                >
                  {text}
                </Text>
              </TouchableOpacity>
            )
          }}
        />
      </View>
    </View>
  )
}
export default ChatBox
