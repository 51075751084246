import React, { useEffect, useState } from 'react'
import { TouchableOpacity, View, Text, StyleSheet } from 'react-native-web'
import solveColors from '../../../components/colors'

const AttachmentButton = props => {
  const [attachment, setAttachment] = useState(props.attachment)
  const fileInput = React.createRef()

  useEffect(() => {
    setAttachment(props.attachment)
  }, [props.attachment])

  const selectAttachment = () => {
    fileInput.current.click()
  }
  const uploadAttachment = async e => {
    const file = e.target.files[0]
    setAttachment(file)
    props.updateProblemAttachments(file)
  }
  const removeAttachment = async () => {
    if (attachment.fileKey) {
      await props.deleteProblemAttachment(attachment.fileKey)
    }
    setAttachment(null)
    props.updateProblemAttachments(null)
  }
  const { complete, review } = props
  return (
    <View style={styles.container}>
      <TouchableOpacity
        disabled={
          complete ||
          review ||
          (attachment !== null && attachment !== undefined)
        }
        style={[styles.attachment_button]}
        onPress={selectAttachment}
      >
        <input
          ref={fileInput}
          type="file"
          hidden
          multiple={false}
          onChange={e => uploadAttachment(e)}
        />
        <Text style={styles.button_text}>{'Attachments...'}</Text>
      </TouchableOpacity>
      <Text style={styles.attachment_text}>
        {attachment ? attachment.name : 'None'}
      </Text>
      {attachment && (
        <TouchableOpacity
          style={[styles.x_button]}
          disabled={review || complete}
          onPress={() => removeAttachment()}
        >
          <Text> X </Text>
        </TouchableOpacity>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    margin: 10,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: solveColors.grey3,
    alignItems: 'center',
    justifyContent: 'center',
  },
  attachment_button: {
    alignItems: 'center',
    padding: 8,
    width: 160,
    backgroundColor: solveColors.blueGreen,
  },
  button_text: {
    color: 'white',
    fontWeight: 'bold',
  },
  attachment_text: {
    marginHorizontal: 20,
  },
  x_button: {
    alignItems: 'center',
    padding: 8,
    width: 40,
  },
})

export default AttachmentButton
