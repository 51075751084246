import { StyleSheet, View, Text, TouchableOpacity, Image } from 'react-native'
import React, { Component } from 'react'
import Modal from 'modal-react-native-web'
import { solveColors } from '../../components/colors'
import { VideoPlayerForm } from '../../components'
import problemStates from './ProblemStates'
import { Query } from 'react-apollo'
import { GET_SUBMISSION } from './../../../services/queries'
import jwt from 'jsonwebtoken'
import { InfoRow } from './../../components/'
import drliA from './../../images/drliA.gif'
import drliB from './../../images/drliB.png'
import drliC from './../../images/drliC.png'
import drliD from './../../images/drliD.png'
import drliF from './../../images/drliF.png'
import { withCookies } from 'react-cookie'
// import chineseNewYear from './../../images/chinesenewyear.png'

class ResultsModal extends Component {
  constructor(props) {
    super(props)
    // const today = new Date()
    this.state = {
      visible: false,
      isChineseNewYear: false,
      //today.getDate() >= 4 && today.getDate() <= 14 && today.getMonth() === 1,
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.visible !== props.visible) {
      return { visible: props.visible }
    }
    return null
  }

  calculateScore = () => {
    let correct = 0
    for (let i = 0; i < this.props.problemStates.length; i++) {
      if (this.props.problemStates[i] === problemStates.correct) correct++
    }
    return correct
  }
  getEmoji = score => {
    // if (this.state.isChineseNewYear) {
    //   return chineseNewYear
    // }
    if (score >= 75) {
      return drliA
    } else if (score >= 60) {
      return drliB
    } else if (score >= 40) {
      return drliC
    } else if (score >= 20) {
      return drliD
    }
    return drliF
  }
  render() {
    return (
      <Query
        query={GET_SUBMISSION}
        variables={{
          sort: {
            sum: -2,
            submittedAt: -1,
          },
          filter: {
            submittedAt: { $exists: true },
            assignmentId: this.props.assignmentId,
            studentId: jwt.decode(this.props.cookies.get('token')).id,
          },
        }}
      >
        {({ _data, loading, _error }) => {
          if (loading) return ' '
          const score = this.calculateScore()
          const percentScore = (score * 100) / this.props.problemSetLength
          const playVideo = Math.random() <= 0.1
          return (
            <Modal visible={this.state.visible} transparent={true}>
              <View style={styles.backdrop}>
                <View
                  style={playVideo ? styles.resultsWithVideo : styles.results}
                >
                  {playVideo && (
                    <VideoPlayerForm
                      videoId={435993227}
                      width={'426'}
                      height={'240'}
                    />
                  )}
                  <Image
                    source={this.getEmoji(percentScore)}
                    style={
                      this.state.isChineseNewYear
                        ? styles.chineseNewYearEmoji
                        : styles.emoji
                    }
                  />
                  {this.state.isChineseNewYear && (
                    <Text
                      style={{
                        fontSize: 13,
                        alignSelf: 'center',
                        marginBottom: 20,
                      }}
                    >
                      Count the Oxen for a Chance to Win! Send your answer to
                      solve@ardentacademy.com. Please include your full name and
                      parent's email.
                    </Text>
                  )}
                  <InfoRow
                    style={[styles.infoRows, { borderTopWidth: 1 }]}
                    text1={'Correct Answers'}
                    text2={`${score}/${this.props.problemSetLength}`}
                  />
                  <InfoRow
                    style={styles.infoRows}
                    text1={'Score:'}
                    text2={`${percentScore.toFixed(1)}%`}
                  />
                  <InfoRow
                    style={styles.infoRows}
                    text1={'Attempts:'}
                    text2={sessionStorage.getItem('attemptNumber')}
                  />
                  <InfoRow
                    style={styles.infoRows}
                    text1={'Time:'}
                    text2={`${sessionStorage.getItem(
                      `timeSpent:${this.props.assignmentId}`
                    )} seconds`}
                  />
                  <TouchableOpacity
                    style={[
                      styles.button,
                      { backgroundColor: solveColors.blue5, bottom: 0 },
                    ]}
                    onPress={this.props.closeModal}
                  >
                    <Text style={{ color: 'white' }}>Close</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </Modal>
          )
        }}
      </Query>
    )
  }
}

const styles = StyleSheet.create({
  results: {
    width: '300px',
    borderRadius: '10px',
    margin: 'auto',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: 25,
  },
  resultsWithVideo: {
    width: '500px',
    borderRadius: '10px',
    margin: 'auto',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: 25,
  },
  backdrop: {
    backgroundColor: 'rgba(52, 52, 52, 0.2)',
    height: '100%',
    width: '100%',
  },
  chineseNewYearEmoji: {
    height: 320,
    width: 250,
  },
  emoji: {
    height: 125,
    width: 125,
    marginBottom: 20,
  },
  button: {
    width: 250,
    height: 40,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 30,
  },
  infoRows: {
    paddingVertical: 10,
    width: 250,
  },
})
export default withCookies(ResultsModal)
