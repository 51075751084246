import gql from 'graphql-tag'

export const STUDENT_LOGIN = gql`
  mutation StudentLogin($id: String!, $pw: String!, $filter: Filters) {
    studentLogin(username: $id, password: $pw) {
      success
      token
      message
      student {
        id
        firstName
        lastName
        imageUrl
        birthdate
        opportunities(filter: $filter) {
          id
          stage
          status
          trials(filter: { status: "SCHEDULED" }) {
            id
            toLesson {
              id
              classroom {
                id
              }
            }
          }
        }
      }
    }
  }
`

export const USER_LOGIN = gql`
  mutation UserLogin(
    $username: String!
    $password: String!
    $studentOpportunityFilter: Filters
  ) {
    userLogin(username: $username, password: $password) {
      success
      token
      message
      userType
      user {
        ... on Student {
          id
          firstName
          lastName
          imageUrl
          birthdate
          gradeUpdated
          opportunities(filter: $studentOpportunityFilter) {
            id
            stage
            status
            trials(filter: { status: "SCHEDULED" }) {
              id
              toLesson {
                id
                classroom {
                  id
                }
              }
            }
          }
        }
        ... on Employee {
          id
          firstName
          lastName
          imageUrl
          isTeacher
          isAdminOfSOLVE
        }
      }
    }
  }
`

export const USERNAME_CHECK = gql`
  query UsernameCheck($id: String!) {
    requestStudentByUsername(username: $id) {
      exists
      image
      firstName
      lastName
    }
  }
`
export const RESET_PASSWORD_UN = gql`
  mutation ResetPassword($username: String!) {
    resetPassword(username: $username) {
      success
      message
    }
  }
`

export const RESET_PASSWORD_EMAIL = gql`
  mutation ResetPassword($email: String!) {
    resetPassword(email: $email) {
      success
      message
    }
  }
`

export const COUNT_SOLVED_PROBLEMS = gql`
  query solvedProblemCount {
    countProblemResponses
  }
`

export const SUBSCRIBE_PROBLEM_RESPONSES = gql`
  subscription subscribedResponses {
    onProblemResponseCountChanged {
      reason
      newCount
    }
  }
`

export const EMPLOYEE_LOGIN = gql`
  mutation InstructorLogin($email: String!, $pw: String!) {
    employeeLogin(email: $email, password: $pw) {
      success
      token
      message
      employee {
        firstName
        lastName
        imageUrl
        isTeacher
        isAdminOfSOLVE
      }
    }
  }
`
export const START_SESSION = gql`
  mutation startSession($studentId: ID!) {
    startStudentSession(studentId: $studentId)
  }
`

export const FAMILY_LOGIN = gql`
  mutation familyLogin($phone: String!, $callInstead: Boolean) {
    familyLogin(phoneNumber: $phone, callInstead: $callInstead) {
      hasAccount
      verificationCodeSent
      secondsToExpire
      message
    }
  }
`

export const FAMILY_VERIFY = gql`
  mutation familyVerify($phone: String!, $code: String!) {
    familyVerify(phoneNumber: $phone, verificationCode: $code) {
      message
      success
      token
      familyMember {
        id
        firstName
        lastName
        imageUrl
      }
      familyAccountId
    }
  }
`

export const GET_FAMILY_STUDENTS = gql`
  query getFamilyStudents($id: ID!) {
    familyAccount(id: $id) {
      id
      students {
        id
      }
    }
  }
`

export const GET_RECENT_PAID_INVOICE = gql`
  query getRecentPaidInvoice($filter: Filters!) {
    invoices(filter: $filter) {
      id
    }
  }
`
