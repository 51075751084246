import { FlatList, View, Text, ScrollView, Dimensions } from 'react-native'
import React, { useEffect, useState } from 'react'
import { TouchableOpacity } from 'react-native-web'
import solveColors from './../../components/colors'
import Card from 'react-bootstrap/Card'
import { formatDate, getDay } from './../../../utils/dateTime'
import { Query } from 'react-apollo'
import { GET_CLASSROOM_GRADES } from './../../../services/queries'
import jwt from 'jsonwebtoken'
import LoadingPage from './../Loading/LoadingPage'
import ScheduleIcon from '@material-ui/icons/Schedule'
import { InfoRow } from './../../components'
import AnnouncementCard from './AnnouncementCard'
import { useCookies } from 'react-cookie'

const ClassroomForm = props => {
  const [windowWidth, setWindowWidth] = useState(Dimensions.get('window').width)
  const [cookies] = useCookies([])
  useEffect(() => {
    const getWidth = () => setWindowWidth(Dimensions.get('window').width)

    window.addEventListener('resize', getWidth)

    return () => window.removeEventListener('resize', getWidth)
  })
  const { preview, classroomId, headline, teachingStyle, trialToLesson } = props
  return (
    <Query
      query={GET_CLASSROOM_GRADES}
      context={{ headers: { 'cache-control': 'no-cache' } }}
      skip={preview}
      variables={{
        classroomId: classroomId,
        enrollmentFilter: {
          studentId: jwt.decode(cookies['token']).id,
        },
        attendeeFilter: { lesson: { classroomId: classroomId } },
      }}
    >
      {({ data, loading, error }) => {
        if (error) return `${error}`
        if (loading) return <LoadingPage />

        const { announceDateTime, subject, message, likes, id: newsId } =
          headline.length > 0 ? headline[0] : {}

        const employee =
          cookies['instructor'] === '1' || cookies['admin'] === '1'
        const lessonGrades =
          employee || preview || trialToLesson
            ? []
            : data.classroom.enrollments[0].student.attendees.map(a => ({
                lessonId: a.lesson.id,
                overallGrade: a.overallGrade,
                percentCompleted: a.percentCompleted,
              }))

        //var for checking previous lesson if teaching style is self_paced
        //first lesson is always opened
        let previousLesson = {
          lessonGrade: 100,
          lessonPercent: 100,
        }

        return (
          <ScrollView contentContainerStyle={{ alignItems: 'center' }}>
            <View
              style={{
                alignSelf: 'flex-end',
                marginRight: 20,
                marginVertical: 20,
                flexDirection: 'row',
              }}
            >
              <ScheduleIcon />
              <Text style={{ marginLeft: 5, fontSize: 16 }}>{`Today is ${getDay(
                new Date().toISOString()
              )}, ${formatDate(new Date().toISOString())}`}</Text>
            </View>
            {headline.length > 0 && (
              <AnnouncementCard
                newsId={newsId}
                dateTime={announceDateTime}
                announcerName={'Ardent Staff'}
                // announcerName={`${announcer.firstName} ${announcer.lastName}`}
                message={message}
                subject={subject}
                likes={likes}
              />
            )}
            <FlatList
              data={props.lessons}
              numColumns={
                Math.floor(windowWidth / 300) >= 1
                  ? Math.floor(windowWidth / 300)
                  : 1
              }
              key={Math.floor(windowWidth / 300)}
              keyExtractor={item => item.id}
              renderItem={({ item }) => {
                const { lessonPlan, startOn, endOn } = item
                const { title, order, lessonPlanType } = lessonPlan

                const lessonDate =
                  new Date(startOn).getDate() === new Date(endOn).getDate() &&
                  new Date(startOn).getMonth() === new Date(endOn).getMonth()
                    ? `${formatDate(startOn)}`
                    : `${formatDate(startOn, false)} - ${formatDate(
                        endOn,
                        false
                      )}`
                const attendee = lessonGrades.find(l => l.lessonId === item.id)
                const {
                  overallGrade: lessonGrade,
                  percentCompleted: lessonPercent,
                } = attendee || { overallGrade: null, percentCompleted: null }

                const instructorLed =
                  teachingStyle === 'BLENDED' || teachingStyle === 'CLASSROOM'

                let locked
                if (order === 0) {
                  locked = false
                } else if (instructorLed || employee) {
                  locked = props.lessonLocks[order - 1]
                } else {
                  locked =
                    previousLesson.lessonGrade < 75 &&
                    previousLesson.lessonPercent < 100
                  previousLesson = {
                    lessonGrade: lessonGrade,
                    lessonPercent: lessonPercent,
                  }
                }
                return (
                  <Card
                    title="LESSON CARD"
                    style={{
                      backgroundColor: 'white',
                      margin: 10,
                      width: 250,
                      boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.25)',
                    }}
                  >
                    <Card.Body
                      style={{
                        padding: 10,
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      {lessonPlanType !== 'INTRODUCTION' && (
                        <Card.Title
                          style={{
                            fontWeight: 'bold',
                            fontSize: '16px',
                          }}
                        >
                          {`Lesson ${order}`}
                        </Card.Title>
                      )}
                      <Card.Title
                        style={
                          lessonPlanType === 'INTRODUCTION'
                            ? {
                                fontWeight: 'bold',
                                fontSize: '18px',
                              }
                            : {
                                fontSize: '16px',
                                textAlign: 'center',
                              }
                        }
                      >
                        {title}
                      </Card.Title>
                      {lessonPlanType === 'INTRODUCTION' && (
                        <React.Fragment>
                          <Text
                            style={{
                              textAlign: 'center',
                              fontSize: 16,
                              marginVertical: 20,
                            }}
                          >
                            Take an assessment to learn how ready you are to
                            take this class
                          </Text>
                        </React.Fragment>
                      )}
                      {lessonPlanType !== 'INTRODUCTION' && (
                        <React.Fragment>
                          <View style={{ marginVertical: 20 }}>
                            <InfoRow
                              style={{ width: 200 }}
                              text1={'Lesson Grade:'}
                              text2={
                                lessonGrade !== undefined &&
                                lessonGrade !== null
                                  ? `${lessonGrade}%`
                                  : 'N/A'
                              }
                            />
                            <InfoRow
                              style={{ width: 200 }}
                              text1={'Percent Complete:'}
                              text2={
                                lessonPercent !== undefined &&
                                lessonPercent !== null
                                  ? `${lessonPercent}%`
                                  : 'N/A'
                              }
                            />
                          </View>
                          {instructorLed && <Text>{lessonDate}</Text>}
                        </React.Fragment>
                      )}
                      <TouchableOpacity
                        disabled={locked}
                        style={[
                          {
                            backgroundColor: solveColors.green,
                            marginVertical: 10,
                            paddingVertical: 7,
                            paddingHorizontal: 20,
                            borderRadius: 5,
                          },
                          locked
                            ? { backgroundColor: solveColors.grey2 }
                            : null,
                        ]}
                        onPress={() => {
                          sessionStorage.setItem(
                            `lessonNum:${props.classroomId}`,
                            order
                          )
                          props.setLessonSelected(true)
                          props.setCurrLessonNum(order)
                        }}
                      >
                        <Text style={{ color: 'white' }}>
                          {lessonPercent
                            ? lessonPercent === 100
                              ? 'Continue'
                              : 'Review'
                            : 'Start'}
                        </Text>
                      </TouchableOpacity>
                    </Card.Body>
                  </Card>
                )
              }}
            />
          </ScrollView>
        )
      }}
    </Query>
  )
}

export default ClassroomForm
