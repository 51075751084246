import React, { useState } from 'react'
import { Text, Image, View } from 'react-native'
import { withRouter } from 'react-router-dom'
import Button from './Button'
import styles from './styles'
import moment from 'moment'
import { solveColors } from '../../components/colors'
import { ApolloConsumer } from 'react-apollo'
import TextField from '../../components/TextField'
import DropDownMenu from '../../components/DropDownMenu'
import {
  UPDATE_ACCOUNT_INFO,
  UPDATE_LOCATION_INFO,
  CREATE_LOCATION_INFO,
  UPDATE_FAMILY_ACCOUNT,
  GET_SIGNEDURL,
} from './../../../services/queries'
import { TouchableOpacity } from 'react-native-web'
import { withCookies } from 'react-cookie'

const stateOptions = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
]

const grades = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
]

const EditProfileForm = props => {
  sessionStorage.setItem('showStudentEdit', false)

  const {
    studentId,
    imageUrl,
    school,
    grade,
    gradeUpdated,
    birthdate,
    familyAccount,
  } = props

  const hasLocation = familyAccount.location != null
  const { id, street, city, state, country, zip } = familyAccount.location || {}
  const fileInput = React.createRef()

  const defaultImageUrl = imageUrl
  const defaultGrade = grade
  const defaultSchool = school
  const defaultStreet = street
  const defaultCity = city
  const defaultState = state
  const defaultCountry = country
  const defaultZip = zip
  const defaultBirthdate = new Date(birthdate)
  const defaultMonth = defaultBirthdate.getMonth() + 1
  const defaultDay = defaultBirthdate.getDate()
  const defaultYear = defaultBirthdate.getFullYear()

  const [imageUrlField, setImageUrl] = useState(imageUrl)
  const [gradeField, setGradeField] = useState(grade)
  const [schoolField, setSchoolField] = useState(school)
  const [streetField, setStreetField] = useState(street)
  const [cityField, setCityField] = useState(city)
  const [stateField, setStateField] = useState(state)
  const [countryField, setCountryField] = useState(country)
  const [zipField, setZipField] = useState(zip)
  const [monthField, setMonth] = useState(defaultMonth)
  const [dayField, setDay] = useState(defaultDay)
  const [yearField, setYear] = useState(defaultYear)
  const [focus, setFocus] = useState(null)

  const handleCancel = () => {
    props.setEditable(false)
  }

  const handleReset = () => {
    setImageUrl(defaultImageUrl)
    setGradeField(defaultGrade)
    setSchoolField(defaultSchool)
    setStreetField(defaultStreet)
    setZipField(defaultZip)
    setCityField(defaultCity)
    setStateField(defaultState)
    setCountryField(defaultCountry)
    setMonth(defaultMonth)
    setDay(defaultDay)
    setYear(defaultYear)
    setFocus(null)
  }

  const handleSave = async client => {
    if (hasLocation) {
      await client.mutate({
        mutation: UPDATE_LOCATION_INFO,
        variables: {
          id: id,
          input: {
            street: streetField,
            city: cityField,
            state: stateField,
            country: countryField,
            zip: zipField,
          },
        },
      })
    } else {
      const loc = await client.mutate({
        mutation: CREATE_LOCATION_INFO,
        variables: {
          input: {
            street: streetField,
            city: cityField,
            state: stateField,
            country: countryField,
            zip: zipField,
          },
        },
      })
      await client.mutate({
        mutation: UPDATE_FAMILY_ACCOUNT,
        variables: {
          id: familyAccount.id,
          input: {
            locationId: loc.data.createLocation.id,
          },
        },
      })
    }
    let imageUrl = null
    if (typeof imageUrlField !== 'string') {
      const file = imageUrlField
      const s3SignResult = await client.mutate({
        mutation: GET_SIGNEDURL,
        variables: {
          filename: file.name,
          contentType: file.type,
        },
      })
      if (
        !s3SignResult ||
        !s3SignResult.data ||
        !s3SignResult.data.s3Sign.success
      ) {
        this.openModal('Failed to upload attachment please try again.')
      } else {
        const { signedUrl, publicUrl } = s3SignResult.data.s3Sign
        imageUrl = publicUrl.split('.com/')[1]
        await fetch(signedUrl, {
          method: 'PUT',
          body: file,
        })
        this.props.cookies.set('imageUrl', publicUrl, { maxAge: 604800 })
      }
    }

    await client.mutate({
      mutation: UPDATE_ACCOUNT_INFO,
      variables: {
        id: studentId,
        input: {
          school: schoolField,
          grade: parseInt(gradeField, 10),
          birthdate: new Date(
            yearField,
            monthField - 1,
            dayField
          ).toISOString(),
          gradeUpdated: true,
          newImage: imageUrl || undefined,
        },
      },
    })

    props.refetch()
    props.setEditable(false)
  }
  const uploadProfileImage = async e => {
    const file = e.target.files[0]
    setImageUrl(file)
  }
  const selectImage = () => {
    fileInput.current.click()
  }
  return (
    <ApolloConsumer>
      {client => (
        <View style={[styles.view, { paddingVertical: '8vh' }]}>
          <View style={styles.editProfileView}>
            <Image
              style={styles.icon}
              source={
                typeof imageUrlField === 'string'
                  ? imageUrlField
                  : URL.createObjectURL(imageUrlField)
              }
            />
            <TouchableOpacity
              style={[
                styles.defaultButton,
                {
                  backgroundColor: solveColors.blue1,
                  width: '210px',
                  marginHorizontal: '5px',
                  alignItems: 'center',
                },
              ]}
              onPress={selectImage}
            >
              <input
                ref={fileInput}
                type="file"
                accept="image/*"
                hidden
                multiple={false}
                onChange={e => uploadProfileImage(e)}
              />
              <Text style={{ color: 'white', fontWeight: 'bold' }}>
                Change Profile Image
              </Text>
            </TouchableOpacity>
            <View style={styles.header}>
              <Text style={styles.title}>
                {!gradeUpdated
                  ? 'Congratualtions on your new school year!'
                  : 'Edit Profile'}
              </Text>
              <Text style={styles.subtitle}>
                {!gradeUpdated
                  ? 'Keep your profile up to date'
                  : 'Modify your personal information here'}
              </Text>
            </View>
            <View style={styles.inputs}>
              <View style={styles.fieldRow}>
                <TextField
                  name={'School'}
                  field={schoolField}
                  onChange={setSchoolField}
                  focus={focus}
                  setFocus={setFocus}
                  style={{ flex: 4, margin: 10 }}
                />
                <DropDownMenu
                  name={'Grade'}
                  field={gradeField}
                  options={grades}
                  onChange={setGradeField}
                  setFocus={setFocus}
                  width={{ flex: 1 }}
                />
              </View>
              <View style={styles.fieldRow}>
                <DropDownMenu
                  name={'Birth Day'}
                  width={{ flex: 1 }}
                  options={Array.from(
                    {
                      length: moment(
                        yearField + '-' + monthField,
                        'YYYY-MM'
                      ).daysInMonth(),
                    },
                    (x, i) => i + 1
                  )}
                  field={dayField}
                  onChange={setDay}
                  setFocus={setFocus}
                />
                <DropDownMenu
                  name={'Birth Month'}
                  width={{ flex: 5 }}
                  options={moment.months()}
                  field={moment()
                    .month(Number(monthField - 1))
                    .format('MMMM')}
                  onChange={val => {
                    setMonth(
                      moment()
                        .month(val)
                        .format('MM')
                    )
                  }}
                  setFocus={setFocus}
                />
                <DropDownMenu
                  name={'Birth Year'}
                  width={{ flex: 2 }}
                  options={Array.from(
                    { length: 60 },
                    (x, i) => i + new Date().getFullYear() - 60
                  )}
                  field={yearField}
                  onChange={setYear}
                  setFocus={setFocus}
                />
              </View>
              <View style={styles.fieldRow}>
                <TextField
                  name={'Street'}
                  field={streetField}
                  onChange={setStreetField}
                  focus={focus}
                  setFocus={setFocus}
                  style={{ flex: 1, margin: 10 }}
                />
              </View>
              <View style={styles.fieldRow}>
                <TextField
                  name={'City'}
                  field={cityField}
                  onChange={setCityField}
                  focus={focus}
                  setFocus={setFocus}
                  style={{ flex: 1, margin: 10 }}
                />
              </View>
              <View style={styles.fieldRow}>
                <DropDownMenu
                  name={'State'}
                  field={stateField}
                  options={stateOptions}
                  onChange={setStateField}
                  setFocus={setFocus}
                  width={{ flex: 3 }}
                />
                <TextField
                  name={'Zip'}
                  keyboardType={'numeric'}
                  field={zipField}
                  onChange={setZipField}
                  focus={focus}
                  setFocus={setFocus}
                  style={{ flex: 2, margin: 10 }}
                />
              </View>
              <View style={styles.fieldRow}>
                <TextField
                  name={'Country'}
                  field={countryField}
                  onChange={setCountryField}
                  focus={focus}
                  setFocus={setFocus}
                  style={{ flex: 1, margin: 10 }}
                />
              </View>
              <View style={styles.editButtons}>
                <Button
                  buttonStyling={[
                    styles.defaultButton,
                    { backgroundColor: solveColors.red1 },
                  ]}
                  text="CANCEL"
                  onPress={handleCancel}
                />
                <Button
                  buttonStyling={[
                    styles.defaultButton,
                    styles.resetButton,
                    { backgroundColor: solveColors.orange },
                  ]}
                  text="RESET"
                  onPress={handleReset}
                />
                <Button
                  buttonStyling={[
                    styles.defaultButton,
                    styles.green,
                    styles.saveButton,
                  ]}
                  text="SAVE"
                  onPress={() => {
                    handleSave(client)
                  }}
                />
              </View>
              <Text style={{ fontSize: 10, color: 'dimgrey', margin: 20 }}>
                To change your password, please refer to the change password
                button which can be found on the profile page
              </Text>
            </View>
          </View>
        </View>
      )}
    </ApolloConsumer>
  )
}

export default withRouter(withCookies(EditProfileForm))
