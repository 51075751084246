import gql from 'graphql-tag'

export const GET_SINGLE = gql`
  query get_single($id: ID!) {
    classroom(id: $id) {
      id
      title
      status
      year
      quarter
      startDate
      endDate
      startTime
      endTime
      meets
      meetsOnMonday
      meetsOnTuesday
      meetsOnWednesday
      meetsOnThursday
      meetsOnFriday
      meetsOnSaturday
      meetsOnSunday
      meetsEveryday
      teachingStyle
      tuitionCents
      earlyBirdtuitionCents
      enrollmentCount
      enrollmentMax
      meetingsPerWeek
      videoId
      course {
        code
        title
        lessonCount
        description
        subject {
          name
        }
        category {
          name
        }
        imageUrl
        startGrade
        endGrade
      }
    }
  }
`

export const FILTER_COURSES = gql`
  query filter_courses($classroomFilter: Filters!) {
    classrooms(filter: $classroomFilter) {
      id
      videoId
      title
      year
      quarter
      session
      startDate
      endDate
      tuitionCents
      earlyBirdtuitionCents
      enrollmentCount
      enrollmentMax
      tuitionCents
      teachingStyle
      meetingsPerWeek
      earlyBirdtuitionCents
      course {
        id
        imageUrl
        startGrade
        endGrade
        subject {
          name
        }
      }
      centerLocation {
        id
        name
      }
      leadInstructor
      instructors {
        id
        displayName
      }
      status
      meets
      startTime
      endTime
      code
    }
  }
`

export const GET_ENROLLMENTS = gql`
  query GetEnrollments($studentId: ID!) {
    student(id: $studentId) {
      enrollments {
        classroom {
          id
        }
      }
    }
  }
`
