import { StyleSheet, Text, TouchableOpacity } from 'react-native'
import React, { Component } from 'react'
import problemStates from './../ProblemStates'
import { solveColors } from '../../../components/colors'
import { CircularProgress } from '@material-ui/core'

class ProblemSubmitButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      probState: props.probState,
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.probState !== props.probState) {
      return { probState: props.probState }
    }
    return null
  }

  render() {
    const {
      handleProblemSubmit,
      feedbackType,
      review,
      lastProb,
      problemType,
      isLoading,
    } = this.props
    const { probState } = this.state
    return (
      <TouchableOpacity
        style={[
          getButtonColor(probState, feedbackType, review),
          styles.submit_button,
        ]}
        onPress={() => {
          handleProblemSubmit()
        }}
        disabled={isLoading}
      >
        {!isLoading && (
          <Text style={styles.buttonText}>
            {getButtonText(probState, review, lastProb, problemType)}
          </Text>
        )}
        {isLoading && (
          <CircularProgress style={{ color: 'white', height: 20, width: 20 }} />
        )}
      </TouchableOpacity>
    )
  }
}

const getButtonColor = (state, feedbackType, review) => {
  if (
    feedbackType === 'INSTANT' ||
    sessionStorage.getItem('corrections') === '1'
  ) {
    if (state === problemStates.correct) return styles.next_correct
    if (state === problemStates.incorrect) return styles.next_incorrect
  }
  return styles.neutral
}

const getButtonText = (state, review, lastProb, problemType) => {
  if (!review && state === problemStates.unanswered && problemType !== 'EMBED')
    return 'SUBMIT'
  if (review && lastProb) return 'END OF REVIEW'
  return 'NEXT'
}

const styles = StyleSheet.create({
  submit_button: {
    alignItems: 'center',
    paddingVertical: 10,
    borderRadius: 10,
    margin: 10,
    width: 160,
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
  },
  neutral: {
    backgroundColor: solveColors.blue1,
  },
  submit_problemset: {
    backgroundColor: solveColors.orange,
  },
  next_correct: {
    backgroundColor: solveColors.green,
  },
  next_incorrect: {
    backgroundColor: solveColors.red1,
  },
})

export default ProblemSubmitButton
