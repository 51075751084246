import { Text, View, Image } from 'react-native'
import { Latex } from '../../components/index'
import React, { useState, useEffect } from 'react'

const PrintProblem = props => {
  const [width, setWidth] = useState(300)
  const [height, setHeight] = useState(300)

  useEffect(() => {
    if (props.questionImageUrl) {
      Image.getSize(props.questionImageUrl, (width, height) => {
        setWidth(width)
        setHeight(height)
      })
    }
  })

  return (
    <View style={{ overflow: 'visible', marginBottom: 30 }}>
      <Text
        style={{
          fontWeight: 'bold',
          fontSize: 20,
          userSelect: 'none',
        }}
      >
        {`Question ${props.questionIndex}\n`}
      </Text>
      <View style={{ margin: 20 }}>
        {props.questionImageUrl && (
          <React.Fragment>
            <Image
              style={{ width: width, height: height, resizeMode: 'contain' }}
              source={props.questionImageUrl}
            />
          </React.Fragment>
        )}
        <Latex latex={props.questionText} />
        {(props.problemType === 'MULTIPLE_CHOICE_ONE_ANSWER' ||
          props.problemType === 'MULTIPLE_CHOICE_MANY_ANSWERS') && (
          <React.Fragment>
            {props.choices.map((choice, mcqIndex) => {
              mcqIndex += 1
              return (
                <View style={{ marginTop: 20, flexDirection: 'row' }}>
                  <Text style={{ marginRight: 10 }}>
                    {String.fromCharCode(mcqIndex + 64)}.
                  </Text>
                  <Latex latex={choice.text} />
                </View>
              )
            })}
          </React.Fragment>
        )}
      </View>
    </View>
  )
}

export default PrintProblem
