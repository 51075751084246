import { ScrollView } from 'react-native'
import React, { Component } from 'react'
import DashboardForm from './DashboardForm'
import InstructorDashboardForm from './InstructorDashboardForm'
import AdminDashboardForm from './AdminDashboardForm'
import { Tabs, BirthdayMessage } from '../../components/'
import { Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import {
  GET_TRIAL_CLASSROOM,
  GET_CLASSROOMS,
  GET_STUDENT_PROGRESS,
  GET_INSTRUCTOR_CLASSROOMS,
  GET_ADMIN_CLASSROOMS,
} from '../../../services/queries'
import { Query } from 'react-apollo'
import Composer from 'react-composer'
import LoadingPage from './../Loading/LoadingPage'
import { withRouter } from 'react-router-dom'
// import BadgeBanner from '../../components/BadgeBanner'
import { withCookies } from 'react-cookie'

class DashboardPage extends Component {
  constructor(props) {
    super(props)
    this.props.changeTitles('Dashboard', 'Welcome Back')
    sessionStorage.removeItem('classroomData')
    const defaultTab = props.location.search
      ? parseInt(props.location.search.split('=')[1])
      : 0

    this.state = {
      defaultTab,
      tabInfo:
        this.props.cookies.get('admin') === '1'
          ? [
              {
                label: 'ACTIVE',
                filter: () => this.handleClassFilter('OPENED'),
              },
            ]
          : this.props.cookies.get('instructor') === '1'
          ? [
              {
                label: 'ACTIVE',
                filter: () => this.handleClassFilter('OPENED'),
              },
              {
                label: 'COMPLETED',
                filter: () => this.handleClassFilter('CLOSED'),
              },
            ]
          : [
              {
                label: 'ENROLLED',
                filter: () => this.handleClassFilter('OPENED'),
              },
              {
                label: 'COMPLETED',
                filter: () => this.handleClassFilter('CLOSED'),
              },
              {
                label: 'RECOMMENDED',
                filter: () => this.handleClassFilter(''),
              },
            ],
      filter: defaultTab === 0 ? 'OPENED' : defaultTab === 1 ? 'CLOSED' : '',
    }
  }
  componentDidMount() {
    sessionStorage.removeItem('preview')
    sessionStorage.removeItem('trial')
  }
  handleClassFilter = enrollmentStatus => {
    this.setState({
      ...this.state,
      filter: enrollmentStatus,
    })
  }

  render() {
    if (!this.props.cookies.get('token')) {
      return (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      )
    }
    if (this.props.cookies.get('admin') === '1') {
      return (
        <Query
          query={GET_ADMIN_CLASSROOMS}
          variables={{
            filter: { status: ['OPENED', 'PUBLISHED'] },
          }}
        >
          {({ data, loading, error }) => {
            if (error) return `${error}`
            if (loading) return <LoadingPage />

            const { classrooms } = data
            return (
              <React.Fragment>
                <Tabs
                  tabInfo={this.state.tabInfo}
                  defaultTab={this.state.defaultTab}
                />
                <ScrollView
                  contentContainerStyle={{
                    alignItems: 'center',
                    paddingVertical: '2vh',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <AdminDashboardForm
                    filter={this.state.filter}
                    classrooms={classrooms}
                  />
                </ScrollView>
              </React.Fragment>
            )
          }}
        </Query>
      )
    }
    if (this.props.cookies.get('instructor') === '1') {
      return (
        <Query
          query={GET_INSTRUCTOR_CLASSROOMS}
          variables={{ id: jwt.decode(this.props.cookies.get('token')).id }}
        >
          {({ data, loading, error }) => {
            if (error) return `${error}`
            if (loading) return <LoadingPage />

            const { instructors } = data.employee
            return (
              <React.Fragment>
                <Tabs
                  tabInfo={this.state.tabInfo}
                  defaultTab={this.state.defaultTab}
                />
                <ScrollView
                  contentContainerStyle={{
                    alignItems: 'center',
                    paddingVertical: '2vh',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <InstructorDashboardForm
                    filter={this.state.filter}
                    instructors={instructors}
                  />
                </ScrollView>
              </React.Fragment>
            )
          }}
        </Query>
      )
    }

    return (
      <Query
        query={GET_CLASSROOMS}
        variables={{ id: jwt.decode(this.props.cookies.get('token')).id }}
      >
        {({ data, loading, error, refetch }) => {
          if (error) {
            return `${error}`
          }
          if (loading) return <LoadingPage />

          const { enrollments, overallGrade } = data.student
          let trialsArray = []
          if (sessionStorage.getItem('trials')) {
            trialsArray = JSON.parse(sessionStorage.getItem('trials'))
          }
          return (
            <Composer
              components={trialsArray.map(trial => (
                <Query
                  query={GET_TRIAL_CLASSROOM}
                  variables={{ id: trial.classroomId }}
                >
                  {function() {
                    return ''
                  }}
                </Query>
              ))}
            >
              {trialClassrooms => {
                // quick fix; works fine but refactor later
                // probably cleaner if we make a wrapper component and query and handle all logic from the wrapper
                // then pass data to this inner component instead of using composer and query components
                for (let i = 0; i < trialClassrooms.length; i++) {
                  if (trialClassrooms[i].error)
                    return `${trialClassrooms[i].error}`
                  if (trialClassrooms[i].loading) return <LoadingPage />
                }
                const trialClasses = trialClassrooms.map(classroom => {
                  return {
                    classroom: classroom.data.classroom,
                    overallGrade: 'NaN',
                  }
                })
                const studentId = jwt.decode(this.props.cookies.get('token')).id
                sessionStorage.setItem('studentId', studentId)
                return (
                  <React.Fragment>
                    <Tabs
                      tabInfo={this.state.tabInfo}
                      defaultTab={this.state.defaultTab}
                    />
                    {/* <BadgeBanner /> */}
                    {sessionStorage.getItem('birthdate') === 'true' && (
                      <BirthdayMessage
                        firstname={this.props.cookies.get('firstName')}
                      />
                    )}
                    <ScrollView
                      contentContainerStyle={{
                        alignItems: 'center',
                        paddingVertical: '2vh',
                      }}
                    >
                      <Query
                        variables={{
                          studentId: studentId,
                        }}
                        query={GET_STUDENT_PROGRESS}
                      >
                        {({ data, loading, error }) => {
                          if (loading) {
                            return <LoadingPage />
                          }

                          return (
                            <DashboardForm
                              progress={data.getStudentProgress}
                              filter={this.state.filter}
                              enrollments={enrollments}
                              overallGrade={overallGrade}
                              trialEnrollments={trialClasses}
                            />
                          )
                        }}
                      </Query>
                    </ScrollView>
                  </React.Fragment>
                )
              }}
            </Composer>
          )
        }}
      </Query>
    )
  }
}

export default withRouter(withCookies(DashboardPage))
