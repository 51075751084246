import * as React from 'react'
import katex from 'katex'

const latexRegex = /\\\((.*?)\\\)/g

const LaTeX = props => {
  return (
    <div>
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/katex@0.11.1/dist/katex.min.css"
        integrity="sha384-zB1R0rpPzHqg7Kpt0Aljp8JPLqbXI3bhnPWROx27a9N0Ll6ZP/+DiW/UqRcLbRjq"
        crossOrigin="anonymous"
      />
      {props.latex.split(latexRegex).map((content, index) => {
        if (index & 1) {
          return (
            <span
              key={content + index}
              dangerouslySetInnerHTML={{
                __html: katex.renderToString(content, {
                  throwOnError: false,
                }),
              }}
            />
          )
        } else {
          if (content.includes('\n\n')) {
            const c = content.split('\n\n')
            return (
              <React.Fragment>
                {c.map((content, index) => {
                  return (
                    <span>
                      {content}
                      {index !== c.length - 1 ? <br /> : ''}
                    </span>
                  )
                })}
              </React.Fragment>
            )
          }
          return content
        }
      })}
    </div>
  )
}

export default LaTeX
