import gql from 'graphql-tag'

export const GET_FAMILY_STUDENTS = gql`
  query getFamilyStudents($id: ID!) {
    familyAccount(id: $id) {
      id
      status
      primaryFamilyMember {
        id
        firstName
        lastName
        relation
        email
        phoneNumber
      }
      students {
        id
        firstName
        lastName
        grade
        school
        imageUrl
        enrollmentStatus
        enrollments {
          id
          classroom {
            id
            code
            title
            status
            startDate
            endDate
          }
          overallGrade
        }
      }
    }
  }
`

export const GET_STUDENT_INFO = gql`
  query GetStudentInfo($id: ID!) {
    student(id: $id) {
      firstName
      lastName
      grade
      school
    }
  }
`
export const UPDATE_STUDENT_INFO = gql`
  mutation UpdateStudentInfo($id: ID!, $input: UpdateStudentInput!) {
    updateStudent(id: $id, input: $input) {
      id
      grade
      school
      firstName
      lastName
    }
  }
`

export const GET_CLIENT_SECRET = gql`
  mutation GetClientSecret($studentId: String!, $classroomId: String!) {
    initiateClassroomPayment(studentId: $studentId, classroomId: $classroomId) {
      success
      message
      clientSecret
    }
  }
`

export const CREATE_PENDING_INVOICE = gql`
  mutation createPendingInvoice($input: CreateInvoiceInput!) {
    createInvoice(input: $input) {
      id
    }
  }
`

export const GET_INVOICE_AND_CREDITS = gql`
  query getInvoiceAndCredits($filter: Filters!, $creditFilter: Filters!) {
    invoices(filter: $filter) {
      id
      invoiceNumber
      issueDate
      familyAccount {
        id
        balance
        primaryFamilyMember {
          id
          firstName
          lastName
          email
          phoneNumber
        }
        customerProfile
        customerId
      }
      items {
        id
        student {
          id
          firstName
          lastName
        }
        earlyBird
        earlyBirdDiscount
        classroom {
          title
          id
          code
          tuitionCents
          earlyBirdtuitionCents
          year
          quarter
          course {
            code
          }
        }
        discount {
          id
          label
        }
        offer {
          id
        }
        description
        unitPrice
        quantity
        price
        itemType
        archivedOn
      }
      balance
      price
      summary
    }
    credits(filter: $creditFilter) {
      id
      creditType
      balance
      status
      creditNumber
      notes
    }
  }
`

export const GET_INVOICE = gql`
  query getInvoice($filter: Filters!) {
    invoices(filter: $filter) {
      id
      invoiceNumber
      issueDate
      familyAccount {
        id
        balance
        primaryFamilyMember {
          id
          firstName
          lastName
          email
          phoneNumber
        }
        customerProfile
        customerId
      }
      items {
        id
        student {
          id
          firstName
          lastName
        }
        earlyBird
        earlyBirdDiscount
        classroom {
          title
          id
          code
          year
          quarter
          course {
            code
          }
        }
        discount {
          id
          label
        }
        offer {
          id
        }
        description
        unitPrice
        quantity
        price
        itemType
        archivedOn
      }
      balance
      price
      summary
    }
  }
`

export const GET_INVOICE_ITEMS = gql`
  query getInvoiceItems($filter: Filters!) {
    invoices(filter: $filter) {
      id
      items {
        id
        itemType
      }
    }
  }
`

export const CREATE_ITEM = gql`
  mutation createItem($input: CreateItemInput!) {
    createItem(input: $input) {
      id
    }
  }
`
export const UPDATE_INVOICE = gql`
  mutation updateInvoice($id: ID!, $input: UpdateInvoiceInput!) {
    updateInvoice(id: $id, input: $input) {
      id
      invoiceNumber
      summary
    }
  }
`

export const CREATE_PAYMENT = gql`
  mutation createPayment($input: CreatePaymentInput!) {
    createPayment(input: $input) {
      id
    }
  }
`

export const ARCHIVE_ITEM = gql`
  mutation archiveItem($id: ID!) {
    archiveItem(id: $id) {
      id
    }
  }
`

export const CREATE_ENROLLMENT = gql`
  mutation enrollStudent($input: CreateEnrollmentInput!) {
    createEnrollment(input: $input) {
      id
    }
  }
`

export const UPDATE_ITEM = gql`
  mutation updateItem($id: ID!, $input: UpdateItemInput!) {
    updateItem(id: $id, input: $input) {
      id
    }
  }
`

export const UPDATE_CREDIT = gql`
  mutation updateCredit($id: ID!, $input: UpdateCreditInput!) {
    updateCredit(id: $id, input: $input) {
      id
    }
  }
`

export const CHARGE_AUTHORIZE_CARD = gql`
  mutation chargeAuthorizeCard(
    $amount: String!
    $invoiceId: String!
    $orderSummary: String!
    $creditCard: AuthorizeNetCreditCardInput!
    $billTo: AuthorizeNetBillingAddressInput!
    $createProfile: Boolean
  ) {
    chargeAuthorizeNetCard(
      amount: $amount
      invoiceId: $invoiceId
      orderSummary: $orderSummary
      creditCard: $creditCard
      billTo: $billTo
      createProfile: $createProfile
    ) {
      success
      errorMessage
      transactionId
      resultCode
      messages {
        code
        text
      }
    }
  }
`

export const CHARGE_PAYMENT_PROFILE = gql`
  mutation chargePaymentProfile(
    $amount: String!
    $invoiceId: String!
    $orderSummary: String!
    $paymentProfileId: String!
  ) {
    chargeAuthorizeNetCustomer(
      amount: $amount
      invoiceId: $invoiceId
      orderSummary: $orderSummary
      paymentProfileId: $paymentProfileId
    ) {
      success
      errorMessage
      transactionId
      resultCode
      messages {
        code
        text
      }
    }
  }
`
