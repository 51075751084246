import React from 'react'
import { Text, View, StyleSheet, CheckBox } from 'react-native'

const CheckBoxMenu = props => {
  const { field, onChange, name } = props
  return (
    <View style={styles.boxView}>
      <CheckBox
        value={field}
        onValueChange={onChange}
        style={styles.checkBoxStyle}
      />
      <Text style={styles.label}>{name}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  boxView: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  checkBoxStyle: {
    marginLeft: 10,
    padding: 0,
  },
  label: {
    paddingRight: 10,
    paddingLeft: 10,
  },
})

export default CheckBoxMenu
