import { StyleSheet, View, Text, TouchableOpacity } from 'react-native'
import React, { Component } from 'react'
import solveColors from './colors'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import { CREATE_NEWS, GET_NEWS, UPDATE_NEWS } from './../../services/queries'
import { ApolloConsumer, Query } from 'react-apollo'
import { TextField } from './../components'
import './../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { TextField as DatePicker } from '@material-ui/core'
import jwt from 'jsonwebtoken'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import { withCookies } from 'react-cookie'

class CreateAnnouncementModal extends Component {
  state = {
    visible: false,
    editorState: EditorState.createEmpty(),
    subject: '',
    date: new Date(),
    message: null,
    error: null,
    headline: false,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.visible !== prevState.visible) {
      return {
        visible: nextProps.visible,
        subject: '',
        date: new Date(),
        message: null,
        error: null,
        headline: false,
      }
    }
    return null
  }
  onEditorStateChange = editorState => {
    this.setState({
      editorState,
      message: convertToRaw(editorState.getCurrentContent()),
    })
  }
  updateNews = async client => {
    const { subject, date, message, headline } = this.state
    const { classroomId, close, refetch, newsId } = this.props

    if (!subject) {
      this.setState({ error: 'Please enter a subject' })
      return
    }
    if (!date) {
      this.setState({ error: 'Please select a date' })
      return
    }
    if (!message) {
      this.setState({ error: 'Please enter a message' })
      return
    }
    await client.mutate({
      mutation: UPDATE_NEWS,
      variables: {
        newsId,
        classroomId,
        headline,
        subject,
        message: JSON.stringify(message),
        announceDateTime: date,
        announcerId: jwt.decode(this.props.cookies.get('token')).id,
      },
    })
    this.setState({ error: null })
    await refetch()
    close()
  }

  createNews = async client => {
    const { headline, subject, date, message } = this.state
    const { classroomId, close, refetch } = this.props

    if (!subject) {
      this.setState({ error: 'Please enter a subject' })
      return
    }
    if (!date) {
      this.setState({ error: 'Please select a date' })
      return
    }
    if (!message) {
      this.setState({ error: 'Please enter a message' })
      return
    }
    await client.mutate({
      mutation: CREATE_NEWS,
      variables: {
        classroomId,
        headline,
        subject,
        message: JSON.stringify(message),
        announceDateTime: date,
        announcerId: jwt.decode(this.props.cookies.get('token')).id,
      },
    })
    this.setState({ error: null })
    await refetch()
    close()
  }

  render() {
    const {
      subject,
      date,
      error,
      message,
      editorState,
      visible,
      headline,
    } = this.state
    const { newsId, close } = this.props
    return (
      <Query
        query={GET_NEWS}
        variables={{ filter: { id: newsId } }}
        skip={newsId === null}
      >
        {({ data, loading, error: error0 }) => {
          if (loading) return 'Loading...'
          if (error0) return error0

          if (data && data.news && data.news.length > 0 && message === null) {
            const {
              message: message1,
              subject: subject1,
              announceDateTime,
              headline: headline1,
            } = data.news[0]
            this.setState({
              message: JSON.parse(message1),
              editorState: EditorState.createWithContent(
                convertFromRaw(JSON.parse(message1))
              ),
              subject: subject1,
              headline: headline1,
              date: new Date(announceDateTime),
            })
          }
          if (!visible) return <View />
          return (
            <ApolloConsumer>
              {client => (
                <View style={styles.backdrop}>
                  <View style={styles.results}>
                    <Text>{newsId}</Text>
                    <Text
                      style={{
                        textAlign: 'center',
                        marginBottom: 20,
                        fontSize: 18,
                      }}
                    >
                      {newsId ? 'Edit Announcement' : 'Create an Announcement'}
                    </Text>
                    {error !== null && (
                      <Text style={{ color: 'red' }}>{error}</Text>
                    )}

                    <View
                      style={{
                        flexDirection: 'row',
                        marginVertical: 7,
                        width: '100%',
                        alignItems: 'center',
                      }}
                    >
                      <TextField
                        style={{ width: 'calc(100% - 300px)', marginRight: 20 }}
                        name={'Subject'}
                        field={subject}
                        onChange={value => {
                          this.setState({ subject: value })
                        }}
                      />
                      <View style={{ alignItems: 'center', marginRight: 20 }}>
                        <Text
                          style={{
                            color: 'dimgrey',
                            fontSize: 12,
                            marginBottom: 7,
                          }}
                        >
                          Headline
                        </Text>
                        <TouchableOpacity
                          onPress={() => {
                            this.setState({ headline: !this.state.headline })
                          }}
                        >
                          {headline && (
                            <RadioButtonCheckedIcon
                              style={{ color: solveColors.blue3 }}
                            />
                          )}
                          {!headline && (
                            <RadioButtonUncheckedIcon
                              style={{ color: solveColors.blue3 }}
                            />
                          )}
                        </TouchableOpacity>
                      </View>
                      <DatePicker
                        id="date"
                        label="Announcement Time"
                        type="date"
                        style={{ marginTop: 5 }}
                        value={`${date.getFullYear()}-${
                          date.getMonth() + 1 < 10 ? '0' : ''
                        }${date.getMonth() + 1}-${
                          date.getDate() < 10 ? '0' : ''
                        }${date.getDate()}`}
                        onChange={event => {
                          const date = event.target.value
                          const year = parseInt(date.split('-')[0])
                          const month = parseInt(date.split('-')[1]) - 1
                          const day = parseInt(date.split('-')[2])
                          this.setState({
                            date: new Date(year, month, day),
                          })
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </View>

                    <Editor
                      editorState={editorState}
                      editorStyle={{
                        height: 300,
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: '#f1f1f1',
                      }}
                      wrapperClassName="demo-wrapper"
                      editorClassName="demo-editor"
                      onEditorStateChange={this.onEditorStateChange}
                    />

                    <View style={{ flexDirection: 'row', marginVertical: 10 }}>
                      <TouchableOpacity
                        style={[
                          styles.button,
                          {
                            backgroundColor: solveColors.blue5,
                            bottom: 0,
                            marginRight: 20,
                          },
                        ]}
                        onPress={async () => {
                          if (newsId) {
                            this.updateNews(client)
                          } else {
                            this.createNews(client)
                          }
                        }}
                      >
                        <Text style={{ color: 'white' }}>Submit</Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={[
                          styles.button,
                          { backgroundColor: solveColors.blue5, bottom: 0 },
                        ]}
                        onPress={() => {
                          close()
                        }}
                      >
                        <Text style={{ color: 'white' }}>Close</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              )}
            </ApolloConsumer>
          )
        }}
      </Query>
    )
  }
}

const styles = StyleSheet.create({
  results: {
    width: '70%',
    borderRadius: '10px',
    marginTop: '2vh',
    marginLeft: '10vw',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: 25,
  },
  backdrop: {
    backgroundColor: 'rgba(52, 52, 52, 0.2)',
    height: 'calc(100vh - 180px)',
    width: '100vw',
    position: 'absolute',
  },
  button: {
    width: 100,
    height: 40,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
})
export default withCookies(CreateAnnouncementModal)
