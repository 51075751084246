import { ApolloClient } from 'apollo-client'
import { split } from 'apollo-link'
import { createHttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { apiUrl } from './../../config'
import { Cookies } from 'react-cookie'

const cookie = new Cookies()

let token

const httpLink = createHttpLink({
  uri: apiUrl + '/graphql',
})

const getToken = () => {
  return sessionStorage.getItem('tempToken') || cookie.get('token')
}

const wsLink = new WebSocketLink({
  uri:
    apiUrl === 'https://ardent-api-next.ardentlabs.io'
      ? 'wss://ardent-api-next.ardentlabs.io/graphql'
      : 'wss://ardent-api.ardentlabs.io/graphql',
  options: {
    reconnect: true,
    connectionParams: () => {
      if (!token) token = getToken()
      return {
        reconnect: true,
        timeot: 30000,
        Authorization: token ? `Bearer ${token}` : '',
        authToken: token,
      }
    },
  },
})

const authLink = setContext((_, { headers }) => {
  const token = getToken()
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
      'cache-control': 'no-cache',
    },
  }
})

export const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  httpLink
)

export const client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache(),
  uri: apiUrl,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
})
