import React, { Component } from 'react'
import { withApollo } from 'react-apollo'
import { TouchableOpacity, View } from 'react-native-web'
import { withRouter } from 'react-router-dom'
import { GET_INVOICE_ITEMS } from '../../services/queries/payment_queries'
import Badge from '@material-ui/core/Badge'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined'
import { withCookies } from 'react-cookie'

class ShoppingCartButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      itemNum: 0,
    }
  }

  doGet = () => {
    this.props.client
      .query({
        query: GET_INVOICE_ITEMS,
        variables: {
          filter: {
            $or: [{ status: 'PENDING' }, { status: 'OPEN' }],
            familyAccountId: this.props.cookies.get('familyAccountId'),
          },
        },
        fetchPolicy: 'no-cache',
      })
      .then(({ data, refetch }) => {
        this.setState({ refetch: refetch })
        if (data.invoices.length === 0) {
          this.setState({ itemNum: 0 })
        } else {
          this.setState({
            itemNum: data.invoices[0].items.filter(
              i => i.itemType === 'CLASSROOM'
            ).length,
          })
        }
      })
  }

  componentDidUpdate() {
    if (this.props.refresh && this.props.refresh === true) {
      this.doGet()
      this.props.setRefresh(false)
    }
  }

  componentDidMount() {
    this.doGet()
  }

  render() {
    console.log(this.state.itemNum)
    return (
      <TouchableOpacity
        onPress={() => this.props.history.push('/cart')}
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        {/* <View
          style={{
            backgroundColor: 'white',
            borderRadius: '50%',
            width: '1.5em',
            height: '1.5em',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text style={{ color: 'red' }}>{this.state.itemNum}</Text>
        </View>
        <ShoppingCartIcon htmlColor="white" /> */}
        <View style={{ marginTop: '5.5px', marginRight: '10px' }}>
          {/* <View
            style={{
              backgroundColor: '#2D97DF',
              borderRadius: '50%',
              width: '1.25em',
              height: '1.2em',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
              left: '25%',
              top: 4,
            }}
          >
            <Text style={{ color: solveColors.yellow, fontWeight: 'bold' }}>
              {this.state.itemNum > 8 ? '9+' : this.state.itemNum}
            </Text>
          </View>
          <ShoppingCartOutlinedIcon
            htmlColor="white"
            style={{ height: '40px', width: '40px' }}
          /> */}
          <Badge
            max={9}
            color={'secondary'}
            badgeContent={this.state.itemNum > 8 ? '9+' : this.state.itemNum}
          >
            <ShoppingCartOutlinedIcon
              htmlColor="white"
              style={{ height: '40px', width: '40px' }}
            />
          </Badge>
        </View>
        {/* <Text style={{ color: 'white' }}>Shopping Cart</Text> */}
      </TouchableOpacity>
    )
  }
}

export default withRouter(withApollo(withCookies(ShoppingCartButton)))
