import gql from 'graphql-tag'

export const GET_EARNED_BADGES = gql`
  query getEarnedBadges($filter: Filters!) {
    earnedBadges(filter: $filter) {
      id
      tentative
      classroom {
        code
        title
      }
      student {
        id
        firstName
      }
      badge {
        title
        ranking
      }
    }
  }
`
