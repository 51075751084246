import { Text, View, FlatList, TouchableOpacity } from 'react-native'
import React, { useEffect, useState, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import {
  GET_RECOMMENDATIONS,
  GET_MORE_RECOMMENDATIONS,
  GET_STUDENT_GRADE,
} from '../../../services/queries'
import { withApollo } from 'react-apollo'
import styles from './styles'
import { getMonth, getYear } from './../../../utils/dateTime'
import SessionForm from './SessionForm'
import solveColors from './../../components/colors'

const AVAILBALE_SUBJECTS = [
  {
    title: 'Math',
    code: 'M',
  },
  {
    title: 'Physics',
    code: 'N',
  },
  {
    title: 'Biology',
    code: 'B',
  },
  {
    title: 'Coding',
    code: 'P',
  },
  {
    title: 'Chemistry',
    code: 'C',
  },
  {
    title: 'English',
    code: 'E',
  },
  {
    title: 'Speech and Debate',
    code: 'D',
  },
  // {
  //   title: 'Test Prep (SAT/ACT/AP)',
  //   code: 'Test Prep',
  // },
]

const RecommendationForm = props => {
  const { windowWidth } = props
  const [recommendations, setRecommendations] = useState([])
  const [moreRecommendations, setMoreRecommendations] = useState([])
  const [moreRecs, setMoreRecs] = useState(false)
  const [loading, setLoading] = useState(false)
  const [sortBy] = useState('DATE')

  const getMoreRecommendations = useCallback(
    async recommendations => {
      const client = props.client
      const studentId = props.studentId
      const { data: data1 } = await client.query({
        query: GET_STUDENT_GRADE,
        context: { headers: { 'cache-control': 'no-cache' } },
        variables: {
          studentId: studentId,
        },
      })
      if (!data1.student || !data1.student.grade) {
        setMoreRecommendations(['No More'])
        return
      }
      const grade = data1.student.grade
      let { data: data2 } = await client.query({
        query: GET_MORE_RECOMMENDATIONS,
        errorPolicy: 'ignore',
        variables: {
          courseFilter: {
            $and: [
              { startGrade: { $lte: grade } },
              { endGrade: { $gte: grade } },
            ],
          },
          classroomFilter: {
            $or: [{ status: 'OPENED' }, { status: 'PUBLISHED' }],
          },
          lessonsFilter: {
            lessonPlan: { lessonPlanType: 'INTRODUCTION' },
          },
        },
      })
      const recs = []
      for (const course of data2.courses) {
        //if (!subjects.includes(course.code[0])) continue
        for (const classroom of course.classrooms) {
          if (!recommendations.find(r => r.classroomId === classroom.id)) {
            const { imageUrl, description, startGrade, endGrade } = course
            const {
              id: classroomId,
              title,
              status,
              startTime,
              endTime,
              startDate,
              endDate,
              instructors,
              meets,
              tuitionCents,
              earlyBirdtuitionCents,
              session,
              quarter,
              videoId,
              centerLocation,
            } = classroom

            if (new Date(endDate).getTime() <= new Date().getTime()) continue

            const instructorName =
              instructors.length > 0 && instructors[0].employee
                ? `${instructors[0].employee.firstName} ${instructors[0].employee.lastName}`
                : 'N/A'
            const classroomDays = meets
            recs.push({
              classroomId,
              title: title.split(':')[1].trim(),
              imageUrl,
              description,
              status,
              startGrade,
              endGrade,
              tuitionCents,
              earlyBirdtuitionCents,
              startDate,
              endDate,
              startTime,
              endTime,
              instructorName,
              classroomDays,
              session,
              //hasPreview: lessons.length > 0,
              hasPreview: false,
              quarter,
              videoId,
              centerLocation,
            })
          }
        }
      }
      if (recs.length === 0) {
        setMoreRecommendations(['No More'])
      } else {
        setMoreRecommendations(recs)
      }
      return recs
    },
    [props.studentId, props.client]
  )

  const getRecommendations = useCallback(async () => {
    const recs = []
    setLoading(true)

    //don't destructure props! it will give a warning!
    const client = props.client
    const studentId = props.studentId
    const { data } = await client.query({
      query: GET_RECOMMENDATIONS,
      variables: {
        studentId: studentId,
        // studentId: 'd756d63b-c60d-495f-968b-dccdcd8643ed',
        currentQuarter: props.currentQuarter,
        nextQuarter: props.nextQuarter,
      },
    })

    if (data && data.getNextClassrooms) {
      if (!data.getNextClassrooms.success) {
        setRecommendations(['Error'])
        return
      }

      for (const classroom of data.getNextClassrooms.nextClassrooms) {
        const { imageUrl, description, startGrade, endGrade } = classroom.course
        const {
          id: classroomId,
          code,
          title,
          status,
          startTime,
          endTime,
          startDate,
          endDate,
          centerLocation,
          instructors,
          meets,
          tuitionCents,
          earlyBirdtuitionCents,
          session,
          quarter,
          videoId,
        } = classroom
        if (new Date(endDate).getTime() <= new Date().getTime()) continue

        const instructorName =
          instructors.length > 0 ? instructors[0].displayName : 'Ardent Staff'
        const classroomDays = meets
        const splitTitle = title.split(':')
        recs.push({
          classroomId,
          title: splitTitle.length > 1 ? splitTitle[1].trim() : splitTitle[0],
          imageUrl,
          description,
          status,
          code,
          startGrade,
          endGrade,
          tuitionCents,
          earlyBirdtuitionCents,
          startDate,
          endDate,
          startTime,
          endTime,
          centerLocation,
          instructorName,
          classroomDays,
          session,
          //hasPreview: lessons.length > 0,
          hasPreview: false,
          quarter,
          videoId,
        })
      }
    }
    if (recs.length > 0) {
      recs.sort((a, b) => a.code.localeCompare(b.code))
      setRecommendations(recs)
    } else {
      setRecommendations(['None'])
    }
    getMoreRecommendations(recs)
    setLoading(false)
    return recs
  }, [
    props.studentId,
    props.client,
    props.currentQuarter,
    props.nextQuarter,
    getMoreRecommendations,
  ])

  const sortByDate = () => {
    const allRecs = moreRecs
      ? recommendations.concat(moreRecommendations)
      : recommendations
    const sortedRecs = []

    if (sortBy === 'DATE') {
      const sortedDateRecs = allRecs
        .concat()
        .sort(
          (r1, r2) =>
            new Date(r1.startDate).getTime() - new Date(r2.startDate).getTime()
        )
      for (let m = 0; m < 12; m++) {
        const fileteredRecs = sortedDateRecs.filter(
          r => new Date(r.startDate).getMonth() === m
        )
        if (fileteredRecs.length > 0) sortedRecs.push(fileteredRecs)
      }
    }
    return sortedRecs
  }

  const handleShowMoreRecs = () => setMoreRecs(true)

  const renderRecommendationTexts = useCallback(() => {
    if (loading) {
      return (
        <Text style={{ marginVertical: 10 }}>Getting Recommendations...</Text>
      )
    } else {
      if (
        recommendations.length > 0 &&
        recommendations[0] === 'None' &&
        moreRecommendations.length > 0 &&
        moreRecommendations[0] === 'No More'
      ) {
        return (
          <Text style={{ marginVertical: 10 }}>
            No recommendations are available for you
          </Text>
        )
      } else if (
        moreRecommendations.length > 0 &&
        moreRecommendations[0] === 'No More'
      ) {
        return (
          <Text style={{ marginVertical: 10 }}>
            No addition recommendations are avaiable for you.
          </Text>
        )
      }
    }
  }, [loading, moreRecommendations, recommendations])

  // if (sortBy === 'SESSION') {
  //   for (const quarter of ['Winter', 'Spring', 'Summer', 'Fall']) {
  //     const quarterRecs = allRecs.filter(r => r.quarter === quarter)
  //     const session1 = quarterRecs.filter(r => r.session === '1')
  //     if (session1.length > 0) sortedRecs.push(session1)
  //     const session2 = quarterRecs.filter(r => r.session === '2')
  //     if (session2.length > 0) sortedRecs.push(session2)
  //   }

  //   const other = allRecs.filter(r => r.session !== '2' && r.session !== '1')
  //   if (other.length > 0) sortedRecs.push(other)
  // }
  // if (sortBy === 'SUBJECT') {
  //   for (const subject of availableSubjects) {
  //     const fileteredRecs = allRecs.filter(r => r.title[0] === subject.code)
  //     if (fileteredRecs.length > 0) {
  //       sortedRecs.push(fileteredRecs)
  //     }
  //   }
  // }

  useEffect(() => {
    const innerQuery = async () => {
      const recs = await getRecommendations()
      //moreRecs is a switch
      //if moreRecs is true, we display our more recs
      //if recommendations is empty, we set more recs to true as default
      if (recs.length === 0) {
        setMoreRecs(true)
      } else {
        setMoreRecs(false)
      }
    }
    innerQuery()
  }, [props.studentId, getRecommendations])

  return (
    <View style={{ alignItems: 'center' }}>
      {/* <View style={styles.sortBar}>
        <Text style={{ fontSize: 16, marginRight: 10 }}>Sort By:</Text>
        <TouchableOpacity
          style={[
            {
              backgroundColor:
                sortBy === 'SESSION' ? solveColors.blue1 : solveColors.blue5,
            },
            styles.sortButton,
          ]}
          onPress={() => setSortBy('SESSION')}
        >
          <Text
            style={{
              color: sortBy === 'SESSION' ? 'white' : solveColors.grey2,
            }}
          >
            Session
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[
            {
              backgroundColor:
                sortBy === 'DATE' ? solveColors.blue1 : solveColors.blue5,
            },
            styles.sortButton,
          ]}
          onPress={() => setSortBy('DATE')}
        >
          <Text
            style={{
              color: sortBy === 'DATE' ? 'white' : solveColors.grey2,
            }}
          >
            Start Date
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[
            {
              backgroundColor:
                sortBy === 'SUBJECT' ? solveColors.blue1 : solveColors.blue5,
            },
            styles.sortButton,
          ]}
          onPress={() => setSortBy('SUBJECT')}
        >
          <Text
            style={{
              color: sortBy === 'SUBJECT' ? 'white' : solveColors.grey2,
            }}
          >
            Subject
          </Text>
        </TouchableOpacity>
      </View> */}
      {!loading && (
        <FlatList
          keyExtractor={(item, index) => index}
          data={sortByDate()}
          numColumns={1}
          //scrollEventThrottle={16}
          renderItem={({ item, index }) => {
            return (
              <View style={{ paddingVertical: 20 }}>
                {sortBy === 'SESSION' && (
                  <View
                    style={[
                      {
                        background: `linear-gradient(to right, #07c1eb 50%, ${solveColors.blue2} 90%)`,
                      },
                      styles.sortButton,
                    ]}
                  >
                    <Text style={{ fontSize: 20, color: 'white' }}>
                      {item[0].session !== '2' && item[0].session !== '1'
                        ? 'Other: '
                        : `${item[0].quarter} Session ${item[0].session}`}
                    </Text>
                  </View>
                )}
                {sortBy === 'SUBJECT' && (
                  <View
                    style={[
                      {
                        background: `linear-gradient(to right, #07c1eb 50%, ${solveColors.blue2} 90%)`,
                      },
                      styles.sortButton,
                    ]}
                  >
                    <Text style={{ fontSize: 20, color: 'white' }}>
                      {`${
                        AVAILBALE_SUBJECTS.find(s => s.code === item[0].title)
                          .title
                      }`}
                    </Text>
                  </View>
                )}
                {sortBy === 'DATE' && (
                  <View
                    style={[
                      {
                        background: `linear-gradient(to right, #07c1eb 50%, ${solveColors.blue2} 90%)`,
                      },
                      styles.sortButton,
                    ]}
                  >
                    <Text style={{ fontSize: 20, color: 'white' }}>
                      {`${getMonth(item[0].startDate)} ${getYear(
                        item[0].startDate
                      )}`}
                    </Text>
                  </View>
                )}
                <SessionForm
                  windowWidth={windowWidth}
                  session={index + 1}
                  recommendations={item}
                  openVideo={videoId => {
                    props.openVid(videoId)
                  }}
                />
              </View>
            )
          }}
        />
      )}
      {renderRecommendationTexts()}
      {!loading && !moreRecs && (
        <TouchableOpacity
          style={[
            styles.moreRecsButton,
            {
              background: `linear-gradient(to right, #07c1eb 50%, ${solveColors.blue2} 90%)`,
            },
          ]}
          onPress={handleShowMoreRecs}
        >
          <Text style={styles.moreRecsText}>Find More Recommendations...</Text>
        </TouchableOpacity>
      )}
    </View>
  )
}

export default withRouter(withApollo(RecommendationForm))
