import React, { useMemo } from 'react'
import { Text } from 'react-native-web'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import solveColors from './../../components/colors'

export const ReferralTable = React.memo(props => {
  const classes = useStyles()
  const { referrals, page, rows } = props

  const referralColumns = useMemo(
    () => [
      { id: 'refereeFirstName', label: 'First Name' },
      { id: 'refereeLastName', label: 'Last Name' },
      { id: 'status', label: 'Status', align: 'right' },
    ],
    []
  )

  return (
    <Box className={classes.root} elevation={0}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow hover>
              {referralColumns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    fontSize: 18,
                    fontWeight: 'bold',
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {referrals.length ? (
              referrals
                .slice(page * rows, page * rows + rows)
                .map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {referralColumns.map(column => {
                        const value = row[column.id]
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })
            ) : (
              <div>
                <Text
                  style={{
                    fontSize: 16,
                    color: solveColors.grey2,
                  }}
                >
                  No Existing Referrals
                </Text>
              </div>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
})

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: 'auto',
    alignSelf: 'center',
    backgroundColor: 'white',
    marginRight: '1px',
    borderTopWidth: '1px',
    borderTopColor: solveColors.grey1,
  },
  container: {
    width: '100%',
    height: '100%',
  },
  tablerow: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  tablecell: {
    display: 'flex',
    flex: 1,
  },
})

export default ReferralTable
