import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import {
  GET_SINGLE,
  GET_ENROLLMENTS,
} from './../../../services/queries/catalog_queries'
import {
  Text,
  View,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
} from 'react-native-web'
import CombinedLoginPage from '../CombinedLogin/CombinedLoginPage'
import { Query, withApollo } from 'react-apollo'
import LoadingPage from './../Loading/LoadingPage'
import colors from './../../components/colors'
import { formatDate, formatTime } from './../../../utils/dateTime'
import { DropDownMenu, MessageModal } from '../../components/'
import logo1 from './../../images/logo1.png'
import logo2 from './../../images/logo2.webp'
import { ApolloConsumer } from 'react-apollo'
import jwt from 'jsonwebtoken'
import { Button } from '@material-ui/core'
import { GET_FAMILY_STUDENTS } from '../../../services/queries'
import {
  CREATE_ITEM,
  CREATE_PENDING_INVOICE,
  GET_INVOICE,
} from '../../../services/queries/payment_queries'
import { EARLY_BIRD } from '../../../config'
import { withCookies } from 'react-cookie'
import moment from 'moment-timezone'
import { gradeLevel } from './../../../utils/gradeLevel'
import { solveColors } from '../../components/colors'

const TEMP_RESPONSIBLE_EMPLOYEE = 'b001d0de-a579-4535-ab3e-ac7ebe165484'

class CoursePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openModal: false,
      username: '',
      hoverTop: false,
      hoverBottom: false,
      message: '',
      students: [],
      selectedStudent: null,
      loading: false,
      cartRefresh: false,
      errorMsg: '',
      loginModal: {
        open: false,
        default: this.props.cookies.get('token')
          ? jwt.decode(this.props.cookies.get('token')).userType === 'Student'
            ? 'parent'
            : null
          : 'registerFamily',
      },
    }
  }

  componentDidMount() {
    if (this.props.cookies.get('token')) {
      this.setState({
        loginSuccess: true,
        username: this.props.cookies.get('firstName'),
      })
    }
    if (this.props.cookies.get('family') !== '1') {
      return
    }
    const token = this.props.cookies.get('token')
    const famId = this.props.cookies.get('familyAccountId')
    if (!token || !famId || !jwt.decode(token).userType === 'FamilyMember') {
      return
    }
    const classId = this.props.location.search.split('=')[1]
    this.props.client
      .query({
        query: GET_FAMILY_STUDENTS,
        variables: { id: famId },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) =>
        this.setState({
          students: data.familyAccount.students
            .filter(student => {
              for (let enrollment of student.enrollments) {
                if (enrollment.classroom.id === classId) {
                  return false
                }
              }
              return true
            })
            .map((student, ind) => {
              const name = `${student.firstName} ${student.lastName}`
              return {
                key: name,
                id: student.id,
                value: ind,
              }
            }),
        })
      )
  }

  handleOpenLoginModal = () => {
    this.setState({
      loginModal: { open: true, default: this.state.loginModal.default },
    })
  }

  handleCloseLoginModal = () => {
    this.setState({
      loginModal: { open: false, default: this.state.loginModal.default },
    })
  }

  openMessageModal = msg => {
    this.setState({ message: msg })
  }

  closeMessageModal = () => {
    this.setState({ message: '' })
    if (this.props.cookies.get('family') === '1') {
      this.props.history.push('/dashboard')
    } else {
      this.props.history.goBack()
    }
  }

  formatSchedule = classroom => {
    let week = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']
    if (classroom.meetsEveryday) {
      return week.slice(0, 5)
    }
    let conditions = [
      classroom.meetsOnMonday,
      classroom.meetsOnTuesday,
      classroom.meetsOnWednesday,
      classroom.meetsOnThursday,
      classroom.meetsOnFriday,
      classroom.meetsOnSaturday,
      classroom.meetsOnSunday,
    ]
    let weekFiltered = week.filter((day, index) => conditions[index])
    return weekFiltered
  }

  closeLoginPopupOnSuccess = async client => {
    this.setState({
      openModal: false,
      username: this.props.cookies.get('firstName'),
    })
    await this.addToCart(client)
  }

  addToCart = async (
    client,
    classroomId,
    quantity,
    unitPrice,
    earlyBirdPrice,
    chosenClassInfo
  ) => {
    const { selectedStudent } = this.state
    const token = this.props.cookies.get('token')
    if (!token || jwt.decode(token).userType !== 'FamilyMember') {
      this.setState({ errorMsg: 'You must login through a family account' })
    } else {
      if (!selectedStudent) {
        this.setState({ errorMsg: 'You must select a student' })
        return
      }
      this.setState({ loading: true })
      const famId = this.props.cookies.get('familyAccountId')
      const { data } = await client.query({
        query: GET_INVOICE,
        variables: {
          filter: {
            familyAccountId: famId,
            $or: [{ status: 'PENDING' }, { status: 'OPEN' }],
          },
        },
        fetchPolicy: 'no-cache',
      })

      let invoiceId
      if (data.invoices.length === 1) {
        invoiceId = data.invoices[0].id
        for (let item of data.invoices[0].items) {
          if (
            item.itemType === 'CLASSROOM' &&
            item.student.id === selectedStudent.id &&
            item.classroom.id === classroomId
          ) {
            this.setState({
              loading: false,
              errorMsg: 'Already in cart',
            })
            return
          }
        }
      } else {
        const { data: invoiceData } = await client.mutate({
          mutation: CREATE_PENDING_INVOICE,
          variables: {
            input: {
              familyAccountId: famId,
              status: 'PENDING',
              responsibleEmployeeId: TEMP_RESPONSIBLE_EMPLOYEE,
            },
          },
        })
        invoiceId = invoiceData.createInvoice.id
      }
      const price = (EARLY_BIRD ? earlyBirdPrice : unitPrice) * quantity
      await client.mutate({
        mutation: CREATE_ITEM,
        variables: {
          input: {
            invoiceId,
            studentId: selectedStudent.id,
            classroomId,
            isClassroom: true,
            itemType: 'CLASSROOM',
            unitPrice,
            quantity,
            earlyBird: EARLY_BIRD,
            price,
          },
        },
      })
      this.setState({ loading: false, cartRefresh: true, errorMsg: '' })
      this.openMessageModal('Shopping cart updated!')
    }
  }

  checkEnrollments = async (client, classroomId, studentId) => {
    const { data } = await client.query({
      query: GET_ENROLLMENTS,
      variables: { studentId },
    })
    const { enrollments } = data.student
    if (enrollments.find(e => e.classroom.id === classroomId)) {
      return true
    }
    return false
  }

  getTimezoneAbbr = (timezone, timestamp) => {
    const zoneObj = moment.tz.zone(timezone)
    return zoneObj.abbr(new Date(timestamp).getTime())
  }

  render() {
    const { students, loading } = this.state
    const timezone = moment.tz.guess()

    return (
      <ApolloConsumer>
        {client => (
          <ScrollView style={{ backgroundColor: 'white' }}>
            {/* <ShoppingCartPopup visible={true} /> */}
            <MessageModal
              visible={this.state.message !== ''}
              message={this.state.message}
              onClose={this.closeMessageModal}
            />
            <View>
              <Query
                query={GET_SINGLE}
                variables={{
                  id: this.props.location.search.split('=')[1],
                }}
              >
                {({ data, loading: queryLoading, error }) => {
                  if (queryLoading) {
                    return <LoadingPage />
                  }
                  if (error) {
                    return `${error}`
                  }

                  let { classroom } = data

                  if (!classroom) {
                    return 'classroom does not exist'
                  }

                  const {
                    id,
                    teachingStyle,
                    year,
                    quarter,
                    title,
                    tuitionCents,
                    earlyBirdtuitionCents,
                    course: {
                      category,
                      imageUrl,
                      description,
                      lessonCount,
                      startGrade,
                      endGrade,
                      code,
                    },
                  } = classroom
                  const grades = gradeLevel(startGrade, endGrade)
                  const tutored = quarter === 'Tutoring'
                  const selfPaced = quarter === 'OnDemand'
                  const pos = title.indexOf('-')
                  const newTitle = title.slice(pos + 1)
                  //const newTitle = classroomTitle.slice(0, 5) + classroomTitle.slice(7)
                  return (
                    <View>
                      <TouchableOpacity
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                        onPress={() => {
                          this.props.history.push('/')
                        }}
                      >
                        <img
                          src={logo1}
                          alt={'Ardent Logo'}
                          style={{
                            height: 'auto',
                            width: '10vh',
                            margin: 5,
                          }}
                        />
                        <img
                          src={logo2}
                          alt={'Ardent Word Logo'}
                          style={{
                            margin: 5,
                          }}
                        />
                      </TouchableOpacity>
                      <Button
                        color="primary"
                        style={{ marginLeft: '1vw', alignSelf: 'flex-start' }}
                        onClick={() => this.props.history.push('/catalog')}
                      >
                        {'< Catalog'}
                      </Button>

                      <View style={styles.contents}>
                        {!this.props.cookies.get('familyAccountId') && (
                          <TouchableOpacity onPress={this.handleOpenLoginModal}>
                            <Text style={styles.defaultText}>
                              You are not logged into a family account, click{' '}
                              <Text style={styles.emphasisText}>HERE</Text> to
                              login
                            </Text>
                          </TouchableOpacity>
                        )}
                        <Text style={{ alignSelf: 'flex-end', padding: 10 }}>
                          {this.state.username
                            ? 'Welcome, ' + this.state.username
                            : ''}
                        </Text>
                        <View style={styles.imgHolder}>
                          <img
                            style={{
                              width: 'calc(150px * 1.33)',
                              height: '150px',
                            }}
                            alt={'Class Logo'}
                            src={imageUrl}
                          />
                          <View
                            style={{
                              flexGrow: 1,
                              margin: '1em',
                              flexShrink: 1,
                            }}
                          >
                            <Text style={styles.courseTitle}>{newTitle}</Text>
                            <View style={{ display: 'flex' }}>
                              <Text style={styles.detailsHeader}>Details</Text>
                              <View
                                style={{
                                  flexDirection: 'row',
                                  flexWrap: 'wrap',
                                }}
                              >
                                <View
                                  style={{ marginRight: '20px', flexShrink: 1 }}
                                >
                                  {!(tutored || selfPaced) && (
                                    <Text
                                      style={styles.infoText}
                                    >{`When: ${formatDate(
                                      classroom.startDate
                                    )} - ${formatDate(
                                      classroom.endDate
                                    )}`}</Text>
                                  )}
                                  <Text
                                    style={styles.infoText}
                                  >{`Course Code: ${code}`}</Text>
                                  {!(tutored || selfPaced) && (
                                    <Text
                                      style={styles.infoText}
                                    >{`Semester: ${classroom.quarter}`}</Text>
                                  )}
                                  <Text
                                    style={styles.infoText}
                                  >{`Grades: ${grades}`}</Text>
                                </View>
                                <View style={{ flexShrink: 1 }}>
                                  {!(tutored || selfPaced) && (
                                    <Text
                                      style={styles.infoText}
                                    >{`Time Zone: ${timezone} (${this.getTimezoneAbbr(
                                      timezone,
                                      classroom.startDate
                                    )})`}</Text>
                                  )}
                                  <Text
                                    style={styles.infoText}
                                  >{`Instruction Method: ${teachingStyle}`}</Text>
                                  {!tutored && (
                                    <Text
                                      style={styles.infoText}
                                    >{`Lessons: ${lessonCount}`}</Text>
                                  )}
                                  <Text
                                    style={styles.infoText}
                                  >{`Category: ${category.name}`}</Text>
                                </View>
                              </View>
                            </View>
                          </View>
                          <View style={styles.buyBtnContainter}>
                            <DropDownMenu
                              name={'Select a student'}
                              options={[{ key: '', value: '' }, ...students]}
                              onChange={v =>
                                this.setState({ selectedStudent: students[v] })
                              }
                            />
                            <Text
                              style={{
                                paddingBottom: '10px',
                                fontWeight: 'bold',
                                fontSize: 20,
                                textDecorationLine:
                                  EARLY_BIRD &&
                                  tuitionCents !== earlyBirdtuitionCents
                                    ? 'line-through'
                                    : 'none',
                              }}
                            >{`$${
                              tuitionCents ? tuitionCents / 100 : 'N/A'
                            }`}</Text>
                            {EARLY_BIRD &&
                              tuitionCents !== earlyBirdtuitionCents && (
                                <Text
                                  style={{
                                    paddingBottom: '10px',
                                    fontWeight: 'bold',
                                    fontSize: '20px',
                                    color: 'red',
                                  }}
                                >{`$${
                                  tuitionCents
                                    ? earlyBirdtuitionCents / 100
                                    : 'N/A'
                                }`}</Text>
                              )}
                            <TouchableOpacity
                              style={styles.buyBtn}
                              onPress={() => {
                                this.addToCart(
                                  client,
                                  id,
                                  1,
                                  tuitionCents / 100,
                                  earlyBirdtuitionCents / 100,
                                  { year, quarter }
                                )
                              }}
                              disabled={loading}
                            >
                              <Text style={styles.buyTxt}>
                                {loading ? 'loading...' : 'Add to cart'}
                              </Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                        <View style={styles.details}>
                          <View style={styles.topRow}>
                            <View
                              style={[styles.description, { flexShrink: 1 }]}
                            >
                              <Text style={styles.detailsHeader}>About:</Text>
                              <Text style={styles.infoText}>
                                {description
                                  ? description
                                  : 'No Description Avaliable'}
                              </Text>
                            </View>
                            <View
                              style={[styles.description, { flexShrink: 1 }]}
                            >
                              {!(tutored || selfPaced) && (
                                <Text style={styles.detailsHeader}>
                                  Schedule:
                                </Text>
                              )}
                              {!(tutored || selfPaced) &&
                                this.formatSchedule(
                                  classroom
                                ).map((dayOfWeek, i) => (
                                  <Text
                                    key={i}
                                    style={styles.infoText}
                                  >{`${dayOfWeek} ${formatTime(
                                    classroom.startTime
                                  )} - ${formatTime(
                                    classroom.endTime
                                  )} ${this.getTimezoneAbbr(
                                    timezone,
                                    classroom.startDate
                                  )} (${teachingStyle.toLowerCase()})`}</Text>
                                ))}
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  )
                }}
              </Query>
            </View>
            <CombinedLoginPage
              visible={this.state.loginModal.open}
              close={this.handleCloseLoginModal}
              redirectTo={
                this.props.location.pathname + this.props.location.search
              }
              defaultMode={this.state.loginModal.default}
              fromCheckout
            />
          </ScrollView>
        )}
      </ApolloConsumer>
    )
  }
}

const styles = StyleSheet.create({
  contents: {
    display: 'flex',
    padding: '20px',
    alignItems: 'center',
  },
  imgHolder: {
    flexDirection: 'row',
    width: '100%',
    backgroundColor: 'white',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: 'Gainsboro',
    borderRadius: '5px',
  },
  defaultText: {
    fontWeight: 'bold',
    fontSize: '28px',
    //color: solveColors.grey4,
  },
  emphasisText: {
    fontWeight: 'bold',
    fontSize: '28px',
    color: solveColors.red1,
  },
  courseTitle: {
    fontWeight: 'bold',

    fontSize: 20,
  },
  buyBtnContainter: {
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '30px',
    paddingRight: '30px',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'Gainsboro',
    borderRadius: '5px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buyBtn: {
    padding: '10px',
    paddingLeft: '20px',
    paddingRight: '20px',
    color: 'white',
    backgroundColor: colors.turquoise,
  },
  details: {
    width: '100%',
    flexDirection: 'column',
    flexWrap: 'wrap',
    paddingLeft: '30px',
    paddingRight: '30px',
    marginTop: '15px',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  detailsHeader: {
    fontWeight: 'bold',
    color: colors.turquoise,
    padding: '5px',
  },
  description: {
    flexDirection: 'column',
    backgroundColor: 'white',
  },
  secondRow: {
    minWidth: '100px',
    backgroundColor: 'white',
  },
  infoText: {
    marginLeft: '20px',
    marginBottom: '5px',
    flexShrink: 1,
  },

  buyTxt: {
    color: 'white',
    fontWeight: 'bold',
    fontFamily:
      'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace',
  },
  return: {
    backgroundColor: colors.turquoise,
    paddingTop: '1.5vh',
    paddingBottom: '1.5vh',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  returnText: {
    color: 'white',
    fontWeight: 'bold',
    fontFamily:
      'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace',
  },
  topRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
})

export default withApollo(withRouter(withCookies(CoursePage)))
