import gql from 'graphql-tag'

export const GET_VIDEO = gql`
  query GetVideo($id: ID!) {
    assignment(id: $id) {
      problemSet {
        videoId
        title
      }
    }
  }
`
