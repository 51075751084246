import React, { useState } from 'react'
import { ScrollView } from 'react-native'
import { Tabs } from '../../components/'
import ProfileForm from './ProfileForm'
import EditProfileForm from './EditProfileForm'
import jwt from 'jsonwebtoken'
import { Query } from 'react-apollo'
import { GET_ACCOUNT_INFO } from '../../../services/queries'
import LoadingPage from '../Loading/LoadingPage'
import dummy_profile from '../../images/dummy_profile.png'
import InstructorProfilePage from './InstructorProfilePage'
import FamilyProfilePage from './FamilyProfilePage'
import { useCookies } from 'react-cookie'

const tabInfo = [
  { label: 'ACCOUNT', filter: () => handlePress('Account Info') },
  // { label: "BANK", filter: () => handlePress("Bank") },
  // { label: "LEADERBOARD", filter: () => handlePress("Leaderboard") }
]

const handlePress = filter => {
  console.log(filter)
}

export const ProfilePage = props => {
  //needed for query
  const [cookies] = useCookies([])
  const [editable, setEditable] = useState(
    //Easier to handle this using sessions
    sessionStorage.getItem('showStudentEdit') === 'true'
  )
  const [school, setSchool] = useState(null)
  const [grade, setGrade] = useState(null)
  const [familyAccount, setfamilyAccount] = useState(null)
  if (cookies['instructor'] === '1' || cookies['admin'] === '1') {
    return <InstructorProfilePage changeTitles={props.changeTitles} />
  }
  if (cookies['family'] === '1') {
    return <FamilyProfilePage changeTitles={props.changeTitles} />
  }
  const id = jwt.decode(cookies['token']).id

  const firstName = cookies['firstName']
  const lastName = cookies['lastName']
  props.changeTitles(`${firstName} ${lastName}`, 'Profile')

  return (
    <Query
      query={GET_ACCOUNT_INFO}
      variables={{ id: id }}
      context={{ headers: { 'cache-control': 'no-cache' } }}
    >
      {({ data, loading, refetch }) => {
        if (loading) return <LoadingPage />
        const {
          username,
          firstName,
          lastName,
          imageUrl,
          newImageUrl,
          birthdate,
          gradeUpdated,
        } = data.student
        setSchool(data.student.school)
        setGrade(data.student.grade)
        setfamilyAccount(data.student.familyAccount)
        const hasLocation = !!data.student.location
        const url = newImageUrl || imageUrl || dummy_profile
        if (data && school && grade && familyAccount) {
          return (
            <React.Fragment>
              <Tabs tabInfo={tabInfo} handleFunction={handlePress} />
              <ScrollView>
                {!editable && (
                  <ProfileForm
                    studentId={id}
                    setEditable={setEditable}
                    school={school}
                    grade={grade}
                    username={username}
                    firstName={firstName}
                    lastName={lastName}
                    imageUrl={url}
                  />
                )}
                {editable && (
                  <EditProfileForm
                    studentId={id}
                    setEditable={setEditable}
                    school={school}
                    refetch={refetch}
                    grade={grade}
                    gradeUpdated={gradeUpdated}
                    username={username}
                    firstName={firstName}
                    lastName={lastName}
                    imageUrl={url}
                    birthdate={birthdate}
                    familyAccount={familyAccount}
                    hasLocation={hasLocation}
                  />
                )}
              </ScrollView>
            </React.Fragment>
          )
        } else {
          return <></>
        }
      }}
    </Query>
  )
}

export default ProfilePage
