import React from 'react'
import { Text } from 'react-native-web'
import solveColors from './../../components/colors'
import { withApollo } from 'react-apollo'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'

export const ReferralForm = props => {
  const { referral, setReferral, error, handleSendReferral } = props

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      item
      style={{
        padding: '10px',
      }}
    >
      {error && <Text>Invalid email or phonenumber</Text>}
      <Text
        style={{
          marginRight: 'auto',
          marginLeft: '10px',
          marginBottom: '15px',
          fontSize: 20,
          color: solveColors.navy,
        }}
      >
        Personal Information
      </Text>
      <Grid
        container
        direction="row"
        alignItems="center"
        item
        style={{
          marginBottom: '10px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <TextField
          value={referral.refereeFirstName}
          label={'Referee First Name'}
          onChange={e => {
            referral.refereeFirstName = e.target.value
            setReferral({ ...referral })
          }}
          InputLabelProps={{
            shrink: true,
          }}
          variant={'outlined'}
          style={{
            width: '44%',
            marginRight: '1%',
            alignSelf: 'center',
          }}
        />
        <TextField
          value={referral.refereeLastName}
          label={'Referee Last Name'}
          onChange={e => {
            referral.refereeLastName = e.target.value
            setReferral({ ...referral })
          }}
          variant={'outlined'}
          style={{ width: '44%', marginLeft: '1%', alignSelf: 'center' }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Divider
        orientation={'horizontal'}
        style={{
          width: '98%',
          margin: '10px',
        }}
      />
      <Text
        style={{
          marginRight: 'auto',
          marginLeft: '10px',
          marginBottom: '15px',
          fontSize: 20,
          color: solveColors.navy,
        }}
      >
        Contact Information
      </Text>
      <TextField
        value={referral.email}
        label={'Email Address'}
        onChange={e => {
          referral.email = e.target.value
          setReferral({ ...referral })
        }}
        variant={'outlined'}
        style={{ marginBottom: '15px', width: '90%', alignSelf: 'center' }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        value={referral.phone}
        label={'Phone Number (Optional)'}
        onChange={e => {
          referral.phone = e.target.value
          setReferral({ ...referral })
        }}
        variant={'outlined'}
        style={{ marginBottom: '15px', width: '90%', alignSelf: 'center' }}
        InputLabelProps={{
          shrink: true,
        }}
        type={'number'}
      />
      <Button
        style={{
          minWidth: '100px',
          minHeight: '26px',
          backgroundColor: solveColors.blue5,
          color: 'white',
          marginLeft: 'auto',
          marginRight: '5%',
          marginBottom: '10px',
        }}
        variant={'contained'}
        size={'large'}
        onClick={handleSendReferral}
      >
        Send
      </Button>
    </Grid>
  )
}

export default withApollo(ReferralForm)
